import { angularAMD } from "@pebblepad/amd";
import template from "./manage-attachments.html";
import "../multiLanguageService/multiLanguageService";
import "../assetSelector/assetSelector.service";
import "../autoFocus/autoFocus.directive";
import "../user/user.service";
import "../react2angular/assetIcon";
import { listIcon, gridIcon } from "@pjs/core-ui";

var manageAttachmentsDefinition = {
    bindings: {
        attachments: "<",
        onChange: "&"
    },
    template: template,
    controller: ManageAttachments
};

ManageAttachments.$inject = ["$rootScope", "multiLanguageService", "assetSelector", "User"];
function ManageAttachments($rootScope, multiLanguageService, assetSelector, User) {
    this.eventHandler = $rootScope.$on("updateItemsInSelector", this._addUploadedAttachments.bind(this));
    this.$rootScope = $rootScope;

    this.multiLanguageService = multiLanguageService;
    this.attachments = this.attachments || [];
    this.attachmentItems = null;
    this._assetSelector = assetSelector;
    this.focusAddAttachments = false;
    this.User = User;
}

ManageAttachments.prototype.$onInit = function () {
    this.attachmentItems = [...this.attachments];
};

ManageAttachments.prototype.$onChanges = function () {
    this.focusAddAttachments = false;
};

ManageAttachments.prototype.select = function () {
    if (this.User.isExternal()) {
        this.$rootScope.$broadcast("openRightHandSidePanel", {
            panel: "file-upload",
            showTagger: false,
            showDescription: false,
            panelSettings: {}
        });
    } else {
        this._selectAssets(this.attachmentItems).then(this._handleAttachmentChange.bind(this));
    }
};

ManageAttachments.prototype._handleAttachmentChange = function (newAttachments) {
    this.focusAddAttachments = true;

    const diff = this._getAttachmentsDiff(this.attachmentItems, newAttachments);
    if (diff.added.length === 0 && diff.removed.length === 0) {
        return;
    }

    if (this.User.isExternal() && diff.added.length > 0) {
        this.attachmentItems = this.attachmentItems.concat(diff.added);
    } else {
        this.attachmentItems = newAttachments;
    }

    this.onChange({
        attachments: this.attachmentItems,
        added: diff.added,
        removed: diff.removed
    });
};

ManageAttachments.prototype.remove = function (attachment) {
    const item = this.attachmentItems.filter(function (item) {
        return item.AssetId === attachment.AssetId;
    })[0];

    if (!item) {
        return;
    }

    const index = this.attachmentItems.indexOf(item);

    if (index === -1) {
        return;
    }

    this.attachmentItems.splice(index, 1);
    this.onChange({
        attachments: this.attachmentItems,
        added: [],
        removed: [attachment]
    });
};

ManageAttachments.prototype._selectAssets = function (attachments) {
    const listView = { id: "list", icon: listIcon, ariaLabel: this.multiLanguageService.getString("accessibility.spa_menu.selector.listView") };
    const galleryView = { id: "gallery", icon: gridIcon, ariaLabel: this.multiLanguageService.getString("accessibility.spa_menu.selector.thumbnailView") };

    return this._assetSelector
        .selectAssets({
            mainTypes: ["File"],
            includeUpload: true,
            selectType: "MainStore",
            selectorViews: [listView, galleryView],
            currentlySelectedIds: attachments.map(function (a) {
                return a.AssetId;
            })
        })
        .then(
            function (assetRefs) {
                return assetRefs.map(this._mapItemToAttachment);
            }.bind(this)
        );
};

ManageAttachments.prototype._getAttachmentsDiff = function (oldAttachments, newAttachments) {
    var removed = [];

    if (!this.User.isExternal()) {
        removed = oldAttachments.filter(function (attachment) {
            return !newAttachments.some(function (newAttachment) {
                return newAttachment.AssetId === attachment.AssetId;
            });
        });
    }

    var added = newAttachments.filter(function (attachment) {
        return !oldAttachments.some(function (oldAttachment) {
            return oldAttachment.AssetId === attachment.AssetId;
        });
    });

    return {
        added: added,
        removed: removed
    };
};

ManageAttachments.prototype._mapItemToAttachment = function (selectorItem) {
    return {
        AssetId: selectorItem.Id,
        MainType: selectorItem.MainType,
        SubType: selectorItem.SubType,
        Title: selectorItem.Title
    };
};

ManageAttachments.prototype._addUploadedAttachments = function (event, data) {
    if (data && data.Items && data.Items.length > 0) {
        var mappedAttachments = data.Items.map(this._mapItemToAttachment);
        this._handleAttachmentChange(mappedAttachments);
    }
};

ManageAttachments.prototype.$onDestroy = function () {
    this.eventHandler();
};

angularAMD.component("manageAttachments", manageAttachmentsDefinition);
export { manageAttachmentsDefinition as manageAttachments };
