import { angularAMD } from "@pebblepad/amd";
import { PANEL_CONSTANTS } from "../constants/panel.constants";
import { TIMEOUT_CONSTANTS } from "../constants/timeout.constants";
import template from "./spa-menu.html";
import "../workbook/standardsOverview/sidebarElementsView/sidebarElementsViewWrapper/sidebarElementsViewWrapper.component";
import "../mapToStandards/mapToStandardsWrapper/mapToStandardsWrapper.component";
import "../assetLocking/services/assetLockingHelper.service";
import "../collections/criteria/directives/criteriaManager";
import "../userSettings/services/UserSettingsService";
import "../assetEndpointService/assetEndpoint.service";
import "../a11y/providers/a11yContainers.provider";
import "../assetStore/services/assetCreateService";
import "../utilities/subscriptionServiceProvider";
import "./spaAssetStore/directives/spaAssetStore";
import "./spaAssetStore/directives/spaAssetStore";
import "../helpBannerWrapper/helpBannerWrapper";
import "./actionMenu/directives/spaActionMenu";
import "./spaMenuServices/productMenuService";
import "../fileUpload/directives/fileUpload";
import "../helpBanner/helpBanner.directive";
import "./spaMenuServices/spaDisplayHelper";
import "../security/pebbleSecurity.service";
import "../preventRedirect/preventRedirect";
import "./logOut/services/spaLogOutService";
import "./selector/directives/spaSelector";
import "../loadingSpinner/loadingSpinner";
import "./history/directives/spaHistory";
import "../utilities/focusStyleProvider";
import "./assetInfoPanel/assetInfoPanel";
import "./services/pageReloadService";
import "../utilities/baseUrlsFactory";
import "../expandable/expandable";
import "../modal/services/modal";
import "../utilities/capitalize";
import "../user/user.service";
import "../dropdown/dropdown";
import "../addEvidence/addEvidence.component";
import "../elementInfoPanel/elementInfoPanel.component";
import "../accessKeys/accessKey.service";
import "../focusTrap/focusTrap.service";
import "./assetFilteringPanel/assetFilteringPanel";

import "../react2angular/icon";
import { cogWheelIcon } from "@pjs/core-ui";
import { tracker } from "@pjs/analytics";

/*
 * Binds the to the expression once at startup,
 * Usage: <span bind-once="myExpression"></span>
 */
angularAMD.directive("bindOnce", [
    "$parse",
    function ($parse) {
        return {
            compile: function compile(element, attributes) {
                var expr = $parse(attributes.bindOnce);

                return function link(scope, element) {
                    element.text(expr(scope));
                };
            }
        };
    }
]);

angularAMD.directive("spaMenu", [
    "$sce",
    "$q",
    "$location",
    "$timeout",
    "$http",
    "$rootScope",
    "$compile",
    "$routeParams",
    "$document",
    "spaLogOutService",
    "baseUrlsFactory",
    "multiLanguageService",
    "productMenuService",
    "preventRedirect",
    "focusStyleProvider",
    "dataManagerService",
    "assetCreateService",
    "spaDisplayHelper",
    "modal",
    "subscriptionServiceProvider",
    "pageReloadService",
    "AssetLockingHelper",
    "User",
    "UserSettingsService",
    "AssetEndpointService",
    "accessKeyService",
    "focusTrapService",
    function (
        $sce,
        $q,
        $location,
        $timeout,
        $http,
        $rootScope,
        $compile,
        $routeParams,
        $document,
        spaLogOutService,
        baseUrlsFactory,
        multiLanguageService,
        productMenuService,
        preventRedirect,
        focusStyleProvider,
        dataManagerService,
        assetCreateService,
        spaDisplayHelper,
        modal,
        subscriptionServiceProvider,
        pageReloadService,
        AssetLockingHelper,
        User,
        UserSettingsService,
        AssetEndpointService,
        accessKeyService,
        focusTrapService
    ) {
        return {
            restrict: "E",
            scope: {
                viewSegments: "=viewSegments",
                excludeSelectorPages: "=excludeSelectorPages",
                excludeAssetFunctionality: "=excludeAssetFunctionality",
                productUrl: "=productUrl",
                userData: "=userData",
                unlinkLogo: "=unlinkLogo"
            },
            template: template,
            controller: [
                "$scope",
                "$element",
                "$attrs",
                function ($scope, $element, $attrs) {
                    subscriptionServiceProvider.addService("spaMenu");
                    //Initialise variables -----------------------------------------------------------------------------
                    $scope.multiLanguageService = multiLanguageService;
                    $scope.loginServiceUrl = baseUrlsFactory.api_base_url + "SharedMenu/Forwarder?location=LoginService&action=JustOpenLocation";
                    $scope.testingEnvironment = baseUrlsFactory.testing_environment;
                    $scope.showProductDropDown = false;
                    $scope.panelContentHistory = [];
                    $scope.extraActionFromOtherSidePanel = false; // overwritten by other component to trigger on close
                    $scope.focusTrapService = focusTrapService;
                    $scope.focusTrapId = null;
                    $scope.spaAppContainer = document.getElementById("app-container");
                    $scope.spaMenuWrapper = document.getElementById("spa-menu-wrapper");

                    const userSettingsTitle = $scope.multiLanguageService.getString("user_settings.modal_title");

                    //Toolbar Buttons;
                    $scope.buttons = {
                        userSettings: {
                            action: () => {
                                tracker.trackEvent("SPA Bar", "Open User Settings Modal");
                                UserSettingsService.showUserSettingsModal();
                            },
                            id: "spa-user-settings",
                            title: userSettingsTitle,
                            label: userSettingsTitle,
                            aria_label: $scope.multiLanguageService.getString("user_settings.a11y.open_modal"),
                            svgIcon: cogWheelIcon
                        },
                        assetStore: {
                            action: function () {
                                tracker.trackEvent("SPA Bar", "Toggle Asset Store Sidebar");
                                $scope.openRightSidePanel("asset-store");
                            },
                            id: "spa-search-btn",
                            title: $scope.multiLanguageService.getString("buttons.generic.search"),
                            icon: "icon-asset-store",
                            label: $scope.multiLanguageService.getString("accessibility.spa_menu.spa_menu_items.asset_store"),
                            aria_label: $scope.multiLanguageService.getString("accessibility.spa_menu.spa_buttons.search")
                        },
                        history: {
                            action: function () {
                                tracker.trackEvent("SPA Bar", "Toggle History Sidebar");
                                $scope.openRightSidePanel("history", "", "spa");
                            },
                            id: "spa-history-btn",
                            title: $scope.multiLanguageService.getString("buttons.history"),
                            icon: "icon-history",
                            label: $scope.multiLanguageService.getString("buttons.history"),
                            aria_label: $scope.multiLanguageService.getString("accessibility.spa_menu.spa_buttons.history")
                        },
                        logOut: {
                            action: function () {
                                tracker.trackEvent("SPA Bar", "Open Logout Modal");
                                $scope.logOut();
                            },
                            id: "spa-log-out-btn",
                            title: $scope.multiLanguageService.getString("logout.title"),
                            icon: "icon-log-out",
                            label: $scope.multiLanguageService.getString("logout.title"),
                            aria_label: $scope.multiLanguageService.getString("accessibility.spa_menu.spa_buttons.logout")
                        }
                    };
                    //Rendered Toolbar
                    $scope.viewToolButtons = [];

                    $scope.savingOverlay = {
                        overlay: null,
                        show: function () {
                            if (this.overlay === null || !this.overlay[0]) {
                                this.overlay = angular.element(document.getElementsByClassName("builder-canvas-dark-layer")[0]);
                            }
                            this.overlay.addClass("show-overlay");
                        },
                        update: function () {
                            this.overlay.addClass("save-complete");
                        },
                        hide: function () {
                            this.overlay.removeClass("save-complete");
                            this.overlay.removeClass("show-overlay");
                        }
                    };

                    $scope.getProductAriaLabel = function (product, target) {
                        var langOptions = {
                            OpensNewTab: target === "_blank" ? multiLanguageService.getString("accessibility.opens_in_new_window") : ""
                        };

                        switch (product) {
                            case "atlas":
                                return multiLanguageService.getString("accessibility.spa_menu.spa_buttons.atlas", langOptions);
                            case "plus":
                                return multiLanguageService.getString("accessibility.spa_menu.spa_buttons.plus", langOptions);
                            case "home":
                                return multiLanguageService.getString("accessibility.spa_menu.spa_buttons.pebble_home", langOptions);
                            default:
                                return product;
                        }
                    };

                    //Private variables (no need to pollute the scope all the time) ------------------------------------
                    var invalidItems = ["help", "pebblepad"];

                    //Product Menu -------------------------------------------------------------------------------------
                    $scope.initialiseProductMenuData = function () {
                        $scope.current_product = $attrs.product;
                        $scope.products = [getHomeProduct()];
                    };

                    $scope.getProductMenuData = function () {
                        productMenuService.getProductMenuData().then(function (response) {
                            $scope.products.length = 0;
                            $scope.products.push(getHomeProduct());
                            var segmentProducts = $scope.viewSegments.products;
                            for (var i = 0; i < response.data.length; i++) {
                                const item = response.data[i];
                                // Validate URL for a product menu item
                                item.Url = $scope.getProductUrl(item.Product, $scope.current_product, item.Url);
                                item.ActionCallback = () => tracker.trackEvent("SPA Bar", `Click ${item.Product} Button`);

                                //Filter what products can be seen.
                                if (!segmentProducts.filters && invalidItems.indexOf(item.Product) === -1) {
                                    $scope.products.push(item);
                                } else if (segmentProducts.filters && segmentProducts.filters.indexOf(item.Product) !== -1) {
                                    $scope.products.push(item);
                                }
                            }
                            spaDisplayHelper.sort(segmentProducts, $scope.products, "Product");
                        });
                    };

                    $scope.getProductUrl = function (product, currentProduct, url) {
                        if (url.indexOf("http") === -1) {
                            if (currentProduct !== product) {
                                url = baseUrlsFactory.api_base_url + url;
                            } else {
                                switch (product) {
                                    case "pebblepad":
                                        url = "#/main";
                                        break;
                                    default:
                                        url = "#/";
                                        break;
                                }
                            }
                        }
                        return url;
                    };

                    $scope.getProductLogoUrl = function (product) {
                        var logo_url = $sce.getTrustedResourceUrl(baseUrlsFactory.shared_component_base_url + "spaMenu/styles/images/logos/");

                        switch (product) {
                            case "home":
                                logo_url = logo_url + "pebble-pad.png";
                                break;
                            case "home-icon":
                                logo_url = logo_url + "pebble-pad-icon.png";
                                break;
                            case "plus":
                                logo_url = logo_url + "plus.png";
                                break;
                            case "atlas":
                                logo_url = logo_url + "atlas.png";
                                break;
                            case "help":
                                logo_url = logo_url + "help.png";
                                break;
                            case "admissions":
                                logo_url = logo_url + "admissions.png";
                                break;
                            default:
                                logo_url = logo_url + "pebble-pad-icon.png";
                        }

                        return logo_url;
                    };

                    //Toolbar ------------------------------------------------------------------------------------------
                    $scope.addToolButtons = function () {
                        $scope.viewToolButtons = spaDisplayHelper.filter($scope.viewSegments.tools, $scope.buttons);
                    };

                    $scope.displayConciseBar = function () {
                        $scope.viewSegments.menu.display = false;
                        $scope.viewSegments.products.display = false;
                        $scope.viewSegments.tools.filters = ["logOut"];
                    };

                    $scope.resetBarFilters = function () {
                        $scope.viewSegments.menu.filters = null;
                        $scope.viewSegments.products.filters = null;
                        $scope.viewSegments.tools.filters = null;
                    };

                    //Reset display and filters
                    $scope.resetBar = function () {
                        $scope.viewSegments.menu.display = true;
                        $scope.viewSegments.menu.filters = null;

                        $scope.viewSegments.products.display = true;
                        $scope.viewSegments.products.filters = null;

                        $scope.viewSegments.tools.display = true;
                        $scope.viewSegments.tools.filters = null;
                        $scope.customLogo = "";
                    };

                    $scope.menuUpdate = function (doRequest, reset, user) {
                        if (reset) {
                            //Reset SpaMenu completely, everything is viewable.
                            $scope.resetBar();
                        }

                        $scope.mainDashboardLink = "#/";

                        if (user) {
                            $scope.userData = user;
                            $scope.isLoggedIn = user.UserId;

                            if (user.DashboardUrl !== "") {
                                $scope.mainDashboardLink = user.DashboardUrl;
                            }
                        }

                        //Get product data again.
                        if (doRequest) {
                            $scope.getProductMenuData();

                            //Filter what tools can be viewed after this MenuUpdate.
                            $scope.viewToolButtons = spaDisplayHelper.filter($scope.viewSegments.tools, $scope.buttons);
                        } else {
                            $scope.viewToolButtons = spaDisplayHelper.filter($scope.viewSegments.tools, $scope.buttons);
                            spaDisplayHelper.sort($scope.viewSegments.products, $scope.products, "Product");
                        }

                        if ($scope.userData && $scope.userData.SpaBarLogo) {
                            const params = {
                                id: $scope.userData.SpaBarLogo,
                                x: 0,
                                y: 0
                            };

                            $scope.customLogo = AssetEndpointService.transmitImageUrl(params);
                        }
                    };

                    var getHomeProduct = function () {
                        switch ($scope.current_product) {
                            case "pebblepad":
                                return {
                                    Product: "home",
                                    Target: "_self",
                                    Url: "#/main",
                                    ActionCallback: () => tracker.trackEvent("SPA Bar", "Click Home Button"),
                                    IconImageUrl: $scope.getProductLogoUrl("home-icon"),
                                    FullImageUrl: $scope.getProductLogoUrl("home")
                                };

                            default:
                                return {
                                    Product: "home",
                                    Target: "_self",
                                    Url: "#/",
                                    ActionCallback: () => tracker.trackEvent("SPA Bar", "Click Home Button"),
                                    IconImageUrl: $scope.getProductLogoUrl("home-icon"),
                                    FullImageUrl: $scope.getProductLogoUrl("home")
                                };
                        }
                    };

                    $scope.updateSpaData = function (data) {
                        if (data && data.updateProducts) {
                            $scope.initialiseProductMenuData();
                            if ($scope.userData) {
                                $scope.getProductMenuData();
                            }
                        }
                    };

                    var cleanUpFunc = $rootScope.$on("spaGetData", function (event, data) {
                        $scope._unbindAccessKeys();
                        $scope.updateSpaData(data);
                    });

                    $scope.$on("$destroy", function () {
                        cleanUpFunc();
                    });

                    focusStyleProvider.bindFocusApplier();

                    $scope.logOut = function () {
                        // Confirmation dialog
                        const can_log_out = preventRedirect.checkIfCanLogOut();
                        const modal_message = preventRedirect.getLogoutMessage(can_log_out);

                        const modalProps = {
                            title: modal_message.title,
                            message: modal_message.message,
                            saveAndContinueBtn: modal_message.saveAndContinueBtn,
                            continueBtn: modal_message.continueBtn,
                            cancelRedirectBtn: modal_message.cancelRedirectBtn,
                            can_log_out: can_log_out
                        };

                        modalProps.saveAndContinue = function () {
                            if (!can_log_out) {
                                modalProps.saveAndLogout();
                            } else {
                                $scope.confirm();
                            }
                        };

                        modalProps.saveAndLogout = function () {
                            // we need to check maintype, as form response put there title inside an element we need to check it in a different way
                            var mainType = dataManagerService.dtos[$routeParams.assetId].data.MainType;
                            var assetTitle = dataManagerService.dtos[$routeParams.assetId].data.Title;
                            if ((assetTitle === null || assetTitle === undefined || assetTitle.toLowerCase() === "no title") && mainType && mainType !== "Form" && mainType !== "FormResponse") {
                                $scope.askUserForTitle();
                            } else {
                                $scope.savingOverlay.show();
                                dataManagerService.saveViaAssetId($routeParams.assetId).then(
                                    function (data) {
                                        if (data && data.validation && data.validation.valid === false) {
                                            $scope.confirmMessage = data.validation.message;
                                            $scope.savingOverlay.hide();
                                            modal.newModal({
                                                scope: $scope.$new(),
                                                templateUrl: `${baseUrlsFactory.shared_component_base_url}formCanvas/templates/invalid-page.html`
                                            });
                                        } else {
                                            $scope.savingOverlay.update();
                                            $timeout(function () {
                                                $scope.savingOverlay.hide();
                                                $scope.confirm();
                                            }, TIMEOUT_CONSTANTS.SAVE_OVERLAY);
                                        }
                                    },
                                    function () {
                                        $scope.savingOverlay.hide();
                                        $scope.confirm();
                                    }
                                );
                            }
                        };

                        modalProps.continue = function () {
                            dataManagerService.cleanupStorageOfAssetId($routeParams.assetId);
                            $scope.confirm();
                        };

                        const templateUrl = preventRedirect.blockingInProgress()
                            ? `${baseUrlsFactory.shared_component_base_url}preventRedirect/templates/warning-modal-alt.html`
                            : `${baseUrlsFactory.shared_component_base_url}preventRedirect/templates/warning-modal.html`;

                        $scope.modal = modal.launch(templateUrl, modalProps);
                    };

                    $scope.askUserForTitle = function () {
                        var isolatedScope = $scope.$new(true),
                            managerObj = dataManagerService.dtos[$routeParams.assetId],
                            assetDto = managerObj.data;
                        isolatedScope.first_title_el = angular.element(document.getElementsByClassName("title-text-editable")[0]);
                        isolatedScope.newTitle = assetDto.Title;
                        isolatedScope.mainType = assetDto.MainType.toLowerCase();
                        isolatedScope.assetMainType = assetDto.MainType;
                        isolatedScope.saveOrPreview = "save";
                        isolatedScope.confirmMessage = $scope.multiLanguageService.getString("labels.pop_ups." + isolatedScope.mainType + "_save_message");

                        isolatedScope.confirmTitle = function (newTitle, saveOrPreview) {
                            managerObj.updateDtoProperty("Title", newTitle);
                            $scope.saveAndContinue();
                        };
                        $timeout(function () {
                            modal.newModal({
                                scope: isolatedScope,
                                templateUrl: baseUrlsFactory.shared_component_base_url + "builder/saveBuilderMessage/templates/save-builder-message.html"
                            });
                        });
                    };

                    $scope.confirm = function () {
                        return AssetLockingHelper.cancelAllActiveLocks()
                            .then(spaLogOutService.logOut)
                            .then(function (response) {
                                $scope.loginServiceUrl = response.data;
                            })
                            .finally(function () {
                                $scope.displayConciseBar();
                                $scope.resetBarFilters();

                                if ($scope.panelContentHistory.length > 0) {
                                    $scope.closeSidePanel($scope.right_panel_holder_el);
                                } else if ($scope.action_menu_holder_el.hasClass("enabled")) {
                                    $scope.closeSidePanel($scope.action_menu_holder_el);
                                }

                                $rootScope.userData = undefined; // some service rely on this existing or they get it... make sure its cleared when user changes
                                User.reset();

                                $location.$search = {};
                                preventRedirect.allowRedirect(true);

                                if ($scope.panelContentHistory.length > 0) {
                                    $scope.closeSidePanel($scope.right_panel_holder_el);
                                } else if ($scope.action_menu_holder_el.hasClass("enabled")) {
                                    $scope.closeSidePanel($scope.action_menu_holder_el);
                                }

                                //Reset both $rootScope and $scope, as the reference could have been broken by external directives updating userData.
                                $scope.userData = $rootScope.userData = undefined; // some service rely on this existing or they get it... make sure its cleared when user changes
                                $location.$search = {};
                                preventRedirect.allowRedirect(true);

                                if ($scope.testingEnvironment) {
                                    $location.path("login");
                                } else {
                                    window.location.href = $scope.loginServiceUrl;
                                }

                                $scope.isLoggedIn = null;
                            });
                    };
                }
            ],

            link: function (scope, element, attrs) {
                var userDataWatch = scope.$watch("userData", function () {
                    if (scope.userData && scope.userData.UserId) {
                        scope.isLoggedIn = scope.userData.UserId;
                        scope.updateViewFromRole(scope.userData);
                        scope.menuUpdate(true, false);

                        userDataWatch(); //Destroy watch
                    }
                });

                scope.currentProduct = attrs.product;
                scope.addToolButtons();
                // core elements used by spa menu
                scope.html_el = angular.element(document.documentElement);
                scope.body_el = angular.element(document.body);
                scope.window_el = angular.element(window);
                scope.panel_backing_el = angular.element(document.getElementsByClassName("panel-backing"));
                scope.panels_holder_el = angular.element(document.getElementById("spa-menu-container"));

                scope.action_menu_holder_el = angular.element(document.getElementsByClassName("action-menu-holder"));
                scope.right_panel_holder_el = angular.element(document.getElementsByClassName("right-panel-holder"));
                scope.spa_assetstore_wrapper_element = angular.element(document.getElementById("spa-asset-store"));
                scope.spa_history_wrapper_element = angular.element(document.getElementById("spa-history-panel"));
                scope.spa_asset_info_panel_wrapper_element = angular.element(document.getElementById("spa-asset-info-panel"));
                scope.spa_collection_criteria_wrapper_element = angular.element(document.getElementById("spa-criteria"));
                scope.spa_map_to_standards_wrapper_el = angular.element(document.getElementById("spa-map-to-standards-panel"));
                scope.spa_file_upload_wrapper_el = angular.element(document.getElementById("spa-file-upload"));
                scope.spa_sidebar_elements_view_el = angular.element(document.getElementById("spa-sidebar-elements-view-panel"));
                scope.spa_add_evidence_wrapper_el = angular.element(document.getElementById("spa-add-evidence"));
                scope.spa_element_info_wrapper_el = angular.element(document.getElementById("spa-element-info"));
                scope.spa_asset_filtering_wrapper_element = angular.element(document.getElementById("spa-asset-filtering-panel"));

                const activeClass = "active";
                const previousClass = "previous";
                const repeatClass = "repeat";
                const asset_info_panel = angular.element(document.getElementsByClassName("spa-asset-info-panel")[0]);
                const elementInfoPanel = angular.element(document.getElementsByClassName("element-info__panel")[0]);

                scope.panels = {
                    history: {
                        name: PANEL_CONSTANTS.PANEL.HISTORY,
                        linkedId: scope.buttons.history.id,
                        element: angular.element(element[0].getElementsByClassName("spa-history")[0]),
                        isStartingPoint: true,
                        canDelete: true
                    },
                    selector: {
                        name: PANEL_CONSTANTS.PANEL.SELECTOR,
                        element: angular.element(element[0].getElementsByClassName("spa-selector")[0])
                    },
                    store: {
                        name: PANEL_CONSTANTS.PANEL.ASSET_STORE,
                        linkedId: scope.buttons.assetStore.id,
                        element: angular.element(element[0].getElementsByClassName("asset-store")[0]),
                        canDelete: true,
                        isStartingPoint: true
                    },
                    info: {
                        name: PANEL_CONSTANTS.PANEL.ASSET_INFO,
                        element: asset_info_panel,
                        canDelete: true
                    },
                    collectionCriteria: {
                        name: PANEL_CONSTANTS.PANEL.COLLECTION_CRITERIA,
                        element: angular.element(element[0].getElementsByClassName("spa-collection-criteria-panel")[0]),
                        canDelete: true
                    },
                    comments: {
                        name: PANEL_CONSTANTS.PANEL.ASSET_COMMENTS,
                        element: asset_info_panel,
                        canDelete: true
                    },
                    feedback: {
                        name: PANEL_CONSTANTS.PANEL.ASSET_FEEDBACK,
                        element: asset_info_panel,
                        canDelete: true
                    },
                    mapping: {
                        name: PANEL_CONSTANTS.PANEL.ASSET_MAPPING,
                        element: asset_info_panel,
                        canDelete: true
                    },
                    versioning: {
                        name: PANEL_CONSTANTS.PANEL.ASSET_VERSIONING,
                        element: asset_info_panel,
                        canDelete: true
                    },
                    progressTracking: {
                        name: PANEL_CONSTANTS.PANEL.ASSET_PROGRESS_TRACKING,
                        element: asset_info_panel,
                        canDelete: true
                    },
                    standardsSelection: {
                        name: PANEL_CONSTANTS.PANEL.STANDARDS_SELECTOR_PANEL,
                        element: angular.element(element[0].getElementsByClassName("spa-standards-selection-info-panel")[0]),
                        canDelete: true
                    },
                    sidebarElementsView: {
                        name: PANEL_CONSTANTS.PANEL.ELEMENTS_OVERVIEW,
                        element: angular.element(element[0].getElementsByClassName("spa-standards-elements-overview__panel")[0]),
                        canDelete: true
                    },
                    fileUpload: {
                        name: PANEL_CONSTANTS.PANEL.FILE_UPLOAD,
                        element: angular.element(element[0].getElementsByClassName("spa-file-upload-panel")[0]),
                        canDelete: true
                    },
                    addEvidence: {
                        name: PANEL_CONSTANTS.PANEL.ADD_EVIDENCE,
                        element: angular.element(element[0].getElementsByClassName("add-evidence__panel")[0]),
                        canDelete: true
                    },
                    elementInfo: {
                        name: PANEL_CONSTANTS.PANEL.ELEMENT_INFO,
                        element: elementInfoPanel,
                        canDelete: true
                    },
                    elementFeedback: {
                        name: PANEL_CONSTANTS.PANEL.ELEMENT_FEEDBACK,
                        element: elementInfoPanel,
                        canDelete: true
                    },
                    elementComments: {
                        name: PANEL_CONSTANTS.PANEL.ELEMENT_COMMENTS,
                        element: elementInfoPanel,
                        canDelete: true
                    },
                    assetFilteringPanel: {
                        name: PANEL_CONSTANTS.PANEL.ASSET_FILTERING_PANEL,
                        element: angular.element(element[0].getElementsByClassName("asset-filtering-panel")[0]),
                        canDelete: true
                    }
                };
                scope.activePanelScopes = {};

                scope._bindAccessKeys = function () {
                    const escape = {
                        key: "escape",
                        altKey: false,
                        callback: () => {
                            subscriptionServiceProvider
                                .runSubscriptions("spaMenu")
                                .then(() => {
                                    scope.previousPanel();
                                })
                                .catch(angular.noop);
                        }
                    };

                    const ie11Esc = { ...escape, key: "esc" };
                    accessKeyService.register("spaMenu", [escape, ie11Esc]);
                };

                scope._unbindAccessKeys = function () {
                    accessKeyService.remove("spaMenu");
                };

                scope.disable_body_scrolling = function (action) {
                    if (action === "bind") {
                        if (scope.html_el[0].scrollTop > 0) {
                            scope.html_el.addClass("side-panel-enabled");
                        }
                    } else if ("unbind") {
                        scope.html_el.removeClass("side-panel-enabled");
                    }
                };

                scope.removeActionMenuListeners = function () {
                    $document[0].removeEventListener("focus", scope.checkActionMenuHasFocus, true);
                };

                scope.setupActionMenuEventListeners = function () {
                    $document[0].addEventListener("focus", scope.checkActionMenuHasFocus, true);
                };

                scope.checkActionMenuHasFocus = function (e) {
                    if (!scope.action_menu_holder_el[0].contains((e.originalEvent || e).target)) {
                        scope.closePanels();
                        var burgerMenuButton = document.getElementById("spa-burger-menu-button");
                        if (burgerMenuButton !== null) {
                            $timeout(function () {
                                burgerMenuButton.focus();
                            });
                        }
                    }
                };

                /**
                 * Toggle any side panel
                 * @param panel_name
                 * @param push_content - usually "false", need "true" for action menu
                 */
                scope.toggleSidePanel = function (panel_name, push_content) {
                    // make current element the one which was clicked
                    switch (panel_name) {
                        case "action-menu":
                            scope.current_el = scope.action_menu_holder_el;
                            scope.panelContentHistory.length = 0;
                            var closed = !scope.current_el.hasClass("enabled");
                            scope.actionMenuOpen = closed;
                            if (closed) {
                                scope.setupActionMenuEventListeners();
                                scope.$emit("spaGetData");
                            }
                            break;
                        case "right-side-menu":
                            scope.current_el = scope.right_panel_holder_el;
                            break;
                        default:
                            throw new Error("No panel with this name");
                    }

                    // check if current element already opened
                    if (scope.current_el.hasClass("enabled")) {
                        scope.closeSidePanel(scope.current_el);
                    } else {
                        scope.openSidePanel(scope.current_el, push_content);
                    }
                };

                /**
                 * Opens any side panel and add all needed classes for transition
                 * Closes all other panels
                 * @param current_el
                 * @param push_content
                 */
                scope.openSidePanel = function (current_el, push_content) {
                    scope.panels_holder_el.addClass("enabled");
                    current_el.addClass("enabled");

                    setTimeout(function () {
                        current_el.addClass(activeClass);

                        if (push_content) {
                            scope.app_el = angular.element(document.getElementById("app"));
                            scope.app_el.addClass("side-menu-enabled");
                        }
                        scope.panel_backing_el.addClass(activeClass);
                        scope.focusInPanel(current_el);
                    }, 50);

                    scope.disable_body_scrolling("bind", current_el);

                    // if left side menu is open, then close right side menu
                    if (scope.any_panel_enabled && current_el === scope.action_menu_holder_el) {
                        scope.closeSidePanel(scope.right_panel_holder_el);
                    }
                    // if right side panel is already open, then close it when left side menu open
                    else if (scope.any_panel_enabled && current_el === scope.right_panel_holder_el) {
                        scope.closeSidePanel(scope.action_menu_holder_el, true);
                    }

                    scope.any_panel_enabled = true;
                };

                /**
                 * Closes the panel
                 * @param current_el
                 * @param keepData
                 */
                scope.closeSidePanel = function (current_el, keepData) {
                    if (scope.focusTrapId !== null && current_el[0] === scope.container_el) {
                        scope.focusTrapService.removeTrap(scope.focusTrapId);
                        scope.focusTrapId = null;
                    }

                    if (current_el[0] === scope.action_menu_holder_el[0]) {
                        scope.actionMenuOpen = false;
                    }

                    pageReloadService.locationReloadOnSidePanelClose();

                    if (current_el.hasClass("action-menu-holder")) {
                        scope.$broadcast("spa-action-menu-collapse");
                    }

                    // if app_el isn't defined, then do define
                    if (!scope.app_el) {
                        scope.app_el = angular.element(document.getElementById("app"));
                    }
                    scope.app_el.removeClass("side-menu-enabled");
                    current_el.removeClass(activeClass);

                    scope.removeActionMenuListeners();

                    setTimeout(function () {
                        current_el.removeClass("enabled");
                        if (!scope.any_panel_enabled) {
                            scope.panels_holder_el.removeClass("enabled");
                            scope.panel_backing_el.removeClass(activeClass);
                        }
                        scope.disable_body_scrolling("unbind", current_el);
                    }, 200);

                    if (current_el !== scope.action_menu_holder_el) {
                        scope.panelContentHistory.length = 0;
                    }
                    scope.any_panel_enabled = false;
                    scope.removePanelClasses();

                    if (!keepData) {
                        scope.removePanelData();
                    }
                };

                scope.focusInPanel = function (current_el, panel_name) {
                    setTimeout(function () {
                        if (current_el === scope.action_menu_holder_el) {
                            scope.action_menu_holder_el[0].focus();
                        }
                    }, 300);
                };

                scope.lastOpened = Date.now();

                scope.openRightSidePanel = function (panel_name, store_name, initiatingButton, onPanelClose) {
                    //Prevent Spam Clicking which can cause issues with Angular cleaning up after itself (Memory leak) - Temporary fix.
                    if (Date.now() - scope.lastOpened < 400) {
                        return;
                    }

                    scope.container_el = element[0].getElementsByClassName("right-panel-holder")[0];

                    if (scope.focusTrapId === null) {
                        scope.focusTrapId = scope.focusTrapService.addTrap(scope.container_el, [scope.spaAppContainer, scope.spaMenuWrapper]);
                    }

                    const active = scope.right_panel_holder_el.hasClass(activeClass);
                    let historyLength = scope.panelContentHistory.length - 1;

                    if (!active) {
                        scope._bindAccessKeys();
                    }

                    //If the sidebar is not open or the panel is not equal to the last panel (e.g. toggle)
                    if (!active || (active && panel_name !== scope.panelContentHistory[historyLength].name)) {
                        var panel = scope.panels.where({ name: panel_name })[0];
                        if (panel && panel.isStartingPoint) {
                            scope.panelContentHistory.length = 0;
                            historyLength = scope.panelContentHistory.length - 1;
                            scope.removePanelClasses();
                            scope.removePanelData();
                        }

                        if (historyLength === -1 || scope.panelContentHistory[historyLength].name !== panel_name) {
                            //Set current side panel content
                            scope.panelContentHistory.push({ name: panel_name, onPanelClose: onPanelClose });
                            scope.updateActivePanel(panel_name);
                        }
                        scope.closeButtonMessage = scope.panelContentHistory.length > 1 ? scope.multiLanguageService.getString("buttons.back") : scope.multiLanguageService.getString("buttons.close");

                        var newScope, html;
                        // create asset store directive from scratch
                        switch (panel_name) {
                            case PANEL_CONSTANTS.PANEL.HISTORY:
                                newScope = scope.$new(true);
                                newScope.initiatingButton = initiatingButton;
                                html = '<spa-history class="spa-history spa-panel"></spa-history>';
                                scope.spa_history_wrapper_element.empty();
                                // tslint:disable-next-line:no-unsafe-jq-lite
                                scope.spa_history_wrapper_element.append($compile(html)(newScope));
                                scope.panels.history.element = angular.element(element[0].getElementsByClassName("spa-history")[0]);
                                break;
                            case PANEL_CONSTANTS.PANEL.ASSET_STORE:
                                newScope = scope.$new(true);
                                newScope.startingSearchType = store_name;
                                newScope.assetStoreLabel = multiLanguageService.getString("asset_store.accessibility.side_panel_main_heading");
                                html = `<asset-store class="asset-store spa-panel" tabindex="0" sidebar-mode starting-search-type="{{startingSearchType}}" current-product="${scope.current_product}" heading-level="2" store-label="{{::assetStoreLabel}}"></asset-store>'`;
                                scope.spa_assetstore_wrapper_element.empty();
                                // tslint:disable-next-line:no-unsafe-jq-lite
                                scope.spa_assetstore_wrapper_element.append($compile(html)(newScope));
                                scope.panels.store.element = angular.element(element[0].getElementsByClassName("asset-store")[0]);
                                break;

                            case PANEL_CONSTANTS.PANEL.ASSET_INFO:
                            case PANEL_CONSTANTS.PANEL.ASSET_COMMENTS:
                            case PANEL_CONSTANTS.PANEL.ASSET_FEEDBACK:
                            case PANEL_CONSTANTS.PANEL.ASSET_VERSIONING:
                            case PANEL_CONSTANTS.PANEL.ASSET_MAPPING:
                            case PANEL_CONSTANTS.PANEL.ASSET_PROGRESS_TRACKING:
                                newScope = scope.$new(true);
                                newScope.closeButtonMessage = scope.closeButtonMessage;
                                newScope.goToPreviousPanel = scope.goToPreviousPanel;
                                newScope.panelActionData = scope.panelActionData;
                                newScope.anchorId = scope.data.anchorId;
                                newScope.assetId = scope.data.assetId;
                                newScope.submissionId = $routeParams.submissionId;

                                html = `<asset-info-panel class="spa-asset-info-panel spa-panel" type="${panel_name}"></asset-info-panel>`;

                                scope.spa_asset_info_panel_wrapper_element.empty();
                                // tslint:disable-next-line:no-unsafe-jq-lite
                                scope.spa_asset_info_panel_wrapper_element.append($compile(html)(newScope));

                                var info_el = angular.element(element[0].getElementsByClassName("spa-asset-info-panel")[0]);
                                scope.panels.versioning.element = info_el;
                                scope.panels.progressTracking.element = info_el;
                                scope.panels.mapping.element = info_el;
                                scope.panels.feedback.element = info_el;
                                scope.panels.comments.element = info_el;
                                scope.panels.info.element = info_el;
                                break;

                            case PANEL_CONSTANTS.PANEL.COLLECTION_CRITERIA:
                                newScope = scope.$new(true);
                                newScope.callback = scope.data.callback;
                                newScope.type = scope.data.type;
                                newScope.id = scope.data.id;
                                newScope.edit = scope.data.edit;
                                newScope.collection = scope.data.collection;
                                newScope.disableSearchResult = scope.data.disableSearchResult;
                                newScope.allowCriteriaResponsesTo = scope.data.allowCriteriaResponsesTo;
                                newScope.collectionWrapper = scope.data.collectionWrapper;
                                newScope.allowSearchDefaulting = scope.data.allowSearchDefaulting;
                                html = '<criteria-manager class="spa-collection-criteria-panel spa-panel" data-hook="sidebar-criteria-manager"></criteria-manager>';

                                // tslint:disable-next-line:no-unsafe-jq-lite
                                scope.spa_collection_criteria_wrapper_element.append($compile(html)(newScope));
                                scope.panels.collectionCriteria.element = angular.element(element[0].getElementsByClassName("spa-collection-criteria-panel")[0]);
                                break;
                            case PANEL_CONSTANTS.PANEL.STANDARDS_SELECTOR_PANEL:
                                newScope = scope.$new(true);
                                newScope.standardsInfo = scope.data.standardsInfo;
                                newScope.callback = scope.data.callback;
                                newScope.assetId = scope.data.assetId;
                                newScope.standardsInfo.close = {
                                    action: scope.previousPanel.bind(scope, false, true),
                                    title: scope.closeButtonMessage
                                };

                                html = '<map-to-standards-wrapper class="spa-panel spa-standards-selection-info-panel" data-hook="standards-selection-info-panel"></map-to-standards-wrapper>';
                                var compileElement = $compile(html)(newScope);
                                scope.spa_map_to_standards_wrapper_el.empty();
                                // tslint:disable-next-line:no-unsafe-jq-lite
                                scope.spa_map_to_standards_wrapper_el.append(compileElement);
                                scope.panels.standardsSelection.element = compileElement;
                                break;
                            case PANEL_CONSTANTS.PANEL.ELEMENTS_OVERVIEW:
                                newScope = scope.$new(true);
                                newScope.data = scope.data.panelData;
                                newScope.assetId = scope.data.assetId;
                                html = `
                                    <sidebar-elements-view-wrapper data="data" 
                                        asset-id="assetId" 
                                        class="spa-panel spa-standards-elements-overview__panel" 
                                        data-hook="sidebar-elements-view">
                                    </sidebar-elements-view-wrapper>
                                `;
                                var compileElement = $compile(html)(newScope);
                                scope.spa_sidebar_elements_view_el.empty();
                                // tslint:disable-next-line:no-unsafe-jq-lite
                                scope.spa_sidebar_elements_view_el.append(compileElement);
                                scope.panels.sidebarElementsView.element = compileElement;
                                break;
                            case PANEL_CONSTANTS.PANEL.FILE_UPLOAD:
                                newScope = scope.$new(true);
                                newScope.panelInfo = scope.data.panelSettings;
                                newScope.panelInfo.close = scope.previousPanel.bind(scope);

                                const showTagger = scope.data.showTagger === undefined ? true : scope.data.showTagger;
                                const showDescription = scope.data.showDescription === undefined ? true : scope.data.showDescription;

                                html =
                                    '<file-upload show-tagger="' +
                                    showTagger +
                                    '" show-description="' +
                                    showDescription +
                                    '" panel-info="panelInfo"' +
                                    ' class="spa-panel spa-file-upload-panel" data-hook="file-upload"></file-upload>';

                                var compileElement = $compile(html)(newScope);
                                scope.spa_file_upload_wrapper_el.empty();
                                // tslint:disable-next-line:no-unsafe-jq-lite
                                scope.spa_file_upload_wrapper_el.append(compileElement);
                                scope.panels.fileUpload.element = compileElement;
                                break;
                            case PANEL_CONSTANTS.PANEL.ADD_EVIDENCE:
                                newScope = scope.$new(true);
                                newScope.closeInfo = {
                                    closeAction: scope.previousPanel.bind(scope),
                                    fullyCloseAction: scope.closePanels.bind(scope),
                                    label: scope.closeButtonMessage
                                };

                                newScope.assetId = scope.data.assetId;
                                newScope.anchorId = scope.data.anchorId;
                                newScope.evidenceAssetIds = scope.data.evidenceAssetIds;
                                newScope.isActivePanel = true;
                                newScope.topLevelAssetId = scope.data.topLevelAssetId;
                                newScope.evidenceMinimum = scope.data.evidenceMinimum;
                                newScope.evidenceCount = scope.data.evidenceCount;

                                html = `<add-evidence class="add-evidence__panel spa-panel"
													  close-info="closeInfo" 
													  is-active-panel="isActivePanel" 
													  asset-id="assetId" 
													  top-level-asset-id="topLevelAssetId" 
													  anchor-id="anchorId" 
                                                      evidence-count="evidenceCount"
                                                      evidence-minimum="evidenceMinimum"
													  evidence-asset-ids="evidenceAssetIds"></add-evidence>`;

                                var compileElement = $compile(html)(newScope);
                                const addEvidenceScope = newScope;

                                compileElement.on("$destroy", () => {
                                    addEvidenceScope.$destroy();
                                });
                                scope.spa_add_evidence_wrapper_el.empty();
                                // tslint:disable-next-line:no-unsafe-jq-lite
                                scope.spa_add_evidence_wrapper_el.append(compileElement);
                                scope.panels.addEvidence.element = compileElement;
                                scope.activePanelScopes[panel_name] = addEvidenceScope;
                                break;
                            case PANEL_CONSTANTS.PANEL.ELEMENT_COMMENTS:
                            case PANEL_CONSTANTS.PANEL.ELEMENT_FEEDBACK:
                            case PANEL_CONSTANTS.PANEL.ELEMENT_INFO:
                                newScope = scope.$new(true);
                                newScope.closeInfo = {
                                    action: scope.previousPanel.bind(scope),
                                    label: scope.closeButtonMessage
                                };

                                newScope.elementId = scope.data.anchorId;
                                newScope.panelType = scope.data.panel;
                                newScope.panelData = scope.data.panelData;
                                newScope.assetId = scope.data.assetId;
                                newScope.submissionId = $routeParams.submissionId;
                                newScope.viewerActions = $rootScope.viewerActions;
                                newScope.isActivePanel = true;
                                newScope.topLevelAssetId = scope.data.topLevelAssetId;

                                html = `<element-info-panel class="element-info__panel spa-panel"
															is-active-panel="isActivePanel"
															element-id="elementId"
															asset-id="assetId"
															top-level-asset-id="topLevelAssetId"
															close-info="closeInfo"
															panel-type="panelType"
															panel-data="panelData"
															submission-id="submissionId"
															viewer-actions="viewerActions"></element-info-panel>`;

                                const elementInfoElement = $compile(html)(newScope);
                                scope.spa_element_info_wrapper_el.empty();
                                // tslint:disable-next-line:no-unsafe-jq-lite
                                scope.spa_element_info_wrapper_el.append(elementInfoElement);

                                scope.panels.elementInfo.element = elementInfoElement;
                                scope.panels.elementFeedback.element = elementInfoElement;
                                scope.panels.elementComments.element = elementInfoElement;

                                const elementInfoPanelScope = newScope;
                                scope.activePanelScopes[panel_name] = elementInfoPanelScope;

                                elementInfoElement.on("$destroy", () => {
                                    elementInfoPanelScope.$destroy();
                                });
                                break;
                            case PANEL_CONSTANTS.PANEL.ASSET_FILTERING_PANEL:
                                newScope = scope.$new(true);
                                newScope.assetId = scope.data.assetId;
                                newScope.closeInfo = {
                                    action: scope.previousPanel.bind(scope),
                                    label: scope.closeButtonMessage
                                };

                                html = `<asset-filtering-panel class="asset-filtering-panel spa-panel" close-info="closeInfo" asset-id="assetId"></asset-filtering-panel>`;
                                const compiledElement = $compile(html)(newScope);
                                scope.spa_asset_filtering_wrapper_element.empty();
                                // tslint:disable-next-line:no-unsafe-jq-lite
                                scope.spa_asset_filtering_wrapper_element.append(compiledElement);
                                scope.panels.assetFilteringPanel.element = compiledElement;

                                const assetFilteringPanelScope = newScope;
                                scope.activePanelScopes[panel_name] = assetFilteringPanelScope;

                                compiledElement.on("$destroy", () => {
                                    assetFilteringPanelScope.$destroy();
                                });
                                break;
                        }
                        html = null;
                        newScope = null;
                        // if previous panel match clicked one, then hide it
                        if (active) {
                            if (scope.panelContentHistory[historyLength] !== undefined && scope.panelContentHistory[historyLength].name === panel_name) {
                                scope.toggleSidePanel("right-side-menu", false);
                            } else {
                                $timeout(function () {
                                    scope.activateCurrentPanel(panel_name);
                                });
                            }
                        } else {
                            //scope.activateCurrentPanel(panel_name);
                            scope.toggleSidePanel("right-side-menu", false);
                            $timeout(function () {
                                scope.activateCurrentPanel(panel_name);
                            });
                        }
                        scope.focusInPanel(scope.right_panel_holder_el, panel_name);
                    } else {
                        scope.closePanels();
                    }
                    scope.lastOpened = Date.now();
                };

                scope.getPanelFromName = function (panel_name) {
                    var item,
                        keys = Object.keys(scope.panels);
                    for (var i = 0, l = keys.length; i < l; i++) {
                        item = scope.panels[keys[i]];

                        if (item.name === panel_name) {
                            return item;
                        }
                    }
                };

                scope.updateActivePanel = function (activePanelName) {
                    for (let [panelName, panelScope] of Object.entries(scope.activePanelScopes)) {
                        if (panelScope !== null) {
                            panelScope.isActivePanel = panelName === activePanelName;
                        }
                    }
                };

                scope.activateCurrentPanel = function (panel_name, historyItemRemoved) {
                    var item,
                        length = scope.panelContentHistory.length - 1,
                        active = { element: null },
                        keys = Object.keys(scope.panels);

                    for (var i = 0, l = keys.length; i < l; i++) {
                        item = scope.panels[keys[i]];

                        if (item.element !== null) {
                            if (item.name === panel_name) {
                                active = item;

                                item.element.addClass(activeClass);
                                if (item.element.hasClass(previousClass)) {
                                    item.element.removeClass(previousClass);
                                }

                                //Stops elements from crossing over each other when switching back and forth.
                                if (length >= 2 && item.name === scope.panelContentHistory[length - 2].name && !historyItemRemoved) {
                                    item.element.addClass(repeatClass);
                                }
                            } else if (item.element !== active.element && item.element.hasClass(activeClass)) {
                                item.element.removeClass(activeClass);
                                item.element.removeClass(repeatClass);
                            }

                            if (length >= 1 && item.name === scope.panelContentHistory[length - 1].name) {
                                item.element.addClass(previousClass);
                            }
                        }
                    }
                };

                scope.closeCurrentPanel = function (panel_name) {
                    var item,
                        lastItem = scope.panelContentHistory.length - 1,
                        keys = Object.keys(scope.panels);
                    for (var i = 0, l = keys.length; i < l; i++) {
                        item = scope.panels[keys[i]];

                        if (item.name === panel_name) {
                            item.element.removeClass(activeClass);
                        } else if (item.name === scope.panelContentHistory[lastItem].name) {
                            item.element.removeClass(previousClass);
                        }
                    }
                };

                scope.removePanelClasses = function () {
                    var item,
                        keys = Object.keys(scope.panels);
                    for (var i = 0, l = keys.length; i < l; i++) {
                        item = scope.panels[keys[i]];

                        if (item.element !== null) {
                            if (item.element.hasClass(activeClass)) {
                                item.element.removeClass(activeClass);
                            } else if (item.element.hasClass(previousClass)) {
                                item.element.removeClass(previousClass);
                            }

                            if (item.element.hasClass(repeatClass)) {
                                item.element.removeClass(repeatClass);
                            }
                        }
                    }
                };

                scope.removePanelData = function () {
                    var item,
                        keys = Object.keys(scope.panels);
                    for (var i = 0, l = keys.length; i < l; i++) {
                        item = scope.panels[keys[i]];

                        //Check to see if the panel can be 'cleaned' - SpaHistory cannot.
                        if (item.element !== null && item.canDelete === true) {
                            if (scope.activePanelScopes[item.name] !== undefined) {
                                scope.activePanelScopes[item.name] = null;
                            }

                            item.element.remove();
                            item.element = null;
                        }
                    }
                };

                scope.previousPanel = function (eraseData, stopExtraAction) {
                    scope.right_panel_holder_el.removeClass("right-panel-holder__width-larger");
                    const panelHistoryItem = scope.panelContentHistory.pop();
                    let panel;
                    if (panelHistoryItem !== undefined) {
                        panel = scope.getPanelFromName(panelHistoryItem.name);
                    }
                    //If you can't find a panel, resort to default behaviour and try and close any panels
                    if (panel === undefined) {
                        scope.closePanels(stopExtraAction);
                        return;
                    }

                    if (panel.canDelete) {
                        panel.element.remove();
                    }
                    const length = scope.panelContentHistory.length;

                    //Erase cached data
                    if (eraseData) {
                        scope.data = null;
                        scope.panelActionData = {};
                    }
                    //Activate last known panel
                    if (length > 0) {
                        const previousPanel = scope.panelContentHistory[length - 1];
                        scope.updateActivePanel(previousPanel.name);
                        scope.activateCurrentPanel(previousPanel.name, true);
                        if (typeof panelHistoryItem.onPanelClose === "function") {
                            panelHistoryItem.onPanelClose();
                        }
                    } else {
                        scope.closePanels(stopExtraAction, panelHistoryItem.onPanelClose);
                    }
                };

                scope.overlayCloseMenu = () => {
                    let onPanelClose;
                    if (scope.panelContentHistory.length > 0) {
                        onPanelClose = scope.panelContentHistory[0].onPanelClose;
                    }
                    scope.closePanels(true, onPanelClose);
                };

                scope.closePanels = function (stop_extra_fn, onPanelClose) {
                    scope.right_panel_holder_el.removeClass("right-panel-holder__width-larger");
                    var promise = subscriptionServiceProvider.runSubscriptions("spaMenu");
                    scope.searchString = "";

                    //Only runs if all of the subscribed promises are successfully resolved. If you need to handle a rejection, add an extra callback parameter to the .then
                    promise
                        .then(() => {
                            scope.forceCloseSidePanels(stop_extra_fn, onPanelClose);
                        })
                        .catch(function (error) {
                            if (scope.focusTrapId === null) {
                                scope.focusTrapId = scope.focusTrapService.addTrap(scope.container_el, [scope.spaAppContainer, scope.spaMenuWrapper]);
                            }
                        });
                };

                // make it possible to close side panel via $broadcast, from any other component
                scope.$on("spaMenuClosePanels", scope.closePanels);

                scope.forceCloseSidePanels = function (stop_extra_fn, onPanelClose = null) {
                    if (scope.extraActionFromOtherSidePanel && !stop_extra_fn) {
                        scope.extraActionFromOtherSidePanel();
                    }

                    if (scope.actionMenuOpen) {
                        scope.closeSidePanel(scope.action_menu_holder_el);
                        scope.actionMenuOpen = false;
                    }
                    if (scope.right_panel_holder_el.hasClass(activeClass)) {
                        scope.closeSidePanel(scope.right_panel_holder_el);
                    }

                    scope.panel_backing_el.removeClass(activeClass);
                    scope.removePanelClasses();
                    scope.removePanelData();
                    scope._unbindAccessKeys();

                    if (typeof onPanelClose === "function") {
                        onPanelClose();
                    }

                    if (typeof scope.unbindAccessKeys === "function") {
                        scope.unbindAccessKeys();
                    }

                    scope.panelContentHistory.length = 0;
                };

                scope.toProductHome = function () {
                    var url = scope.productUrl ? scope.productUrl : "/";
                    $location.path(url);
                };

                scope.updateViewFromRole = function (user) {
                    scope.viewSegments.menu.display = spaDisplayHelper.checkRoles(scope.viewSegments.menu, user);
                    scope.viewSegments.products.display = spaDisplayHelper.checkRoles(scope.viewSegments.products, user);
                };

                // this is used to open the right hand side panel from non-shared components
                $rootScope.$on("openRightHandSidePanel", function (event, data) {
                    scope.data = data;
                    if (data.actionData !== undefined) {
                        scope.panelActionData = data.actionData;
                    } else {
                        scope.panelActionData = {};
                    }

                    if (scope.excludeAssetFunctionality && scope.excludeAssetFunctionality.length) {
                        if (!scope.panelActionData.excludeAssetFunctionality) {
                            scope.panelActionData.excludeAssetFunctionality = [];
                        }
                        scope.panelActionData.excludeAssetFunctionality = scope.panelActionData.excludeAssetFunctionality.concat(scope.excludeAssetFunctionality);
                        scope.panelActionData.excludeAssetFunctionality = scope.panelActionData.excludeAssetFunctionality.distinct();
                    }

                    if (data.panel !== "selector") {
                        scope.openRightSidePanel(data.panel, null, null, data.onPanelClose);
                        return;
                    }

                    const onPanelClose = (...args) => {
                        scope.extraActionFromOtherSidePanel();
                        if (typeof data.onPanelClose === "function") {
                            data.onPanelClose.apply(null, args);
                        }
                    };
                    scope.openRightSidePanel(data.panel, null, null, onPanelClose);
                    scope.$emit("setupSelector", data);
                });

                $rootScope.$on("openRightHandHistoryPanel", function (event, data) {
                    scope.openRightSidePanel("history", "", data);
                });

                $rootScope.$on("openRightHandAssetStore", function (event, store_name) {
                    scope.openRightSidePanel("asset-store", store_name);
                });

                $rootScope.$on("closeRightHandSidePanel", function (event, data) {
                    scope.closeSidePanel(scope.right_panel_holder_el);
                });

                $rootScope.$on("forceCloseRightHandSidePanel", function (event, data) {
                    scope.forceCloseSidePanels(scope.right_panel_holder_el);
                });

                $rootScope.$on("openUserSettingModal", function () {
                    UserSettingsService.showUserSettingsModal();
                });

                $rootScope.$on("openUploadFileModal", function (event, data) {
                    if (data === true) {
                        modal.newModal(
                            {
                                scope: scope,
                                templateUrl: baseUrlsFactory.shared_component_base_url + "fileUpload/templates/modal-wrapper.html"
                            },
                            true
                        );
                    } else {
                        $timeout(function () {
                            modal.newModal(
                                {
                                    scope: scope,
                                    templateUrl: baseUrlsFactory.shared_component_base_url + "fileUpload/templates/modal-wrapper.html"
                                },
                                true
                            );
                        }, 210);
                    }
                });

                // todo: refactor this
                $rootScope.$on("createAndOpenWebfolio", function (event, forceHistory) {
                    assetCreateService.createAndGoToWebfolio(forceHistory);
                });
                $rootScope.$on("createAndOpenFoliopage", function (event, forceHistory, onBeforeRedirect, keepHistory) {
                    assetCreateService.createAndGoToFolioPage(forceHistory, onBeforeRedirect, keepHistory);
                });
                $rootScope.$on("createAndOpenCollection", function () {
                    assetCreateService.createAndGoToCollection();
                });
                $rootScope.$on("createAndOpenActivityLog", function () {
                    assetCreateService.createAndGoToActivityLog();
                });
                $rootScope.$on("createAndOpenBlog", function () {
                    assetCreateService.createAndGoToBlog();
                });
                $rootScope.$on("createAndOpenTemplateBuilder", function (event, forceHistory) {
                    assetCreateService.createAndGoToTemplateBuilder(forceHistory);
                });

                $rootScope.$on("createAndOpenWorkbookBuilder", function (event, forceHistory) {
                    assetCreateService.createAndGoToWorkbookBuilder(forceHistory);
                });

                scope.$on("$routeChangeStart", function (event, next, current) {
                    if (scope.any_panel_enabled === true) {
                        scope.closePanels(scope.right_panel_holder_el);
                    }
                });

                scope.$on("loginSuccess", function (event, obj) {
                    scope.isLoggedIn = obj.user.UserId;
                    scope.updateViewFromRole(obj.user);
                });

                scope.$on("menuUpdate", function (event, doRequest, reset, user) {
                    scope.menuUpdate(doRequest, reset, user);
                });

                scope.updateSpaData({ updateProducts: true });

                scope.spa_products_icon_folder = $sce.getTrustedResourceUrl(baseUrlsFactory.shared_component_base_url + "spaMenu/styles/images/");
            }
        };
    }
]);
