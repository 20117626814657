import { angularAMD } from "@pebblepad/amd";
import "../../../utilities/baseUrlsFactory";
import "../../../tagger/tagger";
import "../../../datePicker/datePicker";
import "../../../dropdown/dropdown";
import "../../../loadingSpinner/loadingSpinner";
import "../../../extensions/arrayExtensions";
import "../../../assetStore/services/filterFactory";
import "../../services/collectionService";
import "../../../builder/dataManager/dtoFactory";
import "../../../mappers/assetMapper";
import "../../../assetStore/directives/advancedSearchContent";
import "../../../utilities/helpers";
import template from "../templates/criteria-advanced.html";

angularAMD.directive("criteriaAdvanced", [
    "$sce",
    "baseUrlsFactory",
    "multiLanguageService",
    "$rootScope",
    "$timeout",
    "filterFactory",
    "collectionService",
    "dtoFactory",
    "assetMapper",
    "helpers",
    function ($sce, baseUrlsFactory, multiLanguageService, $rootScope, $timeout, filterFactory, collectionService, dtoFactory, assetMapper, helpers) {
        return {
            restrict: "E",
            template: template,
            scope: {
                wrapperId: "@", //String Id of CollectionWrapperDto
                collection: "=", //CollectionDto
                onConfirm: "=", //Callback Function
                onCancel: "=", //Callback Function
                disableSearchResult: "=",
                disableResponsesToFilter: "<",
                showAllTypes: "=", // don't get type in the filter from server... just show all
                allowSearchDefaulting: "<"
            },

            controller: [
                "$scope",
                function ($scope) {
                    //Initialise variables ---------------------------------------------------------------------------------
                    $scope.multiLanguageService = multiLanguageService;
                    $scope.filterFactory = new filterFactory();
                    $scope.filterOptions = null;
                    $scope.searchType = "asset";
                    $scope.buttonOptions = [];
                    $scope.collectionDto = new dtoFactory.CollectionDto({});
                    $scope.collectionDto.Search.ExcludeAssetIds = [$scope.wrapperId];

                    $scope.criteria = {
                        assetTypes: null,
                        responsesTo: null,
                        ownership: null,
                        tags: [],
                        keywords: null,
                        dateFrom: null,
                        dateTo: null,
                        enableSearchByDefault: false
                    };

                    $scope.viewData = {
                        saving: false,
                        loaded: false,
                        labels: {
                            dates: {
                                after: $scope.multiLanguageService.getString("collections.criteria.datePicker.after"),
                                before: $scope.multiLanguageService.getString("collections.criteria.datePicker.before")
                            },
                            dateOptions: {}
                        }
                    };

                    $scope.elements = {
                        dropdowns: null
                    };

                    //Functions --------------------------------------------------------------------------------------------
                    $scope.loadFromExistingCriteria = function (collectionDto) {
                        $scope.collectionDto = collectionDto;
                        assetMapper.collection.collectionDtoToCriteria($scope.collectionDto, $scope.criteria);

                        //Link has not initialised yet, no need to reset the dropdown events
                        if ($scope.elements.dropdowns) {
                            $scope.resetDropdownEvents();
                        }
                    };

                    $scope.addCriteria = function (criteriaFilters) {
                        var collectionCriteria = $scope.collectionDto;
                        //Map Data
                        assetMapper.collection.advancedFiltersToCollection(criteriaFilters, collectionCriteria);
                        $scope.onConfirm(collectionCriteria);
                    };

                    $scope.resetDropdownEvents = function () {
                        angular.forEach($scope.elements.dropdowns, function (dropdown_el) {
                            angular.element(dropdown_el).scope().reset();
                        });
                    };

                    //Private functions ------------------------------------------------------------------------------------
                    //Map filter value to Criteria, then compile filterData and update the search criteria.
                    var onFilterChange = function (event, filterName, filter, isNotEmpty) {
                        event.stopPropagation();
                        var criteria = $scope.criteria,
                            criteriaFilter = criteria[filterName];
                        if (criteriaFilter !== undefined) {
                            criteria[filterName] = filter.Filter;
                        }
                        doSearch(isNotEmpty);
                    };

                    var doSearch = function (isNotEmpty) {
                        if (isNotEmpty) {
                            var criteria = $scope.criteria,
                                filterData = {
                                    SearchType: "asset",
                                    Keywords: helpers.isNotEmptyArray($scope.criteria.keywords) ? $scope.criteria.keywords : null,
                                    Ownership: [criteria.ownership],
                                    TypeFilters: criteria.assetTypes ? [criteria.assetTypes] : [],
                                    FormId: criteria.responsesTo !== null ? criteria.responsesTo.Id : null,
                                    LastModifiedBefore: criteria.dateTo,
                                    LastModifiedAfter: criteria.dateFrom,
                                    Tags: $scope.criteria.tags
                                };
                        }
                        if (!filterData || !filterData.Keywords) {
                            $scope.$emit("criteriaUpdated", filterData);
                        }
                    };

                    var init = function () {
                        if ($scope.showAllTypes === true) {
                            $scope.filterOptions = { filters: $scope.filterFactory.getAllFilters() };
                        } else {
                            $scope.filterFactory.init().then(function () {
                                var availableSearches = $scope.filterFactory.getAvailableSearches();
                                // Find selected search object from available searches
                                $scope.filterOptions = availableSearches[availableSearches.findInNestedObject("id", "asset")];
                                $scope.filterOptions.filters.secondary.ownership = null;
                                var searchObj = $scope.collectionDto.Search;

                                if (helpers.isNotEmptyArray(searchObj.Keywords)) {
                                    $scope.filterOptions.filters.primary.keywords = searchObj.Keywords.join(" ");
                                }

                                if ($scope.collection) {
                                    $timeout(function () {
                                        doSearch(true);
                                    }, 250);
                                }
                            });
                        }

                        $scope.buttonOptions.push(
                            {
                                title: $scope.multiLanguageService.getString("buttons.confirm"),
                                class: "btn-success",
                                action: $scope.addCriteria,
                                validation: true
                            },
                            {
                                title: $scope.multiLanguageService.getString("buttons.cancel"),
                                class: "btn-danger",
                                action: $scope.onCancel,
                                validation: false
                            }
                        );
                    };

                    //EVENTS -----------------------------------------------------------------------------------------------
                    $scope.$on("searchFilterChange", onFilterChange);
                    $scope.$on("dateToggleLoaded", function () {
                        $scope.viewData.loaded = true;
                    });

                    //Init -------------------------------------------------------------------------------------------------
                    init();
                }
            ],

            link: function (scope, element, attr) {
                //Init -------------------------------------------------------------------------------------------------
                //Temp fix for dropdown registering events too early...this needs fixing or at least a cleaner way to handle it.
                $timeout(function () {
                    scope.elements.dropdowns = element[0].querySelectorAll(".filter-dropdown[dropdown]");
                    scope.resetDropdownEvents();
                }, 250);

                if (scope.collection) {
                    scope.loadFromExistingCriteria(scope.collection);
                }
            }
        };
    }
]);
