import { REDIRECT_BLOCKING_PURPOSES } from "../../constants/redirectBlockingPurposes.constants";

export class UnsavedChangesPreventRedirectResolver {
    /**
     * @typedef UnsavedChangesWarningMessage
     * @type {object}
     * @property {string} cancelRedirectBtn
     * @property {string} continueBtn
     * @property {string} title
     * @property {string} message
     */

    /**
     * @param {{ logout: UnsavedChangesWarningMessage, locationChange: UnsavedChangesWarningMessage }} messages
     */
    constructor(messages) {
        this.purpose = REDIRECT_BLOCKING_PURPOSES.GENERIC;
        this._trackedIds = new Set();
        this._messages = messages;
    }

    addTrackedId(id) {
        if (!this._trackedIds.has(id)) {
            this._trackedIds.add(id);
        }
    }

    removeTrackedId(id) {
        if (this._trackedIds.has(id)) {
            this._trackedIds.delete(id);
        }
    }

    redirectionIsBlocked() {
        return this._trackedIds.size > 0;
    }

    onLocationChange(newUrl, currentUrl) {
        const nextRoute = newUrl.split("?");
        const currentRoute = currentUrl.split("?");
        const nextUrlParams = new URLSearchParams(nextRoute[1]);
        const currentUrlParams = new URLSearchParams(currentRoute[1]);
        const sameRoute = nextRoute[0] === currentRoute[0] && nextUrlParams.get("pageId") === currentUrlParams.get("pageId");

        return this.redirectionIsBlocked() ? { allowRedirect: sameRoute, removeWatcher: !sameRoute } : { allowRedirect: true, removeWatcher: !sameRoute };
    }

    getLogoutMessage() {
        return this._messages.logout;
    }

    getLocationChangeMessage() {
        return this._messages.locationChange;
    }

    cleanUp() {
        this._trackedIds.clear();
    }

    /**
     * @param {getString(): string} multiLanguageService
     * @returns {{ logout: UnsavedChangesWarningMessage, locationChange: UnsavedChangesWarningMessage }}
     */
    static defaultMessages(multiLanguageService) {
        return {
            logout: {
                title: multiLanguageService.getString("logout.title_unsaved_changes"),
                message: multiLanguageService.getString("logout.message_logout_unsaved_changes"),
                continueBtn: multiLanguageService.getString("buttons.logout_without_saving"),
                cancelRedirectBtn: multiLanguageService.getString("buttons.cancel")
            },
            locationChange: {
                title: multiLanguageService.getString("logout.title_unsaved_changes"),
                message: multiLanguageService.getString("logout.message_unsaved_changes"),
                continueBtn: multiLanguageService.getString("buttons.discard_changes"),
                cancelRedirectBtn: multiLanguageService.getString("buttons.cancel")
            }
        };
    }
}
