import { makeStringRegexSafeFunction } from "./MakeStringRegexSafe.function";

export function getBestTextMatches(dataSet, toMatch, dataSetKey) {
    const matchingItems = [];
    const weights = new Map();
    const toMatchLower = toMatch.toLowerCase();
    const findMatchRegex = new RegExp(`(^|[-"'\\s])${makeStringRegexSafeFunction(toMatchLower)}([-"'\\s]|$)`);

    for (const item of dataSet) {
        const toCompare = item[dataSetKey].toLowerCase();
        if (!toCompare.includes(toMatchLower)) {
            continue;
        }

        const exactMatchIndex = toCompare.search(findMatchRegex);
        const startsWithSearchText = toCompare.startsWith(toMatchLower);

        let exactMatchWeight = 0;
        if (exactMatchIndex !== -1) {
            exactMatchWeight = exactMatchIndex + 1 <= toMatchLower.length ? 1 : toMatchLower.length / exactMatchIndex;
        }

        const normalWeight = toMatchLower.length / toCompare.length;
        const initialMatchWeight = startsWithSearchText ? normalWeight : 0;
        const weight = normalWeight + exactMatchWeight + initialMatchWeight;

        weights.set(item, weight);
        matchingItems.push(item);
    }

    return matchingItems.sort((a, b) => weights.get(b) - weights.get(a));
}
