import { angularAMD } from "@pebblepad/amd";
import { STORE_CONSTANTS } from "../../constants/store.constants";
import { commentIcon, feedbackCommentIcon, informationIcon, shareToWebIcon, shareToPeopleIcon, shareToWorkspaceIcon, shareIcon } from "@pjs/core-ui";
import { favouritesObserver } from "../../observer/favourites/FavouritesObserver.const";
import "../../react2angular/icon";
import "../../dropdown/dropdown";
import "../services/assetStoreService";
import "../../utilities/baseUrlsFactory";
import "../../comboBox/directives/comboBox";
import "../../expandable/expandable";
import "../../datePicker/datePicker";
import "./searchResults";
import "../services/filterFactory";
import "../../datePicker/datePickerHelper";
import "../../shareStart/directives/shareStart";
import "../../share/directives/share";
import "../../modal/directives/modalDialog";
import "../services/useResourceService";
import "../../takeACopy/directives/takeACopy";
import "../../tagger/tagger";
import "../../utilities/assetActionService";
import "../../modal/services/modal";
import "./advancedSearchContent";
import "../../a11y/services/a11yGridFactory.service";
import "../../accessKeys/accessKey.service";
import "../../heading/heading.component";
import template from "../templates/asset-store.html";

angularAMD.directive("assetStore", [
    "$sce",
    "$timeout",
    "$rootScope",
    "$compile",
    "assetStoreService",
    "baseUrlsFactory",
    "filterFactory",
    "datePickerHelper",
    "$routeParams",
    "$location",
    "useResourceService",
    "assetActionService",
    "modal",
    "multiLanguageService",
    "A11yGridFactory",
    "accessKeyService",
    function (
        $sce,
        $timeout,
        $rootScope,
        $compile,
        assetStoreService,
        baseUrlsFactory,
        filterFactory,
        datePickerHelper,
        $routeParams,
        $location,
        useResourceService,
        assetActionService,
        modal,
        multiLanguageService,
        A11yGridFactory,
        accessKeyService
    ) {
        return {
            template: template,
            restrict: "E",
            controller: [
                "$scope",
                function ($scope) {
                    $scope.STORE_CONSTANTS = STORE_CONSTANTS;
                    $scope.defaultExcludeOptions = ["Tag", "Export"];
                    $scope.excludeOptions = $scope.defaultExcludeOptions.slice(); //Copy Defaults - Mutable array.
                    $scope.selectedAssetType = "";
                    // SVG Images
                    $scope.imageComments = commentIcon;
                    $scope.imageFeedback = feedbackCommentIcon;
                    $scope.imageShare = shareIcon;
                    $scope.imageShareToPeople = shareToPeopleIcon;
                    $scope.imageShareForAssessment = shareToWorkspaceIcon;
                    $scope.imageShareToWeb = shareToWebIcon;
                    $scope.imageInfo = informationIcon;
                    // PNG Images
                    $scope.tickUrl = $sce.getTrustedResourceUrl(baseUrlsFactory.shared_component_base_url + "images/asset/tick.png");
                    $scope.advancedSearchLabels = {
                        dates: {
                            after: multiLanguageService.getString("date_picker.from"),
                            before: multiLanguageService.getString("date_picker.to")
                        },
                        dateOptions: {}
                    };
                    $scope.multiLanguageService = multiLanguageService;

                    if (!$scope.activePage) {
                        $scope.activePage = {};
                    }

                    //Advanced Search --------------------------------------------------------------------------------------
                    $scope.onAdvanceSearch = function (filters) {
                        $scope.applyFilters(filters);
                        $scope.stage = $scope.factory.getCurrentStage();

                        if ($scope.activePage.type === STORE_CONSTANTS.STORE_TYPE.RESOURCE && $scope.activeResourceButton !== filters.assetTypes.DisplayName) {
                            $scope.toggleResourceFilterBtns();
                        }

                        $scope.doSearch($scope.chosenSearch.id);
                    };

                    $scope.applyFilters = function (filters) {
                        var filter;

                        for (var key in filters) {
                            if (filters.hasOwnProperty(key)) {
                                filter = filters[key];

                                if (filter && filter.Filter && (!filter.Filter.length || filter.Filter.length > 0)) {
                                    $scope.addFilter(filter, filter.FilterType, Array.isArray(filter.Filter));
                                } else {
                                    $scope.removeFilter(filter);
                                }
                            }
                        }
                    };
                }
            ],
            link: function (scope, element, attrs) {
                //used to make sure that the side bar open readout only happens the first time the sidebar is opened.

                attrs.$observe("headingLevel", function (newVal) {
                    scope.headingLevel = parseInt(newVal, 10);
                    scope.searchResultsHeadingLevel = scope.headingLevel + 1;
                });

                attrs.$observe("storeLabel", function (newVal) {
                    scope.storeLabel = newVal;
                });

                clearTimeout(scope.accessibility_timer);
                scope.accessibility_timer = setTimeout(function () {
                    var child = document.getElementById("accessibility-readout-node");
                    if (parent) {
                        var parent = child.parentNode;
                        parent.removeChild(child);
                    }
                }, 1500);

                scope.helpData = {
                    content: [
                        {
                            Image: "",
                            Text: baseUrlsFactory.shared_component_base_url + "assetStore/templates/help/asset-store-help-slide-1.html",
                            BackgroundColour: "#FFF",
                            Order: 1
                        },
                        {
                            Image: "",
                            Text: baseUrlsFactory.shared_component_base_url + "assetStore/templates/help/asset-store-help-slide-2.html",
                            BackgroundColour: "#FFF",
                            Order: 2
                        },
                        {
                            Image: "",
                            Text: baseUrlsFactory.shared_component_base_url + "assetStore/templates/help/asset-store-help-slide-3.html",
                            BackgroundColour: "#FFF",
                            Order: 3
                        },
                        {
                            Image: "",
                            Text: baseUrlsFactory.shared_component_base_url + "assetStore/templates/help/asset-store-help-slide-4.html",
                            BackgroundColour: "#FFF",
                            Order: 4
                        },
                        {
                            Image: "",
                            Text: baseUrlsFactory.shared_component_base_url + "assetStore/templates/help/asset-store-help-slide-5.html",
                            BackgroundColour: "#FFF",
                            Order: 5
                        },
                        {
                            Image: "",
                            Text: baseUrlsFactory.shared_component_base_url + "assetStore/templates/help/asset-store-help-slide-6.html",
                            BackgroundColour: "#FFF",
                            Order: 6
                        }
                    ],
                    type: "banner"
                };

                scope.resourceButtonFilters = {
                    custom: {
                        Icon: "generic-template",
                        DisplayName: multiLanguageService.getString("asset_store.buttons.resourceButtonFiltersCustom"),
                        Filter: "CustomTemplates",
                        FilterType: "primary.assetTypes",
                        Visible: false
                    },
                    pebble: {
                        Icon: "generic-template",
                        DisplayName: multiLanguageService.getString("asset_store.buttons.resourceButtonFiltersPebble"),
                        Filter: "PebbleTemplates",
                        FilterType: "primary.assetTypes",
                        Visible: false
                    },
                    workbook: {
                        Icon: "workbook-reversed",
                        DisplayName: multiLanguageService.getString("asset_store.buttons.resourceButtonFiltersWorkbook"),
                        Filter: "WorkBook",
                        FilterType: "primary.assetTypes",
                        Visible: false
                    }
                };

                var actionPromise;

                // Cached elements
                var search_results_list_el = angular.element(element[0].querySelector("#search-results-list"));
                var narrow_your_search_el = angular.element(element[0].querySelector("#search-filter-advanced-bar"));
                var scroller_el = element[0].querySelector(".scroller");

                // Init scope variables
                scope.loading = true;

                scope.currentProduct = attrs["currentProduct"];

                scope.isSidebar = attrs["sidebarMode"] !== undefined;

                scope.allowMultiSelect = attrs["allowMultiSelect"] === undefined || attrs["allowMultiSelect"] === "true";
                scope.selectOnly = attrs["selectOnly"] === "true";

                scope.comboBoxClass = scope.isSidebar ? "combo-box" : "combo-box-light";
                scope.orderByOptions = [
                    {
                        title: multiLanguageService.getString("asset_store.order_by.last_modified_desc"),
                        value: ["LastModified", "Title"],
                        reverse: true,
                        name: "Modified date (newest first)"
                    },
                    {
                        title: multiLanguageService.getString("asset_store.order_by.last_modified_asc"),
                        value: ["LastModified", "Title"],
                        reverse: false,
                        name: "Modified date (oldest first)"
                    },
                    {
                        title: multiLanguageService.getString("asset_store.order_by.created_date_desc"),
                        value: ["Created", "Title"],
                        reverse: true,
                        name: "Created date (newest first)"
                    },
                    {
                        title: multiLanguageService.getString("asset_store.order_by.created_date_asc"),
                        value: ["Created", "Title"],
                        reverse: false,
                        name: "Created date (oldest first)"
                    },
                    {
                        title: multiLanguageService.getString("asset_store.order_by.title_asc"),
                        value: ["Title", "Created"],
                        reverse: false,
                        name: "Title (A - Z)"
                    },
                    {
                        title: multiLanguageService.getString("asset_store.order_by.title_desc"),
                        value: ["Title", "Created"],
                        reverse: true,
                        name: "Title (Z - A)"
                    },
                    {
                        title: multiLanguageService.getString("asset_store.order_by.comments"),
                        value: ["CommentCount", "Title"],
                        reverse: true,
                        name: "No. of comments"
                    },
                    {
                        title: multiLanguageService.getString("asset_store.order_by.feedback"),
                        value: ["FeedbackCount", "Title"],
                        reverse: true,
                        name: "No. of feedback items"
                    },
                    {
                        title: multiLanguageService.getString("asset_store.order_by.shares"),
                        value: ["ShareCount", "ShareStatus"],
                        reverse: true,
                        name: "No. of shares"
                    },
                    {
                        title: multiLanguageService.getString("asset_store.order_by.submissions"),
                        value: ["SubmissionCount", "Title"],
                        reverse: true,
                        name: "No. of submissions"
                    },
                    {
                        title: multiLanguageService.getString("asset_store.order_by.share_to_web"),
                        value: ["PublicShare", "ShareStatus"],
                        reverse: true,
                        name: "Shared to web"
                    }
                ];

                scope.searchButtons = [
                    {
                        title: multiLanguageService.getString("asset_store.buttons.search_now"),
                        class: "btn-success wide",
                        action: scope.onAdvanceSearch,
                        validation: false
                    }
                ];

                var resetSearchFilters = function () {
                    scope.showResults = false;
                    scope.showToolbar = false;
                    scope.selectedArray = [];
                    scope.resultLimit = 5;
                    scope.tag = ""; // tag text box
                    scope.includeHidden = false;
                    scope.filterDates = {
                        // from and to dates
                        dateFrom: null,
                        dateTo: null
                    };
                    scope.secondaryFilters = {
                        // object to map the view to
                        tags: []
                    };

                    scope.availableSearches = scope.factory.getAvailableSearches();
                    scope.externalServices = scope.factory.getExternalServices();
                    scope.stage = scope.factory.getCurrentStage();
                    scope.viewData = {};

                    scope.chosenSearch = scope.availableSearches[0];

                    scope.loading = false;
                    scope.tagUpdate = false;
                };

                scope.factory = new filterFactory();

                scope.adjustLocalsForFilters = function () {
                    scope.filters = scope.factory.getAppliedFilters(true);
                    scope.filterCount = scope.filters.length;
                    scope.filterCountText = scope.multiLanguageService.getString("asset_store.accessibility.asset_store.number_of_applied_filters", { count: scope.filterCount });
                };

                scope.adjustLocalsForFilters();

                // Init scope arrays
                scope.selectedArray = [];

                scope.bindAccessKeys = function () {
                    const q = {
                        key: "q",
                        altKey: true,
                        callback: function () {
                            document.getElementsByClassName("asset-store-action-bar")[0].focus();
                        }
                    };

                    accessKeyService.register("assetStore", [q]);
                };

                scope.unbindAccessKeys = function () {
                    accessKeyService.remove("assetStore");
                };

                scope.factory.init().then(function () {
                    resetSearchFilters();
                    scope.bindAccessKeys();
                    scope.$emit("storeInitialised", scope.externalServices);
                    // You can start searching straight away
                    // tslint:disable-next-line:triple-equals
                    if (attrs["startingSearchType"] != undefined) {
                        scope.searchType = attrs["startingSearchType"];
                        scope.doSearch(scope.searchType);
                        // Also observe the attribute for further changes
                        attrs.$observe("startingSearchType", function (newVal) {
                            var temp = scope.viewData.SearchTerms;
                            resetSearchFilters();
                            scope.viewData.SearchTerms = temp;
                            // Remove all filters
                            for (var i = 0; i < scope.factory.getAppliedFilters().length; i++) {
                                scope.factory.removeFilter(i);
                            }
                            scope.adjustLocalsForFilters();
                            // Close expanded filter panels
                            if (narrow_your_search_el.hasClass("expanded")) {
                                scope.close_on_demand = true;
                            }
                            scope.changeStage("standard-filtering");
                            scope.doSearch(newVal);
                        });
                    }

                    if (scope.factory.getAssetStoreLoggedInUser()) {
                        //open the help because the user is new and hasn't closed it yet.
                        scope.$broadcast("openHelpBanner");
                    }

                    if (scope.factory.getUseHiddenFolder()) {
                        scope.showIncludeHiddenOption = true;
                    }
                });

                scope.advancedSearchClick = function () {
                    scope.$broadcast("resetTagsList");
                    scope.showSecondaryFilters = !scope.showSecondaryFilters;
                    toggleAdvancedFiltersIcon();
                    if (scope.showSecondaryFilters) {
                        scroller_el.scrollTop = 0;
                    }
                };

                var toggleAdvancedFiltersIcon = function () {
                    var advanced_filters_btn_icon_el = angular.element(element[0].querySelector("#advanced-search-btn-icon"));
                    if (scope.showSecondaryFilters) {
                        //it's open
                        if (!advanced_filters_btn_icon_el.hasClass("icon-thin-arrow-up")) {
                            advanced_filters_btn_icon_el.addClass("icon-thin-arrow-up");
                        }
                    } else {
                        if (advanced_filters_btn_icon_el.hasClass("icon-thin-arrow-up")) {
                            advanced_filters_btn_icon_el.removeClass("icon-thin-arrow-up");
                        }
                    }
                };

                scope.doSearch = function (searchId) {
                    //Reset mutable array back to default on new search.
                    scope.excludeOptions = scope.defaultExcludeOptions.slice();
                    scope.showSecondaryFilters = false;
                    toggleAdvancedFiltersIcon();

                    // Reset results in view
                    var html = "";
                    search_results_list_el.empty();
                    scope.didYouWant = scope.factory.resetDidYouWant();

                    // Set length to 0 to make sure all references to the array are reset correctly (different to setting it to new array!!)
                    scope.selectedArray.length = 0;
                    scope.showToolbar = false;

                    if (!searchId) {
                        searchId = STORE_CONSTANTS.SEARCH_TYPE.DEFAULT;
                    }
                    searchId = searchId.toLowerCase();

                    // Check to see if search terms contain reserved words (eg. blog, webfolio)
                    scope.factory.checkReservedWordList(scope.viewData.SearchTerms, searchId);

                    //Check for did you mean phrase.
                    scope.didYouWant = scope.factory.getDidYouWant();
                    // Get the available searches again
                    scope.availableSearches = scope.factory.getAvailableSearches();

                    // Find selected search object from available searches
                    for (var i = 0; i < scope.availableSearches.length; i++) {
                        if (scope.availableSearches[i].id === searchId) {
                            scope.chosenSearch = angular.copy(scope.availableSearches[i]);

                            if (!scope.isSidebar) {
                                scope.chosenSearch.id = searchId;
                            }

                            break;
                        }
                    }

                    // Apply filters to the selected search
                    scope.viewData = scope.factory.convertFiltersToSearchData(scope.chosenSearch, scope.viewData.SearchTerms, scope.isSidebar);
                    // then clean up tags
                    scope.$broadcast("clearTagsFromFilters");

                    // If searching everything or all external services then group the results
                    if (searchId === STORE_CONSTANTS.SEARCH_TYPE.DEFAULT || (searchId === STORE_CONSTANTS.SEARCH_TYPE.EXTERNAL && scope.isSidebar)) {
                        scope.changeStage("all");
                    } else {
                        // If you have chosen a preset search
                        if (scope.stage === "default") {
                            scope.changeStage("standard-filtering");
                        }
                    }

                    // Build each group of search results
                    scope.resultLimit = 5;

                    if (searchId === STORE_CONSTANTS.SEARCH_TYPE.DEFAULT && !scope.viewData.TypeFilters) {
                        for (i = 0; i < scope.availableSearches.length; i++) {
                            var type = scope.availableSearches[i].id;
                            if (type.toLowerCase() !== STORE_CONSTANTS.SEARCH_TYPE.DEFAULT && type.toLowerCase() !== STORE_CONSTANTS.SEARCH_TYPE.EXTERNAL) {
                                html = html + '<search-results search-type="' + type + '" data-hook="search-' + type + '" heading-level="' + scope.searchResultsHeadingLevel + '"></search-results>';
                            }
                        }
                    } else {
                        // Show a single list of results
                        scope.resultLimit = 100;
                        html = html + "<search-results search-type=" + searchId + " heading-level='" + scope.searchResultsHeadingLevel + "'></search-results>";
                    }

                    scope.showResults = true;
                    // tslint:disable-next-line:no-unsafe-jq-lite
                    search_results_list_el.append($compile(html)(scope));
                };

                scope.showMore = function (searchId) {
                    scope.changeStage("standard-filtering");

                    // If external then add the filter before loading the search
                    if (searchId.toLowerCase().indexOf(STORE_CONSTANTS.SEARCH_TYPE.EXTERNAL) !== -1) {
                        scope.addExternalServiceFilter(searchId);
                    }

                    scope.doSearch(searchId);
                };

                scope.addExternalServiceFilter = function (searchId) {
                    for (var i = 0; i < scope.availableSearches.length; i++) {
                        if (scope.availableSearches[i].id === STORE_CONSTANTS.SEARCH_TYPE.EXTERNAL) {
                            scope.chosenSearch = scope.availableSearches[i];
                        }
                    }

                    var splits = searchId.split("_");
                    // Set the correct location based on the searchType
                    for (i = 0; i < scope.externalServices.length; i++) {
                        var externalService = scope.externalServices[i];
                        if (externalService.ServiceId.toLowerCase() === splits[1].toLowerCase()) {
                            scope.addFilter({
                                DisplayName: externalService.Name,
                                Filter: externalService.ServiceId,
                                FilterType: "location"
                            });
                            break;
                        }
                    }
                };

                scope.addFilter = function (filter, overrideType, isArray) {
                    scope.factory.addFilter(filter, overrideType, isArray);
                    scope.adjustLocalsForFilters();
                    scope.stage = scope.factory.getCurrentStage();

                    $timeout(function () {
                        var first_filter_el = element[0].querySelector(".applied-filter-wrapper");
                        if (first_filter_el) {
                            first_filter_el.focus();
                        }
                    }, 0);
                };

                scope.addFilterAndRunSearch = function (filter, type) {
                    scope.addFilter(filter, type);

                    if (narrow_your_search_el.hasClass("expanded")) {
                        scope.close_on_demand = true;
                    }
                    scope.$broadcast("setActiveFilter", filter);

                    return scope.doSearch(scope.chosenSearch.id);
                };

                scope.runDidYouWantSearch = function (filter, type) {
                    scope.clearSearchTerms();
                    scope.addFilterAndRunSearch(filter, type);
                };

                var setupCheckboxFilters = function () {
                    // Remove it so it can be added if needed
                    removeStoredFilter("secondary.includeHidden");

                    if (scope.includeHidden) {
                        scope.addSecondaryFilter({
                            DisplayName: multiLanguageService.getString("asset_store.labels.show_hidden_items"),
                            Filter: scope.includeHidden,
                            FilterType: "secondary.includeHidden"
                        });
                    }
                };

                scope.removeFilter = function (filter) {
                    removeStoredFilter(filter.FilterType, false, filter.Filter);
                };
                // Called from the page when clicking to remove a filter
                scope.removeVisibleFilter = function (filter) {
                    removeStoredFilter(filter.type, true, filter.value);
                };

                // Remove applied and stored filters
                var removeStoredFilter = function (filterType, doSearch, filterValue) {
                    // Remove from the APPLIED filters
                    var appliedFilters = scope.factory.getAppliedFilters();
                    for (var i = 0; i < appliedFilters.length; i++) {
                        if (appliedFilters[i].type.indexOf(filterType) !== -1 && (filterValue === null || appliedFilters[i].value === filterValue)) {
                            scope.factory.removeFilter(i);
                            scope.$broadcast("removeFilter", filterType, filterValue);

                            if (scope.isSidebar && filterType === "location") {
                                scope.chosenSearch = scope.availableSearches[0];
                            }
                            break;
                        }
                    }
                    scope.adjustLocalsForFilters();

                    if (doSearch) {
                        if (narrow_your_search_el.hasClass("expanded")) {
                            scope.close_on_demand = true;
                        }
                        if (scope.factory.getAppliedFilters().length > 0) {
                            // tslint:disable-next-line:triple-equals
                            return scope.viewData.Location == undefined ? scope.doSearch(scope.chosenSearch.id) : scope.doSearch(scope.chosenSearch.id + "_" + scope.viewData.Location);
                        } else {
                            // Ensure asset type dropdown is reset
                            //scope.viewData.SelectedMainType = undefined;
                            // go back to the everything search
                            if (scope.isSidebar) {
                                return scope.doSearch(scope.availableSearches[0].id);
                            }

                            return scope.doSearch(scope.chosenSearch.id);
                        }
                    }
                };

                scope.clearSearchTerms = function () {
                    scope.viewData.SearchTerms = "";
                    scope.doSearch(scope.chosenSearch ? scope.chosenSearch.id : STORE_CONSTANTS.SEARCH_TYPE.DEFAULT);
                    document.getElementById("search-input").focus();
                };

                scope.changeStage = function (newStage) {
                    var oldStage = scope.stage;

                    scope.stage = scope.factory.setStage(newStage);
                    scope.showSecondaryFilters = scope.stage === "advanced-filtering";

                    // Resetting search
                    // tslint:disable-next-line:triple-equals
                    if (oldStage !== "default" && oldStage != newStage && (newStage === "default" || newStage === "all")) {
                        if (scope.viewData !== null && scope.viewData !== undefined) {
                            // if you press the back button just after trying to load store this errors

                            var temp = scope.viewData.SearchTerms;
                            resetSearchFilters();

                            // If not a full reset then keep the search terms
                            if (newStage !== "default") {
                                scope.viewData.SearchTerms = temp;
                            }

                            if (scope.stage === "default") {
                                scope.chosenSearch = scope.availableSearches[0];
                            }
                        }
                    }
                };

                scope.setActionBarVisibility = function () {
                    scope.showToolbar = scope.selectedArray.length > 0;
                };

                scope.deselectAll = function (e, assetIds) {
                    if (typeof assetIds === "string") {
                        var index;
                        // tslint:disable-next-line:no-conditional-assignment
                        if ((index = scope.selectedArray.indexOf(assetIds)) !== -1) {
                            scope.selectedArray.splice(index, 1);
                        }
                    } else {
                        var newLength = scope.selectedArray.length,
                            currentVal = "",
                            temp = "",
                            foundIndex = 0;

                        //Deselect only duplicates
                        for (var i = 0, l = assetIds.length; i < l; i++) {
                            currentVal = assetIds[i];

                            //Better for garbage collector than splicing every iteration or creating a 'clean' array
                            // tslint:disable-next-line:no-conditional-assignment
                            if ((foundIndex = scope.selectedArray.indexOf(currentVal)) !== -1) {
                                temp = scope.selectedArray[--newLength];
                                scope.selectedArray[newLength] = currentVal;
                                scope.selectedArray[foundIndex] = temp;
                            }
                        }
                        scope.selectedArray.length = newLength;
                    }
                };

                // While the Auto complete is not done do this:
                scope.searchTermsOnKeyDown = function (key_event) {
                    var keyPressed = key_event.which;
                    switch (keyPressed) {
                        case 13: // return pressed
                            cancelDefaultAction(key_event);
                            scope.doSearch(scope.chosenSearch ? scope.chosenSearch.id : STORE_CONSTANTS.SEARCH_TYPE.DEFAULT);
                            break;
                    }
                };

                var cancelDefaultAction = function (e) {
                    // this function cancels the default behaviour of a normal keypress
                    var evt = e ? e : window.event;
                    if (evt.preventDefault) {
                        evt.preventDefault();
                    }
                    evt.returnValue = false;
                    return false;
                };

                scope.hasOptions = function (item) {
                    var allowedOptionsOnAssetTypes = ["Form", "WorkBook"];
                    return allowedOptionsOnAssetTypes.indexOf(item.MainType) > -1 && (!!item.EditUrl || /Collaborate/.test(item.ShareStatus));
                };

                scope.goToBuilder = function (assetId, type) {
                    var typeToLocation = { Form: "template", WorkBook: "workbook" };
                    $location.url("/builder/" + typeToLocation[type] + "/" + assetId);
                };

                scope.editResource = function (item) {
                    scope.goToBuilder(item.Id, item.MainType);
                };

                scope.openResource = function (item) {
                    var details = {
                        AssetId: item.Id,
                        AutoSubmit: item.AutoSubmit,
                        AssignmentId: item.AssignmentId,
                        WorkspaceId: item.WorkspaceId,
                        AutoSubmitTo: item.AutoSubmitTo
                    };

                    useResourceService.checkUse(details);
                };

                scope.selectResource = function (item) {
                    const requestId = $routeParams.requestId;
                    if (requestId !== undefined) {
                        window.location.href = `${baseUrlsFactory.api_base_url}SharedMenu/OpenIdForwarder?requestId=${requestId}&resourceId=${item.Id}`;
                    }
                };

                ////********************
                //// I WANT TO FUNCTIONS
                ////********************

                var assetActionHandler = new assetActionService();

                var removeIWantToListener = scope.$on("executeIWantToOption", function (event, iWantTo_event) {
                    if (!assetActions) {
                        var assetActions = {
                            deleteAssets: { name: "delete", func: assetActionHandler.delete, confirm: true },
                            archiveAssets: { name: "archive", func: assetActionHandler.archive, confirm: true },
                            purgeAssets: { name: "purge", func: assetActionHandler.purge, confirm: true },
                            unarchiveAssets: { name: "unhide", func: assetActionHandler.unarchive, confirm: true },
                            restoreAssets: { name: "restore", func: assetActionHandler.restore, confirm: true },
                            favouriteAssets: {
                                name: "favourite",
                                func: (assetIds) => {
                                    return assetActionHandler.favourite(assetIds).then((response) => {
                                        if (response.length < 1) {
                                            return;
                                        }

                                        favouritesObserver.add(response, scope.isSidebar);
                                        scope.deselectAll(null, response);
                                    });
                                },
                                confirm: false
                            },
                            unfavouriteAssets: {
                                name: "unfavourite",
                                func: (assetIds) => {
                                    return assetActionHandler.unfavourite(assetIds).then((response) => {
                                        if (response.length < 1) {
                                            return;
                                        }

                                        favouritesObserver.remove(response, scope.isSidebar);

                                        if (scope.chosenSearch.id !== STORE_CONSTANTS.SEARCH_TYPE.FAVOURITE) {
                                            scope.deselectAll(null, response);
                                        }
                                    });
                                },
                                confirm: false
                            }
                        };
                    }
                    scope.assetAction = assetActions[iWantTo_event.broadcast_name];
                    if (scope.assetAction) {
                        scope.assetActionName = assetActions[iWantTo_event.broadcast_name].name;
                        scope.plural = scope.selectedArray.length > 1;
                        scope.has_linked_assets = iWantTo_event.has_linked_assets;
                        scope.warning_message = "";

                        if (scope.has_linked_assets) {
                            if (scope.activePage.type === STORE_CONSTANTS.STORE_TYPE.DELETED) {
                                scope.warning_message = multiLanguageService.getString("asset_store.confirmations.items_warning");
                            } else {
                                scope.warning_message =
                                    scope.activePage.type === STORE_CONSTANTS.STORE_TYPE.ASSET
                                        ? multiLanguageService.getString("asset_store.confirmations.asset_warning")
                                        : multiLanguageService.getString("asset_store.confirmations.resource_warning");
                            }
                        }

                        scope.confirmAssetAction = function () {
                            function removeFromSearch(removedIds) {
                                if (
                                    iWantTo_event.broadcast_name === "purgeAssets" ||
                                    iWantTo_event.broadcast_name === "restoreAssets" ||
                                    iWantTo_event.broadcast_name === "deleteAssets" ||
                                    (iWantTo_event.broadcast_name === "archiveAssets" && !scope.includeHidden)
                                ) {
                                    $rootScope.$broadcast("removeFromSearchResultsEvent", { removedArray: removedIds, fromSidebar: scope.isSidebar });
                                }
                            }

                            function unarchiveResults(assetIds) {
                                if (iWantTo_event.broadcast_name === "unarchiveAssets") {
                                    scope.$broadcast("unarchiveSearchResultsEvent", { assetArray: assetIds });
                                }
                            }

                            assetActions[iWantTo_event.broadcast_name].func(iWantTo_event.for_ids).then(function (response) {
                                removeFromSearch(response);
                                unarchiveResults(response);
                            });
                        };

                        if (scope.assetAction.confirm) {
                            scope.modalTitleString = multiLanguageService.getString("asset_store.confirmations.action_message", {
                                Action: scope.assetActionName,
                                count: scope.selectedArray.length
                            });

                            scope.modalMessage = multiLanguageService.getString("asset_store.confirmations.are_you_sure_action", {
                                Action: scope.assetActionName.toLowerCase(),
                                count: scope.selectedArray.length
                            });

                            scope.confirmationBtnText = scope.modalTitleString;

                            if (scope.has_linked_assets && (scope.assetActionName === "delete" || scope.assetActionName === "purge")) {
                                scope.modalTitleString = multiLanguageService.getString("asset_store.confirmations.action_message_in_use", {
                                    Action: scope.assetActionName,
                                    count: scope.selectedArray.length
                                });
                            }

                            modal.newModal(
                                {
                                    scope: scope,
                                    templateUrl: baseUrlsFactory.shared_component_base_url + "iwantto/templates/asset-action-modal.html"
                                },
                                true
                            );
                        } else {
                            scope.confirmAssetAction();
                        }
                    } else {
                        assetActionHandler.invoke(iWantTo_event.broadcast_name, iWantTo_event.for_ids, {
                            type: scope.searchType,
                            scope: scope
                        });
                        scope.deselectAll(null, scope.selectedArray);
                    }
                });

                assetActionHandler.addAction("editAssets", function (assetIds) {
                    //Broadcast to searchresult so we can open
                    scope.$broadcast("editResource", assetIds[0]);
                    scope.deselectAll(null, assetIds);
                });

                element.on("$destroy", function () {
                    scope.unbindAccessKeys();
                    assetStoreService.cancel(actionPromise);
                    scope.changeStage("default");
                    removeIWantToListener();
                    scope.selectedArray.length = 0;
                    resetSearchFilters();
                    if (chosenSearchWatch) {
                        chosenSearchWatch();
                    }
                    off();
                    scope.$destroy();
                });

                scope.$on("fileUploadSuccessful", function () {
                    scope.factory.init().then(function () {
                        $timeout(function () {
                            scope.availableSearches = scope.factory.getAvailableSearches();
                            // Find selected search object from available searches
                            if (scope.activePage.type === STORE_CONSTANTS.STORE_TYPE.ASSET) {
                                for (var i = 0; i < scope.availableSearches.length; i++) {
                                    if (scope.availableSearches[i].id === STORE_CONSTANTS.SEARCH_TYPE.ASSET) {
                                        scope.chosenSearch = scope.availableSearches[i];
                                        break;
                                    }
                                }
                            }
                        }, 0);
                    });
                    if (scope.activePage.type === STORE_CONSTANTS.STORE_TYPE.ASSET) {
                        scope.doSearch(STORE_CONSTANTS.SEARCH_TYPE.ASSET);
                    }
                });

                scope.getExternalServiceLogo = function (logoName) {
                    return $sce.getTrustedResourceUrl(baseUrlsFactory.shared_component_base_url + "images/asset/" + logoName + ".png");
                };

                scope.toggleHelpBanner = function () {
                    scope.$broadcast("toggleHelpBanner");
                };

                scope.uploadFile = function () {
                    modal.newModal(
                        {
                            scope: scope,
                            templateUrl: baseUrlsFactory.shared_component_base_url + "fileUpload/templates/modal-wrapper.html"
                        },
                        true
                    );
                };

                scope.$on("shareSuccessful", scope.deselectAll);
                scope.$on("copySuccessful", scope.deselectAll);

                scope.getClickedIcon = function (event, asset) {
                    var panel = event.currentTarget.getAttribute("data-panelSection");
                    if (panel) {
                        scope.openInfoPanel(asset.Id, panel, { jumpToSection: event.currentTarget.getAttribute("data-panelAction") });
                    }
                };

                scope.openInfoPanel = function (id, panel, actionData) {
                    panel = typeof panel !== "undefined" ? panel : "asset-info";
                    $rootScope.$broadcast("openRightHandSidePanel", {
                        panel: panel,
                        assetId: id,
                        actionData: actionData
                    });
                };

                scope.updateSelectedAssetType = function (selectedArray, searchResults) {
                    if (selectedArray.length === 1) {
                        var selectedId = selectedArray[0];
                        var assetItem = searchResults.find(function (item) {
                            return item.Id === selectedId;
                        });

                        scope.selectedAssetType = assetItem ? assetItem.MainType : "";
                        updateEditExcludeOption(assetItem.ResultType === STORE_CONSTANTS.RESULT_TYPE.RESOURCE);
                    } else {
                        scope.selectedAssetType = "";
                        updateEditExcludeOption(false);
                    }
                };

                scope.activeResourceButton = multiLanguageService.getString("asset_store.buttons.activeResourceButton");
                scope.toggleResourceFilterBtns = function (buttonType) {
                    var custom_btn_el = angular.element(element[0].querySelector("#custom-btn"));
                    var pebble_templates_btn_el = angular.element(element[0].querySelector("#pebble-templates-btn"));
                    var workbooks_btn_el = angular.element(element[0].querySelector("#workbooks-btn"));

                    if (pebble_templates_btn_el.hasClass("toggled") && buttonType === "PebbleTemplates") {
                        removeStoredFilter("primary.assetTypes", true, "PebbleTemplates");
                    } else if (workbooks_btn_el.hasClass("toggled") && buttonType === "WorkBook") {
                        removeStoredFilter("primary.assetTypes", true, "WorkBook");
                    } else if (custom_btn_el.hasClass("toggled") && buttonType === "CustomTemplates") {
                        removeStoredFilter("primary.assetTypes", true, "CustomTemplates");
                    }

                    if (custom_btn_el.hasClass("toggled")) {
                        scope.activeResourceButton = multiLanguageService.getString("asset_store.buttons.activeResourceButton");
                        custom_btn_el.removeClass("toggled");
                    } else if (buttonType === "CustomTemplates") {
                        scope.activeResourceButton = scope.resourceButtonFilters.custom.DisplayName;
                        custom_btn_el.addClass("toggled");
                    }

                    if (pebble_templates_btn_el.hasClass("toggled")) {
                        scope.activeResourceButton = multiLanguageService.getString("asset_store.buttons.activeResourceButton");
                        pebble_templates_btn_el.removeClass("toggled");
                    } else if (buttonType === "PebbleTemplates") {
                        scope.activeResourceButton = scope.resourceButtonFilters.pebble.DisplayName;
                        pebble_templates_btn_el.addClass("toggled");
                    }

                    if (workbooks_btn_el.hasClass("toggled")) {
                        scope.activeResourceButton = multiLanguageService.getString("asset_store.buttons.activeResourceButton");
                        workbooks_btn_el.removeClass("toggled");
                    } else if (buttonType === "WorkBook") {
                        scope.activeResourceButton = scope.resourceButtonFilters.workbook.DisplayName;
                        workbooks_btn_el.addClass("toggled");
                    }
                };

                if ($routeParams.filter) {
                    var chosenSearchWatch = scope.$watch("chosenSearch", function (chosen) {
                        // tslint:disable-next-line:triple-equals
                        if (chosen != undefined) {
                            var filterObj = scope.resourceButtonFilters[$routeParams.filter.toLowerCase()];
                            if (filterObj && scope.activePage.type === STORE_CONSTANTS.STORE_TYPE.RESOURCE) {
                                scope.addFilterAndRunSearch(filterObj);
                                scope.toggleResourceFilterBtns(filterObj.Filter);
                            }
                            chosenSearchWatch();
                        }
                    });
                }

                var off = $rootScope.$on("$locationChangeStart", function () {
                    $location.search("filter", null);
                    off();
                });

                const updateEditExcludeOption = (allowEdit) => {
                    var index = scope.excludeOptions.indexOf("Edit");
                    if (!allowEdit && index === -1) {
                        scope.excludeOptions.push("Edit");
                    } else if (allowEdit && index !== -1) {
                        scope.excludeOptions.splice(index, 1);
                    }
                };
            }
        };
    }
]);
