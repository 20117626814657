import { angularAMD } from "@pebblepad/amd";
import { ASSET_CONSTANTS } from "../../constants/asset.constants";
import { PANEL_CONSTANTS } from "../../constants/panel.constants";
import { informationIcon } from "@pjs/core-ui";
import "../../utilities/urlService";
import "../../loadingSpinner/loadingSpinner";
import "../services/collectionService";
import "../../history/services/historyService";
import "../../datePicker/datePickerHelper";
import "../../utilities/pebbleDate";
import template from "../templates/collection.html";

angularAMD.directive("collection", [
    "$sce",
    "$rootScope",
    "$http",
    "$timeout",
    "$routeParams",
    "baseUrlsFactory",
    "urlService",
    "collectionService",
    "historyService",
    "datePickerHelper",
    function ($sce, $rootScope, $http, $timeout, $routeParams, baseUrlsFactory, urlService, collectionService, historyService, datePickerHelper) {
        return {
            template: template,
            restrict: "E",
            scope: {
                asset_id: "=assetId",
                collection: "=asset",
                view_mode: "=viewMode",
                is_workbook_page: "=isWorkbookPage",
                show_hours_and_points: "=showHoursAndPoints"
            },

            controller: [
                "$scope",
                "$element",
                "$attrs",
                function ($scope, $element, $attrs) {
                    $scope.asset = null;
                    $scope.multiLanguageService = $rootScope.multiLanguageService;
                    $scope.reverseOrder = true;
                    $scope.searchResult = [];
                    $scope.showHeader = true; // could be set to false when opened via the assetViewer
                    $scope.showHoursAndPoints = $scope.show_hours_and_points !== undefined && $scope.show_hours_and_points;
                    $scope.versionedMode = !!$routeParams.timeslice;
                    $scope.imageInfo = informationIcon;

                    $scope.collection_wrapper = angular.element(document.getElementsByClassName("collection-wrapper-container")[0]);

                    $scope.blankTarget = false;

                    $scope.addMainAreaStyles = function () {
                        if ($scope.collection_wrapper[0] && $scope.collection_wrapper[0].clientWidth < 699 && $scope.collection_wrapper[0].clientWidth > 560) {
                            $scope.collection_wrapper.addClass("sidebar-open");
                            $scope.collection_wrapper.removeClass("sidebar-open__560");
                        } else if ($scope.collection_wrapper[0] && $scope.collection_wrapper[0].clientWidth < 560) {
                            $scope.collection_wrapper.addClass("sidebar-open__560");
                            $scope.collection_wrapper.removeClass("sidebar-open");
                        } else {
                            $scope.collection_wrapper.removeClass("sidebar-open");
                            $scope.collection_wrapper.removeClass("sidebar-open__560");
                        }
                    };

                    $scope.splitAreasListener = $rootScope.$on("splitAreaSizes", $scope.addMainAreaStyles);

                    $scope.viewData = {
                        lang: {
                            friendlyMainType: $scope.showHoursAndPoints
                                ? $scope.multiLanguageService.getString("collections.headers.assets_in_this_activity_log")
                                : $scope.multiLanguageService.getString("collections.headers.assets_in_this_collection")
                        },
                        images: {
                            hours: $sce.getTrustedResourceUrl(baseUrlsFactory.shared_component_base_url + "collections/images/hours.svg"),
                            points: $sce.getTrustedResourceUrl(baseUrlsFactory.shared_component_base_url + "collections/images/points.svg")
                        }
                    };

                    // ***************
                    // ORDERING STUFF
                    $scope.orderByOptions = [
                        {
                            title: $scope.multiLanguageService.getString("collections.order_by.last_modified_desc"),
                            value: ["LastModified", "Title"],
                            reverse: true,
                            icon: "calendar"
                        },
                        {
                            title: $scope.multiLanguageService.getString("collections.order_by.last_modified_asc"),
                            value: ["LastModified", "Title"],
                            reverse: false,
                            icon: "calendar"
                        },
                        {
                            title: $scope.multiLanguageService.getString("collections.order_by.created_date_desc"),
                            value: ["Created", "Title"],
                            reverse: true,
                            icon: "calendar"
                        },
                        {
                            title: $scope.multiLanguageService.getString("collections.order_by.created_date_asc"),
                            value: ["Created", "Title"],
                            reverse: false,
                            icon: "calendar"
                        },
                        {
                            title: $scope.multiLanguageService.getString("collections.order_by.title_asc"),
                            value: ["Title", "Created"],
                            reverse: false,
                            icon: "title"
                        },
                        {
                            title: $scope.multiLanguageService.getString("collections.order_by.title_desc"),
                            value: ["Title", "Created"],
                            reverse: true,
                            icon: "title"
                        }
                    ];
                    if ($scope.showHoursAndPoints) {
                        $scope.orderByOptions.push(
                            {
                                title: $scope.multiLanguageService.getString("collections.order_by.hours_asc"),
                                value: ["Hours", "Minutes"],
                                reverse: false,
                                image: $scope.viewData.images.hours
                            },
                            {
                                title: $scope.multiLanguageService.getString("collections.order_by.hours_desc"),
                                value: ["Hours", "Minutes"],
                                reverse: true,
                                image: $scope.viewData.images.hours
                            },
                            {
                                title: $scope.multiLanguageService.getString("collections.order_by.points_asc"),
                                value: ["Points", "Title"],
                                reverse: false,
                                image: $scope.viewData.images.points
                            },
                            {
                                title: $scope.multiLanguageService.getString("collections.order_by.points_desc"),
                                value: ["Points", "Title"],
                                reverse: true,
                                image: $scope.viewData.images.points
                            }
                        );
                    }

                    //V3 sorting lookup table
                    var legacySortOrderDict = {
                        OldestFirst: "Created"
                    };

                    var initOrdering = function () {
                        if ($scope.asset.AssetOptions.length) {
                            var assetOptionSortOrder = $scope.asset.AssetOptions.where({ Key: "SortOrder" })[0];
                            var assetOptionReverseOrder = $scope.asset.AssetOptions.where({ Key: "ReverseOrder" })[0];

                            //Check for V3 order options
                            if (!assetOptionSortOrder) {
                                assetOptionSortOrder = $scope.asset.AssetOptions.where({ Key: "sortOrder" })[0];
                                if (assetOptionSortOrder && assetOptionSortOrder.Value) {
                                    assetOptionSortOrder.Value = legacySortOrderDict[assetOptionSortOrder.Value];
                                }
                            }

                            if (assetOptionReverseOrder) {
                                /* tslint:disable:triple-equals */
                                assetOptionReverseOrder.Value = !!(
                                    (typeof assetOptionReverseOrder.Value === "string" && assetOptionReverseOrder.Value.toLowerCase() === "true") ||
                                    assetOptionReverseOrder.Value == true
                                );
                                /* tslint:enable:triple-equals */
                            } else {
                                assetOptionReverseOrder = { Value: false };
                            }
                            if (assetOptionSortOrder) {
                                var orderVal = assetOptionSortOrder.Value;
                                if (orderVal === "Alphabetically") {
                                    orderVal = "Title";
                                }
                                var orderByColumn = $scope.orderByOptions[0];
                                for (var i = 0; i < $scope.orderByOptions.length; i++) {
                                    // tslint:disable-next-line:triple-equals
                                    if ($scope.orderByOptions[i].value[0] == orderVal && $scope.orderByOptions[i].reverse == assetOptionReverseOrder.Value) {
                                        orderByColumn = $scope.orderByOptions[i];
                                        break;
                                    }
                                }
                                $scope.setOrderByColumn(orderByColumn);
                            }
                        }
                        if (!$scope.orderByColumn) {
                            $scope.setOrderByColumn($scope.orderByOptions[0], true);
                        }
                    };

                    $scope.setOrderByColumn = function (orderByOption, preventEmit) {
                        if ($scope.orderByColumn !== orderByOption.value || $scope.reverseOrder !== orderByOption.reverse) {
                            $scope.orderByColumn = orderByOption.value;
                            $scope.selectedOrderByTitle = orderByOption.title;
                            $scope.reverseOrder = orderByOption.reverse;

                            if (!preventEmit) {
                                $scope.$emit("collectionOrderChanged", orderByOption);
                            }
                        }
                    };
                    $scope.primarySort = function (item) {
                        $scope.primarySortingBy = $scope.orderByColumn[0];
                        return item[$scope.orderByColumn[0]];
                    };
                    $scope.secondarySort = function (item) {
                        return item[$scope.orderByColumn[1]];
                    };
                    // *************

                    $scope.presetData = function (forceReload) {
                        $scope.searchComplete = false;
                        var promise = null;
                        if (!$scope.collection || forceReload) {
                            var params = [
                                { key: "assetId", value: $scope.asset_id },
                                { key: "permissionRequired", value: ASSET_CONSTANTS.PERMISSIONS.VIEW },
                                { key: "submissionId", value: $routeParams.submissionId }
                            ];
                            promise = collectionService.getCollection(params).then(function (response) {
                                $scope.asset = response.data.Asset;
                                return $timeout(setupCollection);
                            });
                        } else {
                            $scope.asset = $scope.collection;
                            promise = $timeout(setupCollection);
                        }

                        promise.then(function () {
                            $scope.$emit("collectionListLoaded", true);
                        });
                    };

                    $scope.editCollectionCriteria = function () {
                        $rootScope.$broadcast("openRightHandSidePanel", {
                            panel: PANEL_CONSTANTS.PANEL.COLLECTION_CRITERIA,
                            id: $scope.asset_id,
                            edit: true,
                            allowCriteriaResponsesTo: false,
                            collectionWrapper: $scope.asset,
                            callback: angular.noop
                        });
                    };

                    var setupCollection = function () {
                        if ($scope.showHoursAndPoints) {
                            $scope.totalPoints = 0;
                            $scope.totalHours = 0;
                            $scope.totalMinutes = 0;
                        }
                        // Configure url, hours and points for each asset
                        $scope.asset.Items.forEach(function (item) {
                            item.Created = datePickerHelper.normalizeDate(item.Created);
                            item.LastModified = datePickerHelper.normalizeDate(item.LastModified);
                            var url = urlService.configureAssetUrl(item.OpenUrl, $scope.view_mode ? "view" : undefined, null, null, $routeParams.submissionId);
                            var historyId = historyService.getCurrentHistoryId();
                            item.url = urlService.addParameters(url, [{ key: "historyId", value: historyId }]);
                            addHoursAndPointsToAsset(item);
                        });

                        calculateTotalHoursAndPoints();
                        $scope.searchResult = $scope.asset.Items;
                        initOrdering();
                        refreshMultilangStrings();

                        $scope.searchComplete = true;
                    };

                    var refreshMultilangStrings = function () {
                        $scope.viewData.lang.there_are = $scope.multiLanguageService.getString("collections.headers.n_there_are", {
                            count: $scope.searchResult.length,
                            mainType: $scope.viewData.lang.friendlyMainType
                        });

                        $scope.viewData.lang.chosen_criteria = $scope.multiLanguageService.getString("collections.headers.chosen_criteria", {
                            mainType: $scope.viewData.lang.friendlyMainType,
                            searches: $scope.multiLanguageService.getString("collections.headers.chosen_criteria_searches", { count: $scope.asset.AssetCollections.length }),
                            assets: $scope.multiLanguageService.getString("collections.headers.chosen_criteria_assets", { count: $scope.searchResult.length })
                        });

                        $scope.viewData.lang.hours_total = $scope.multiLanguageService.getString("collections.labels.hours", { count: $scope.totalHours });
                        $scope.viewData.lang.mins_total = $scope.multiLanguageService.getString("collections.labels.minutes", { count: $scope.totalMinutes });
                        $scope.viewData.lang.hours_mins_total = $scope.multiLanguageService.getString("collections.labels.hours_and_mins_mobile", {
                            hours: $scope.totalHoursText,
                            mins: $scope.totalMinutesText
                        });
                        $scope.viewData.lang.points_total = $scope.multiLanguageService.getString("collections.labels.points", { count: $scope.totalPoints });

                        $scope.viewData.lang.hours_remaining = $scope.multiLanguageService.getString("collections.labels.hours", { count: $scope.remainingHours });
                        $scope.viewData.lang.mins_remaining = $scope.multiLanguageService.getString("collections.labels.minutes", { count: $scope.remainingMinutes });
                        $scope.viewData.lang.hours_mins_remaining = $scope.multiLanguageService.getString("collections.labels.hours_and_mins_mobile", {
                            hours: $scope.remainingHoursText,
                            mins: $scope.remainingMinutesText
                        });
                        $scope.viewData.lang.points_remaining = $scope.multiLanguageService.getString("collections.labels.points", { count: $scope.remainingPoints });
                    };

                    var addHoursAndPointsToAsset = function (item) {
                        if ($scope.showHoursAndPoints && item.AssetOptions) {
                            var minutes = item.AssetOptions.where({ Key: "CpdTimeInMins" })[0];
                            if (!minutes || !minutes.Value) {
                                minutes = { Value: 0 };
                            }

                            var points = item.AssetOptions.where({ Key: "CpdPoints" })[0];
                            if (!points || !points.Value) {
                                points = { Value: 0 };
                            }

                            item.Points = parseInt(points.Value, 10);
                            item.Hours = Math.floor(minutes.Value / 60); // for ordering
                            item.HoursText = item.Hours.toString().length === 1 ? "0" + item.Hours : item.Hours; // for display
                            item.Minutes = minutes.Value % 60; // for ordering
                            item.MinutesText = item.Minutes.toString().length === 1 ? "0" + item.Minutes : item.Minutes; // for display

                            $scope.totalPoints += parseInt(item.Points, 10);
                            $scope.totalMinutes += parseInt(minutes.Value, 10);
                        }
                    };

                    var calculateTotalHoursAndPoints = function () {
                        // tslint:disable-next-line:triple-equals
                        if ($scope.asset.TargetHours == 0 && $scope.asset.TargetPoints == 0) {
                            $scope.asset = $scope.collection;
                        }

                        $scope.actualTotalMinutes = $scope.totalMinutes;
                        $scope.totalHours = Math.floor($scope.totalMinutes / 60);
                        $scope.totalMinutes = $scope.totalMinutes % 60;
                        $scope.totalHoursText = $scope.totalHours.toString().length === 1 ? "0" + $scope.totalHours : $scope.totalHours; // for display
                        $scope.totalMinutesText = $scope.totalMinutes.toString().length === 1 ? "0" + $scope.totalMinutes : $scope.totalMinutes; // for display
                        $scope.remainingHours = Math.max(0, Math.floor($scope.asset.TargetHours - $scope.actualTotalMinutes / 60));
                        $scope.remainingMinutes = $scope.actualTotalMinutes > $scope.asset.TargetHours * 60 || $scope.actualTotalMinutes % 60 === 0 ? 0 : 60 - ($scope.actualTotalMinutes % 60);
                        $scope.remainingHoursText = $scope.remainingHours.toString().length === 1 ? "0" + $scope.remainingHours : $scope.remainingHours; // for display
                        $scope.remainingMinutesText = $scope.remainingMinutes.toString().length === 1 ? "0" + $scope.remainingMinutes : $scope.remainingMinutes; // for display
                        $scope.remainingPoints = Math.max(0, $scope.asset.TargetPoints - $scope.totalPoints);

                        $scope.hoursClass = "negative";
                        if ($scope.actualTotalMinutes > 0) {
                            $scope.hoursClass = $scope.actualTotalMinutes >= $scope.asset.TargetHours * 60 ? "positive" : "neutral";
                        }
                        $scope.pointsClass = "negative";
                        if ($scope.totalPoints > 0) {
                            $scope.pointsClass = $scope.totalPoints >= $scope.asset.TargetPoints ? "positive" : "neutral";
                        }

                        $rootScope.$broadcast("updatedLogTotals", {
                            remainingHours: $scope.remainingHours,
                            remainingMinutes: $scope.remainingMinutes,
                            remainingPoints: $scope.remainingPoints,
                            totalHours: $scope.totalHours,
                            totalMinutes: $scope.totalMinutes,
                            totalPoints: $scope.totalPoints
                        });

                        refreshMultilangStrings();
                    };

                    if (!$scope.openInfoPanel) {
                        $scope.openInfoPanel = function (id, panel, actionData) {
                            panel = typeof panel !== "undefined" ? panel : "asset-info";
                            $rootScope.$broadcast("openRightHandSidePanel", {
                                panel: panel,
                                assetId: id,
                                actionData: actionData
                            });
                        };
                    }

                    $scope.$on("collectionListReload", () => $scope.presetData(true));

                    const onUserAssetStoreUpdate = (data) => {
                        if ($scope.asset !== null && $scope.asset.Items.some((a) => a.Id === data.Id)) {
                            $scope.presetData(true);
                            return;
                        }

                        collectionService.getCriteriaFromCollectionWrapper($scope.asset_id).then((response) => {
                            if (Array.isArray(response.data)) {
                                refreshIfTagInUse(data, response.data);
                            }
                        });
                    };

                    const refreshIfTagInUse = (updatedAsset, listOfCriteria) => {
                        const matchesCriteria = listOfCriteria.some((c) => c.Search !== null && c.Search.Tags !== null && c.Search.Tags.containsAny(updatedAsset.Tags));
                        if (matchesCriteria) {
                            $scope.presetData(true);
                        }
                    };
                }
            ],

            link: function (scope, element, attrs) {
                /**
                 * Auto-run on start
                 */
                element.on("$destroy", function () {
                    scope.$destroy();
                    scope.splitAreasListener();
                });

                scope.init = (function () {
                    //scope.cacheElems();
                    $timeout(function () {
                        scope.presetData();
                        scope.addMainAreaStyles();
                    }, 1000);

                    // todo: focus on first element
                })();
            }
        };
    }
]);
