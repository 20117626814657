import { angularAMD } from "@pebblepad/amd";
import { ASSET_CONSTANTS } from "../../constants/asset.constants";
import "../../utilities/baseUrlsFactory";
import "../../builder/dataManager/dataManagerService";
import "../../modal/services/modal";
import "./useResourceService";
import "../../utilities/urlService";
import "../../utilities/typeCheck.service";
import "../../extensions/arrayExtensions";
import { createPortfolio } from "../../assetCreation/CreatePortfolio.function";
import { createPage } from "../../assetCreation/CreatePage.function";
import { createCollection } from "../../assetCreation/CreateCollection.function";
import { createActivityLog } from "../../assetCreation/CreateActivityLog.function";
import { createBlog } from "../../assetCreation/CreateBlog.function";
import { createPost } from "../../assetCreation/CreatePost.function";
import { createTemplateResource } from "../../assetCreation/CreateTemplateResource.function";
import { createWorkbookResource } from "../../assetCreation/CreateWorkbookResource.function";

angularAMD.factory("assetCreateService", [
    "baseUrlsFactory",
    "dataManagerService",
    "$location",
    "$rootScope",
    "$q",
    "modal",
    "useResourceService",
    "urlService",
    "typeCheck",
    function (baseUrlsFactory, dataManagerService, $location, $rootScope, $q, modal, useResourceService, urlService, typeCheck) {
        const withManager = (callback) => callback.bind(null, dataManagerService);
        const withManagerAndMultiLang = (callback) => callback.bind(null, dataManagerService, $rootScope.multiLanguageService);

        return {
            _allowedRedirectAppName: ["Plus", "Admissions"],
            _assetTypeCreators: new Map([
                [ASSET_CONSTANTS.TYPES.WEBFOLIO, withManagerAndMultiLang(createPortfolio)],
                [ASSET_CONSTANTS.TYPES.WEBFOLIO_PAGE, withManager(createPage)],
                [ASSET_CONSTANTS.TYPES.COLLECTION_WRAPPER, withManager(createCollection)],
                [ASSET_CONSTANTS.TYPES.ACTIVITY_LOG, withManager(createActivityLog)],
                [ASSET_CONSTANTS.TYPES.BLOG, withManager(createBlog)],
                [ASSET_CONSTANTS.TYPES.POST, withManager(createPost)],
                [ASSET_CONSTANTS.TYPES.TEMPLATE_BUILDER, withManager(createTemplateResource)],
                [ASSET_CONSTANTS.TYPES.WORKBOOK_BUILDER, withManager(createWorkbookResource)]
            ]),

            createAndGoToWebfolio: function (forceHistory) {
                $rootScope.appIsLoaded = false;

                createPortfolio(dataManagerService, $rootScope.multiLanguageService)
                    .then((assetDetails) => {
                        dataManagerService.primary_id = assetDetails.assetId;
                        setTimeout(function () {
                            dataManagerService.dtos[assetDetails.assetId].is_saved = true;
                        }, 700);

                        var historyPath = $location.path("webfolio/" + assetDetails.assetId).search({ pageId: assetDetails.pageId });

                        if (!forceHistory) {
                            historyPath.replace();
                        }
                    })
                    .catch(this._errorHandler.bind(this, ASSET_CONSTANTS.TYPES.WEBFOLIO));
            },

            createAndGoToFolioPage: function (forceHistory, onBeforeRedirect, keepHistory, defaultAssetProperties) {
                this._createThenGoToAsset(createPage, ASSET_CONSTANTS.TYPES.WEBFOLIO_PAGE, onBeforeRedirect, keepHistory, true, defaultAssetProperties);
            },

            createAndGoToCollection: function (onBeforeRedirect, keepHistory, defaultAssetProperties) {
                this._createThenGoToAsset(createCollection, ASSET_CONSTANTS.TYPES.COLLECTION_WRAPPER, onBeforeRedirect, keepHistory, true, defaultAssetProperties);
            },

            createAndGoToActivityLog: function (onBeforeRedirect, keepHistory, defaultAssetProperties) {
                this._createThenGoToAsset(createActivityLog, ASSET_CONSTANTS.TYPES.ACTIVITY_LOG, onBeforeRedirect, keepHistory, true, defaultAssetProperties);
            },

            createAndGoToBlog: function (onBeforeRedirect, keepHistory, defaultAssetProperties) {
                this._createThenGoToAsset(createBlog, ASSET_CONSTANTS.TYPES.BLOG, onBeforeRedirect, keepHistory, true, defaultAssetProperties);
            },

            /**
             * Create a post:
             * Used on Add Post button on blog
             *
             * AddToId is the id of the blog the post should be
             * automatically be adding to.
             * @param addToId
             * @param backgroundAssetId
             * @param historyId
             */
            createAndGoToPost: function (addToId, backgroundAssetId, historyId) {
                $rootScope.appIsLoaded = false;

                createPost(dataManagerService, addToId, backgroundAssetId)
                    .then((assetDetails) => {
                        if ($rootScope.appName !== "Plus") {
                            window.location.href = baseUrlsFactory.api_base_url + "SharedMenu/Forwarder?location=PlusHTML5&action=Edit&mainType=post&id=" + assetDetails.assetId;
                            return;
                        }

                        const searchParams = historyId ? { historyId: historyId } : "";
                        $location.path("post/" + assetDetails.assetId).search(searchParams);
                    })
                    .catch(this._errorHandler.bind(this, ASSET_CONSTANTS.TYPES.POST));
            },

            createAndGoToTemplateBuilder: function (forceHistory) {
                this._createThenGoToAsset(createTemplateResource, ASSET_CONSTANTS.TYPES.TEMPLATE_BUILDER, null, false, forceHistory);
            },

            createAndGoToWorkbookBuilder: function (forceHistory) {
                this._createThenGoToAsset(createWorkbookResource, ASSET_CONSTANTS.TYPES.WORKBOOK_BUILDER, null, false, forceHistory);
            },

            createAndGoToActivityExperience: function (details) {
                urlService.promisedGet(baseUrlsFactory.api_base_url + "Search/GetGenericTemplate", { purpose: "Activity" }).then(function (response) {
                    const templateDetails = {
                        AssetId: response.data,
                        AddToId: details.addToId,
                        AutoSubmit: details.autoSubmit,
                        AssignmentId: details.assignmentId,
                        WorkspaceId: details.workspaceId,
                        AutoSubmitTo: details.autoSubmitTo
                    };
                    useResourceService.checkUse(templateDetails);
                });
            },

            createAssetFromUrl: function (mainType) {
                const createAsset = this._assetTypeCreators.get(mainType);
                if (createAsset === undefined) {
                    return $q.reject(`"Cannot create asset. The provided type ${mainType} cannot be handled.`);
                }

                return createAsset().then((assetDetails) => urlService.createUrl(assetDetails.assetId, assetDetails.manager.data.MainType));
            },

            /**
             * @private
             * @param {function} assetCreator
             * @param {string} errorType
             * @param {function=} onBeforeRedirect
             * @param {boolean=} keepHistory
             * @param {boolean=} forceHistory
             * @param {object=} defaultAssetProperties
             */
            _createThenGoToAsset: function (assetCreator, errorType, onBeforeRedirect, keepHistory, forceHistory, defaultAssetProperties) {
                $rootScope.appIsLoaded = false;
                assetCreator(dataManagerService, defaultAssetProperties)
                    .then((assetDetails) => this._onCreateSuccess(assetDetails, onBeforeRedirect, keepHistory, forceHistory))
                    .catch(this._errorHandler.bind(this, errorType));
            },

            /**
             * @private
             * @param {{ assetId: string, manager: object}} assetDetails
             * @param {function=} callback
             * @param {boolean=} keepHistory
             * @param {boolean=} forceHistory
             */
            _onCreateSuccess: function (assetDetails, callback, keepHistory, forceHistory = true) {
                const assetId = assetDetails.assetId;
                const mainType = assetDetails.manager.data.MainType;
                dataManagerService.primary_id = assetDetails.assetId;

                // used by inline placeholder asset creation
                // please check assetManipulation.service.js for more info.
                if (typeCheck.isFunction(callback)) {
                    callback(assetDetails.manager);
                }

                if (!this._allowedRedirectAppName.contains($rootScope.appName)) {
                    window.location.href = baseUrlsFactory.api_base_url + "SharedMenu/Forwarder?location=PlusHTML5&action=Edit&mainType=" + mainType + "&id=" + assetId;
                    return;
                }

                const redirectPath = $location.path(urlService.createUrl(assetId, mainType));
                redirectPath.search("pageId", null);

                if (keepHistory !== true) {
                    redirectPath.search("");
                }

                if (!forceHistory) {
                    redirectPath.replace();
                }
            },

            _errorHandler: function (type) {
                $rootScope.appIsLoaded = true;

                const i18nType = $rootScope.multiLanguageService.getString(`types.main_types.${type.toLowerCase()}`);
                const props = {
                    title: $rootScope.multiLanguageService.getString("portfolioAndFoliopages.unable_to_continue"),
                    message: $rootScope.multiLanguageService.getString("portfolioAndFoliopages.unable_to_create", { type: i18nType }),
                    button_label: $rootScope.multiLanguageService.getString("portfolioAndFoliopages.okay")
                };

                modal.launch(baseUrlsFactory.shared_component_base_url + "modalDialogComponent/templates/generic-pop-up.html", props);
            }
        };
    }
]);
