import { angularAMD } from "@pebblepad/amd";
import "../../utilities/baseUrlsFactory";
import "../../modal/services/modal";
import "../../multiLanguageService/multiLanguageService";
import "../../exampleRecoveredList/exampleRecoveredList";
import "../../recoveredAssetsGuide/recoveredAssetsGuide";
import "../../assetStore/services/assetStoreService";
import "../../overlay/services/overlayFactory";
import "../../assetEndpointService/assetEndpoint.service";
import "../../recoveryLogger/recoveryLogger";
import { ASSET_CONSTANTS } from "../../constants/asset.constants";
import { PageModelSearch } from "../../pageModelSearch/pageModelSearch";
import { determinePageAssetId } from "../../pageModelSearch/helpers/DeterminePageAssetId.function";
import { withoutDuplicatePageAssetIdsFactory } from "../../pageModelSearch/helpers/WithoutDuplicatePageAssetIdsFactory.function";
import { withoutEmptyPageAssetIds } from "../../pageModelSearch/helpers/WithoutEmptyPageAssetIds.function";

angularAMD.service("localChangesResolver", [
    "$rootScope",
    "$http",
    "$q",
    "$sce",
    "$timeout",
    "baseUrlsFactory",
    "modal",
    "multiLanguageService",
    "assetStoreService",
    "overlayFactory",
    "$routeParams",
    "AssetEndpointService",
    "recoveryLogger",
    function ($rootScope, $http, $q, $sce, $timeout, baseUrlsFactory, modal, multiLanguageService, assetStoreService, overlayFactory, $routeParams, AssetEndpointService, recoveryLogger) {
        var LocalChangesResolver = {};
        var typesThatHasNestedItems = ["Webfolio", "WorkBook", "WorkBookResponse", "WorkbookBuilder"];
        var resourceTypes = ["WorkbookBuilder", "TemplateBuilder"];
        var recoverNotLoggedIn = "Can't recover, you are not logged in.";
        /**
         * @param {string} assetId
         * @param {Object} dto
         * @param {string} type
         * @param {string} icon
         * @param {OutdatedDataHolder} lockedAssets
         * @param {OutdatedDataHolder} outdatedAssets
         * @param {Boolean} standaloneLockedResponse
         * @constructor
         */
        function RecoveryDto(assetId, dto, type, icon, lockedAssets, outdatedAssets, standaloneLockedResponse) {
            this.mainAssetInfo = {
                assetId: assetId,
                dto: dto,
                assetGlobalType: type,
                assetIcon: icon
            };
            this.lockedAssets = lockedAssets;
            this.outdatedAssets = outdatedAssets;
            this.standaloneLockedResponse = standaloneLockedResponse;
        }

        function OutdatedDataHolder(dtos, ids) {
            this.dtos = dtos || {};
            this.ids = ids || [];
            this.serverRevisions = {};
        }

        const whenNotLoggedOutError = (callback) => {
            return (rejection) => {
                if (rejection.status === 401) {
                    return;
                }

                callback();
            };
        };

        //Can't inject DataManagerService through the usual way, as we get a Circular reference. Instead we create an 'API' - Could do a direct reference, but that seems lest tidy in this circumstance
        LocalChangesResolver.dataService = {
            clear: null,
            getDto: null,
            checkIfSaved: null,
            loadFromPersistentObjStore: null,
            destroy: null
        };

        /**
         * Pass string or array of ids to get local storage dtos in case any have been found.
         *
         * @param {string|string[]} ids
         * @param {boolean=} returnOnlyDto - default value: false. Set 'true' to get only first item.
         * @returns {{dtos: {}, ids: Array}}
         */
        LocalChangesResolver.getLocalDto = function (ids, returnOnlyDto) {
            var idsIsArray = ids.constructor !== Array;
            var foundData = {
                dtos: {},
                ids: []
            };

            if (idsIsArray) {
                ids = [ids];
            }

            for (var id, localItem, i = 0, len = ids.length; i < len; i++) {
                id = ids[i];
                localItem = this.getLocalStorageItem(id);

                if (localItem) {
                    foundData.dtos[id] = this.getLocalStorageItem(id);
                    foundData.ids.push(id);
                }
            }

            // make the whole object null in case there found 0 local items
            foundData = foundData.ids.length > 0 ? foundData : null;

            // return only first dto in case returnOnlyDto is set to true
            if (foundData && returnOnlyDto === true) {
                foundData = foundData.dtos[foundData.ids[0]];
            }

            return foundData;
        };

        /**
         * @param {string} keyName
         * @returns {{}|null}
         */
        LocalChangesResolver.getLocalStorageItem = function (keyName) {
            return this.dataService.loadFromPersistentObjStore(keyName);
        };

        LocalChangesResolver.cleanupLocalStorage = function (items) {
            if (items && items.length > 0) {
                for (var i = 0, len = items.length; i < len; i++) {
                    this.dataService.destroy(items[i]);
                }
            }
        };

        LocalChangesResolver.getRevisionData = function (assetIds) {
            return $http.post(baseUrlsFactory.api_base_url + "AssetDto/GetRevisionInformation", assetIds).then(function (response) {
                return response.data;
            });
        };

        LocalChangesResolver.hasNestedItems = function (item) {
            return typesThatHasNestedItems.indexOf(item.PageType || item.MainType) > -1 && ((item.Children && item.Children.length > 0) || (item.Pages && item.Pages.length > 0));
        };

        LocalChangesResolver.getMainAsset = function (assetId, hasNestedItems) {
            // try and get dto from local storage
            var dto = this.getLocalDto(assetId, true),
                deferred = $q.defer();

            if (dto) {
                deferred.resolve({ dto: dto, assetId: assetId });
            } else {
                if (hasNestedItems) {
                    // dto is not in local storage but we need to check children so get asset from server and check child ids
                    var responseParams = { assetId: assetId, permissionRequired: "View", submissionId: $routeParams.submissionId };
                    AssetEndpointService.getAssetAndCheckPermission(responseParams).then(function (response) {
                        if (response.data.MessageType === "Success") {
                            deferred.resolve({ dto: response.data.Asset, assetId: assetId });
                        } else {
                            deferred.reject();
                        }
                    });
                } else {
                    deferred.resolve({ assetId: assetId });
                }
            }

            return deferred.promise;
        };

        LocalChangesResolver.getIdKeyName = function (type) {
            var pageAssetId = "PageAssetId",
                userResponseId = "UserResponseId",
                contentId = "ContentId",
                genericPagesKeys = [pageAssetId],
                map = {
                    Webfolio: genericPagesKeys,
                    WebfolioPage: genericPagesKeys,
                    WorkBook: [userResponseId, contentId],
                    WorkbookBuilder: [contentId],
                    WorkBookResponse: [userResponseId],
                    default: genericPagesKeys
                };

            return map[type] || map["default"];
        };

        LocalChangesResolver.getValueFromKeys = function (item, keys) {
            var value = null;
            for (var i = 0, l = keys.length; i < l; i++) {
                value = item[keys[i]];
                if (value) {
                    break;
                }
            }

            return value;
        };

        LocalChangesResolver.getAllNestedIds = function (mainType, items, existingIds = new Set()) {
            if (!Array.isArray(items) || items.length === 0) {
                return existingIds;
            }

            for (const item of items) {
                if (this.hasNestedItems(item)) {
                    this.getAllNestedIds(item.PageType, item.Children, existingIds);
                } else {
                    existingIds.add(this.getValueFromKeys(item, this.getIdKeyName(mainType)));
                }
            }

            return existingIds;
        };

        LocalChangesResolver.validateItem = function (dto, revisionData, checkForSave, checkTableLegacy) {
            var isOutdated, isDeprecated, hasOldTable;

            dto.is_saved = dto.is_saved === undefined ? false : dto.is_saved;

            if (dto && revisionData) {
                // tslint:disable-next-line:triple-equals
                isOutdated = !this.itemIsDeprecated(dto) && dto.is_saved == false && this.checkRevision(revisionData, dto, checkForSave); // Local Asset out of date
                isDeprecated = this.itemIsDeprecated(dto) && this.checkDates(dto); // Local Asset out of date and created before 1604 Release
                hasOldTable = this.itemHasOldTable(dto, checkTableLegacy);

                return isOutdated || isDeprecated || hasOldTable;
            }

            return false;
        };

        LocalChangesResolver.revisionCheckAssetList = function (itemsToValidate, checkForSave, checkTableLegacy) {
            return this.getRevisionData(itemsToValidate.ids).then(
                function (revisionData) {
                    var outdatedData = new OutdatedDataHolder();

                    var revisionItem;
                    if (!revisionData) {
                        return null;
                    }

                    for (var i = 0, len = revisionData.length; i < len; i++) {
                        // if item is outdated, then add it to the 'outdatedData' object
                        revisionItem = revisionData[i];
                        if (this.validateItem(itemsToValidate.dtos[revisionItem.Key], revisionItem, checkForSave, checkTableLegacy)) {
                            outdatedData.dtos[revisionItem.Key] = itemsToValidate.dtos[revisionItem.Key];
                            outdatedData.ids.push(revisionItem.Key);
                            outdatedData.serverRevisions[revisionItem.Key] = revisionItem.Value;
                        }
                    }

                    if (outdatedData.ids.length > 0) {
                        recoveryLogger.step("recover", `Found ${outdatedData.ids.length} old revision assets to recover`);
                        return outdatedData;
                    }

                    return null;
                }.bind(this)
            );
        };

        LocalChangesResolver.lockCheckAssets = function (assetIds) {
            return assetStoreService.getEditStatusForAssets(assetIds).then(function (keyValueDtos) {
                var viewOnlyAssetIds = [];
                var keyValueDto = null;

                for (var i = 0, l = keyValueDtos.length; i < l; i++) {
                    keyValueDto = keyValueDtos[i];
                    if (keyValueDto.Value === "View") {
                        viewOnlyAssetIds.push(keyValueDto.Key);
                    }
                }
                return viewOnlyAssetIds;
            });
        };

        /**
         * Given an assetDto and assetId, works through the asset and any children it may have, checking if they exist in localStorage.
         * @param {Object} mainDto
         * @param {string} assetId
         * @returns {{dtos: Object, ids: Array<string>}}
         */
        LocalChangesResolver.getLocalAssetsFromDto = function (mainDto, assetId) {
            let allNestedIds = [];

            if (mainDto) {
                allNestedIds = Array.from(this.getAllNestedIds(mainDto.MainType || mainDto.PageType, mainDto.Children || mainDto.Pages));
            }
            allNestedIds.unshift(assetId);

            return this.getLocalDto(allNestedIds);
        };

        /**
         * @param {string} assetId
         * @param {Boolean} hasNestedItems
         * @param {Boolean} checkForSave
         * @param {Boolean} checkTableLegacy
         * @returns {Promise}
         * Resolves with RecoveryDto
         */
        LocalChangesResolver.getAssetsWithConflicts = function (assetId, hasNestedItems, checkForSave, checkTableLegacy) {
            return this.getLocalAssetsFromId(assetId, hasNestedItems).then(
                function (data) {
                    if (!data || data.local === null) {
                        return null;
                    }

                    if (data.mainDto.MainType === ASSET_CONSTANTS.TYPES.WORKBOOK_RESPONSE || data.mainDto.MainType === ASSET_CONSTANTS.TYPES.WEBFOLIO) {
                        const index = data.local.ids.indexOf(assetId);
                        if (index !== -1) {
                            data.local.ids.splice(index, 1);
                        }
                    }

                    if (data.local.ids.length === 0) {
                        return null;
                    }

                    return this.getLockedAssetsToRecover(data).then(
                        function (lockedAssets) {
                            return this.revisionCheckAssetList(data.local, checkForSave, checkTableLegacy).then(
                                function (outdatedAssets) {
                                    if ((!lockedAssets || !lockedAssets.toRecover) && !outdatedAssets) {
                                        return null;
                                    } else {
                                        var lockedToRecover = null;
                                        var standaloneLockedResponse = false;

                                        if (lockedAssets) {
                                            lockedToRecover = lockedAssets.toRecover;
                                            standaloneLockedResponse = lockedAssets.standaloneLockedResponse;
                                        }

                                        return this.createRecoveryDto(assetId, data.mainDto, lockedToRecover, outdatedAssets, standaloneLockedResponse);
                                    }
                                }.bind(this)
                            );
                        }.bind(this)
                    );
                }.bind(this)
            );
        };

        /**
         * Give an asset Id, it gets the Full Asset, then works through the asset and any children it may have, checking if they exist in localStorage.
         * @param {string} assetId
         * @param {Boolean} hasNestedItems
         * @returns {{mainDto: Object, local: {dtos: Object, ids: Array<string>}}} | null}
         */
        LocalChangesResolver.getLocalAssetsFromId = function (assetId, hasNestedItems) {
            return this.getMainAsset(assetId, hasNestedItems).then(
                function (data) {
                    // if this is a dummy asset used by the workbook/template builder then never recover it
                    if (data && data.dto && this.isDummyAsset(data.dto)) {
                        return null;
                    }

                    var localData = this.getLocalAssetsFromDto(data.dto, data.assetId);
                    return {
                        mainDto: data.dto,
                        local: localData
                    };
                }.bind(this)
            );
        };

        LocalChangesResolver.dtosObjectToArray = function (object) {
            var arr = [];

            for (var key in object) {
                if (!object.hasOwnProperty(key) || !object[key]) {
                    continue;
                }
                var obj = object[key];

                if (isNaN(obj.Revision)) {
                    obj.Revision = -1;
                }

                arr.push(obj);
            }

            return arr;
        };

        LocalChangesResolver.createRecoveryDto = function (assetId, dto, lockedAssets, outdatedAssets, standaloneLockedResponse) {
            return new RecoveryDto(assetId, dto, this.getAssetGlobalType(dto), this.getAssetIcon(dto), lockedAssets, outdatedAssets, standaloneLockedResponse);
        };

        LocalChangesResolver.removeDuplicatesFromOutdatedDataHolder = function (searchingDataHolder, changingDataHolder) {
            changingDataHolder.ids.removeItems(searchingDataHolder.ids);
            searchingDataHolder.ids.forEach(function (id) {
                if (changingDataHolder.dtos[id]) {
                    delete changingDataHolder.dtos[id];
                    delete changingDataHolder.serverRevisions[id];
                }
            });
        };

        LocalChangesResolver.recoverOutdatedAssets = function (mainAssetInfo, outdatedAssets) {
            const dtos = this.dtosObjectToArray(outdatedAssets.dtos);
            const type = mainAssetInfo && mainAssetInfo.dto ? mainAssetInfo.dto.MainType || mainAssetInfo.PageType : "";
            recoveryLogger.end("Recovery by load", `Recovery | Main type: ${type} | Item types: ${dtos.map((dto) => dto.MainType).join(",")}`);
            return $http
                .post(baseUrlsFactory.api_base_url + "AssetDto/Recover", {
                    MainAssetId: mainAssetInfo.assetId,
                    Dtos: dtos
                })
                .then(function (response) {
                    return response.data && response.data.Success ? response.data : $q.reject(response);
                });
        };

        LocalChangesResolver.recoverOutdatedAssetsBeforeSave = function (mainAssetInfo, outdatedAssets) {
            recoveryLogger.end("Recovery by save", `Recovery ${mainAssetInfo && mainAssetInfo.dto ? mainAssetInfo.dto.MainType || mainAssetInfo.PageType : ""}`);

            const ids = outdatedAssets.ids;
            let idsWithTitles = null;

            if (Array.isArray(mainAssetInfo.dto.Pages)) {
                const matchOutdatedAssets = (p) => ids.includes(determinePageAssetId(p));
                const withoutDuplicatePageAssetIds = withoutDuplicatePageAssetIdsFactory();

                idsWithTitles = new PageModelSearch(mainAssetInfo.dto.Pages)
                    .filter(withoutEmptyPageAssetIds, withoutDuplicatePageAssetIds, matchOutdatedAssets)
                    .map((p) => ({ Id: determinePageAssetId(p), Title: p.PageTitle }));
            } else {
                idsWithTitles = ids.map((id) => {
                    const localModel = outdatedAssets.dtos[id];
                    return {
                        Id: id,
                        Title: localModel !== undefined ? localModel.Title : null
                    };
                });
            }

            return $http.post(`${baseUrlsFactory.api_base_url}AssetDto/RecoverAssetsByServerBackup?assetId=${mainAssetInfo.assetId}`, idsWithTitles).then(function (response) {
                return response.data && response.data.RecoveredIds.length > 0 ? response.data : $q.reject(response);
            });
        };

        LocalChangesResolver.updateRevisionOfActiveDtosOnSave = function (outdatedAssets) {
            var id = "";
            for (var i = 0, l = outdatedAssets.ids.length; i < l; i++) {
                id = outdatedAssets.ids[i];
                var manager = this.dataService.getDto(id);
                if (manager) {
                    // +1 as checkRevision() on save uses > not >=.
                    // Meaning if latest Revision is 10, then we save, server is now 11, when we make a local change it will be 11, thus failing checkRevision() on Save
                    manager.data.Revision = outdatedAssets.serverRevisions[id] + 1;
                }
            }
        };

        LocalChangesResolver.getLockedAssetsToRecover = function (data) {
            return this.lockCheckAssets(data.local.ids).then(
                function (lockedAssetIds) {
                    if (lockedAssetIds.length === 0) {
                        return null;
                    }

                    var standaloneLockedResponse = lockedAssetIds.length === 1 && data.local.dtos[lockedAssetIds[0]].MainType === "WorkBookResponse";
                    var lockedAssetsToRecover = lockedAssetIds.map(function (lockedAssetId) {
                        return data.local.dtos[lockedAssetId];
                    });

                    if (lockedAssetsToRecover.length > 0) {
                        recoveryLogger.step("recover", `Found ${lockedAssetsToRecover.length} locked assets to recover`);
                    }
                    var toRecover = new OutdatedDataHolder(lockedAssetsToRecover, lockedAssetIds);
                    return {
                        standaloneLockedResponse: standaloneLockedResponse,
                        toRecover: toRecover
                    };
                }.bind(this)
            );
        };

        LocalChangesResolver.onRecoverySuccess = function (result, isSaving) {
            if (result.recoveryDto.lockedAssets && !result.recoveryDto.outdatedAssets) {
                return this.notifyUserOfLockedAssetRecovery(result.recoveryDto.mainAssetInfo.assetIcon, isSaving).then(function () {
                    return result;
                });
            } else {
                return $q.when(result);
            }
        };

        LocalChangesResolver.onRevisionRecoverSuccess = function (data) {
            if (!data.recoveryDto.outdatedAssets) {
                return;
            }

            this.cleanupLocalStorageOnResolve(data.recoveryDto);
            // show modal
            return this.notifyUser(false, data.recoveryDto.mainAssetInfo.assetGlobalType === "resource", data.recoveryDto.mainAssetInfo.assetIcon);
        };

        LocalChangesResolver.onRevisionRecoverFailure = function (data) {
            if (!data) {
                return;
            }

            this.cleanupLocalStorageOnResolve(data.recoveryDto);
        };

        LocalChangesResolver.onRevisionRecoverBeforeSaveSuccess = function (data) {
            if (!data.recoveryDto.outdatedAssets) {
                return;
            }

            var recoveryDto = data.recoveryDto;
            var onCloseCallback = function () {
                this.updateRevisionOfActiveDtosOnSave(recoveryDto.outdatedAssets);
            }.bind(this);
            return this.notifyUser(true, recoveryDto.mainAssetInfo.assetGlobalType === "resource", recoveryDto.mainAssetInfo.assetIcon, null, null, onCloseCallback);
        };

        LocalChangesResolver.onRevisionRecoverBeforeSaveFailure = function (data) {
            if (!data) {
                return;
            }
            this.onRevisionRecoverFailure(data);
        };

        /**
         * @param {RecoveryDto} recoveryDto
         * @param {Function} revisionRecoveryAPICall
         * @returns {Promise} - Resolves with {{recoveryDto: recoveryDto, lockedResponse: Object|null, revisionResponse: Object|null}}
         */
        LocalChangesResolver.runRecoveries = function (recoveryDto, revisionRecoveryAPICall) {
            var promises = [];
            var lockedResult = null;
            var revisionResult = null;

            if (recoveryDto.lockedAssets !== null && recoveryDto.lockedAssets.ids.length !== 0) {
                if (recoveryDto.outdatedAssets !== null) {
                    //Remove any ids which are in both the locked and revision objects
                    this.removeDuplicatesFromOutdatedDataHolder(recoveryDto.lockedAssets, recoveryDto.outdatedAssets);
                }

                promises.push(
                    this.recoverOutdatedAssets(recoveryDto.mainAssetInfo, recoveryDto.lockedAssets).then(
                        function (result) {
                            lockedResult = result;
                        }.bind(this)
                    )
                );
            }

            if (recoveryDto.outdatedAssets !== null && recoveryDto.outdatedAssets.ids.length !== 0) {
                promises.push(
                    revisionRecoveryAPICall(recoveryDto.mainAssetInfo, recoveryDto.outdatedAssets).then(function (result) {
                        revisionResult = result;
                    })
                );
            }

            return $q.all(promises).then(
                function () {
                    return {
                        recoveryDto: recoveryDto,
                        lockedResponse: lockedResult,
                        revisionResponse: revisionResult
                    };
                }.bind(this)
            );
        };

        LocalChangesResolver.checkDto = function (assetId, hasNestedItems, checkForSave, checkTableLegacy) {
            // if this is a dummy asset used by the workbook/template builder then never recover it
            var activeDto = this.dataService.getDto(assetId);
            if (activeDto && activeDto.data) {
                if (this.isDummyAsset(activeDto.data)) {
                    return $q.when();
                }
            }

            return this.getAssetsWithConflicts(assetId, hasNestedItems, checkForSave, checkTableLegacy).then(
                function (recoveryDto) {
                    if (!recoveryDto) {
                        return null;
                    }

                    if (recoveryDto.standaloneLockedResponse) {
                        this.cleanupLocalStorageAndResetManager([recoveryDto.mainAssetInfo.assetId]);
                        return null;
                    }

                    var onFailure = this.onRevisionRecoverFailure.bind(this);
                    return this.runRecoveries(recoveryDto, this.recoverOutdatedAssets.bind(this)).then(
                        function (result) {
                            this.cleanupLocalStorageOnResolve(result.recoveryDto);
                            return this.onRecoverySuccess(result, false).then(this.onRevisionRecoverSuccess.bind(this, result));
                        }.bind(this),
                        onFailure
                    );
                }.bind(this)
            );
        };

        LocalChangesResolver.checkBeforeSave = function (assetId) {
            var activeDto = this.dataService.getDto(assetId);

            if (activeDto && activeDto.data) {
                // if this is a dummy asset used by the workbook/template builder then never recover it
                if (this.isDummyAsset(activeDto.data)) {
                    return $q.when();
                }

                return this.getAssetsWithConflicts(assetId, this.hasNestedItems(activeDto.data), true, false).then(
                    function (recoveryDto) {
                        if (!recoveryDto) {
                            return null;
                        }

                        if (recoveryDto.standaloneLockedResponse) {
                            this.cleanupLocalStorageAndResetManager([recoveryDto.mainAssetInfo.assetId]);
                            return $q.reject({
                                standaloneLockedResponse: recoveryDto.standaloneLockedResponse
                            });
                        }

                        var onFailure = this.onRevisionRecoverBeforeSaveFailure.bind(this);
                        return this.runRecoveries(recoveryDto, this.recoverOutdatedAssetsBeforeSave.bind(this)).then(
                            function (result) {
                                this.cleanupLocalStorage(result.recoveryDto.lockedAssets !== null && Array.isArray(result.recoveryDto.lockedAssets.ids) ? result.recoveryDto.lockedAssets.ids : null);
                                return this.onRecoverySuccess(result, true)
                                    .then(this.onRevisionRecoverBeforeSaveSuccess.bind(this, result))
                                    .then(function () {
                                        return result;
                                    });
                            }.bind(this),
                            onFailure
                        );
                    }.bind(this)
                );
            } else {
                return $q.when();
            }
        };

        LocalChangesResolver.isDummyAsset = function (dto) {
            var isDummyAsset = false;
            if (dto && dto.AssetOptions && dto.AssetOptions.length) {
                for (var i = 0; i < dto.AssetOptions.length; i++) {
                    var option = dto.AssetOptions[i];
                    if (option && option.Key && option.Key === "dummyAssetForBuilder") {
                        isDummyAsset = true;
                    }
                }
            }
            return isDummyAsset;
        };

        LocalChangesResolver.getOnlyAssets = function (keys) {
            var allAssetData = { ids: { hasNested: [], noNested: [] }, dtos: {} },
                key,
                currentItem;

            for (var i = 0, len = keys.length; i < len; i++) {
                key = keys[i];
                currentItem = this.getLocalStorageItem(key);

                if (currentItem) {
                    if (this.hasNestedItems(currentItem)) {
                        allAssetData.ids.hasNested.push(key);
                    } else {
                        allAssetData.ids.noNested.push(key);
                    }

                    allAssetData.dtos[key] = currentItem;
                }
            }

            return allAssetData;
        };

        LocalChangesResolver.cleanupLocalStorageOnResolve = function (data) {
            if (!data) {
                return;
            }

            this.cleanupLocalStorage(data.outdatedAssets && data.outdatedAssets.ids ? data.outdatedAssets.ids : null);
            this.cleanupLocalStorage(data.lockedAssets && data.lockedAssets.ids ? data.lockedAssets.ids : null);
        };

        LocalChangesResolver.cleanupLocalStorageAndResetManager = function (ids) {
            var manager = null;

            this.cleanupLocalStorage(ids);
            for (var i = 0, l = ids.length; i < l; i++) {
                manager = this.dataService.getDto(ids[i]);

                if (manager) {
                    manager.is_saved = true;
                    manager.data.is_saved = true;
                }
            }
        };

        LocalChangesResolver.recoverAllAssets = function (ids, dtos, startRecoveryPromise) {
            var id,
                promise,
                promises = [],
                recoveredSubmittedAssets = [];

            if (ids && ids.length > 0) {
                for (var i = 0, len = ids.length; i < len; i++) {
                    id = ids[i];

                    promise = this.getAssetsWithConflicts(id, this.hasNestedItems(dtos[id]), false, false).then(
                        function (recovery) {
                            if (!recovery) {
                                return null;
                            }

                            if (recovery.standaloneLockedResponse) {
                                this.cleanupLocalStorageAndResetManager([recovery.mainAssetInfo.assetId]);
                                return null;
                            }

                            const onFailure = whenNotLoggedOutError(this.cleanupLocalStorageOnResolve.bind(this, recovery));

                            return this.runRecoveries(recovery, this.recoverOutdatedAssets.bind(this)).then(
                                function (result) {
                                    if (!result.recoveryDto.lockedAssets && !result.recoveryDto.outdatedAssets) {
                                        return null;
                                    }

                                    if (result.lockedResponse) {
                                        recoveredSubmittedAssets.push(result.lockedResponse);
                                    }

                                    if (result.revisionResponse) {
                                        recoveredSubmittedAssets.push(result.revisionResponse);
                                    }
                                    this.cleanupLocalStorageOnResolve(result.recoveryDto);
                                    startRecoveryPromise.resolve(result.recoveryDto);

                                    return result.recoveryDto;
                                }.bind(this),
                                onFailure
                            );
                        }.bind(this)
                    );

                    promises.push(promise);
                }
                return $q.all(promises).then(function (data) {
                    return { resolved: data, submittedAssets: recoveredSubmittedAssets };
                });
            } else {
                return $q.when(null);
            }
        };

        LocalChangesResolver.forceRecoveryIfUnsaved = function (assetId) {
            return this.dataService.checkIfSaved(assetId) ? $q.reject() : this.forceRecovery(assetId);
        };

        LocalChangesResolver.forceRecovery = function (assetId) {
            var manager = this.dataService.getDto(assetId);

            //If manager doesn't exist bail!
            if (!manager || !manager.data) {
                return $q.reject();
            }

            //Get local storage items which could be linked with the asset. Skip validation (revision checking).
            return this.getLocalAssetsFromId(assetId, this.hasNestedItems(manager.data)).then(
                function (data) {
                    if (data.local === null) {
                        return $q.reject();
                    }

                    const recoveryDto = this.createRecoveryDto(assetId, data.mainDto, null, data.local);
                    const ids = [recoveryDto.mainAssetInfo.assetId].distinctConcat(recoveryDto.outdatedAssets.ids);
                    const onFailure = whenNotLoggedOutError(this.cleanupLocalStorageAndResetManager.bind(this, ids));

                    //Call to server to initiate recovery.
                    return this.recoverOutdatedAssets(recoveryDto.mainAssetInfo, recoveryDto.outdatedAssets).then(
                        function (response) {
                            if (response.Message === recoverNotLoggedIn) {
                                $rootScope.$broadcast("allowRedirect");
                            } else {
                                this.cleanupLocalStorageAndResetManager(ids);
                            }
                        }.bind(this),
                        onFailure
                    );
                }.bind(this)
            );
        };

        LocalChangesResolver.checkAndRecoverAll = function (allLocalStorageKeys, startRecoveryPromise) {
            var needToRecover = false;
            var allAssetData = this.getOnlyAssets(allLocalStorageKeys),
                hasRecoveredData = function (value) {
                    return !!value;
                };

            return this.recoverAllAssets(allAssetData.ids.hasNested, allAssetData.dtos, startRecoveryPromise).then(
                function (data) {
                    recoveryLogger.step("recover", "App load recovery check - nested");
                    var recoveredItems = data || { resolved: [], submittedAssets: [] };
                    // check if any asset is ready for recovery
                    if (data && data.resolved.length) {
                        for (var i = 0, len = data.length; i < len; i++) {
                            // tslint:disable-next-line:triple-equals
                            if (data[i] != null) {
                                needToRecover = true;
                                break;
                            }
                        }
                    }

                    // check all assets without nested items
                    allAssetData = this.getOnlyAssets(allLocalStorageKeys);
                    return this.recoverAllAssets(allAssetData.ids.noNested, allAssetData.dtos, startRecoveryPromise).then(function (data) {
                        recoveryLogger.step("recover", "App load recovery check - root");
                        if (data) {
                            recoveredItems.resolved = recoveredItems.resolved.concat(data.resolved);
                            recoveredItems.submittedAssets = recoveredItems.submittedAssets.concat(data.submittedAssets);
                        }

                        if (!needToRecover && data !== null && data.resolved.some(hasRecoveredData)) {
                            needToRecover = true;
                        }

                        if (needToRecover === false) {
                            startRecoveryPromise.reject();
                        }

                        return recoveredItems;
                    });
                }.bind(this)
            );
        };

        LocalChangesResolver.checkRevision = function (revisionData, localDto, checkForSave) {
            var latestSavedRevision = (revisionData[0] || revisionData)["Value"];
            return checkForSave ? !(localDto.Revision > latestSavedRevision) : latestSavedRevision >= localDto.Revision;
        };

        LocalChangesResolver.getAssetIcon = function (item) {
            return (item.PageIcon || item.SubType || item.TemplateSubType || item.MainType || "").toLowerCase();
        };

        LocalChangesResolver.getAssetGlobalType = function (item) {
            return resourceTypes.indexOf(item.MainType) > -1 ? "resource" : "asset";
        };

        LocalChangesResolver.itemIsDeprecated = function (item) {
            return item.Revision === undefined || item.Revision === null || isNaN(item.Revision);
        };

        LocalChangesResolver.itemHasOldTable = function (item, checkTableLegacy) {
            var sections = item.Sections || item.Elements,
                section,
                sectionContent;

            if (checkTableLegacy && sections) {
                for (var i = 0, len = sections.length; i < len; i++) {
                    section = sections[i];

                    if (section) {
                        section = section.Sections || section;
                        sectionContent = section.length > 0 ? (sectionContent = section[0]) : section;
                        var result = this.elementIsOldTable(sectionContent);
                        if (result) {
                            return result;
                        }
                    }
                }
            }

            return false;
        };

        LocalChangesResolver.elementIsOldTable = function (element) {
            var sectionType = element.SectionType || element.ElementType || "";
            sectionType = /table/i.test(sectionType);
            return sectionType && element.TableType === void 0;
        };

        LocalChangesResolver.checkDates = function (localDto) {
            return new Date(localDto.LastModified) > new Date(localDto.Created);
        };

        LocalChangesResolver.notifyUser = function (isSaving, isResource, type, recoveryPromise, responseData, onClose) {
            var imgLocation = baseUrlsFactory.shared_component_base_url + "localChanges/images/";

            //Add values to modal scope;
            var modalScope = $rootScope.$new(true);
            modalScope.text = isResource ? "workbook.revision_modals.recovered_resource" : "workbook.revision_modals.recovered_asset";
            modalScope.subHeading = isSaving ? multiLanguageService.getString(modalScope.text + ".save_subHeading") : multiLanguageService.getString(modalScope.text + ".load_subHeading");
            modalScope.confirm = multiLanguageService.getString("workbook.revision_modals.confirm_recovered_asset");
            modalScope.img = $sce.getTrustedResourceUrl(imgLocation + (isResource ? "recovered-resource.jpg" : "recovered-asset.jpg"));
            modalScope.hint = multiLanguageService.getString("workbook.revision_modals.hint");
            modalScope.iconType = type ? type.toLowerCase() : null;
            modalScope.submittedAssets = responseData;
            modalScope.showMultiStaged = false;

            if (recoveryPromise) {
                modalScope.onLoadingHeading = multiLanguageService.getString("workbook.revision_modals.recovered_all.on_loading_heading");
                modalScope.subHeading = multiLanguageService.getString("workbook.revision_modals.recovered_all.sub_heading");
                modalScope.text = multiLanguageService.getString("workbook.revision_modals.recovered_all");
                recoveryPromise.then(function (data) {
                    modalScope.submittedAssets = data ? data.submittedAssets : null;
                    modalScope.dateReady = $timeout(function () {
                        modalScope.showMultiStaged = true;
                        modalScope.showFinalMessage = true;
                    }, 500);
                });
            } else {
                modalScope.showFinalMessage = true;
            }

            return this.launchRecoveryModal(modalScope, isSaving, onClose);
        };

        LocalChangesResolver.notifyUserOfLockedAssetRecovery = function (type, isSaving) {
            //Add values to scope;
            var modalScope = $rootScope.$new(true);
            modalScope.multiLanguageService = multiLanguageService;
            modalScope.subHeading = multiLanguageService.getString("workbook.locked_recovery.subHeading");
            modalScope.confirm = multiLanguageService.getString("workbook.revision_modals.confirm_recovered_asset");
            modalScope.img = $sce.getTrustedResourceUrl(baseUrlsFactory.shared_component_base_url + "localChanges/images/recovered-asset.jpg");
            modalScope.hint = multiLanguageService.getString("workbook.revision_modals.hint");
            modalScope.iconType = type ? type.toLowerCase() : null;
            modalScope.submittedAssets = null;
            modalScope.showMultiStaged = false;
            modalScope.showFinalMessage = true;

            return this.launchRecoveryModal(modalScope, isSaving);
        };

        LocalChangesResolver.launchRecoveryModal = function (modalScope, isSaving, onClose) {
            overlayFactory.saveOverlay.hide();
            var deferred = $q.defer();

            modalScope.onClose = function () {
                deferred.resolve();
                if (onClose) {
                    onClose();
                }

                if (isSaving) {
                    overlayFactory.saveOverlay.show();
                }

                modalScope.$destroy();
            };

            modal.newModal({
                scope: modalScope,
                disableClose: true,
                templateUrl: baseUrlsFactory.shared_component_base_url + "localChanges/templates/local-changes-export.html"
            });

            return deferred.promise;
        };

        return LocalChangesResolver;
    }
]);
