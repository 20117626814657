import { angularAMD } from "@pebblepad/amd";
import { richTextEditorComponentDefinition } from "../components/richTextEditor.component";
import { persistentCompactRichTextEditorDefinition } from "../../persistentCompactRichTextEditor/peristentCompactRichTextEditor.component";
import { withEditorFallback } from "../../withEditorFallback/WithEditorFallback.function";
import "../components/richTextEditor.component";
import "../../persistentCompactRichTextEditor/peristentCompactRichTextEditor.component";

const component = withEditorFallback(
    "persistentCompactMultiLineEditor",
    {
        legacyEditor: richTextEditorComponentDefinition,
        editor: persistentCompactRichTextEditorDefinition
    },
    false,
    "compact-fallback-editor",
    "legacy-compact-editor"
);
angularAMD.component(component.name, component);
