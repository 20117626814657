import { angularAMD } from "@pebblepad/amd";
import { richTextEditorComponentDefinition } from "../components/richTextEditor.component";
import { feedbackCommentsEditorDefinition } from "../../feedbackCommentsEditor/feedbackCommentsEditor";
import { withEditorFallback } from "../../withEditorFallback/WithEditorFallback.function";
import "../components/richTextEditor.component";

const component = withEditorFallback(
    "feedbackCommentsEditor",
    {
        legacyEditor: richTextEditorComponentDefinition,
        editor: feedbackCommentsEditorDefinition
    },
    false,
    "compact-fallback-editor",
    "legacy-compact-editor-feedback"
);
angularAMD.component(component.name, component);
