import { angularAMD } from "@pebblepad/amd";
import "../../utilities/baseUrlsFactory";
import "../../modal/services/modal";
import "../../utilities/pebbleDate";

angularAMD.factory("useResourceService", [
    "$q",
    "$http",
    "$routeParams",
    "baseUrlsFactory",
    "$location",
    "$rootScope",
    "$compile",
    "$route",
    "$window",
    "$sce",
    "modal",
    function ($q, $http, $routeParams, baseUrlsFactory, $location, $rootScope, $compile, $route, $window, $sce, modal) {
        var cachedIsResourceUsed = {};

        return {
            clearUrlParams: function () {
                $location.search("type", null);
                $location.search("pageId", null);
            },

            redirect: function (url) {
                $window.location.href = url;
            },

            isResourceInUse: function (assetId, ignoreCache) {
                if (!ignoreCache && cachedIsResourceUsed[assetId] !== undefined) {
                    return $q.when(cachedIsResourceUsed[assetId]);
                } else {
                    return $http.get(baseUrlsFactory.api_base_url + "CheckUse/IsResourceInUse?assetId=" + assetId).then(function (response) {
                        cachedIsResourceUsed[assetId] = response.data;
                        return response.data;
                    });
                }
            },

            //informs the user that there single use asset is in the deleted folder
            informUser: function (informType, message) {
                var self = this;
                var modalScope = $rootScope.$new(true),
                    deferred = $q.defer();
                modalScope.openDeletedAssetStore = function () {
                    self.clearUrlParams();
                    $location.path("/store").search("type", "deleted");
                    modalScope.redirected = true;
                };
                modalScope.openAssetStore = function () {
                    self.clearUrlParams();
                    $location.path("/store").search("type", "asset");
                    modalScope.redirected = true;
                };

                modalScope.onClose = function () {
                    deferred.resolve(modalScope.redirected);
                    modalScope.$destroy();
                };

                modalScope.informType = informType;

                modalScope.message = $sce.trustAsHtml(message);

                modal.newModal({
                    scope: modalScope,
                    templateUrl: baseUrlsFactory.shared_component_base_url + "assetStore/templates/single-use-inform-user-message.html"
                });

                return deferred.promise;
            },

            //gives the user multiple assets to open or a new responce
            multipleRedirect: function (data, modalScope, ignoreHistory) {
                var self = this,
                    deferred = $q.defer();

                modalScope.imageShareForAssessment = $sce.getTrustedResourceUrl(baseUrlsFactory.shared_component_base_url + "images/sharing/shareForAssessment.svg");
                modalScope.allowNew = data.AllowNew;
                modalScope.allowNewRedirect = data.AllowNewRedirect;
                modalScope.allowNewRedirectRelative = data.AllowNewRedirectRelative;
                modalScope.assetsInDeletedStore = data.AssetsInDeletedStore;
                modalScope.assetsInEditableStore = data.AssetsInEditableStore;
                modalScope.ignoreHistory = ignoreHistory;

                if (data.AssetsInEditableStore && data.AssetsInEditableStore.length > 0) {
                    modalScope.isWorkbook = /workbook/i.test(data.AssetsInEditableStore[0].Icon);
                } else if (data.AssetsInDeletedStore && data.AssetsInDeletedStore.length > 0) {
                    modalScope.isWorkbook = /workbook/i.test(data.AssetsInDeletedStore[0].Icon);
                } else {
                    modalScope.isWorkbook = false; // shouldn't happen
                }

                modalScope.redirectToUrl = function (url, relativeRedirect, ignoreHistory) {
                    if ($rootScope.appName === "Plus") {
                        $location.url(ignoreHistory ? relativeRedirect : modalScope.addHistoryId(relativeRedirect));
                    } else {
                        $window.location.href = ignoreHistory ? url : modalScope.addHistoryId(url);
                    }
                    modalScope.redirected = true;
                };

                modalScope.openDeletedAssetStore = function () {
                    self.clearUrlParams();
                    $location.path("/store").search("type", "deleted");
                    modalScope.redirected = true;
                };

                modalScope.onClose = function () {
                    deferred.resolve(modalScope.redirected);
                    modalScope.$destroy();
                };

                modalScope.addHistoryId = function (url) {
                    const historyId = $routeParams.historyId ? $routeParams.historyId : "";
                    if (historyId === "") {
                        return url;
                    }

                    if (url.indexOf("historyId") < 0) {
                        url = url + (url.indexOf("?") < 0 ? "?" : "&") + "historyId=" + historyId;
                    }

                    return url;
                };

                modal.newModal({
                    scope: modalScope,
                    templateUrl: baseUrlsFactory.shared_component_base_url + "assetStore/templates/multiple-redirects.html"
                });

                return deferred.promise;
            },

            //show an error message to the user
            error: function (message) {
                var modalScope = $rootScope.$new(true);
                modalScope.modal_message = message;

                modal.newModal({
                    scope: modalScope,
                    templateUrl: baseUrlsFactory.shared_component_base_url + "assetStore/templates/single-use-error-message.html"
                });
            },

            forwardUser: function (data, ignoreHistory) {
                var self = this;
                switch (data.Action) {
                    case "Redirect":
                        if ($rootScope.appName === "Plus" || $rootScope.appName === "Admissions") {
                            var historyId = $routeParams.historyId ? $routeParams.historyId : "";
                            // tslint:disable-next-line:triple-equals
                            if (historyId != "" && !ignoreHistory) {
                                if (data.RelativeRedirectIfInPlus.indexOf("?") > 0) {
                                    data.RelativeRedirectIfInPlus = data.RelativeRedirectIfInPlus + "&historyId=" + historyId;
                                } else {
                                    data.RelativeRedirectIfInPlus = data.RelativeRedirectIfInPlus + "?historyId=" + historyId;
                                }
                            }
                            $location.url(data.RelativeRedirectIfInPlus);
                        } else {
                            self.redirect(data.Redirect);
                        }
                        break;
                    case "InformUser":
                    case "InformUserDeletedStore":
                    case "InformUserAssetStore":
                        $rootScope.appIsLoaded = true;
                        return self.informUser(data.Action, data.Message);
                        break;
                    case "MultipleRedirect":
                        var modalScope = $rootScope.$new(true);
                        $rootScope.appIsLoaded = true;
                        return self.multipleRedirect(data, modalScope);
                        break;
                    case "Error":
                        self.error(data.Message);
                        $rootScope.appIsLoaded = true;
                        return $q.reject();
                        break;
                    default:
                    //code block
                }
                return $q.when(true);
            },

            checkUse: function (details, ignoreHistory) {
                $rootScope.appIsLoaded = false;

                var self = this;
                $http.defaults.withCredentials = true;
                return $http
                    .post(baseUrlsFactory.api_base_url + "CheckUse/CheckUse", details)
                    .then(function (response) {
                        return self.forwardUser(response.data, ignoreHistory);
                    })
                    .catch(angular.noop);
            }
        };
    }
]);
