import { angularAMD } from "@pebblepad/amd";
import { ASSET_CONSTANTS } from "../constants/asset.constants";
import "../builder/dataManager/helperService";
import "../utilities/urlService";
import "../modal/services/modal";
import "../utilities/baseUrlsFactory";
import "../multiLanguageService/multiLanguageService";
import "./collaborationLoaderAPI.service";
import "../history/services/historyService";
import "../assetStore/services/assetStoreService";

angularAMD.service("CollaborationLoaderService", CollaborationLoaderService);
CollaborationLoaderService.$inject = [
    "$rootScope",
    "$q",
    "$location",
    "$routeParams",
    "helperService",
    "urlService",
    "modal",
    "baseUrlsFactory",
    "multiLanguageService",
    "CollaborationLoaderAPI",
    "historyService",
    "assetStoreService"
];

function CollaborationLoaderService(
    $rootScope,
    $q,
    $location,
    $routeParams,
    helperService,
    urlService,
    modal,
    baseUrlsFactory,
    multiLanguageService,
    CollaborationLoaderAPI,
    historyService,
    assetStoreService
) {
    //Public API
    //=============================================================================================================
    this.resolveCollaboration = resolveCollaboration;
    this.showAcceptCollabModal = showAcceptCollabModal;
    this.declineAndRedirect = declineAndRedirect;
    this.checkAssetForCollaboration = checkAssetForCollaboration;

    //Private methods
    //=============================================================================================================
    var collaborationModal;

    function resolveCollaboration(collabStatus, assetId, mainType, url) {
        var deferred = $q.defer();

        if (collabStatus === ASSET_CONSTANTS.PERMISSIONS.COLLABORATE) {
            if (isResource(mainType) || !urlService.isViewer(url)) {
                // Show accept collab modal straight away. Either we're trying to access a
                // resource or an asset directly.
                showAcceptCollabModal(assetId, deferred.resolve, declineAndRedirect);
            } else {
                // Don't show modal until edit button is clicked in viewer
                deferred.resolve(collabStatus);
            }
        } else {
            deferred.resolve();
        }

        return deferred.promise;
    }

    function checkAssetForCollaboration(assetId) {
        return assetStoreService.getEditStatusForAsset(assetId).then(function (status) {
            return status === ASSET_CONSTANTS.PERMISSIONS.COLLABORATE;
        });
    }

    function declineAndRedirect() {
        $location.url(historyService.getLastHistoryPath($routeParams.historyId) || urlService.createStoreRedirectUrl($location.path()));
        collaborationModal.hideModal();
        return $q.reject();
    }

    function showAcceptCollabModal(assetId, onAccept, onDecline) {
        var modalScope = $rootScope.$new();
        modalScope.title = multiLanguageService.getString("asset_store.accept_collaboration_modal.title");
        modalScope.message = multiLanguageService.getString("asset_store.accept_collaboration_modal.message");
        modalScope.buttons = [
            {
                primary: true,
                label: multiLanguageService.getString("asset_store.accept_collaboration_modal.accept_and_edit"),
                action: function () {
                    // Send req to server to accept asset
                    CollaborationLoaderAPI.acceptCollab(assetId).then(function (response) {
                        // tslint:disable-next-line:triple-equals
                        if (response.status == 202) {
                            onAccept();
                        }
                    }, onDecline);
                }
            },
            {
                primary: false,
                label: multiLanguageService.getString("buttons.cancel"),
                action: onDecline
            }
        ];

        modalScope.onClose = function () {
            modalScope.$destroy();
        };

        collaborationModal = modal.newModal({
            scope: modalScope,
            templateUrl: baseUrlsFactory.shared_component_base_url + "collaborationLoader/accept-collaboration-modal.html",
            disableClose: true
        });
    }

    function isResource(type) {
        return helperService.isResourceBuilder(type);
    }
}
