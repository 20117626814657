import { angularAMD } from "@pebblepad/amd";
import "../../modal/services/modal";
import "../../assetEndpointService/assetEndpoint.service";
import "../../iWantTo/services/iWantToService";
import "../../builder/dataManager/dataManagerService";
import { PageModelSearch } from "../../pageModelSearch/pageModelSearch";
import "../../spaMenu/assetFilteringService/assetFiltering.service";
import "../../assetFiltering/assetFilteringConfig.provider";
import { MAP_TO_STANDARDS_CONSTANTS } from "../../constants/mapToStandards.constants";
import { ASSET_CONSTANTS } from "../../constants/asset.constants";
import { ELEMENT_CONSTANTS } from "../../constants/element.constants";

angularAMD.service("StandardsService", StandardsService);

StandardsService.$inject = ["$http", "baseUrlsFactory", "urlService", "modal", "AssetEndpointService", "dataManagerService", "$q", "assetFilteringService", "assetFilteringConfig"];

function StandardsService($http, baseUrlsFactory, urlService, modal, AssetEndpointService, dataManagerService, $q, assetFilteringService, assetFilteringConfig) {
    this.getStandards = (workBookId, submissionId = null) => {
        return $http.get(`${baseUrlsFactory.api_base_url}WorkBook/GetStandards?workBookId=${workBookId}&submissionId=${submissionId}`).then((response) => {
            const data = response.data;
            data.Groups = data.Groups.map((group) => this._convertCapabilitiesToStandards(group));
            return data;
        });
    };

    /*
        TODO: Remove convertCapabilitiesToStandards once getAllStandardsWorkbooksForOrg is removed
        getAllStandardsWorkbooksForOrg returns .Standards, this endpoint returns .Capabilities
        In an attempt to keep as much of the code as possibly unchanged to prevent any possibly issues with
        EWC standards we're converting .Capabilities to .Standards.
    */
    this._convertCapabilitiesToStandards = (group) => {
        if (group.Groups.length) {
            group.Groups.map((group) => this._convertCapabilitiesToStandards(group));
        }

        group.Standards = group.Capabilities.map((capability) => {
            capability.Checked = capability.IsMapped;
            return capability;
        });

        delete group.Capabilities;
        return group;
    };

    this.canFilterEvidence = (assetId, canWaitForDto = true) => {
        let managerPromise;

        if (!canWaitForDto) {
            let manager = dataManagerService.getDto(assetId);

            if (manager === null || manager === undefined) {
                return $q.resolve(false);
            }

            managerPromise = $q.resolve(manager);
        } else {
            managerPromise = dataManagerService.waitForDto(assetId);
        }

        return managerPromise
            .then((manager) => {
                if (manager.data.MainType !== ASSET_CONSTANTS.TYPES.WORKBOOK_RESPONSE) {
                    return false;
                }

                if (assetFilteringConfig.disableWhenInFolders.includes(manager.data.AssetFolder)) {
                    return false;
                }

                if (manager.data.SubType === ASSET_CONSTANTS.SUB_TYPES.STANDARDS) {
                    return true;
                }

                const pageSearch = new PageModelSearch(manager.data.Pages);
                const hasStandardsPage = pageSearch.contains((p) => p.PageSubType === ASSET_CONSTANTS.SUB_TYPES.STANDARDS);

                if (hasStandardsPage) {
                    return true;
                }

                return false;
            })
            .catch(() => {
                return false;
            });
    };

    this.includesStandardsElements = (assetId) => {
        const managerPromise = dataManagerService.waitForDto(assetId);

        return managerPromise.then((manager) => {
            const elements = manager.data.Elements;

            if (elements === undefined) {
                return false;
            }

            return elements.some(
                (element) =>
                    element.ElementType === ELEMENT_CONSTANTS.TYPES.STANDARDS_SELECTION ||
                    element.ElementType === ELEMENT_CONSTANTS.TYPES.STANDARDS ||
                    element.ElementType === ELEMENT_CONSTANTS.TYPES.FORM_BASIC_CONTAINER
            );
        });
    };

    this.getMappableCapabilities = (userId, assetId) => {
        return $http.get(`${baseUrlsFactory.api_base_url}Capability/GetMappableCapabilities?userId=${userId}&assetId=${assetId}`).then((response) => {
            const data = response.data.map((item) => {
                item.Groups = item.Groups.map((group) => this._convertCapabilitiesToStandards(group));
                return item;
            });
            return data;
        });
    };

    this.getAllStandardsWorkbooksForOrg = (assetId, submissionId = null) => {
        return $http.get(`${baseUrlsFactory.api_base_url}WorkBook/GetAllStandardsWorkbooksForOrg?assetId=${assetId}&submissionId=${submissionId}`).then((response) => {
            return response.data.map((item) => {
                item.Groups = item.Groups.map((group) => this._convertCapabilitiesToStandards(group));
                return item;
            });
        });
    };

    this.getSelectedStandards = (standardsAnswersArray) => {
        return standardsAnswersArray.map((answer) => {
            return {
                PageId: answer.PageId,
                PageFormId: answer.PageFormId,
                FormElementId: answer.ElementId,
                ElementId: answer.ElementId,
                WorkBookId: answer.WorkBookRefId || answer.WorkBookId,
                Rating: answer.Rating
            };
        });
    };

    this.setStandards = (standards) => {
        return urlService.promisedPost(`${baseUrlsFactory.api_base_url}WorkBook/SetStandards`, standards);
    };

    this._buildNewCapabilityMap = (capabilities, newCapabilities, assetRefId = null) => {
        return capabilities.reduce((accumulator, capability) => {
            const groupItem = {
                Pages: [],
                Capabilities: [],
                AssetRefId: capability.AssetRefId || capability.PageFormId,
                PageId: capability.AssetId || capability.PageId
            };

            const currentRefId = capability.AssetRefId || assetRefId;

            if (capability.MainType === "Form") {
                if (capability.Groups.length > 0) {
                    groupItem.Capabilities = capability.Groups[0].Standards.reduce((caps, item) => {
                        var addCapability = newCapabilities.find((newCap) => currentRefId === newCap.AssetRefId && newCap.ElementId === item.ElementId);
                        if (addCapability !== undefined) {
                            caps.push({
                                ElementId: addCapability.ElementId,
                                Justification: addCapability.Justification
                            });
                        }
                        return caps;
                    }, []);
                }
            } else {
                if (capability.Groups !== undefined && capability.Groups.length > 0) {
                    groupItem.Pages = this._buildNewCapabilityMap(capability.Groups, newCapabilities, currentRefId);
                } else if (capability.Standards !== undefined && capability.Standards.length > 0) {
                    groupItem.Capabilities = capability.Standards.reduce((caps, item) => {
                        const addCapability = newCapabilities.find((newCap) => currentRefId === newCap.AssetRefId && newCap.PageId === capability.PageId && newCap.ElementId === item.ElementId);
                        if (addCapability !== undefined) {
                            caps.push({
                                ElementId: addCapability.ElementId,
                                Justification: addCapability.Justification
                            });
                        }

                        return caps;
                    }, []);
                }
            }

            if ((groupItem.Pages !== undefined && groupItem.Pages.length > 0) || (groupItem.Capabilities !== undefined && groupItem.Capabilities.length > 0)) {
                accumulator.push(groupItem);
            }
            return accumulator;
        }, []);
    };

    this.saveCapabilitiesFromStandards = (assetId, standardsData, addedCaps, removedCaps, updatedJustifications) => {
        const promises = [];
        const ADD_FAIL = MAP_TO_STANDARDS_CONSTANTS.CAPABILTY_SAVE_RESPONSES.ADD_FAIL;
        const UPDATE_FAIL = MAP_TO_STANDARDS_CONSTANTS.CAPABILTY_SAVE_RESPONSES.UPDATE_FAIL;
        const REMOVE_FAIL = MAP_TO_STANDARDS_CONSTANTS.CAPABILTY_SAVE_RESPONSES.REMOVE_FAIL;

        if (updatedJustifications.length > 0) {
            const updateCapabilitiesFormat = {
                EvidenceAssetRefId: assetId,
                Mappings: this._buildNewCapabilityMap(standardsData, updatedJustifications)
            };

            promises.push(
                this._updateCapabilities(updateCapabilitiesFormat)
                    .then(() => MAP_TO_STANDARDS_CONSTANTS.CAPABILTY_SAVE_RESPONSES.UPDATE_SUCCESS)
                    .catch(() => UPDATE_FAIL)
            );
        }

        if (addedCaps.length > 0) {
            const addCapabilitiesFormat = {
                EvidenceAssetRefId: assetId,
                Mappings: this._buildNewCapabilityMap(standardsData, addedCaps)
            };

            promises.push(
                this._addCapabilities(addCapabilitiesFormat)
                    .then(() => MAP_TO_STANDARDS_CONSTANTS.CAPABILTY_SAVE_RESPONSES.ADD_SUCCESS)
                    .catch(() => ADD_FAIL)
            );
        }

        if (removedCaps.length > 0) {
            const removeCapabilitiesFormat = {
                EvidenceAssetRefId: assetId,
                Mappings: this._buildNewCapabilityMap(standardsData, removedCaps)
            };

            promises.push(
                this._removeCapabilities(removeCapabilitiesFormat)
                    .then(() => MAP_TO_STANDARDS_CONSTANTS.CAPABILTY_SAVE_RESPONSES.REMOVE_SUCCESS)
                    .catch(() => REMOVE_FAIL)
            );
        }

        return $q.all(promises).then((messages) => {
            const failureMessages = [ADD_FAIL, UPDATE_FAIL, REMOVE_FAIL];
            const hasARequestFailed = messages.some((msg) => failureMessages.includes(msg));
            if (hasARequestFailed) {
                return $q.reject(messages);
            }
            return messages;
        });
    };

    this._updateCapabilities = (updatedCapabilities) => {
        return $http.post(`${baseUrlsFactory.api_base_url}EvidenceMap/Update`, updatedCapabilities);
    };

    this._addCapabilities = (saveObj) => {
        return $http.put(`${baseUrlsFactory.api_base_url}EvidenceMap/Add`, saveObj);
    };

    this._removeCapabilities = (removeObj) => {
        return $http({
            method: "DELETE",
            url: `${baseUrlsFactory.api_base_url}EvidenceMap/Remove`,
            data: removeObj,
            headers: { "Content-type": "application/json" }
        });
    };

    this._pendingStandardsForAssetRequests = new Map();

    this.getStandardsForAsset = (assetId, submissionId = null, timeslice = null) => {
        const key = `${assetId}-${submissionId}-${timeslice}`;
        const pending = this._pendingStandardsForAssetRequests.get(key);
        if (pending !== undefined) {
            return pending;
        }

        const request = $http.get(`${baseUrlsFactory.api_base_url}WorkBook/GetStandardsForAsset?assetId=${assetId}&submissionId=${submissionId}&timeslice=${timeslice}`).then((response) => {
            const data = response.data;
            data.Standards = data.Standards.map((standard) => {
                standard.AssetRefId = standard.WorkBookId;
                return standard;
            });
            return data;
        });

        this._pendingStandardsForAssetRequests.set(key, request);
        request.finally(() => setTimeout(this._pendingStandardsForAssetRequests.delete(key), 5000));
        return request;
    };

    this.isStandardSelected = (standardElementId, standardPageId, selectedStandards) => {
        if (selectedStandards.length > 0) {
            for (let i = 0; i < selectedStandards.length; i++) {
                if (selectedStandards[i].ElementId === standardElementId && selectedStandards[i].PageId === standardPageId) {
                    return true;
                }
            }
        }
    };

    this.openStandardsInfo = (title, info, btnLabel) => {
        modal.launch(
            `${baseUrlsFactory.shared_component_base_url}modalDialogComponent/templates/generic-pop-up.html`,
            {
                title: title,
                message: info,
                button_label: btnLabel
            },
            {
                focusStyle: false,
                disableFocusScroll: true
            }
        );
    };

    this.removeMapping = (data) => {
        return AssetEndpointService.removeMapping(data);
    };

    this.getStandardsOverviewData = (assetId, pageId, submissionId, timeslice) => {
        return assetFilteringService
            .loadExistingFilter(assetId)
            .then((filter) => {
                const nonPersistentFilter = this._getNonPersistentFilter(filter);
                const filterArray = nonPersistentFilter === null ? [] : [nonPersistentFilter];
                return AssetEndpointService.getStandardsOverviewData(assetId, pageId, submissionId, timeslice, filterArray);
            })
            .then((response) => response.data);
    };

    this.getStandardsPageOverviews = (assetId, pageId, submissionId, timeslice) => {
        return assetFilteringService
            .loadExistingFilter(assetId)
            .then((filter) => {
                const nonPersistentFilter = this._getNonPersistentFilter(filter);
                const filterArray = nonPersistentFilter === null ? [] : [nonPersistentFilter];
                return AssetEndpointService.getStandardsPageOverviews(assetId, pageId, submissionId, timeslice, filterArray);
            })
            .then((response) => response.data);
    };

    this.getStandardsElementsData = (assetId, pageId, submissionId, timeslice) => {
        return assetFilteringService
            .loadExistingFilter(assetId)
            .then((filter) => {
                const nonPersistentFilter = this._getNonPersistentFilter(filter);
                const filterArray = nonPersistentFilter === null ? [] : [nonPersistentFilter];
                return AssetEndpointService.getStandardsElementsData(assetId, pageId, submissionId, timeslice, filterArray);
            })
            .then((response) => response.data);
    };

    this.getAssessmentOverview = (assetId, pageId, submissionId, timeslice) => {
        return assetFilteringService
            .loadExistingFilter(assetId)
            .then((filter) => {
                const nonPersistentFilter = this._getNonPersistentFilter(filter);
                const filterArray = nonPersistentFilter === null ? [] : [nonPersistentFilter];
                return AssetEndpointService.getAssessmentOverview(assetId, pageId, submissionId, timeslice, filterArray);
            })
            .then((response) => response.data);
    };

    this._getNonPersistentFilter = (filter) => {
        if (filter !== null && !filter.IsPersistent) {
            return filter;
        }

        return null;
    };
}
