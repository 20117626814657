import { angularAMD } from "@pebblepad/amd";
import "../../../multiLanguageService/multiLanguageService";
import "../../../inlineTextEditor/services/richTextEditingSettings.service";
import template from "./feedback-text.html";

var feedbackTextDefinition = {
    bindings: {
        text: "<"
    },
    template: template,
    controller: feedbackTextComponent
};

feedbackTextComponent.$inject = ["multiLanguageService", "richTextEditingSettings"];
function feedbackTextComponent(multiLanguageService, richTextEditingSettings) {
    this.multiLanguageService = multiLanguageService;
    this.isCKEditor5Enabled = richTextEditingSettings.isEditorEnabled();
}

angularAMD.component("feedbackText", feedbackTextDefinition);
export { feedbackTextDefinition as feedbackText };
