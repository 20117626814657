import { AnimationFunction } from "./types/AnimationFunction";

export const webAnimationFactory = (keyframes: Array<Keyframe>, duration: number, easing?: string): AnimationFunction => {
    return async (element: HTMLElement): Promise<void> => {
        const prefersNoAnimations = window.matchMedia(`(prefers-reduced-motion: reduce)`).matches;

        if (prefersNoAnimations) {
            const finalStyles: Partial<CSSStyleDeclaration> = {};

            for (const frame of keyframes) {
                Object.assign(finalStyles, frame);
            }

            Object.assign(element.style, finalStyles);
            return Promise.resolve();
        }

        return element
            .animate(keyframes, {
                duration: duration,
                easing: easing,
                fill: "forwards"
            })
            .finished.then((a) => {
                a.commitStyles();
                a.cancel();
            });
    };
};
