import { angularAMD } from "@pebblepad/amd";
import "../../ckEditorModule/pebbleCkEditor";
import "../../modal/services/modal";
import "../../utilities/baseUrlsFactory";
import "./directives/legacyLinkerModal.directive";
import "../../multiLanguageService/multiLanguageService";

angularAMD.service("CkEditorLinkerPluginService", CkEditorLinkerPluginService);

CkEditorLinkerPluginService.$inject = ["$compile", "CkEditorService", "modal", "$rootScope", "baseUrlsFactory", "$q", "multiLanguageService"];

function CkEditorLinkerPluginService($compile, ckEditorService, modal, rootScope, baseUrlsFactory, $q, multiLanguageService) {
    this.services = {
        modal: modal,
        rootScope: rootScope,
        baseUrlsFactory: baseUrlsFactory,
        ckEditor: ckEditorService,
        q: $q,
        multiLanguageService: multiLanguageService
    };

    this.services.ckEditor.plugins.add("linker", {
        icons: "link,unlink",
        hidpi: true,
        init: this.initLinkerPlugin.bind(this)
    });
}

CkEditorLinkerPluginService.prototype.initLinkerPlugin = function (editor) {
    editor.addCommand("linker", new LinkCommand(editor, this.services));
    editor.addCommand("unlink", new UnlinkCommand(editor, this.services));
    editor.setKeystroke(this.services.ckEditor.CTRL + 76 /*L*/, "linker");

    if (editor.ui.addButton) {
        editor.ui.addButton("Link", {
            label: this.services.multiLanguageService.getString("ckeditor.plugins.linker.link_button_label"),
            command: "linker",
            toolbar: "linker,10"
        });
        editor.ui.addButton("Unlink", {
            label: this.services.multiLanguageService.getString("ckeditor.plugins.linker.unlink_button_label"),
            command: "unlink",
            toolbar: "linker,20"
        });
    }

    if (editor.addMenuItems) {
        editor.addMenuItems({
            link: {
                label: this.services.multiLanguageService.getString("ckeditor.plugins.linker.link_button_label"),
                command: "linker",
                group: "linker",
                order: 1
            },
            unlink: {
                label: this.services.multiLanguageService.getString("ckeditor.plugins.linker.unlink_button_label"),
                command: "unlink",
                group: "linker",
                order: 5
            }
        });
    }

    editor.on(
        "selectionChange",
        function (evt) {
            this.setState(editor);
        }.bind(this)
    );

    editor.on(
        "contentDom",
        function (contentDom) {
            var editor = contentDom.editor;
            var editable = editor.editable();
            var documentElement = editor.document.getDocumentElement();
            var mouseUpTimeout;
            editable.attachListener(
                this.services.ckEditor.env.ie ? editable : documentElement,
                "mouseup",
                function (stuff) {
                    mouseUpTimeout = setTimeout(
                        function () {
                            this.setState(editor);
                        }.bind(this),
                        0
                    );
                }.bind(this)
            );

            editor.on("destroy", function () {
                clearTimeout(mouseUpTimeout);
            });

            editable.on(
                "keyup",
                function () {
                    this.setState(editor);
                }.bind(this)
            );
        }.bind(this)
    );
};

CkEditorLinkerPluginService.prototype.getButtonState = function (editor) {
    var selection = editor.getSelection();
    if (selection === null) {
        return this.services.ckEditor.TRISTATE_DISABLED;
    }

    var ranges = selection.getRanges();
    var selectionIsEmpty = selection.getType() === this.services.ckEditor.SELECTION_NONE || (ranges.length === 1 && ranges[0].collapsed);
    return selectionIsEmpty ? this.services.ckEditor.TRISTATE_DISABLED : this.services.ckEditor.TRISTATE_OFF;
};

CkEditorLinkerPluginService.prototype.setState = function (editor) {
    var state = this.getButtonState(editor);
    editor.getCommand("linker").setState(state);
    editor.getCommand("unlink").setState(state);
};

//Link Class
//=============================================================================================================================================
function LinkCommand(editor, services) {
    this.editor = editor;
    this.services = services;
    this.focusManager = null;
    this.activeRanges = [];
}

LinkCommand.prototype.exec = function () {
    this.saveSelectionState();

    var isolatedScope = this.services.rootScope.$new(true);
    var deferred = this.services.q.defer();
    isolatedScope.deferred = deferred;
    isolatedScope.title = this.services.multiLanguageService.getString("ckeditor.plugins.linker.create_link");

    this.services.modal.newModal({
        scope: isolatedScope,
        templateUrl: this.services.baseUrlsFactory.shared_component_base_url + "ckEditorPluginServices/Linker/templates/legacy-linker-modal-dialog.html"
    });

    deferred.promise.then(this.insertLinkIntoSelection.bind(this), this.restoreSelectionState.bind(this));

    isolatedScope.onClose = function () {
        isolatedScope.$destroy();
    };

    //Need to apply as this is done outside of Angular, need to trigger digest
    isolatedScope.$apply();
};

LinkCommand.prototype.insertLinkIntoSelection = function (data) {
    this.restoreSelectionState();
    var range = this.editor.getSelection().getRanges()[0];
    var style = new this.services.ckEditor.style({
        element: "a",
        attributes: {
            href: data.link,
            target: data.target
        }
    });
    style.type = this.services.ckEditor.STYLE_INLINE;

    var nestedLinks = range._find("a");
    for (var i = 0; i < nestedLinks.length; i++) {
        nestedLinks[i].remove(true);
    }

    // Remove any existing style.
    var removeStyle = new this.services.ckEditor.style({ element: "a", type: this.services.ckEditor.STYLE_INLINE, alwaysRemoveElement: 1 });
    this.editor.removeStyle(removeStyle);

    //Apply style
    style.apply(this.editor);
    this.editor.fire("saveSnapshot");
};

LinkCommand.prototype.saveSelectionState = function () {
    this.activeRanges = this.editor.getSelection().getRanges();
    this.focusManager = new this.services.ckEditor.focusManager(this.editor);
    this.focusManager.lock();
};

LinkCommand.prototype.restoreSelectionState = function () {
    this.focusManager.unlock();
    this.editor.focus();
    this.focusManager = null;

    var selection = this.editor.getSelection();
    selection.removeAllRanges();
    selection.selectRanges(this.activeRanges);
    this.activeRanges = [];
};

//Unlink Class
//=============================================================================================================================================
function UnlinkCommand(editor, services) {
    this.editor = editor;
    this.services = services;
}

UnlinkCommand.prototype.exec = function () {
    if (this.services.ckEditor.env.ie) {
        var range = this.editor.getSelection().getRanges()[0],
            link = (range.getPreviousEditableNode() && range.getPreviousEditableNode().getAscendant("a", true)) || (range.getNextEditableNode() && range.getNextEditableNode().getAscendant("a", true)),
            bookmark;

        if (range.collapsed && link) {
            bookmark = range.createBookmark();
            range.selectNodeContents(link);
            range.select();
        }
    }

    var style = new this.services.ckEditor.style({ element: "a", type: this.services.ckEditor.STYLE_INLINE, alwaysRemoveElement: 1 });
    this.editor.removeStyle(style);

    if (bookmark) {
        range.moveToBookmark(bookmark);
        range.select();
    }
};
