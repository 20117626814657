import { angularAMD } from "@pebblepad/amd";
import "../../utilities/baseUrlsFactory";
import "../../utilities/urlService";
import "../../builder/dataManager/dtoFactory";
import "../../extensions/arrayExtensions";
import "../../user/user.service";
import "../../assetEndpointService/assetEndpoint.service";

angularAMD.factory("collectionService", [
    "$http",
    "$routeParams",
    "baseUrlsFactory",
    "urlService",
    "dtoFactory",
    "User",
    "AssetEndpointService",
    function ($http, $routeParams, baseUrlsFactory, urlService, dtoFactory, User, AssetEndpointService) {
        return {
            getCollection: function (paramsList) {
                var params = {};

                paramsList.forEach(function (param) {
                    params[param.key] = param.value;
                });
                if ($routeParams.timeslice && params.timeslice === undefined) {
                    params.timeslice = $routeParams.timeslice;
                }

                return AssetEndpointService.getAssetAndCheckPermission(params);
            },

            getCriteriaFromCollectionWrapper: function (id) {
                var params = [{ key: "assetId", value: id }];
                if ($routeParams.timeslice) {
                    params.push({ key: "timeslice", value: $routeParams.timeslice });
                }

                if ($routeParams.submissionId) {
                    params.push({ key: "submissionId", value: $routeParams.submissionId });
                }
                return $http.get(urlService.addParameters(baseUrlsFactory.api_base_url + "Collection/GetCollectionCriteria", params));
            },

            saveCollection: function (data) {
                if (data) {
                    // possible user hasn't activited collection yet
                    if (data.Revision && isNaN(data.Revision)) {
                        data.Revision = -1;
                    }
                    return urlService.promisedPost(baseUrlsFactory.api_base_url + "assetDto/saveOrUpdate", { model: data });
                }

                return undefined;
            },

            addManualCriteriaToCollectionDto: function (assets, collectionDto) {
                var ids = assets.getValuesFromNestedKeys("Id");
                if (collectionDto) {
                    collectionDto.StaticCollectionAssetIds = ids;
                } else {
                    collectionDto = this.createNewManualCollectionDto(ids);
                }
                return collectionDto;
            },

            createNewManualCollectionDto: function (ids) {
                return dtoFactory.create({
                    MainType: "AssetCollection",
                    StaticCollection: true,
                    StaticCollectionAssetIds: ids || [],
                    CreatedBy: User.getId()
                });
            },

            getCollectionResults: function (assetId, searchObject) {
                const params = [{ key: "assetId", value: assetId }];
                const url = urlService.addParameters(baseUrlsFactory.api_base_url + "Collection/GetCollectionResults", params);
                return urlService.promisedPost(url, searchObject);
            }
        };
    }
]);
