import { angularAMD } from "@pebblepad/amd";
import "../builder/dataManager/dataManagerService";
import "../utilities/baseUrlsFactory";
import "../modal/services/modal";
import { REDIRECT_BLOCKING_PURPOSES } from "../constants/redirectBlockingPurposes.constants";

angularAMD.factory("preventRedirect", [
    "$rootScope",
    "$window",
    "baseUrlsFactory",
    "dataManagerService",
    "multiLanguageService",
    "modal",
    function ($rootScope, $window, baseUrlsFactory, dataManagerService, multiLanguageService, modal) {
        const customResolvers = [];

        var WindowNavigationHandler = (function () {
            function WindowNavigationHandler() {
                this.onWindowUnload = null;
                this.timeout = null;
            }

            WindowNavigationHandler.prototype.bind = function (msg, onPrompt, postPrompt) {
                this.onWindowUnload = function (e) {
                    if (postPrompt) {
                        this.timeout = setTimeout(postPrompt, 0); //Async, will not run until the main thread can resume (Browser modal blocks thread).
                    }

                    //If there is a onPrompt callback and it returns true, DONT return a value.
                    if (!onPrompt || !onPrompt()) {
                        e.returnValue = msg; //Support for odd versions of Chrome.
                        return msg;
                    }
                }.bind(this);

                window.addEventListener("beforeunload", this.onWindowUnload);
                if (postPrompt) {
                    //Don't care about removing 'unload' listener. If that fires Browser is removing everything about the current page.
                    window.addEventListener(
                        "unload ",
                        function () {
                            clearTimeout(this.timeout);
                        }.bind(this)
                    );
                }

                return this;
            };

            WindowNavigationHandler.prototype.unbind = function () {
                window.removeEventListener("beforeunload", this.onWindowUnload);
                return this;
            };

            return WindowNavigationHandler;
        })();

        return {
            asset_id: null,
            allow_redirect: null,
            doesExist: false,
            redirectNext: null,
            saveThenRedirect: false,
            checkForUnsavedChanges: true,
            unwatch: null,
            _windowNavigationHandler: null,

            setCheckForUnsavedChanges: function (shouldCheck) {
                this.checkForUnsavedChanges = shouldCheck;
            },

            addCustomResolver: (resolver) => {
                customResolvers.push(resolver);
            },

            removeCustomResolver: (resolver) => {
                const index = customResolvers.indexOf(resolver);
                if (index !== -1) {
                    customResolvers.splice(index, 1);
                }
            },

            getLogoutMessage: (can_log_out) => {
                for (const resolver of customResolvers) {
                    if (resolver.redirectionIsBlocked()) {
                        return resolver.getLogoutMessage();
                    }
                }

                if (can_log_out) {
                    return {
                        title: multiLanguageService.getString("logout.title"),
                        message: multiLanguageService.getString("logout.message"),
                        saveAndContinueBtn: multiLanguageService.getString("buttons.logout"),
                        continueBtn: ""
                    };
                } else {
                    return {
                        title: multiLanguageService.getString("logout.title_no_save"),
                        message: multiLanguageService.getString("logout.message_no_save"),
                        saveAndContinueBtn: multiLanguageService.getString("buttons.save_and_logout"),
                        continueBtn: multiLanguageService.getString("buttons.logout_without_saving")
                    };
                }
            },

            canGoNextPage: function (next, current, saveEvt) {
                var regex = {
                    find_viewer: /(\/viewer)/i,
                    find_webfolio: /(\/webfolio)/i,
                    find_workbook: /(\/workbook)/i,
                    find_template: /(\/template)/i,
                    find_id: new RegExp("(" + this.asset_id + ")", "g")
                };

                var goingToViewer = regex.find_viewer.test(next);
                var goingFromViewer = regex.find_viewer.test(current);
                var next_is_valid = goingToViewer || regex.find_webfolio.test(next) || regex.find_workbook.test(next);
                var current_is_template = regex.find_template.test(current);
                var is_same_page = regex.find_id.test(next);

                if (this.isSaved(saveEvt) === true) {
                    return true;
                } else {
                    if (!this.allow_redirect) {
                        if (is_same_page && goingToViewer) {
                            if (goingFromViewer) {
                                return true;
                            } else {
                                this.saveThenRedirect = true;
                                return false;
                            }
                        }
                        return next_is_valid && is_same_page && (!current_is_template || (current_is_template && is_same_page));
                    } else {
                        return true;
                    }
                }
            },

            isSaved: function (saveEvt) {
                if (saveEvt === "saveFile") {
                    return $rootScope.checkIfFileIsSaved(this.asset_id);
                } else if (saveEvt === "saveWorkbook") {
                    if (dataManagerService.workbookManagerObject) {
                        return dataManagerService.workbookManagerObject.checkForIsSavedAnywhereInWorkbook(this.asset_id);
                    } else {
                        return true;
                    }
                } else {
                    return dataManagerService.checkIfSaved(this.asset_id);
                }
            },

            checkIfCanLogOut: function () {
                if (this.asset_id && this.isSaved() === false) {
                    return false;
                }

                return customResolvers.every((resolver) => !resolver.redirectionIsBlocked());
            },

            blockingInProgress: () => {
                for (const resolver of customResolvers) {
                    if (resolver.redirectionIsBlocked()) {
                        return true;
                    }
                }

                if (this.checkForUnsavedChanges) {
                    return !this.isSaved();
                }

                return false;
            },

            /**
             * @return {Array<REDIRECT_BLOCKING_PURPOSES>}
             */
            whatIsBlocking: () => {
                const blockingPurposes = customResolvers.filter((r) => r.redirectionIsBlocked()).map((r) => r.purpose);

                if (this.checkForUnsavedChanges && !this.isSaved()) {
                    blockingPurposes.push(REDIRECT_BLOCKING_PURPOSES.ASSET);
                }

                return blockingPurposes;
            },

            allowRedirect: function (boolean) {
                this.allow_redirect = boolean;
            },

            continue: function (opts) {
                opts.$off();
                this._windowNavigationHandler.unbind();
                dataManagerService.cleanupStorageOfAssetId(this.asset_id);

                this.asset_id = null;

                for (const resolver of customResolvers) {
                    resolver.cleanUp();
                }

                $window.location.href = this.redirectNext;
            },

            saveAndContinue: function (opts) {
                this._windowNavigationHandler.unbind();
                $rootScope.$broadcast(opts.saveEvt, { afterSaveAction: { type: "urlChange", url: this.redirectNext } });
            },

            stay: function () {
                $rootScope.appIsLoaded = true; // some redirects start the spinner off... on cancel show the page
            },

            showModal: function (opts) {
                const self = this;

                if (self.doesExist) {
                    return;
                }

                self.doesExist = true;
                let isolate_scope = null;

                if (customResolvers.length > 0) {
                    for (const resolver of customResolvers) {
                        if (resolver.redirectionIsBlocked()) {
                            const modalContent = resolver.getLocationChangeMessage();
                            isolate_scope = $rootScope.$new();
                            isolate_scope.title = modalContent.title;
                            isolate_scope.message = modalContent.message;
                            isolate_scope.continueBtn = modalContent.continueBtn;
                            isolate_scope.cancelRedirectBtn = modalContent.cancelRedirectBtn;
                            break;
                        }
                    }
                }

                if (isolate_scope === null) {
                    isolate_scope = $rootScope.$new();
                    isolate_scope.title = multiLanguageService.getString("labels.pop_ups.exit_without_saving");
                    isolate_scope.message = multiLanguageService.getString("labels.pop_ups.exit_without_saving_message");
                    isolate_scope.continueBtn = multiLanguageService.getString("buttons.exit_without_saving");
                    isolate_scope.saveAndContinueBtn = multiLanguageService.getString("buttons.save_and_exit");

                    isolate_scope.saveAndContinue = () => {
                        self.saveAndContinue(opts);
                    };
                }

                isolate_scope.continue = function () {
                    self.continue(opts);
                }.bind(self);

                isolate_scope.stay = () => {
                    self.stay();
                };

                isolate_scope.onClose = () => {
                    self.doesExist = false;
                    isolate_scope.$destroy();
                };

                modal.newModal({
                    scope: isolate_scope,
                    templateUrl: this.blockingInProgress()
                        ? `${baseUrlsFactory.shared_component_base_url}preventRedirect/templates/warning-modal-alt.html`
                        : `${baseUrlsFactory.shared_component_base_url}preventRedirect/templates/warning-modal.html`
                });
            },

            watchLocationChange: function (asset_id, saveEvt, canSave = true) {
                this.allow_redirect = false;
                this.saveThenRedirect = false;

                if (this.asset_id) {
                    return this.unwatch;
                }

                this.asset_id = asset_id;

                this._windowNavigationHandler = new WindowNavigationHandler().bind(multiLanguageService.getString("exit_without_saving.browser_message"), () => {
                    return this.allow_redirect === true || ((!canSave || this.isSaved(saveEvt)) && !this.blockingInProgress());
                });

                const $off = $rootScope.$on("$locationChangeStart", (event, next, current) => {
                    if (this.allow_redirect) {
                        this.unwatch();
                        return;
                    }

                    const resolverResults = [];
                    if (this.checkForUnsavedChanges) {
                        resolverResults.push({ allowRedirect: this.canGoNextPage(next, current, saveEvt), removeWatcher: true });
                    }

                    for (const resolver of customResolvers) {
                        resolverResults.push(resolver.onLocationChange(next, current));
                    }

                    if (!resolverResults.every((x) => x.allowRedirect)) {
                        event.preventDefault();
                        this.redirectNext = next;
                        $rootScope.appIsLoaded = true;

                        if (this.saveThenRedirect) {
                            this.saveAndContinue({ saveEvt: saveEvt });
                        } else {
                            this.showModal({ $off: $off, saveEvt: saveEvt });
                        }
                        return;
                    }

                    if (resolverResults.every((x) => x.removeWatcher)) {
                        this.unwatch();
                    }
                });

                this.unwatch = () => {
                    this._windowNavigationHandler.unbind();
                    $off();
                    allowRedirectOff();
                    this.asset_id = null;
                };

                var allowRedirectOff = $rootScope.$on("allowRedirect", () => {
                    this.allow_redirect = true;
                    $off();
                    allowRedirectOff();
                });

                return this.unwatch;
            },

            reset: function () {
                if (this._windowNavigationHandler !== null) {
                    this._windowNavigationHandler.unbind();
                }

                if (this.unwatch !== null) {
                    this.unwatch();
                }

                this.asset_id = null;
                customResolvers.forEach((r) => r.cleanUp());
            }
        };
    }
]);
