import { createRef, PureComponent, ReactNode, RefObject } from "react";
import { IParseHtmlProps } from "./interfaces/IParseHtmlProps";
import { ParseHtmlSanitiser } from "./ParseHtmlSanitiser";
import { IParseHtmlSanitiser } from "./interfaces/IParseHtmlSanitiser";

export class ParseHtml extends PureComponent<IParseHtmlProps> {
    private readonly _parseHtmlSanitiser: IParseHtmlSanitiser = new ParseHtmlSanitiser(this.props.debounceTime ?? 0);
    private readonly _elementRef: RefObject<HTMLDivElement> = createRef<HTMLDivElement>();

    public render(): ReactNode {
        return <div className={this.props.className} ref={this._elementRef} data-hook={this.props.dataHook} />;
    }

    public componentDidMount(): void {
        this._parseHtmlSanitiser.sanitise(this.props.htmlString, this._elementRef, true);
    }

    public componentDidUpdate(): void {
        this._parseHtmlSanitiser.sanitise(this.props.htmlString, this._elementRef, false);
    }

    public componentWillUnmount(): void {
        this._parseHtmlSanitiser.destroy();
    }
}
