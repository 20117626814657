import { angularAMD } from "@pebblepad/amd";
import "./assetLockingHelper.service";
import "../directives/assetLockedWarning/assetLockedWarning.directive";
import "../../utilities/urlService";
import "../../builder/dataManager/helperService";
import "../../multiLanguageService/multiLanguageService";
import "../../recoveryLogger/recoveryLogger";

AssetLockingNotificationService.$inject = ["$q", "$location", "baseUrlsFactory", "multiLanguageService", "AssetLockingHelper", "modal", "urlService", "helperService", "recoveryLogger"];
function AssetLockingNotificationService($q, $location, baseUrlsFactory, multiLanguageService, assetLockingHelper, modal, urlService, helperService, recoveryLogger) {
    this._$q = $q;
    this._$location = $location;
    this._baseUrlsFactory = baseUrlsFactory;
    this._multiLanguageService = multiLanguageService;
    this._assetLockingHelper = assetLockingHelper;
    this._modal = modal;
    this._urlService = urlService;
    this._helperService = helperService;
    this._recoveryLogger = recoveryLogger;
}

/**
 * Setups lock and displays error modal on failure.
 * @param {string} assetId
 * @param {string} mainType
 * @param {string} url
 * @returns {Promise} - Resolved on lock success, Rejected on lock failure.
 */
AssetLockingNotificationService.prototype.setupLockWithModal = function (assetId, mainType, url) {
    this._recoveryLogger.start(mainType, "Pre-Asset builder load");
    return this._assetLockingHelper.setupLock(assetId, mainType, url).then(
        function (resolvedData) {
            return resolvedData;
        },
        function (lockedInfo) {
            if (lockedInfo) {
                if (lockedInfo.LockGranted && lockedInfo.IsPreexistingLock) {
                    //Asset has been opened by the same user already. Redirect to viewer to allow message / notification prompts to handle the issue.
                    var redirect = this._urlService.createUrl(assetId, "", true);
                    this._$location.path(redirect).replace();
                    return this._$q.reject();
                } else if (lockedInfo.RequestedLockAssetId) {
                    var modalTemplate = "assetLocking/directives/assetLockedWarning/templates/asset-locked-warning-modal.html";
                    return this._launchLockedModal(assetId, mainType, lockedInfo, modalTemplate).then(
                        function () {
                            if (!this._urlService.checkIfOnBuilderForAsset(assetId, mainType)) {
                                this._$location.path(this._urlService.createUrl(assetId, mainType));
                            }
                        }.bind(this)
                    );
                }
            }
            return this._$q.reject();
        }.bind(this)
    );
};

/**
 * Setups lock and displays error modal on failure.
 * @param {string} assetId
 * @param {string} mainType
 * @param {string} url
 * @returns {Promise} - Resolved on lock success, Rejected on lock failure.
 */
AssetLockingNotificationService.prototype.setupLockWithModalForAdding = function (assetId, mainType, url) {
    this._recoveryLogger.step("lock", `Added lock for ${mainType} being added to another asset`);
    return this._assetLockingHelper.setupLock(assetId, mainType, url).then(
        function (resolvedData) {
            return resolvedData;
        },
        function (lockedInfo) {
            if (lockedInfo && lockedInfo.RequestedLockAssetId) {
                var modalTemplate = "assetLocking/directives/assetLockedWarning/templates/asset-locked-on-add-warning-modal.html";
                return this._launchLockedModal(assetId, mainType, lockedInfo, modalTemplate);
            }
            return this._$q.reject();
        }.bind(this)
    );
};

/**
 * @param {string} assetId
 * @param {string} mainType
 * @param {Object} lockedInfo - Requires LockedBy and LockOriginatedOnAsset properties
 * @param {string} templateUrl - the location of the template for the modal
 * @returns {Promise}
 */
AssetLockingNotificationService.prototype._launchLockedModal = function (assetId, mainType, lockedInfo, templateUrl) {
    var isAsset = !this._helperService.isResourceBuilder(mainType);
    var deferred = this._$q.defer();
    var i18nTitleKey = isAsset ? "locking.locked_asset.heading" : "locking.locked_resource.heading";

    var modalProps = {
        assetId: assetId,
        isAsset: isAsset,
        lockedAsset: lockedInfo.LockOriginatedOnAsset,
        lockedByUser: lockedInfo.LockedBy,
        mainType: mainType,
        multiLanguageService: this._multiLanguageService,
        onRedirect: deferred.reject,
        onSuccess: deferred.resolve.bind(null, { id: assetId, mainType: mainType }),
        title: this._multiLanguageService.getString(i18nTitleKey)
    };

    this._modal.launch(this._baseUrlsFactory.shared_component_base_url + templateUrl, modalProps, {
        focusStyle: false,
        disableClose: true
    });

    return deferred.promise;
};

angularAMD.service("assetLockingNotificationService", AssetLockingNotificationService);

export { AssetLockingNotificationService as assetLockingNotificationService };
