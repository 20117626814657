import { NgComponent } from "angular/ngComponent";
import { angularAMD } from "@pebblepad/amd";
import { noCkEditorLinkUnlinkPlugin as editorConfig } from "../inlineTextEditor/editorConfigs/noCkEditorLinkUnlinkPlugin";
import "../ckEditorModule/editors/compactFloatingMultiLineEditorWithFallback.component";
import template from "./titled-text-editor.html";
import { createGuid } from "@pjs/utilities";

class TitledTextEditor extends NgComponent {
    constructor() {
        super();

        this.richTextAutoFocus = this.focusOnLoad !== undefined ? this.focusOnLoad : false;
        this.richTextConfig = {
            ...editorConfig,
            extraPlugins: "sharedspace,linker",
            startupFocus: this.richTextAutoFocus
        };
        this.richTextEditorDatahook = "titled-text-editor-textbox";
        this.onChange = this._onChange.bind(this);
        this.id = createGuid();
        this.labelledBy = `titled-textarea-title-${this.id}`;
    }

    _onChange(content) {
        this.onUpdate({ content: content });
    }
}

export const titledTextEditor = {
    template: template,
    controller: TitledTextEditor,
    bindings: {
        heading: "<",
        entry: "<",
        onUpdate: "&",
        isPersistentEditor: "<",
        subTitle: "<?",
        focusOnLoad: "<?"
    }
};

angularAMD.component("titledTextEditor", titledTextEditor);
