import { angularAMD } from "@pebblepad/amd";
import { ASSET_CONSTANTS } from "../constants/asset.constants";
import "./baseUrlsFactory";
import "../assetStore/services/assetStoreService";
import "../assetLocking/services/assetLockingHelper.service";
import "../assetLocking/services/assetLockingNotification.service";
import "../collaborationLoader/collaborationLoader.service";
import "./urlService";
import "../modal/services/modal";
import "../multiLanguageService/multiLanguageService";
import "../assetStore/services/useResourceService";
import "../user/user.service";

angularAMD.service("AssetBuilderHelper", AssetBuilderHelper);
AssetBuilderHelper.$inject = [
    "$q",
    "$location",
    "$rootScope",
    "baseUrlsFactory",
    "assetStoreService",
    "AssetLockingHelper",
    "assetLockingNotificationService",
    "CollaborationLoaderService",
    "urlService",
    "modal",
    "multiLanguageService",
    "useResourceService",
    "User"
];

function AssetBuilderHelper(
    $q,
    $location,
    $rootScope,
    baseUrlsFactory,
    AssetStoreService,
    assetLockingHelper,
    assetLockingNotificationService,
    CollaborationLoaderService,
    UrlService,
    modal,
    multiLanguageService,
    useResourceService,
    User
) {
    //Public API
    //=============================================================================================================
    this.beforeLoad = beforeLoad;
    this.runActions = runActions;
    this.checkAssetStatus = checkAssetStatus;

    //Private methods
    //=============================================================================================================
    /**
     * @param {string} assetId
     * @param {string} mainType
     * @param {string} url - Provide empty string if no url.
     * @param {Array=} callbacks - Optional array of callbacks.
     * @returns {Promise}
     */
    function beforeLoad(assetId, mainType, url, callbacks) {
        if (callbacks === void 0) {
            callbacks = [checkAssetStatus, assetLockingHelper.cancelAllActiveLocks, assetLockingNotificationService.setupLockWithModal.bind(assetLockingNotificationService)];
        }

        return runActions(callbacks, assetId, mainType, url);
    }

    /**
     * @param {Array} actions - Array of callbacks
     * @param {string} assetId
     * @param {string} mainType
     * @param {string} url
     * @returns {Promise}
     */
    function runActions(actions, assetId, mainType, url) {
        var deferred = $q.defer(),
            lastPromise = deferred.promise;

        for (var i = 0, l = actions.length; i < l; i++) {
            /*Use function wrapper to ensure that whatever resolved value the promise provides, the arguments for the callback are the same.
             Using .bind(null, assetId, mainType, url) would mean the resolved value gets appended to the arguments.
             e.g. [assetId, mainType, url, _promiseResolvedValue_] rather than [assetId, mainType, url]. */

            lastPromise = lastPromise.then(
                function (callback) {
                    return callback(assetId, mainType, url);
                }.bind(null, actions[i])
            );
        }

        deferred.resolve();
        return lastPromise;
    }

    /**
     * @param {string} assetId
     * @param {string} mainType
     * @param {string} url
     * @returns {Promise}
     */
    function checkAssetStatus(assetId, mainType, url) {
        return AssetStoreService.getEditStatusForAsset(assetId).then(function (status) {
            switch (status) {
                case ASSET_CONSTANTS.PERMISSIONS.VIEW:
                    if (/FormResponse|WorkBookResponse/i.test(mainType)) {
                        return useResourceService.checkUse({ AssetId: assetId }).then(function (redirected) {
                            return !redirected ? rejectOnError() : $q.reject();
                        }, rejectOnError);
                    } else if (!UrlService.checkIfAssetFromPath($location.url())) {
                        rejectOnError();
                    } else if (UrlService.checkIfOnBuilderForAsset(assetId, mainType)) {
                        //If on builder for asset and you only have view permission, redirect to viewer.
                        $location.path(UrlService.createUrl(assetId, null, true)).replace();
                        return $q.reject();
                    } else {
                        return true;
                    }
                case ASSET_CONSTANTS.PERMISSIONS.COLLABORATE:
                    return CollaborationLoaderService.resolveCollaboration(status, assetId, mainType, url);
                case ASSET_CONSTANTS.PERMISSIONS.WRITE:
                    return true;
                default:
                    return User.getDto() !== null ? rejectOnError() : $q.reject({ needsAuthentication: true });
            }
        }, rejectOnError);
    }

    function rejectOnError(response) {
        var rejection = {
            response: response,
            modal: null
        };

        //Display modal if we get an odd server response which doesn't reply as not authorised (401 redirects to login).
        if (!response || response.status !== 401) {
            var serverError = response && response.status !== 200;
            return (rejection.modal = launchErrorModal(serverError).then(function () {
                return $q.reject(rejection);
            }));
        } else {
            return $q.reject(rejection);
        }
    }

    /**
     * @returns {Object} - Modal
     */
    function launchErrorModal(serverError) {
        var modalScope = $rootScope.$new(true);

        var deferred = $q.defer();

        modalScope.title = multiLanguageService.getString("builder.loading_error.generic_title");
        modalScope.message = serverError
            ? multiLanguageService.getString("builder.loading_error.failed_to_load_item_server_error")
            : multiLanguageService.getString("builder.loading_error.failed_to_load_item");
        modalScope.button_label = multiLanguageService.getString("buttons.ok");

        modalScope.onClose = function () {
            $location.url("/");
            modalScope.$destroy();
            deferred.resolve();
        };

        modal.newModal({
            scope: modalScope,
            templateUrl: baseUrlsFactory.shared_component_base_url + "modalDialogComponent/templates/generic-pop-up.html",
            disableClose: true
        });

        return deferred.promise;
    }
}
