import { angularAMD } from "@pebblepad/amd";
import "../pebbleCkEditor";
import "../../inlineTextEditor/services/inlineCkEditorHelper.service";
import "../../loadingSpinner/loadingSpinner";
import "../../utilities/debounce.service";
import "../../autoFocus/autoFocus.directive";
import template from "./rich-text-editor.html";

export const richTextEditorComponentDefinition = {
    bindings: {
        onChange: "<",
        content: "<?",
        config: "<?",
        editorElementId: "<?",
        className: "<?",
        datahook: "<?",
        labelledBy: "<?"
    },
    template: template,
    controller: RichTextEditorComponent,
    name: "richTextEditorComponent"
};

RichTextEditorComponent.$inject = ["$element", "$timeout", "CkEditorService", "InlineCkEditorHelper", "debounce", "CKEDITOR_EVENTS"];

function RichTextEditorComponent($element, $timeout, ckEditorService, inlineCkEditorHelper, debounce, CKEDITOR_EVENTS) {
    this.ready = false;
    this._$element = $element;
    this._$timeout = $timeout;
    this._ckEditorService = ckEditorService;
    this._inlineCkEditorHelper = inlineCkEditorHelper;
    this.CKEDITOR_EVENTS = CKEDITOR_EVENTS;
    this._pasteHandler = this._inlineCkEditorHelper.createMultiLinePasteHandler();
    this.autoFocus = false;
    this._debounceUpdate = debounce.fn(this._onChange, this, 100);
    this._editor = null;
}

RichTextEditorComponent.prototype.$postLink = function () {
    var ckEditorToolbarElement = this._$element[0].querySelector(".rich-text-editor-component--toolbar");
    var ckEditorElement = this._$element[0].querySelector(".rich-text-editor-component--body");
    var jointConfig = Object.assign(this._inlineCkEditorHelper.createEditorConfig(ckEditorToolbarElement, false), this._defaultConfiguration, this.config);
    this._editor = this._ckEditorService.inline(ckEditorElement, jointConfig);
    this._editor.on(
        this.CKEDITOR_EVENTS.READY,
        function (event) {
            var debounceUpdate = this._debounceUpdate.run.bind(this._debounceUpdate);
            this._editor.on(this.CKEDITOR_EVENTS.CHANGE, debounceUpdate);
            this._editor.on(this.CKEDITOR_EVENTS.BLUR, debounceUpdate);
            this._editor.on(this.CKEDITOR_EVENTS.PASTE, this._onPaste.bind(this));

            this._editor.setReadOnly(false);
            this._editor.setData(this.content);
            this._$timeout(
                function () {
                    this.ready = true;
                    this.autoFocus = jointConfig.startupFocus;
                }.bind(this)
            );
        }.bind(this)
    );
};

RichTextEditorComponent.prototype.$onChanges = function (changes) {
    if (this._editor !== null && this._editor.getData().replace(/\r\n|\n/g, "") !== this.content) {
        this._editor.setData(this.content);
    }
};

RichTextEditorComponent.prototype._defaultConfiguration = {};

RichTextEditorComponent.prototype._onPaste = function (event) {
    this._pasteHandler(event);
};

RichTextEditorComponent.prototype._onChange = function (event) {
    this._updateContent(this._editor.getData().replace(/\r\n|\n/g, ""));
};

RichTextEditorComponent.prototype._updateContent = function (content) {
    this.onChange(content);
};

RichTextEditorComponent.prototype.$onDestroy = function () {
    this._editor.destroy();
    this._debounceUpdate.cancel();
};

angularAMD.component("richTextEditorComponent", richTextEditorComponentDefinition);
export { RichTextEditorComponent };
