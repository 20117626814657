import { angularAMD } from "@pebblepad/amd";
import { CsrfCookie } from "@pjs/http";
import "../../utilities/baseUrlsFactory";
import "../services/fileUploadService";
import "../../extensions/arrayExtensions";
import "../../utilities/bytesToSize";
import "../../inlineTextEditor/directives/inlineTextEditor";
import "../../modal/services/modal";
import "../../utilities/subscriptionServiceProvider";
import "../../overlay/services/overlayFactory";
import "../../userAgent/userAgent.service";
import "../../utilities/urlService";
import "../../utilities/helpers";
import "../../clickAndDisable/directives/clickAndDisable.directive";
import "../../builder/textSectionInput/textSectionInput";
import "../../react2angular/dismissableHtmlNotice";
import { INPUT_LIMIT_CONSTANTS } from "../../constants/inputLimits.constants";
import template from "../templates/file-upload.html";
import { warningNotice } from "@pjs/core-ui";

/**
 * usage: <file-upload change-existing give-ticket-to="ticketId"></file-upload>
 */
angularAMD.directive("fileUpload", [
    "$q",
    "$http",
    "$sce",
    "$compile",
    "$timeout",
    "$rootScope",
    "baseUrlsFactory",
    "fileUploadService",
    "modal",
    "subscriptionServiceProvider",
    "overlayFactory",
    "multiLanguageService",
    "UserAgentService",
    "urlService",
    "helpers",
    function (
        $q,
        $http,
        $sce,
        $compile,
        $timeout,
        $rootScope,
        baseUrlsFactory,
        fileUploadService,
        modal,
        subscriptionServiceProvider,
        overlayFactory,
        multiLanguageService,
        UserAgentService,
        urlService,
        helpers
    ) {
        return {
            template: template,
            restrict: "E",
            scope: {
                changeExisting: "@",
                showTagger: "@",
                showDescription: "@",
                panelInfo: "="
            },
            controller: [
                "$scope",
                "$element",
                "$attrs",
                function ($scope, $element, $attrs) {
                    $scope.multiLanguageService = multiLanguageService;
                    $scope.isChangingExisting = $attrs.changeExisting !== undefined;
                    $scope.isPanelMode = $scope.panelInfo !== undefined;
                    $scope.uploadUrl = fileUploadService.getUploadUrl();
                    $scope.imageDragFile = $sce.getTrustedResourceUrl(baseUrlsFactory.shared_component_base_url + "images/file/fileDrag.svg");
                    $scope.imageDropFile = $sce.getTrustedResourceUrl(baseUrlsFactory.shared_component_base_url + "images/file/file.svg");
                    $scope.imageSelectFile = $sce.getTrustedResourceUrl(baseUrlsFactory.shared_component_base_url + "images/file/selectFile.svg");
                    $scope.imageWarning = $sce.getTrustedResourceUrl(baseUrlsFactory.shared_component_base_url + "images/general/warning48.svg");
                    $scope.numberOfFiles = null;
                    $scope.tags = [];
                    $scope.onCloseModalActive = false;
                    $scope.errorUploadStatuses = ["overSingle", "overQuota"];
                    $scope.INPUT_LIMIT_CONSTANTS = INPUT_LIMIT_CONSTANTS;
                    $scope.noExtensionError = null;

                    $scope.getHeaders = function (flowFile, flowChunk) {
                        var headers = {};
                        var csrfToken = CsrfCookie.get();
                        if (csrfToken !== "") {
                            headers["X-CSRF-Token"] = csrfToken;
                        }
                        return headers;
                    };

                    $scope.flowInit = {
                        target: $scope.uploadUrl,
                        testChunks: false,
                        withCredentials: true,
                        simultaneousUploads: 1,
                        chunkSize: 3 * 1024 * 1024,
                        forceChunkSize: true,
                        singleFile: $scope.isChangingExisting,
                        headers: $scope.getHeaders
                    };

                    $scope.addTagToFileDetails = function (e, tag) {
                        $scope.tags = e.targetScope.tags;
                    };

                    $scope.removeTagFromFileDetails = function (e, tag) {
                        $scope.tags = e.targetScope.tags;
                    };

                    $scope.onSpaMenuClose = function () {
                        if ($scope.numberOfFiles && $scope.numberOfFiles > 0 && !$scope.onCloseModalActive) {
                            $scope.onCloseModalActive = true;

                            var isolatedScope = $scope.$new(true);
                            var saving = false;
                            isolatedScope.modal_title = $scope.multiLanguageService.getString("labels.pop_ups.close_without_saving_file");
                            isolatedScope.modal_message = $scope.multiLanguageService.getString("file_uploads.h3.unfinished_upload");
                            isolatedScope.saveAndContinue = function () {
                                saving = true;
                                $scope
                                    .saveFile()
                                    .then(function () {
                                        $rootScope.$broadcast("forceCloseRightHandSidePanel");
                                        subscriptionServiceProvider.unsubscribe("spaMenu", $scope.subscriptionId);
                                    })
                                    // tslint:disable-next-line:no-empty
                                    .catch(() => {});
                            };
                            isolatedScope.continue = function () {
                                $rootScope.$broadcast("forceCloseRightHandSidePanel");
                            };

                            isolatedScope.onClose = function () {
                                if (!saving) {
                                    subscriptionServiceProvider.unsubscribe("spaMenu", $scope.subscriptionId);
                                }

                                $scope.onCloseModalActive = false;
                                isolatedScope.$destroy();
                            };

                            modal.newModal({
                                scope: isolatedScope,
                                templateUrl: baseUrlsFactory.shared_component_base_url + "fileUpload/templates/file-in-progress.html"
                            });

                            return $q.reject("File has not been saved");
                        }
                    };

                    // Default show attributes to true if not set
                    $scope.show_tagger = $attrs.showTagger === undefined || $attrs.showTagger === "true";
                    $scope.show_description = $attrs.showDescription === undefined || $attrs.showDescription === "true";

                    if ($scope.isPanelMode) {
                        $scope.subscriptionId = "fileUploadInProgress";
                        subscriptionServiceProvider.subscribe("spaMenu", $scope.subscriptionId, $scope.onSpaMenuClose);
                    }
                }
            ],
            link: function (scope, element, attrs) {
                scope.type = "";
                scope.hasFile = false;

                scope.getDescribedByText = function () {
                    scope.describedByText = scope.hasFile
                        ? scope.multiLanguageService.getString("file_uploads.accessibility.confirming_uploads")
                        : scope.multiLanguageService.getString("file_uploads.accessibility.selecting_uploads");
                };

                scope.getDescribedByText();

                scope.helpLink = "#/learningCentre?type=uploading";
                scope.tooltipMessage = scope.multiLanguageService.getString("tips.tags.adding_tags.content");
                //Container for tooltip
                scope.boundary_el = angular.element(element[0].getElementsByClassName("file-upload")[0]);

                var template = "withUploadingText",
                    icon = "fileUpload/images/save-tick.svg",
                    text = scope.multiLanguageService.getString("overlays.upload_successful");
                var overlay = overlayFactory.createSpinnerOverlay($rootScope, template, icon, text);

                if (UserAgentService.touch) {
                    scope.isTouchDevice = true;
                }

                var fileQuotasPromise;

                fileQuotasPromise = fileUploadService.getFileQuotas();

                fileQuotasPromise
                    .then(function (response) {
                        //scope.startingFileQuota is a way of tracking if the quota is already exceeded at the start of uploading a file.
                        //This could happen if a persons quota is reduced by admin after they have already uploaded a load of item.

                        scope.startingFileQuota = response.data.quotaRemaining;
                        scope.fileQuotas = response.data;

                        if (scope.startingFileQuota < 0) {
                            scope.overStartQuota = -1 * scope.startingFileQuota;
                        }
                    })
                    .finally(() => {
                        scope.isReady = true;
                        scope.hasFile = false;
                    });

                var saveUploadPromise;

                // cached elements
                var file_drop_el = angular.element(element[0].querySelector("#file-drop-area"));
                var file_click_el = angular.element(element[0].querySelector("#file-click-area"));
                var upload_title_el = document.getElementById("uploadTitleField");

                scope.fileProcessed = false;
                scope.titleLength = 255;
                scope.saveObjects = [];
                scope.fileObject = { title: "", description: "", altText: null };
                scope.saveButtonText = scope.multiLanguageService.getString("buttons.confirm_upload");

                scope.updateAltTextValue = (value) => {
                    scope.fileObject.altText = value;
                };

                scope.$on("tagAdded", scope.addTagToFileDetails);
                scope.$on("tagRemoved", scope.removeTagFromFileDetails);

                scope.resetHasFile = () => (scope.hasFile = false);

                scope.fileAdded = function (flow, file) {
                    if (!file.getExtension() && !scope.hasFile) {
                        scope.noExtensionError = {
                            type: warningNotice,
                            label: scope.multiLanguageService.getString("accessibility.notice.labels.warning", { type: scope.multiLanguageService.getString("accessibility.notice.type.file_upload") }),
                            content: scope.multiLanguageService.getString("file_uploads.extension_error"),
                            onDismiss: () => {
                                scope.noExtensionError = null;
                                scope.$applyAsync();
                            }
                        };

                        return false;
                    }

                    scope.noExtensionError = null;
                    if (flow.files.length === 0) {
                        scope.fileObject.title = file.name;
                    }

                    for (var i = 0, flowFile; i < flow.files.length; i++) {
                        flowFile = flow.files[i];
                        //If the File has the same id and lastModified date, File is the same, don't add again.
                        if (flowFile.flowIdentifier === file.uniqueIdentifier && file.file.lastModified && flowFile.file.lastModified === file.file.lastModified) {
                            return false;
                        }
                    }

                    file.flowIdentifier = file.uniqueIdentifier;
                    file.uniqueIdentifier = newGuid();
                    file.added = Date.now();

                    setupThumbnail(file);
                    scope.hasFile = true;
                    scope.getDescribedByText();
                };

                // Work out whether to show a thumbnail preview or not
                var setupThumbnail = function (file) {
                    var type = file.file.type;
                    $timeout(function () {
                        if (type.indexOf("video") > -1) {
                            var video_player = element[0].querySelector("video");
                            var canPlay = video_player.canPlayType(type);
                            scope.type = "Video";
                            if (canPlay !== "") {
                                file.thumbnailUrl = $sce.trustAsResourceUrl(URL.createObjectURL(file.file));
                            }
                        } else if (type.indexOf("audio") > -1) {
                            var audio_player = element[0].querySelector("audio");
                            var canPlay = audio_player.canPlayType(type);
                            scope.type = "Audio";
                            if (canPlay !== "") {
                                file.thumbnailUrl = $sce.trustAsResourceUrl(URL.createObjectURL(file.file));
                            }
                        } else if (type.indexOf("image") > -1) {
                            scope.type = "Image";

                            // only load thumbnail if less than 10Mb and of browser accepted type
                            var maxMb = 10;
                            if (
                                file.file.size < maxMb * 1024 * 1024 &&
                                (type.indexOf("bmp") > -1 || type.indexOf("gif") > -1 || type.indexOf("jpeg") > -1 || type.indexOf("jpg") > -1 || type.indexOf("png") > -1)
                            ) {
                                file.thumbnailUrl = "flow-img";
                            }
                        }
                    }, 0);
                };

                scope.onFileSubmitted = function (fileArray, flow) {
                    for (var i = 0; i < fileArray.length; i++) {
                        scope.checkQuotas(flow, fileArray[i]);
                    }

                    flow.upload();
                    if (scope.allFilesProcessed(flow)) {
                        scope.onFilesCompleted(flow);
                    }
                };

                scope.checkQuotas = function (flow, file) {
                    file.uploadStatus = "";

                    var quotaRemaining = scope.fileQuotas.quotaRemaining;

                    if (file.size > scope.fileQuotas.maxSingleFileSize) {
                        file.pause();
                        file.uploadStatus = "overSingle";
                    }

                    //putting this here still allows the uploadStatus to be set to overSingle, if the new file is over the single file size limit.
                    if (scope.fileQuotas.setQuota === 0 && file.uploadStatus !== "overSingle") {
                        //This catches if there is no quota and makes sure the the overQuota message is never shown in these instances.
                        return true;
                    }

                    if (quotaRemaining - file.size > 0) {
                        if (file.uploadStatus !== "overSingle") {
                            quotaRemaining -= file.size;
                            scope.fileQuotas.quotaRemaining = quotaRemaining;
                            return true;
                        }
                        // tslint:disable-next-line:triple-equals
                    } else if (quotaRemaining != 0) {
                        if (file.uploadStatus !== "overSingle") {
                            file.uploadStatus = "overQuota";
                        }
                        file.pause();
                    }
                    // tslint:disable-next-line:triple-equals
                    if (quotaRemaining != 0) {
                        scope.updateQuotaRemaining(flow);
                    }
                };

                scope.updateQuotaRemaining = function (flow) {
                    //This makes sure that the quotaRemaining isn't set to a negative number in the case of no quota being set.
                    //This results in the inability to restart uploads if they are paused or re-upload a previously canceled upload.
                    if (scope.fileQuotas.setQuota === 0) {
                        //shouldn't need to set it to 0 it should already be set but it can't hurt.
                        scope.fileQuotas.quotaRemaining = 0;
                        return;
                    }

                    var runningFileSizeTotal = 0;
                    for (var i = 0; i < flow.files.length; i++) {
                        if (flow.files[i].paused === false) {
                            runningFileSizeTotal += flow.files[i].size;
                        }
                    }

                    scope.fileQuotas.quotaRemaining = scope.startingFileQuota - runningFileSizeTotal;
                };

                scope.fileSuccess = function (flow, file, message) {
                    message = JSON.parse(message);
                    if (!file.error) {
                        file.complete = true;

                        var fileExtention = file.name.split(".");
                        scope.numberOfFiles = flow.files.length;
                        scope.saveObjects.push({
                            ticketId: file.uniqueIdentifier,
                            title: file.name,
                            description: file.description,
                            flowFile: file,
                            icon: fileUploadService.getCorrectFileIcon(fileExtention.pop()),
                            altText: null
                        });
                        if (upload_title_el) {
                            //time out is needed to allow the dom to render so we can focus the title field.
                            $timeout(function () {
                                if (!scope.changeExisting) {
                                    upload_title_el.focus();
                                }
                            }, 0);
                        }

                        if (scope.isChangingExisting) {
                            scope.$emit("sendGiveTicketTo", file);
                        }
                    }

                    if (scope.allFilesProcessed(flow)) {
                        scope.onFilesCompleted(flow);
                    }
                };

                var handleErrorResponse = function (file, response) {
                    scope.saveObjects.forEach((saveObject, index) => {
                        if (saveObject.flowFile.flowIdentifier === file.flowIdentifier) {
                            scope.saveObjects.splice(index, 1);
                        }
                    });

                    if (response.error.includes("flow_filename_error")) {
                        // Flow.JS when reading files changes the / character in filenames to a colon.
                        // Changed back to a / here. Mac and Windows make sure files cannot be named with a colon.
                        // Changed back here to get the original character back.
                        const invalidCharacters = response.error.replace("flow_filename_error", "");
                        file.invalidCharacters = invalidCharacters.replace(":", "/");
                        file.name = file.name.replace(":", "/");
                        scope.fileObject.title = file.name.replace(":", "/");
                        file.uploadStatus = "filenameError";
                        return;
                    }

                    if (response && response.error && response.correspondenceId) {
                        file.handledError = {
                            type: response.error,
                            correspondenceId: response.correspondenceId
                        };
                    } else {
                        file.unhandledError = true;
                    }
                    fileUploadService.cancelUpload(file.uniqueIdentifier);
                };

                scope.fileError = function (flow, file, message) {
                    message = JSON.parse(message);
                    handleErrorResponse(file, message);
                    if (scope.allFilesProcessed(flow)) {
                        scope.onFilesCompleted(flow);
                    }
                };

                scope.onFilesCompleted = function (flow) {
                    var successful = [];
                    var errored = [];

                    for (var i = 0; i < flow.files.length; i++) {
                        if (flow.files[i].complete) {
                            successful.push(flow.files[i]);
                        } else {
                            errored.push(flow.files[i]);
                        }
                    }

                    if (errored.length === 0 && successful.length > 0) {
                        scope.filesProcessedMessage = scope.multiLanguageService.getString("file_uploads.accessibility.finished_uploading_successful");
                        return;
                    }

                    if (errored.length > 0 && successful.length > 0) {
                        scope.filesProcessedMessage = scope.multiLanguageService.getString("file_uploads.accessibility.finished_uploading_some_failed");
                    } else {
                        scope.filesProcessedMessage = scope.multiLanguageService.getString("file_uploads.accessibility.finished_uploading_all_failed");
                    }

                    scope.filesProcessedMessage += errored.reduce(function (accumulatedValue, file) {
                        return accumulatedValue + scope.getA11yErrorMessageForFile(file);
                    }, "");
                };

                scope.getA11yErrorMessageForFile = function (file) {
                    var msg = "";
                    if (file.uploadStatus === "overSingle") {
                        msg = scope.multiLanguageService.getString("file_uploads.accessibility.warning_exceeds_max_size");
                    } else if (file.uploadStatus === "overQuota") {
                        msg = scope.multiLanguageService.getString("file_uploads.accessibility.warning_exceeds_storage_space");
                    } else if (file.cancelledMidway) {
                        msg = scope.multiLanguageService.getString("file_uploads.accessibility.warning_canceled");
                    } else if (file.uploadStatus === "filenameError") {
                        msg = scope.multiLanguageService.getString("file_uploads.accessibility.warning_filename", { invalidCharacters: file.invalidCharacters });
                    } else {
                        msg = scope.multiLanguageService.getString("file_uploads.accessibility.warning_failed");
                    }

                    return file.name + msg;
                };

                scope.allFilesProcessed = function (flow) {
                    scope.filesProcessed = flow.files.every(function (file) {
                        return file.complete || file.error || scope.errorUploadStatuses.contains(file.uploadStatus);
                    });

                    return scope.filesProcessed;
                };

                scope.fileRetry = function (flow, file) {
                    file.handledError = null;
                    file.unhandledError = null;
                    file.uniqueIdentifier = newGuid();
                    file.retry();
                    scope.checkQuotas(flow, file);
                };

                scope.fileCancel = function (flow, file) {
                    file.cancelledMidway = !file.complete;
                    fileUploadService.cancelUpload(file.uniqueIdentifier);

                    if (file.isUploading()) {
                        file.pause();
                    }

                    for (var i = 0; i < flow.files.length; i++) {
                        if (flow.files[i].paused === true) {
                            // tslint:disable-next-line:triple-equals
                            if (flow.files[i] != file && !flow.files[i].cancelledMidway) {
                                if (scope.checkQuotas(flow, flow.files[i])) {
                                    flow.files[i].resume();
                                    flow.files[i].uploadStatus = undefined;
                                }
                            }
                        }
                    }

                    file.uploadStatus = undefined;
                    scope.numberOfFiles = flow.files.length;
                    if (scope.allFilesProcessed(flow)) {
                        scope.onFilesCompleted(flow);
                    }
                };

                scope.fileRestart = function (flow, file) {
                    file.uniqueIdentifier = newGuid();
                    file.bootstrap();
                    file.resume();
                    file.complete = false;
                    file.cancelledMidway = false;
                    file.uploadStatus = undefined;
                    scope.checkQuotas(flow, file);
                };

                scope.fileRemove = function (flow, file) {
                    if (!file.error) {
                        scope.saveObjects.removeOne(scope.saveObjects.selectOne("flowFile").indexesOf(file));
                    }

                    file.cancel();
                    if (!file.unhandledError && !file.handledError) {
                        var fileCancelPromise = fileUploadService.cancelUpload(file.uniqueIdentifier);
                        fileCancelPromise.then(function (response) {
                            scope.updateQuotaRemaining(flow);
                            for (var i = 0; i < flow.files.length; i++) {
                                if (flow.files[i].paused === true) {
                                    // tslint:disable-next-line:triple-equals
                                    if (!flow.files[i].cancelledMidway && flow.files[i] != file) {
                                        if (scope.checkQuotas(flow, flow.files[i])) {
                                            flow.files[i].resume();
                                            flow.files[i].uploadStatus = undefined;
                                        }
                                    }
                                }
                            }
                        });
                    }
                    if (scope.isChangingExisting) {
                        scope.$emit("sendGiveTicketTo", null);
                    }

                    if (scope.$parent.focusOnFirstElement) {
                        scope.$parent.focusOnFirstElement();
                    }
                    scope.hasFile = flow.files.length > 0;
                    scope.numberOfFiles = flow.files.length;

                    scope.getDescribedByText();

                    if (scope.allFilesProcessed(flow)) {
                        scope.onFilesCompleted(flow);
                    }
                };

                var processed = [];
                scope.fileChange = function (flow, file) {
                    var changeStarted = Date.now(),
                        lastFlowLength = flow.files.length,
                        input = file_click_el[0].querySelector("input[type='file']");

                    var onFileChanged = function (e) {
                        if (processed.indexOf(changeStarted) !== -1) {
                            return;
                        }

                        input.removeEventListener("change", onFileChanged);
                        processed.push(changeStarted);
                        // IE runs event listeners twice
                        var flowLength = flow.files.length,
                            numberOfDuplicates = 0;

                        if (!scope.isChangingExisting) {
                            //Find duplicates
                            flow.files.forEach(function (f) {
                                if (f.flowIdentifier === file.flowIdentifier && file.file.lastModified && f.file.lastModified === file.file.lastModified) {
                                    numberOfDuplicates++;
                                }
                            });

                            if (numberOfDuplicates >= 1 && lastFlowLength === flowLength) {
                                return;
                            }

                            //Replace old file with the newest file added.
                            flow.files[flow.files.indexOf(file)] = flow.files[flowLength - 1];
                            flow.files.pop();

                            //Remove the old file.
                            scope.saveObjects.removeOne(scope.saveObjects.findInNestedObject("ticketId", file.uniqueIdentifier));
                            scope.fileCancel(flow, file);
                            scope.fileRemove(flow, file);
                        }

                        input.removeEventListener("change", onFileChanged);
                        processed.push(changeStarted);

                        //If we are dealing with a single file upload.
                        if (flow.files.length === 1) {
                            //if the title is still the same name as the old filename
                            if (file.name === scope.fileObject.title) {
                                //update it to the new filename
                                scope.fileObject.title = flow.files[0].name;
                            }
                        }
                    };

                    input.addEventListener("change", onFileChanged);
                    file_click_el[0].click();
                };

                scope.dropFile = function (fileReady) {
                    scope.fileReady = fileReady;
                    if (fileReady) {
                        file_drop_el.addClass("drop");
                    } else {
                        file_drop_el.removeClass("drop");
                    }
                };

                // Creates a ticketId for tracking the file chunks with the backend
                var newGuid = function () {
                    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
                        var r = (Math.random() * 16) | 0,
                            v = c === "x" ? r : (r & 0x3) | 0x8;
                        return v.toString(16);
                    });
                };

                var hideOnComplete = function () {
                    if (attrs.noModal === undefined) {
                        scope.$parent.hideModal();
                    }
                };

                scope.emitSelectorData = function (savedResponses) {
                    const items = savedResponses.map((assetRef) => {
                        return {
                            Id: assetRef.Id,
                            Title: assetRef.Title,
                            SubType: assetRef.SubType,
                            MainType: assetRef.MainType,
                            Icon: assetRef.Icon
                        };
                    });

                    const data = { Items: items, SelectorId: scope.panelInfo.selectorId };

                    if (scope.panelInfo.close !== undefined) {
                        scope.panelInfo.close();
                    }

                    $rootScope.$broadcast("updateItemsInSelector", data);
                    return overlayFactory.updateThenHide(1000);
                };

                scope.canSave = function (flow) {
                    return flow !== undefined && scope.saveObjects.length && flow.files.length && scope.saveObjects.length === flow.files.length;
                };

                scope.batchedSaveFile = function (saveObjects) {
                    if (saveObjects.length === 0) {
                        return $q.when([]);
                    }

                    const batches = helpers.chunkArray(saveObjects, 6);
                    const batchIterator = batches.values();
                    const result = [];
                    const saveBatch = (batch) => $q.all(batch.map(scope.commitFileSave));
                    const onBatchSave = (responses) => {
                        result.push(...responses);
                        const nextBatch = batchIterator.next();
                        if (nextBatch.done) {
                            return;
                        }

                        return saveBatch(nextBatch.value).then(onBatchSave);
                    };

                    return saveBatch(batchIterator.next().value)
                        .then(onBatchSave)
                        .then(() => result);
                };

                scope.commitFileSave = function (saveObject) {
                    return fileUploadService
                        .sendSaveFile(saveObject)
                        .then((data) => {
                            return {
                                Id: data.assetRef.Id,
                                MainType: data.assetRef.MainType,
                                SubType: data.assetRef.SubType,
                                TicketId: saveObject.ticketId,
                                Title: saveObject.title,
                                Icon: saveObject.icon
                            };
                        })
                        .catch((response) => {
                            handleErrorResponse(saveObject.flowFile, response);
                            return null;
                        });
                };

                scope.saveFile = function () {
                    // need to use a clone of the save objects as the
                    // save objects list MAY be edited during this iteration
                    const saveObjectsClone = scope.saveObjects.clone();

                    // Set the upload information if only one file is being processed
                    if (saveObjectsClone.length === 1) {
                        saveObjectsClone[0].title = scope.fileObject.title;
                        saveObjectsClone[0].description = scope.fileObject.description;

                        if (saveObjectsClone[0].flowFile.file.type.indexOf("image") > -1) {
                            saveObjectsClone[0].altText = scope.fileObject.altText;
                        }
                    }

                    for (let i = 0, l = saveObjectsClone.length; i < l; i++) {
                        let currentClone = saveObjectsClone[i];

                        if (scope.tags.length !== 0) {
                            currentClone.tags = scope.tags;
                        }
                        if (currentClone.description === undefined) {
                            currentClone.description = "";
                        }
                    }

                    overlayFactory.setActiveOverlay(overlay);
                    overlayFactory.show();

                    return scope.batchedSaveFile(saveObjectsClone).then((responses) => {
                        const containsFailedFiles = responses.some((response) => response === null);
                        if (containsFailedFiles) {
                            overlayFactory.hide(1000);
                            return $q.reject();
                        }

                        scope.numberOfFiles = 0;
                        $rootScope.$broadcast("fileUploadSuccessful");
                        return $timeout(() => {
                            if (scope.isPanelMode) {
                                return scope.emitSelectorData(responses);
                            } else {
                                hideOnComplete();
                                return overlayFactory.updateThenHide(1000);
                            }
                        }, 1000);
                    });
                };

                scope.close = function () {
                    scope.$emit("hideModal");
                };

                scope.checkTitleContent = function () {
                    if (scope.fileObject.title === undefined || scope.fileObject.title === "") {
                        //this will need to be changed to refer to the originalSaveObjectTitle when editing a file upload
                        scope.fileObject.title = scope.saveObjects[0].title;
                    }
                };

                scope.triggerFlowBtnClick = function (e) {
                    //added because the enter keypress wasn't triggering off the click on the browser for file flow-btn.
                    if (e.keyCode === 13) {
                        file_click_el[0].click();
                    }
                };

                scope.calcProgress = function (file) {
                    var progress = file.progress();
                    return (progress >= 0 ? progress : 0) * 100;
                };

                element.on("$destroy", function () {
                    scope.$destroy();
                });

                scope.$on("$destroy", function () {
                    subscriptionServiceProvider.unsubscribe("spaMenu", scope.subscriptionId);
                });

                scope.termsAndConditionsURL = urlService.getForwardingUrl([
                    {
                        key: "location",
                        value: "PebblePadWebsite"
                    },
                    {
                        key: "action",
                        value: "Terms"
                    }
                ]);
            }
        };
    }
]);
