import { NgComponent } from "angular/ngComponent";
import { angularAMD } from "@pebblepad/amd";
import template from "./element-info-panel.html";
import { PANEL_CONSTANTS } from "../constants/panel.constants";
import { UnsavedChangesPreventRedirectResolver } from "../preventRedirect/resolvers/unsavedChangesPreventRedirectResolver";
import "../multiLanguageService/multiLanguageService";
import "../utilities/baseUrlsFactory";
import "../utilities/subscriptionServiceProvider";
import "../modal/services/modal";
import "../preventRedirect/preventRedirect";
import "../formComponents/formElementInfo/formElementInfo.component";
import "../feedbackListWrapper/feedbackListWrapper.component";
import "../spaMenu/assetInfoPanel/assetComments/assetComments";
import "../tabSet/tabSet.component";
import "../tabContent/tabContent.component";

class ElementInfoPanelComponent extends NgComponent {
    constructor(multiLanguageService, modal, subscriptionServiceProvider, baseUrlsFactory, $q, preventRedirect, $rootScope) {
        super();

        this.multiLanguageService = multiLanguageService;
        this._modal = modal;
        this._subscriptionServiceProvider = subscriptionServiceProvider;
        this._subscriptionId = "element-info";
        this.preventRedirectResolver = new UnsavedChangesPreventRedirectResolver(UnsavedChangesPreventRedirectResolver.defaultMessages(multiLanguageService));
        this._$q = $q;
        this._$rootScope = $rootScope;
        this._preventRedirect = preventRedirect;
        this._baseUrlsFactory = baseUrlsFactory;

        const evidenceAllowed = this.panelData.evidenceAllowed || this.panelData.isCapability;
        if (this.viewerActions !== undefined && this.viewerActions.infoPanel !== undefined) {
            this._canViewElementInfo = this.viewerActions.infoPanel["element-info"] && evidenceAllowed;
        } else {
            this._canViewElementInfo = evidenceAllowed;
        }

        this._locationChangeSuccessHandler = null;

        this.infoTab = {
            active: false,
            id: "element-info",
            title: this.panelData.tabTitle,
            canView: this._canViewElementInfo,
            disabled: false
        };
        this.commentsTab = {
            active: false,
            id: "element-comments",
            title: multiLanguageService.getString("asset_summary.headings.comments"),
            canView: this.panelData.commentsAllowed,
            disabled: false
        };
        this.feedbackTab = {
            active: false,
            id: "element-feedback",
            title: multiLanguageService.getString("asset_summary.headings.feedback"),
            canView: this.panelData.feedbackAllowed,
            disabled: false
        };

        this.focusTitleType = null;
        if (this.panelData.focusTitleType !== undefined) {
            this.focusTitleType = this.panelData.focusTitleType;
        }

        this.tabs = [this.infoTab, this.commentsTab, this.feedbackTab];
        this.showElementInfoOnly = false;
        this.onEditStateChange = this._editStateChange.bind(this);
        this.onFormElementLoadComplete = this._onFormElementLoadComplete.bind(this);
    }

    $onInit() {
        this._setupPanel();
        this._subscriptionServiceProvider.subscribe("spaMenu", this._subscriptionId, () => this._onSpaMenuAction());
        this._preventRedirect.addCustomResolver(this.preventRedirectResolver);
        this._locationChangeSuccessHandler = this._$rootScope.$on("$locationChangeSuccess", () => this.closePanel());
        this._preventRedirect.watchLocationChange(this.assetId);
    }

    $onDestroy() {
        this._subscriptionServiceProvider.unsubscribe("spaMenu", this._subscriptionId);
        this._allowCloseOrRedirect();
        this._preventRedirect.removeCustomResolver(this.preventRedirectResolver);
        if (this._locationChangeSuccessHandler !== null) {
            this._locationChangeSuccessHandler();
        }
    }

    setActiveTab(activeTab) {
        this.tabs.forEach((tab) => (tab.active = tab.id === activeTab.id));
    }

    _setupPanel() {
        if (!this.panelData.commentsAllowed && !this.panelData.feedbackAllowed && this._canViewElementInfo) {
            this.showElementInfoOnly = true;
            return;
        }

        if (this.panelData.commentsAllowed) {
            if (this.panelType === PANEL_CONSTANTS.PANEL.ELEMENT_COMMENTS) {
                this.setActiveTab(this.commentsTab);
                return;
            }
        }

        if (this.panelData.feedbackAllowed) {
            if (this.panelType === PANEL_CONSTANTS.PANEL.ELEMENT_FEEDBACK) {
                this.setActiveTab(this.feedbackTab);
                return;
            }
        }

        if (this._canViewElementInfo) {
            if (this.panelType === PANEL_CONSTANTS.PANEL.ELEMENT_INFO) {
                this.setActiveTab(this.infoTab);
                return;
            }
        }
    }

    closePanel() {
        if (this.preventRedirectResolver.redirectionIsBlocked()) {
            this._showConfirmCloseModal();
        } else {
            this._allowCloseOrRedirect();
            this.closeInfo.action();
        }
    }

    _onSpaMenuAction() {
        if (this.preventRedirectResolver.redirectionIsBlocked()) {
            this._showConfirmCloseModal();
            return this._$q.reject("Preventing close due to user choice");
        }

        this._allowCloseOrRedirect();
        return this._$q.resolve();
    }

    _showConfirmCloseModal() {
        if (!this._preventRedirect.doesExist) {
            const modalTemplateUrl = `${this._baseUrlsFactory.shared_component_base_url}preventRedirect/templates/warning-modal-alt.html`;

            this._modal.launch(modalTemplateUrl, {
                title: this.multiLanguageService.getString("logout.title_unsaved_changes"),
                message: this.multiLanguageService.getString("logout.message_unsaved_changes"),
                continueBtn: this.multiLanguageService.getString("buttons.discard_changes"),
                cancelRedirectBtn: this.multiLanguageService.getString("buttons.cancel"),
                continue: () => {
                    this._allowCloseOrRedirect();
                    this.closeInfo.action();
                }
            });
        }
    }

    _editStateChange(isEditing) {
        this.tabs.forEach((tab) => (tab.disabled = isEditing));
    }

    _allowCloseOrRedirect() {
        this.preventRedirectResolver.cleanUp();
    }

    _onFormElementLoadComplete() {
        const evidenceAnchorId = this.panelData.evidenceAnchorId;

        if (evidenceAnchorId !== "") {
            const anchorEvidence = document.getElementById(`evidence-item-${evidenceAnchorId}`);
            const tabContent = document.getElementById(`${this.infoTab.id}-tab-content-body`);
            tabContent.scrollTop = anchorEvidence.offsetTop - tabContent.offsetHeight / 2 + anchorEvidence.offsetHeight / 2;
        }

        this.focusTitleType = null;
    }
}

export const ElementInfoPanelComponentDefinition = {
    bindings: {
        elementId: "<",
        assetId: "<",
        topLevelAssetId: "<",
        closeInfo: "<",
        panelType: "<",
        panelData: "<",
        isActivePanel: "<",
        submissionId: "<?",
        viewerActions: "<?"
    },
    template: template,
    controller: ElementInfoPanelComponent
};

ElementInfoPanelComponent.$inject = ["multiLanguageService", "modal", "subscriptionServiceProvider", "baseUrlsFactory", "$q", "preventRedirect", "$rootScope"];
angularAMD.component("elementInfoPanel", ElementInfoPanelComponentDefinition);
