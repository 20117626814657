import { NgComponent } from "angular/ngComponent";
import { angularAMD } from "@pebblepad/amd";
import { createGuid } from "@pjs/utilities";
import { MAP_TO_STANDARDS_CONSTANTS } from "../../../constants/mapToStandards.constants";
import "../../../spaMenu/assetInfoPanel/assetFeedback/helpers/FeedbackHelper";
import "../../../ckEditorModule/editors/feedbackCommentsEditorWithEditorFallback.component";
import "../../../multiLanguageService/multiLanguageService";
import template from "./approval-collection-item.html";

class ApprovalCollectionItemComponent extends NgComponent {
    constructor(multiLanguageService, feedbackHelper) {
        super();

        this.MAP_TO_STANDARDS_CONSTANTS = MAP_TO_STANDARDS_CONSTANTS;
        this.multiLanguageService = multiLanguageService;
        this.feedbackHelper = feedbackHelper;

        this.textEditorLabel = this.getTextEditorLabel();
        this.richTextEditorClass = "rich-text-comment-box";
        this.richTextEditorDataHook = "approval-feedback-textbox";
        this.richTextLabelledBy = createGuid();
        this.richTextAutoFocus = true;
        this.editorPlaceholder = "";

        this.richTextConfig = {
            removePlugins: "colordialog,floatingspace,link,unlink,linker,colorbutton,formatting,stylescombo,format,font,size,justify",
            extraPlugins: "feedbackStatements",
            startupFocus: this.richTextAutoFocus
        };
        this.onAnswerChange = this._updateContent.bind(this);
    }

    updateAnswer() {
        this.onEdit({
            validation: this.feedbackHelper.getApprovalCollectionValidation(this.status, this.comment),
            changes: { ApprovalCollectionStatus: this.status, Comment: this.comment }
        });

        this.textEditorLabel = this.getTextEditorLabel();
    }

    getTextEditorLabel() {
        return this.status === this.MAP_TO_STANDARDS_CONSTANTS.STATUS.APPROVED
            ? this.multiLanguageService.getString("atlas_sidebar.tabs.mapping_approval.feedback_label_optional")
            : this.multiLanguageService.getString("atlas_sidebar.tabs.mapping_approval.feedback_label_compulsory");
    }

    _updateContent(comment) {
        this.comment = comment;
        this.updateAnswer();
    }
}

export const approvalCollectionItem = {
    bindings: {
        assetId: "<",
        comment: "<",
        status: "<",
        onEdit: "&",
        feedbackStatements: "<"
    },
    template: template,
    controller: ApprovalCollectionItemComponent
};

ApprovalCollectionItemComponent.$inject = ["multiLanguageService", "feedbackHelper"];
angularAMD.component("approvalCollectionItem", approvalCollectionItem);
