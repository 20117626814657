import { NgComponent } from "angular/ngComponent";
import { camelToKebabCase } from "../utilities/CamelToKebabCase.function";
import { convertBindingsToAttributes } from "../utilities/ConvertBindingsToAttributes.function";
import "../inlineTextEditor/services/richTextEditingSettings.service";
import "../userAgent/userAgent.service";
import "../featureSettings/FeatureSettings";
import "../autoFocus/autoFocus.directive";

class EditorFallbackController extends NgComponent {
    constructor(richTextEditingSettings) {
        super();

        this.isEditorEnabled = richTextEditingSettings.isEditorEnabled();
        this.isLegacyEditorEnabled = richTextEditingSettings.isLegacyEditorEnabled();
    }
}
EditorFallbackController.$inject = ["richTextEditingSettings"];

/**
 * @param {string} name
 * @param {{legacyEditor: {bindings: Record<string, string>, name: string}, editor: {bindings: Record<string, string>, name: string} | null}} componentDefinitions
 * @param {boolean} singleLine
 * @param {string} fallbackClassName
 * @param {string} legacyClassName
 * @returns {{name: string, bindings: Record<string, string>, template: string, controller: EditorFallbackController}}
 */
export function withEditorFallback(name, componentDefinitions, singleLine, fallbackClassName = "", legacyClassName = "") {
    const ck4AttributeBindings = convertBindingsToAttributes(componentDefinitions.legacyEditor.bindings);
    const ck4ComponentName = camelToKebabCase(componentDefinitions.legacyEditor.name);

    const singleLineProp = singleLine ? 'single-line="true"' : "";

    const ngIfCkeditor5Enabled = `ng-if="$ctrl.isEditorEnabled"`;
    let newEditorComponent = `<h2 ${ngIfCkeditor5Enabled}>CKEditor5 Coming soon to an editor near you</h2>`;

    let editorFallbackBindings = componentDefinitions.legacyEditor.bindings;

    if (componentDefinitions.editor !== null) {
        const ck5AttributeBindings = convertBindingsToAttributes(componentDefinitions.editor.bindings);
        const ck5ComponentName = camelToKebabCase(componentDefinitions.editor.name);
        newEditorComponent = `<${ck5ComponentName} ${ngIfCkeditor5Enabled} class="${legacyClassName}"
                                ${singleLineProp} ${ck5AttributeBindings}></${ck5ComponentName}>`;
        editorFallbackBindings = Object.assign({}, componentDefinitions.legacyEditor.bindings, componentDefinitions.editor.bindings);
    }

    const template = `
        <div ng-if="!$ctrl.isEditorEnabled && !$ctrl.isLegacyEditorEnabled"
             contenteditable="true"
             class="{{$ctrl.className}} fallback-text-editor ${fallbackClassName}"
             conditional-placeholder="{{$ctrl.placeholder}}"
             data-hook="{{$ctrl.datahook}}"
             auto-focus="{{$ctrl.autoFocus}}"
             on-update="$ctrl.onChange"
             ng-model="$ctrl.content"
             uber-label="{{::$ctrl.label}}"
             uber-labelled-by="{{::$ctrl.labelledBy}}"
             ${singleLineProp}>
        </div>
        <${ck4ComponentName} ng-if="$ctrl.isLegacyEditorEnabled" class="${legacyClassName}"
                          ${singleLineProp} ${ck4AttributeBindings}></${ck4ComponentName}>
        ${newEditorComponent}        
    `;

    return {
        name: `${name}WithEditorFallback`,
        bindings: editorFallbackBindings,
        template: template,
        controller: EditorFallbackController
    };
}
