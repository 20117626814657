import { angularAMD } from "@pebblepad/amd";
import { EventEmitter } from "../../eventEmitter/eventEmitter";
import "../../utilities/helpers";

angularAMD.factory("StatusToolbarFactory", StatusToolbarFactory);
StatusToolbarFactory.$inject = ["helpers"];

function StatusToolbarFactory(helpers) {
    return {
        createStatusDictionary: function (message, a11yNotification, a11yActionLabel, a11yCloseLabel, closeTitleLabel) {
            return new StatusItemDictionary(message, a11yNotification, a11yActionLabel, a11yCloseLabel, closeTitleLabel);
        },
        createStatus: function (message, close, duration, action, onComplete, icon, colour, content) {
            var textTheme = colour && helpers.getContrastYIQ(colour, 128) === "black" ? "status-toolbar__list-item--dark-text" : "";
            return new StatusItem(message, close, duration, action, onComplete, icon, colour, textTheme, content);
        },
        createQueuedItemCollection: function (statuses, maxActiveStatuses) {
            return new QueuedStatusItemCollection(statuses, maxActiveStatuses);
        },
        createHandler: function (timeoutFactory, maxDisplayedTimedStatuses) {
            return new StatusHandler(timeoutFactory, maxDisplayedTimedStatuses);
        }
    };
}

/**
 * @typedef {Object} ContentItem
 * @property {String} text
 * @property {Function} onClick
 * @property {String} ariaLabel
 * @property {String} dataHook
 *
 * @param {StatusItemDictionary} dictionary
 * @param {Boolean} close
 * @param {Number} duration - 0 or Infinity for persistent.
 * @param {Function=} action
 * @param {Function=} onComplete
 * @param {String=} icon
 * @param {String=} colour - Hex or RGB colour value
 * @param {String=} textTheme
 * @param {ContentItem[]} content - Array of {@link ContentItem} objects to display as buttons on the toolbar
 * @constructor
 */
function StatusItem(dictionary, close, duration, action, onComplete, icon, colour, textTheme, content) {
    this.dictionary = dictionary || "";
    this.close = typeof close === "boolean" ? close : true;
    this.duration = !isNaN(duration) && duration >= 0 ? duration : 5000;
    this.action = typeof action === "function" ? action : null;
    this.onComplete = typeof onComplete === "function" ? onComplete : null;
    this.icon = icon || "";
    this.colour = colour || "";
    this.textTheme = textTheme || "";
    this.content = content || [];
}

/**
 * @param {String} message
 * @param {String} a11yNotification
 * @param {String} a11yActionLabel
 * @param {String} a11yCloseLabel
 * @param {String} closeTitleLabel
 * @constructor
 */
function StatusItemDictionary(message, a11yNotification, a11yActionLabel, a11yCloseLabel, closeTitleLabel) {
    this.message = message;
    this.a11yNotification = a11yNotification || message;
    this.a11yActionLabel = a11yActionLabel || message;
    this.a11yCloseLabel = a11yCloseLabel || message;
    this.closeTitleLabel = closeTitleLabel || null;
}

/**
 * @return {Boolean}
 */
StatusItem.prototype.isPersistent = function () {
    return this.duration <= 0 || this.duration === Infinity;
};

/**
 * @param {Array<StatusItem>=} statuses
 * @param {Number=} maxActiveStatuses
 * @constructor
 */
function QueuedStatusItemCollection(statuses, maxActiveStatuses) {
    this.statuses = statuses || [];
    this.maxActiveStatuses = maxActiveStatuses || Infinity;
    this.activeList = statuses.slice(0, this.maxActiveStatuses);
}

QueuedStatusItemCollection.prototype.add = function (status) {
    var displayed = false;
    this.statuses.push(status);

    if (this.activeList.length <= this.maxActiveStatuses) {
        displayed = true;
        this.activeList.push(status);
    }

    return displayed;
};

QueuedStatusItemCollection.prototype.remove = function (status) {
    this.statuses.remove(status);
    this.activeList.remove(status);
};

QueuedStatusItemCollection.prototype.inActiveList = function (status) {
    return this.activeList.some(function (displayedStatus) {
        return displayedStatus === status;
    });
};

/**
 * @return {StatusItem|null}
 */
QueuedStatusItemCollection.prototype.incrementQueue = function () {
    if (this.activeList.length <= this.maxActiveStatuses && this.activeList.length < this.statuses.length) {
        var item = this.statuses[this.activeList.length];
        this.activeList.push(item);
        return item;
    }

    return null;
};

/**
 * @param {$timeout} timeoutFactory
 * @param {Number=} maxDisplayedTimedStatuses
 * @constructor
 */
function StatusHandler(timeoutFactory, maxDisplayedTimedStatuses) {
    this.timeoutFactory = timeoutFactory;
    this.timeouts = [];
    this.statusCollection = new QueuedStatusItemCollection([], maxDisplayedTimedStatuses || StatusHandler.maxDisplayedTimedStatuses);

    this.events = new EventEmitter(["statusListChange"]);
}

StatusHandler.maxDisplayedTimedStatuses = 5;

StatusHandler.prototype.addStatus = function (status, push) {
    var isActive = this.statusCollection.add(status, push);

    if (isActive && !status.isPersistent()) {
        this.timeouts.push(this._createNewTimeout(status));
    }

    this.events.emit("statusListChange");

    return this;
};

/**
 * @param {StatusItem} status
 */
StatusHandler.prototype.removeStatus = function (status) {
    this.statusCollection.remove(status);

    this.events.emit("statusListChange");

    var index = this.timeouts.findIndex(function (item) {
        return item.key === status;
    });

    if (index === -1) {
        return;
    }

    this.timeoutFactory.cancel(this.timeouts[index].value);
    this.timeouts.splice(index, 1);
};

StatusHandler.prototype.removeAllStatus = function () {
    var statuses = this.statusCollection.activeList.slice();
    statuses.forEach(this.removeStatus.bind(this));
    this.statusCollection.statuses.length = 0;
};

StatusHandler.prototype.updateQueue = function () {
    var lastActiveItem = this.statusCollection.incrementQueue();

    if (lastActiveItem === null || lastActiveItem.isPersistent()) {
        return;
    }

    this.timeouts.push(this._createNewTimeout(lastActiveItem));
};

/**
 * @param {StatusItem} status
 * @return {{key: StatusItem, value: Promise}}
 * @private
 */
StatusHandler.prototype._createNewTimeout = function (status) {
    var timeoutPromise = this.timeoutFactory(this.onComplete.bind(this, status), status.duration);
    return { key: status, value: timeoutPromise };
};

/**
 * @param {StatusItem} status
 */
StatusHandler.prototype.onComplete = function (status) {
    if (typeof status.onComplete === "function") {
        status.onComplete();
    }

    this.removeStatus(status);
    this.updateQueue();
};
