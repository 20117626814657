import { angularAMD } from "@pebblepad/amd";
import { warningNotice } from "@pjs/core-ui";
import "../../utilities/baseUrlsFactory";
import "../../utilities/pebbleDate";
import "../../datePicker/datePickerHelper";
import "../../assetStore/services/assetStoreService";
import "../../extensions/arrayExtensions";
import "../services/shareService";
import "../../autocomplete/contactAutoComplete/contactAutoComplete";
import "../../expandable/expandable";
import "../../assetsWithLinks/directives/assetsWithLinks";
import "../../tooltip/tooltip";
import "../../utilities/text-editable/contenteditable";
import "../../inlineTextEditor/directives/inlineTextEditor";
import "../../builder/dataManager/helperService";
import "../../multiLanguageService/multiLanguageService";
import "../../utilities/urlService";
import "../../utilities/animationHelperService";
import "../../autoFocus/autoFocus.directive";
import "../../assetViews/sidebar/shares/deletableShare.service";
import "../../share/services/shareStatusService.service";
import "../../react2angular/htmlNotice";
import template from "../templates/share.html";

angularAMD.directive("share", [
    "$q",
    "$http",
    "$sce",
    "$compile",
    "$timeout",
    "$rootScope",
    "$location",
    "$filter",
    "baseUrlsFactory",
    "assetStoreService",
    "shareService",
    "datePickerHelper",
    "helperService",
    "multiLanguageService",
    "urlService",
    "animationHelperService",
    "DeletableShareService",
    "shareStatusService",
    function (
        $q,
        $http,
        $sce,
        $compile,
        $timeout,
        $rootScope,
        $location,
        $filter,
        baseUrlsFactory,
        assetStoreService,
        shareService,
        datePickerHelper,
        helperService,
        multiLanguageService,
        urlService,
        animationHelperService,
        DeletableShareService,
        shareStatusService
    ) {
        // usage: <share changingExisting></share>
        return {
            template: template,
            restrict: "E",
            scope: {
                assetIds: "=", // list of Asset Id's?,
                assetsWithLinks: "=",
                changingExisting: "=", // use to edit an existing share
                noModal: "@",
                shareMode: "=",
                shareType: "=",
                hideAssetWithLinks: "=",
                showTopLevelAssetWithLinks: "=?",
                modalInterface: "=?",
                collaboratorIds: "<"
            },
            controller: [
                "$scope",
                "$element",
                "$attrs",
                function ($scope, $element, $attrs) {
                    // Just in case I need to get an Image at some point
                    $scope.imageWorkspace = $sce.getTrustedResourceUrl(baseUrlsFactory.shared_component_base_url + "images/sharing/workspace.svg");
                    $scope.imageAssignment = $sce.getTrustedResourceUrl(baseUrlsFactory.shared_component_base_url + "images/sharing/assignment.svg");
                    $scope.imageSharedAfter = $sce.getTrustedResourceUrl(baseUrlsFactory.shared_component_base_url + "images/sharing/sharedAfter.svg");
                    $scope.imageSharedBy = $sce.getTrustedResourceUrl(baseUrlsFactory.shared_component_base_url + "images/sharing/sharedBy.svg");
                    $scope.imageRevisedUntil = $sce.getTrustedResourceUrl(baseUrlsFactory.shared_component_base_url + "images/sharing/revisedUntil.svg");
                    $scope.imageWarning = $sce.getTrustedResourceUrl(baseUrlsFactory.shared_component_base_url + "images/general/warning48.svg");

                    $element.on("$destroy", function () {
                        $scope.$destroy();
                    });
                }
            ],
            link: function (scope, element, attrs) {
                scope.multiLanguageService = multiLanguageService;
                scope.shareStatusService = shareStatusService;
                scope.permissionCollaborateFlagId = "permission-collaborate-flag";

                const labelType = multiLanguageService.getString("accessibility.notice.type.submission");
                scope.warningNotice = {
                    ariaLabel: multiLanguageService.getString("accessibility.notice.labels.warning", { type: labelType }),
                    type: warningNotice
                };

                scope.collabOptionDisabled = false;
                if (scope.collaboratorIds === undefined) {
                    scope.collaboratorIds = [];
                }
                scope.helpLink = "#/learningCentre?type=privacyAndSharing";
                var saving_share_el = angular.element(element[0].getElementsByClassName("saving-share")[0]);
                var modal_dialog_wrapper = document.getElementsByClassName("modal-dialog__wrapper")[0];
                var assetMainTypes = [];
                scope.container = angular.element(document.getElementsByClassName("modal-dialog")[0]);
                scope.sharees = [];
                scope.saveStatus = "";
                scope.shareForAssessmentUrl = baseUrlsFactory.shared_component_base_url + "share/templates/share-for-assessment.html";
                scope.workspaceAssignmentBlockUrl = baseUrlsFactory.shared_component_base_url + "share/templates/workspace-assignment-deadline-blocks.html";
                scope.copyBtnText = scope.multiLanguageService.getString("sharing.buttons.copy_url");
                scope.collaborationShowing = false; // default collaboration to on (it can be switch off with an org setting)
                scope.allowHideFromSearchEngineChoice = false; // if share to web there is an org setting which allows the user to do this or not
                scope.collabConfirmDescriptionId = "share-with-collab-confirmation-" + scope.$id;
                scope.showExport = scope.shareType === "asset";

                var shareContainer = element[0].getElementsByClassName("share")[0];
                animationHelperService.applyAnimationClass(shareContainer, "animate--fade-in").then(function () {
                    scope.$broadcast("focusInput");
                });

                scope.tooltips = {
                    webShare: scope.multiLanguageService.getString("tips.sharing.share_web.content"),
                    shareWithUsers: scope.multiLanguageService.getString("tips.sharing.auto_complete.content"),
                    shareePermissions: scope.multiLanguageService.getString("tips.sharing.recipient_abilities.content")
                };

                $http.get(baseUrlsFactory.api_base_url + "Share/IsCollaborationDisabled").then(function (response) {
                    scope.collaborationEnabled = response.data !== true;
                    if (scope.collaborationEnabled === false) {
                        scope.share.collaborate = false;
                        scope.collaborationShowing = false;
                    } else {
                        scope.collaborationShowing = true;
                    }
                });

                $http.get(baseUrlsFactory.api_base_url + "Share/DoesUserHaveChoiceToHideFromSearchEngines").then(
                    function (response) {
                        scope.allowHideFromSearchEngineChoice = response.data === true;
                    },
                    function () {
                        scope.allowHideFromSearchEngineChoice = false;
                    }
                );

                bindWatchers();
                if (scope.assetsWithLinks && scope.assetsWithLinks.length) {
                    scope._assetIds = scope.assetsWithLinks.selectOne("Id");
                    assetMainTypes = scope.assetsWithLinks.selectOne("MainType");
                } else {
                    scope._assetIds = scope.assetIds;

                    var removeEvent = scope.$on("updateAssetMainTypes", function (event, data) {
                        assetMainTypes = data;
                        scope.setCopyOption();
                        removeEvent();
                    });
                }

                // share info
                if (scope.changingExisting) {
                    var collaboratorObjs = scope.collaboratorIds.map(function (c) {
                        return { Id: c };
                    });
                    var thisShareIsDeletable = !DeletableShareService.getNonDeletableShareIds([scope.changingExisting], collaboratorObjs).some(function (s) {
                        return s === scope.changingExisting.ShareInfo.Id;
                    });
                    scope.saveStatus = "saved";

                    scope.shareUserRecipients = scope.changingExisting.Sharees || [];

                    // Autocomplete model to view the users on the form
                    angular.forEach(scope.changingExisting.Sharees, function (sharee) {
                        scope.sharees.push({
                            UserId: sharee.Id,
                            IsExternal: sharee.IsExternal,
                            IconUrl: baseUrlsFactory.api_base_url + "File/TransmitProfilePic?id=" + sharee.ProfileImageId + "&x=32&y=32",
                            Title: sharee.Forename + " " + sharee.Surname,
                            SubTitle: sharee.Email,
                            canRemoveFromShare: (!scope.collaboratorIds.contains(sharee.Id) && scope.changingExisting.ShareInfo.AllSharees.contains(sharee.Id)) || thisShareIsDeletable
                        });
                    });

                    scope.collabOptionDisabled = scope.sharees.some(function (s) {
                        return !s.canRemoveFromShare;
                    });

                    // External contacts
                    scope.shareContactRecipients = [];

                    var expiryDate = new Date(scope.changingExisting.ShareInfo.SharedUntil);
                    scope.share = {
                        hasExpiration: expiryDate.getUTCFullYear() < 2100,
                        expiresIn: expiryDate.getUTCFullYear() < 2100 ? "date" : "week",
                        expiration: expiryDate.getUTCFullYear() < 2100 ? expiryDate : Date.now(),
                        message: scope.changingExisting.ShareInfo.Message
                    };

                    if (scope.changingExisting.ShareInfo.SharedWithPermissions) {
                        scope.share.canLeaveComments = scope.changingExisting.ShareInfo.SharedWithPermissions.contains("Comment");
                        scope.share.canTakeACopy = scope.changingExisting.ShareInfo.SharedWithPermissions.contains("Copy");
                        scope.share.canExport = scope.changingExisting.ShareInfo.SharedWithPermissions.contains("Export");
                        scope.share.copyCriteriaOnly = "false";
                        scope.share.confirmCopy = scope.share.copyCriteriaOnly === "false";
                        scope.share.hideFromSearchEngines = scope.changingExisting.ShareInfo.SharedWithPermissions.contains("IsPrivate");
                        scope.share.collaborate = scope.changingExisting.ShareInfo.Collaborative; //TODO: Remove later!
                    }

                    scope.storedObject = angular.copy(scope.share);
                } else {
                    // base init
                    scope.shareUserRecipients = [];
                    scope.shareContactRecipients = [];

                    scope.share = {
                        canLeaveComments: false,
                        canTakeACopy: false,
                        copyCriteriaOnly: "false",
                        confirmCopy: false,
                        canExport: false,
                        hideFromSearchEngines: true,
                        hasExpiration: false,
                        expiresIn: "week",
                        expiration: Date.now(),
                        message: "",
                        collaborate: false //TODO: Remove later!
                    };
                }

                // Setup visible permissions
                scope.setCopyOption = function () {
                    if (assetMainTypes.containsAny(["AssetCollectionWrapper", "ActivityLog"])) {
                        scope.showExtendedCopyOptions = scope.share.canTakeACopy;
                        if (scope.changingExisting) {
                            scope.share.copyCriteriaOnly = scope.changingExisting.ShareInfo.SharedWithPermissions.contains("CopyLinked") ? "false" : "true"; // radio button value
                        }
                    }

                    if (scope.showExtendedCopyOptions) {
                        // tslint:disable-next-line:triple-equals
                        if (extendedCopyWatch == undefined) {
                            var extendedCopyWatch = scope.$watch("share.copyCriteriaOnly", function (newVal) {
                                if (newVal === "true") {
                                    scope.share.confirmCopy = false;
                                }
                            });
                        }

                        if (!scope.share.canTakeACopy) {
                            scope.share.copyCriteriaOnly = "true";
                        }
                    }

                    // set the asset type for the hint text
                    scope.assetType = assetMainTypes.contains("AssetCollectionWrapper") ? helperService.friendlyMainType("AssetCollectionWrapper") : helperService.friendlyMainType("ActivityLog");
                    scope.permissionHint = multiLanguageService.getString("sharing.permissions.copy_criteria_hint", { assetType: scope.assetType });
                };

                if (assetMainTypes.length) {
                    scope.setCopyOption();
                }

                switch (scope.shareType) {
                    case "asset":
                        scope.sharingModelSubTitle = $rootScope.multiLanguageService.getString("sharing.labels.share_the_following_asset");
                        break;
                    case "resource":
                        scope.sharingModelSubTitle = $rootScope.multiLanguageService.getString("sharing.labels.share_the_following_resource");
                        break;
                    case "workbook":
                        scope.sharingModelSubTitle = $rootScope.multiLanguageService.getString("sharing.labels.share_the_following_workbook");
                        break;
                    case "template":
                        scope.sharingModelSubTitle = $rootScope.multiLanguageService.getString("sharing.labels.share_the_following_template");
                        break;
                    default:
                        scope.sharingModelSubTitle = $rootScope.multiLanguageService.getString("sharing.labels.share_the_following");
                }

                scope.onCollaborationSelect = function (collaborationState) {
                    // deselect 'share.hasExpiration' option in case it was selected
                    scope.share.hasExpiration = false;

                    if (collaborationState === true) {
                        // select these options because collaboration implies them to be true
                        scope.share.canLeaveComments = true;
                        scope.share.canTakeACopy = true;
                        scope.share.canExport = true;
                    } else {
                        scope.share.canLeaveComments = false;
                        scope.share.canTakeACopy = false;
                        scope.share.canExport = false;
                    }
                };

                function bindWatchers() {
                    scope.$watch("sharees", onShareesWatch, true);
                }

                function onShareesWatch(newVal) {
                    scope.hasExternal = onShareesUpdate(newVal);
                }

                function onShareesUpdate(sharees) {
                    if (sharees) {
                        return sharees.some(function (user) {
                            return user.IsExternal;
                        });
                    }

                    return false;
                }

                scope.confirmCollaboration = function () {
                    scope.collaborationConfirmation = true;
                    scope.showTopLevelAssetWithLinks = false;
                    scope.share.hasExpiration = false;

                    if (scope.modalInterface) {
                        scope.modalInterface.updateTitle(scope.multiLanguageService.getString("sharing.collaboration.modal.title"));
                        scope.modalInterface.updateDescribedBy(scope.collabConfirmDescriptionId);
                    }
                };

                scope.onDateChange = function (date) {
                    scope.share.expiration = date;
                };

                scope.shareAsset = function () {
                    if (scope.shareMode === "web") {
                        scope.saveStatus = "saving";
                    } else {
                        // get the overlay at this point... tried caching it at the start but wasn't reliable
                        var overlay = angular.element(document.getElementsByClassName("builder-canvas-dark-layer")[0]);
                        overlay.addClass("show-overlay");
                    }

                    var shareId = scope.changingExisting ? scope.changingExisting.ShareInfo.Id : null;
                    var assetIds = scope._assetIds;
                    var userIds = scope.shareUserRecipients.selectOne("Id");
                    var contactIds = scope.shareContactRecipients.selectOne("Id");
                    var expiration = null;
                    if (scope.selectedWorkspace && scope.selectedAssignment) {
                        var workspaceId = scope.selectedWorkspace.Id || "";
                        var assignmentId = scope.selectedAssignment.Id || "";
                    }

                    function newDate(date) {
                        date = date || new Date();
                        return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59);
                    }

                    if (scope.share.hasExpiration) {
                        if (scope.share.expiresIn === "week") {
                            expiration = newDate();
                            expiration.setDate(expiration.getDate() + 7);
                        } else if (scope.share.expiresIn === "month") {
                            expiration = newDate();
                            expiration.setMonth(expiration.getMonth() + 1);
                        } else if (scope.share.expiresIn === "date") {
                            expiration = newDate(new Date(scope.share.expiration));
                        }
                    }

                    if (scope.noModal === undefined && scope.shareMode !== "web") {
                        scope.$emit("hideModal");
                    }

                    // if collaboration is disabled ensure it is turned off before save
                    if (scope.collaborationEnabled === false) {
                        scope.share.collaborate = false;
                        scope.collaborationShowing = false;
                    }

                    const sharePromise = shareService.shareAssets(
                        { shareId: shareId, assetIds: assetIds, userIds: userIds, contactIds: contactIds, workspaceId: workspaceId, assignmentId: assignmentId },
                        {
                            canLeaveComments: scope.share.canLeaveComments,
                            canTakeCopy: scope.share.canTakeACopy,
                            copyCriteriaOnly: scope.share.copyCriteriaOnly,
                            hideFromSearchEngines: scope.share.hideFromSearchEngines,
                            expiration: expiration,
                            collaborate: scope.share.collaborate,
                            canExport: scope.share.canExport
                        },
                        scope.shareMode,
                        scope.share.message
                    );

                    sharePromise.then(function () {
                        if (scope.shareMode === "web") {
                            saving_share_el.addClass("action-complete");
                            // Show the successful share
                            $timeout(function () {
                                saving_share_el.removeClass("action-complete");
                                scope.saveStatus = "saved";
                                scope.storedObject = angular.copy(scope.share);
                                $rootScope.$broadcast("shareSuccessful", assetIds);
                            }, 1000);
                        } else {
                            // Allow modal to close
                            $timeout(function () {
                                overlay.addClass("share-complete");
                                // Show the successful share
                                $timeout(function () {
                                    overlay.removeClass("share-complete");
                                    overlay.removeClass("show-overlay");
                                    $rootScope.$broadcast("shareSuccessful", assetIds);

                                    scope.shareStatusService.notifyShareUpdate(scope.shareMode, assetIds);

                                    //Redirect to view mode if we are submitting to a Workspace, LockAssetsOnSubmission is true and we are currently in the builder for the asset.
                                    if (assignmentId && scope.selectedAssignment.LockAssetsOnSubmission) {
                                        var redirectAsset = scope.getSubmittedAssetForRedirect(assetIds, assetMainTypes);
                                        if (redirectAsset) {
                                            $location.path(urlService.createUrl(redirectAsset.id, redirectAsset.mainType, true)).replace();
                                        }
                                    }
                                }, 1000);
                            }, 1000);
                        }
                    });
                };

                /**
                 * @param {string} assetIds
                 * @param {mainType} mainTypes
                 * @returns {{id: string, mainType: string} | null}
                 */
                scope.getSubmittedAssetForRedirect = function (assetIds, mainTypes) {
                    var assetId = "";
                    var mainType = "";

                    for (var i = 0, l = assetIds.length; i < l; i++) {
                        assetId = assetIds[i];
                        mainType = mainTypes[i];

                        if (urlService.checkIfOnBuilderForAsset(assetId, mainType)) {
                            return { id: assetId, mainType: mainType };
                        }
                    }

                    return null;
                };

                scope.isDirty = function () {
                    if (scope.saveStatus === "saving") {
                        return false;
                    }

                    if (!scope.shareMode || scope.shareMode === "users") {
                        // You must agree to allow the recipients to copy everything
                        if (scope.showExtendedCopyOptions && scope.share.copyCriteriaOnly === "false" && !scope.share.confirmCopy) {
                            return false;
                        }
                        // You must have people to share with
                        return scope.shareUserRecipients.length + scope.shareContactRecipients.length > 0;
                    }

                    if (scope.shareMode === "web") {
                        for (var item in scope.storedObject) {
                            // skip nested properties and expiration date because it is always reformatted by the page object
                            if (!scope.storedObject.hasOwnProperty(item)) {
                                continue;
                            }
                            // Specific check for expiry date change as the bound expiration date always gets reformatted
                            if (item === "expiration" && Object.prototype.toString.call(scope.storedObject.expiration) === "[object Date]") {
                                if (scope.storedObject.expiration.getTime() !== scope.share.expiration.getTime()) {
                                    return true;
                                } else {
                                    continue;
                                }
                            }
                            // if the properties don't match then object is dirty
                            if (scope.storedObject[item] !== scope.share[item]) {
                                return true;
                            }
                        }
                    }
                    return scope.storedObject === undefined || scope.storedObject === null;
                };

                var getSubmissionLocations = (function () {
                    if (scope.shareMode === "assessment") {
                        scope.loadingLocations = true;
                        var submissionLocationPromise = shareService.getSubmissionLocations(scope._assetIds[0]);
                        submissionLocationPromise.then(function (response) {
                            scope.locations = response.data;
                            scope.loadingLocations = false;
                            var focus_el = element[0].getElementsByClassName("autofocus")[0];
                            if (focus_el) {
                                focus_el.focus();
                            }
                        });
                    }
                })();

                scope.updateSubmissionDeadlineInfo = function () {
                    var pebbleDate = $filter("pebbleDate");
                    scope.submissionDeadlineInfo = {
                        acceptingSubmissionsFrom: multiLanguageService.getString("pebble_date.time_on_date", {
                            time: pebbleDate(scope.selectedAssignment.AcceptSubmissionsFrom, "timeOnly", true),
                            date: pebbleDate(scope.selectedAssignment.AcceptSubmissionsFrom, undefined, true)
                        }),
                        submissionDeadline: multiLanguageService.getString("pebble_date.time_on_date", {
                            time: pebbleDate(scope.selectedAssignment.SubmissionDeadline, "timeOnly", true),
                            date: pebbleDate(scope.selectedAssignment.SubmissionDeadline, undefined, true)
                        }),
                        revisionDeadline: multiLanguageService.getString("pebble_date.time_on_date", {
                            time: pebbleDate(scope.selectedAssignment.RevisionDeadline, "timeOnly", true),
                            date: pebbleDate(scope.selectedAssignment.RevisionDeadline, undefined, true)
                        })
                    };
                };

                scope.selectWorkspace = function (workspaceId) {
                    for (var i = 0; i < scope.locations.length; i++) {
                        // tslint:disable-next-line:triple-equals
                        if (scope.locations[i].Id == workspaceId) {
                            scope.selectedWorkspace = scope.locations[i];
                            if (scope.selectedWorkspace.SubLocations.length === 1) {
                                scope.selectedAssignment = scope.selectedWorkspace.SubLocations[0];
                                scope.updateSubmissionDeadlineInfo();
                                setupRemainingDays(scope.selectedAssignment);
                            } else {
                                //null it out in case they go back and select a different workspace.
                                scope.selectedAssignment = null;
                            }
                            scope.share.agreeToTerms = false;
                            modal_dialog_wrapper.scrollTop = 0;
                            var focus_el = element[0].getElementsByClassName("autofocus")[0];
                            if (focus_el) {
                                focus_el.focus();
                            }
                            return;
                        }
                    }
                };

                scope.selectAssignment = function (assignmentId) {
                    for (var i = 0; i < scope.selectedWorkspace.SubLocations.length; i++) {
                        if (scope.selectedWorkspace.SubLocations[i].Id === assignmentId) {
                            scope.selectedAssignment = scope.selectedWorkspace.SubLocations[i];
                            scope.submissionCountInfo = multiLanguageService.getString("sharing.labels.submission_count_info", {
                                user_num_shares: scope.selectedAssignment.UserSubmissionCount,
                                total_num_shares: scope.selectedAssignment.SubmissionsPerUser,
                                count: scope.selectedAssignment.UserSubmissionCount
                            });
                            scope.updateSubmissionDeadlineInfo();
                            scope.share.agreeToTerms = false;

                            setupRemainingDays(scope.selectedAssignment);
                            modal_dialog_wrapper.scrollTop = 0;
                            var focus_el = element[0].getElementsByClassName("autofocus")[0];
                            if (focus_el) {
                                focus_el.focus();
                            }
                            return;
                        }
                    }
                };

                //This is pure filth.
                //But essential... thanks Lethal-DBiz.
                scope.processWarningMessage = function (warning, acceptedTypes) {
                    if (warning.indexOf("Only the following Asset types are accepted") > -1) {
                        var newWarning = "Only the following Asset types are accepted: <div class='accepted-types'>";
                        angular.forEach(acceptedTypes, function (acceptedType) {
                            // tslint:disable-next-line:triple-equals
                            var type = acceptedType.SubType != "" ? acceptedType.SubType : acceptedType.MainType;

                            //Strip out anything before the actual asset type.
                            //Again... filth.
                            type = type.replace("Conversation", "Summary");
                            type = type.replace("Document/", "");
                            type = type.replace("Personal/AboutMe", "");
                            type = type.replace("Academic/AboutMe", "");
                            type = type.replace("Professional/AboutMe", "");

                            newWarning += "<div class='accepted-type'><span class='icon-" + type.toLowerCase() + "'></span> " + type.replace(/([a-z])([A-Z])/g, "$1 $2") + "</div>";
                        });
                        return (newWarning += "</div>");
                    } else {
                        return $sce.trustAsHtml(warning);
                    }
                };

                var setupRemainingDays = function (assignment) {
                    var now = new Date();
                    now.setHours(0, 0, 0, 0);

                    var sharedAfter = mydiff(now, datePickerHelper.normalizeDate(assignment.AcceptSubmissionsFrom, true), "days");
                    if (sharedAfter < 0) {
                        sharedAfter = undefined;
                    }
                    var sharedBy = mydiff(now, datePickerHelper.normalizeDate(assignment.SubmissionDeadline, true), "days");
                    if (sharedBy < 0) {
                        sharedBy = undefined;
                    }
                    var revisedUntil = mydiff(now, datePickerHelper.normalizeDate(assignment.RevisionDeadline, true), "days");
                    if (revisedUntil < 0) {
                        revisedUntil = undefined;
                    }

                    scope.remainingDays = {
                        sharedAfter: sharedAfter,
                        sharedBy: sharedBy,
                        revisedUntil: revisedUntil
                    };
                };

                scope.cancel = function () {
                    scope.$emit("hideModal");
                };

                scope.back = function () {
                    if (scope.selectedWorkspace.SubLocations.length > 1 && scope.selectedAssignment) {
                        scope.selectedAssignment = null;
                    } else {
                        scope.selectedWorkspace = null;
                    }
                    modal_dialog_wrapper.scrollTop = 0;
                };

                // I would REALLY like to see this available as a toolkit function somewhere
                function mydiff(date1, date2, interval) {
                    var second = 1000,
                        minute = second * 60,
                        hour = minute * 60,
                        day = hour * 24,
                        week = day * 7;
                    date1 = new Date(date1);
                    date2 = new Date(date2);
                    var timediff = date2 - date1;
                    if (isNaN(timediff)) {
                        return undefined;
                    }
                    switch (interval) {
                        case "years":
                            return date2.getFullYear() - date1.getFullYear();
                        case "months":
                            return (date2.getFullYear() * 12 + date2.getMonth())(date2.getFullYear() * 12 + date2.getMonth()) - (date1.getFullYear() * 12 + date1.getMonth());
                        case "weeks":
                            return Math.floor(timediff / week);
                        case "days":
                            return Math.floor(timediff / day);
                        case "hours":
                            return Math.floor(timediff / hour);
                        case "minutes":
                            return Math.floor(timediff / minute);
                        case "seconds":
                            return Math.floor(timediff / second);
                        default:
                            return undefined;
                    }
                }
            }
        };
    }
]);
