import { angularAMD } from "@pebblepad/amd";
import { tracker } from "@pjs/analytics";
import { first, merge, from, tap, combineLatest, finalise } from "@pjs/observables";
import "../../assetEndpointService/assetEndpoint.service";
import "../../builder/builderToolbar/toolbar-tools/components/dropDownOption.component";
import "../../builder/builderToolbar/toolbar-tools/components/radioButtonOption.component";
import "../../builderBlocks/blockEmbed/embedConsent/embedConsent.service";
import "../../user/user.service";
import "../../utilities/baseUrlsFactory";
import "../../loadingSpinner/loadingSpinner";

class UserSettingsService {
    constructor($q, assetEndpointService, multiLanguageService, modal, baseUrlsFactory, embedConsentService) {
        this._$q = $q;
        this._modal = modal;
        this._baseUrlsFactory = baseUrlsFactory;
        this.multiLanguageService = multiLanguageService;
        this._assetEndpointService = assetEndpointService;
        this._embedConsentService = embedConsentService;
        this._consentValues = {
            consented: "consented",
            notConsented: "notconsented"
        };
        this._loading = false;
    }

    showUserSettingsModal() {
        combineLatest([from(this._assetEndpointService.getUserSettings()), this._embedConsentService.consent()])
            .pipe(first())
            .subscribe(([userSettingsResponse, hasConsented]) => {
                const modalProps = this._getUserSettingsModalProps(userSettingsResponse, hasConsented);
                this._modal.launch(this._baseUrlsFactory.shared_component_base_url + "userSettings/templates/user-settings.html", modalProps);
            });
    }

    updateUserSettings(originalSettings, settings, hideModel) {
        const updateObservables = [];
        this._loading = true;

        if (originalSettings.userLanguage !== settings.userLanguage) {
            updateObservables.push(from(this._assetEndpointService.updateUserSettings({ Language: settings.userLanguage })).pipe(tap(() => location.reload())));
            tracker.trackEvent("User Settings", "Click Language dropdown", "Save language change");
        }

        if (originalSettings.consentValue !== settings.consentValue) {
            updateObservables.push(settings.consentValue === this._consentValues.consented ? this._embedConsentService.grantConsent() : this._embedConsentService.revokeConsent());
            tracker.trackEvent("User Settings", "Click cookie radio buttons", "Save cookie change");
        }

        merge(...updateObservables)
            .pipe(
                finalise(() => {
                    this._loading = false;
                    hideModel();
                })
            )
            .subscribe({
                error: () => {
                    this._modal.launch(this._baseUrlsFactory.shared_component_base_url + "spaMenu/okmessage.html", this._getErrorModalProps());
                }
            });
    }

    _getUserSettingsModalProps(userSettingsResponse, hasConsented) {
        const originalSettings = {
            userLanguage: userSettingsResponse.data.Language,
            consentValue: hasConsented ? this._consentValues.consented : this._consentValues.notConsented
        };

        const modalProps = {
            languages: userSettingsResponse.data.PossibleLanguages,
            settings: { ...originalSettings },
            settingsLanguageSet: (key, value) => {
                modalProps.settings.userLanguage = value;
            },
            consentChanged: (value) => {
                modalProps.settings.consentValue = value;
            },
            confirm: (updatedSettings, hideModal) => this.updateUserSettings(originalSettings, updatedSettings, hideModal),
            consentOptions: [
                {
                    key: this._consentValues.consented,
                    label: this.multiLanguageService.getString("user_settings.embed_consent_options.consent_yes")
                },
                {
                    key: this._consentValues.notConsented,
                    label: this.multiLanguageService.getString("user_settings.embed_consent_options.consent_no")
                }
            ],
            showLoading: () => this._loading
        };

        return modalProps;
    }

    _getErrorModalProps() {
        return {
            confirmMessage: this.multiLanguageService.getString("user_settings.something_went_wrong")
        };
    }
}

UserSettingsService.$inject = ["$q", "AssetEndpointService", "multiLanguageService", "modal", "baseUrlsFactory", "embedConsentService"];
angularAMD.service("UserSettingsService", UserSettingsService);

export { UserSettingsService };
