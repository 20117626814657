"use strict";
import { ASSESSMENT_CONSTANTS } from "../constants/assessment.constants"; // todo: check if any problem with this?. If so where should that feedbacktype logic go?
import { angularAMD } from "@pebblepad/amd";
import "../utilities/urlService";

angularAMD.provider("AssetEndpointService", AssetEndpointServiceProvider);
function AssetEndpointServiceProvider() {
    this.endPoints = null;
    this.extraParams = null;

    this.setEndPoints = function (endPoints) {
        this.endPoints = endPoints;
    }.bind(this);

    this.setExtraParameters = function (params) {
        this.extraParams = params;
    }.bind(this);

    this.$get = [
        "$http",
        "$routeParams",
        "$route",
        "urlService",
        function ($http, $routeParams, $route, urlService) {
            return new AssetEndpointService($http, $routeParams, $route, urlService, this.endPoints, this.extraParams);
        }.bind(this)
    ];
}

function AssetEndpointService($http, $routeParams, $route, urlService, endPoints, extraParams) {
    this.httpRequester = $http;
    this.$route = $route;
    this.$routeParams = $routeParams;
    this.endPoints = endPoints;
    this.extraParams = extraParams;
    this.urlService = urlService;
}

/***
 * Check permission for an asset
 * @param {Object} params
 * @returns {Promise<bool>}
 */
AssetEndpointService.prototype.doIHavePermission = function (params) {
    params = this.mapExtraParameters(params);
    return this.httpRequester.post(this.endPoints.doIHavePermission, null, { params: params }).then(function (response) {
        return response.data;
    });
};

/***
 * Check the permission for a submission and the current user
 * @param {Object} params
 * @returns {Promise<bool>}
 */
AssetEndpointService.prototype.checkSubmissionPermission = function (params) {
    return this.httpRequester.get(this.replaceRouteValuesWithParams(this.endPoints.checkSubmissionPermission, this.mapExtraParameters(params)), null).then(function (response) {
        return response.data;
    });
};

/***
 * Get file stream link
 * @param {Object} params
 * @returns {Promise} - Resolves with {Object<Key: string, Value: string>}
 */
AssetEndpointService.prototype.getDirectStreamLink = function (params) {
    params = this.mapExtraParameters(params);
    return this.httpRequester.get(this.endPoints.getDirectStreamLink, { params: params }).then(function (response) {
        return response;
    });
};

/***
 * Get media stream information
 * @param {Object} params
 * @returns {Promise<Object>}
 */
AssetEndpointService.prototype.transmitImage = function (params) {
    return this.httpRequester.get(this.endPoints.transmitImage, { params: params }).then(function (response) {
        return response;
    });
};

/***
 * @param {Object} params
 * @returns {string}
 */
AssetEndpointService.prototype.transmitImageUrl = function (params) {
    params = this.mapExtraParameters(params);

    const keys = Object.keys(params);
    const paramArray = [];

    for (let i = 0; i < keys.length; i++) {
        const key = keys[i];
        if (key !== void 0 && params[key] !== void 0) {
            paramArray.push({ key: key, value: params[key] });
        }
    }

    return this.urlService.addParameters(this.endPoints.transmitImage, paramArray);
};

/***
 * @param {Object} params
 * @returns {string}
 */
AssetEndpointService.prototype.getFeedback = function (params) {
    return this.httpRequester.get(this.replaceRouteValuesWithParams(this.endPoints.feedback, this.mapExtraParameters(params)), null).then(function (response) {
        return response;
    });
};

AssetEndpointService.prototype.getSingleFeedback = function (params) {
    return this.httpRequester.get(this.replaceRouteValuesWithParams(this.endPoints.feedbackWithId, this.mapExtraParameters(params)), null).then(function (response) {
        return response;
    });
};

AssetEndpointService.prototype.saveMultipleFeedback = function (params, feedback) {
    return this.httpRequester.put(this.replaceRouteValuesWithParams(this.endPoints.feedbackWithType, this.mapExtraParameters(params)), feedback).then(function (response) {
        return response;
    });
};

AssetEndpointService.prototype.addFeedback = function (params, feedback) {
    if (
        params.feedbackType === ASSESSMENT_CONSTANTS.FEEDBACK_TYPES.APPROVAL_LEVEL_ONE ||
        params.feedbackType === ASSESSMENT_CONSTANTS.FEEDBACK_TYPES.APPROVAL_LEVEL_TWO ||
        params.feedbackType === ASSESSMENT_CONSTANTS.FEEDBACK_TYPES.APPROVAL_LEVEL_THREE
    ) {
        params.feedbackType = ASSESSMENT_CONSTANTS.FEEDBACK_TYPES.APPROVAL;
    }
    return this.httpRequester.post(this.replaceRouteValuesWithParams(this.endPoints.feedbackWithType, this.mapExtraParameters(params)), feedback).then(function (response) {
        return response;
    });
};

AssetEndpointService.prototype.editFeedback = function (params, feedback) {
    return this.httpRequester.patch(this.replaceRouteValuesWithParams(this.endPoints.feedbackWithType, this.mapExtraParameters(params)), feedback).then(function (response) {
        return response;
    });
};

AssetEndpointService.prototype.deleteFeedback = function (params) {
    return this.httpRequester.delete(this.replaceRouteValuesWithParams(this.endPoints.feedbackWithId, this.mapExtraParameters(params)), null).then(function (response) {
        return response;
    });
};

AssetEndpointService.prototype.getAssessorPage = function (params) {
    return this.httpRequester.get(this.replaceRouteValuesWithParams(this.endPoints.assessorPage, this.mapExtraParameters(params)), null).then(function (response) {
        return response;
    });
};

AssetEndpointService.prototype.getAnswerHistory = function (params) {
    return this.httpRequester.get(this.replaceRouteValuesWithParams(this.endPoints.getAnswerHistory, this.mapExtraParameters(params)), null).then(function (response) {
        return response;
    });
};

/***
 * @returns {string}
 */
AssetEndpointService.prototype.getFeedbackTemplates = function () {
    return this.httpRequester.get(this.replaceRouteValuesWithParams(this.endPoints.feedbackTemplate, this.mapExtraParameters([])), null).then(function (response) {
        return response;
    });
};

AssetEndpointService.prototype.getMappingDetails = function (assetId, submissionId, timeslice) {
    const params = {
        assetId: assetId,
        submissionId: submissionId,
        timeslice: timeslice !== undefined ? timeslice : 0
    };

    return this.httpRequester.get(this.replaceRouteValuesWithParams(this.endPoints.getMappingDetails, this.mapExtraParameters(params)), null).then((response) => {
        return response;
    });
};

AssetEndpointService.prototype.getApprovalOptions = function (params) {
    return this.httpRequester.get(this.replaceRouteValuesWithParams(this.endPoints.approvalStatusTexts, this.mapExtraParameters(params)), null).then(function (response) {
        return response;
    });
};

AssetEndpointService.prototype.getApprovalLevelSettings = function () {
    return this.httpRequester.get(this.replaceRouteValuesWithParams(this.endPoints.approvalSettings, this.mapExtraParameters([])), null).then(function (response) {
        return response;
    });
};

AssetEndpointService.prototype.saveApproval = function (params, feedback) {
    return this.httpRequester.post(this.replaceRouteValuesWithParams(this.endPoints.feedbackWithType, this.mapExtraParameters(params)), feedback).then((response) => {
        return response;
    });
};

AssetEndpointService.prototype.getPageVerificationSetting = function (params) {
    return this.httpRequester.get(this.replaceRouteValuesWithParams(this.endPoints.getPageVerificationSetting, this.mapExtraParameters(params))).then((response) => {
        return response;
    });
};

AssetEndpointService.prototype.getStandardsOverviewData = function (assetId, pageId, submissionId, timeslice, filters) {
    return this.httpRequester.post(
        this.replaceRouteValuesWithParams(this.endPoints.getStandardsOverviewData, { assetId: assetId, pageId: pageId, submissionId: submissionId, timeslice: timeslice }),
        filters
    );
};

AssetEndpointService.prototype.removeMapping = function (data) {
    return this.httpRequester.delete(this.replaceRouteValuesWithParams(this.endPoints.removeMapping, this.mapExtraParameters(data)), null).then((response) => {
        return response;
    });
};

AssetEndpointService.prototype.getAssessmentOverview = function (assetId, pageId, submissionId, timeslice, filters) {
    return this.httpRequester.post(
        this.replaceRouteValuesWithParams(this.endPoints.getAssessmentOverview, { assetId: assetId, pageId: pageId, submissionId: submissionId, timeslice: timeslice }),
        filters
    );
};

AssetEndpointService.prototype.getStandardsPageOverviews = function (assetId, pageId, submissionId, timeslice, filters) {
    return this.httpRequester.post(
        this.replaceRouteValuesWithParams(this.endPoints.getStandardsPageOverviews, { assetId: assetId, pageId: pageId, submissionId: submissionId, timeslice: timeslice }),
        filters
    );
};

AssetEndpointService.prototype.getStandardsElementsData = function (assetId, responseId, submissionId, timeslice, filters) {
    return this.httpRequester.post(
        this.replaceRouteValuesWithParams(this.endPoints.getStandardsElementsData, { assetId: assetId, responseId: responseId, submissionId: submissionId, timeslice: timeslice }),
        filters
    );
};

AssetEndpointService.prototype.getCreatorPermission = function (permission, assetId) {
    return this.httpRequester.get(this.replaceRouteValuesWithParams(this.endPoints.getCreatorPermission, { permission: permission, assetId: assetId }), null);
};

AssetEndpointService.prototype.getCurrentProgress = function (assetId, submissionId, timeslice) {
    return this.httpRequester
        .get(this.replaceRouteValuesWithParams(this.endPoints.getCurrentProgress, { assetId: assetId, submissionId: submissionId, timeslice: timeslice }), null)
        .then((response) => {
            return response;
        });
};

AssetEndpointService.prototype.isAsset = function (assetId, mainType) {
    return this.httpRequester.get(this.replaceRouteValuesWithParams(this.endPoints.isAsset, { assetId: assetId, mainType: mainType }), null).then((response) => {
        return response;
    });
};

AssetEndpointService.prototype.getAssetWithAvailableActions = function (assetId) {
    return this.httpRequester.get(this.replaceRouteValuesWithParams(this.endPoints.getAssetWithAvailableActions, { assetId: assetId }), null).then((response) => {
        return response;
    });
};

AssetEndpointService.prototype.getWorkflowState = function (assetId, templateId, submissionId) {
    return this.httpRequester
        .get(this.replaceRouteValuesWithParams(this.endPoints.getWorkflowState, { assetId: assetId, templateId: templateId, submissionId: submissionId }), null)
        .then((response) => {
            return response;
        });
};

AssetEndpointService.prototype.updateWorkflowState = function (assetId, templateId, target, actionType, submissionId) {
    return this.httpRequester
        .get(
            this.replaceRouteValuesWithParams(this.endPoints.updateWorkflowState, { assetId: assetId, templateId: templateId, target: target, actionType: actionType, submissionId: submissionId }),
            null
        )
        .then((response) => {
            return response;
        });
};

AssetEndpointService.prototype.addWorkflowAdditionalInfo = function (assetId, postData, submissionId) {
    return this.httpRequester.post(this.replaceRouteValuesWithParams(this.endPoints.addWorkflowAdditionalInfo, { assetId: assetId, submissionId: submissionId }), postData);
};

AssetEndpointService.prototype.removeWorkflowAdditionalInfo = function (assetId, postData, submissionId) {
    return this.httpRequester.post(this.replaceRouteValuesWithParams(this.endPoints.removeWorkflowAdditionalInfo, { assetId: assetId, submissionId: submissionId }), postData);
};

AssetEndpointService.prototype.getComment = function (assetId, commentId, submissionId) {
    return this.httpRequester.get(this.replaceRouteValuesWithParams(this.endPoints.getComment, { assetId: assetId, commentId: commentId, submissionId: submissionId }), null);
};

AssetEndpointService.prototype.getComments = function (assetId, submissionId, answerId) {
    return this.httpRequester.get(this.replaceRouteValuesWithParams(this.endPoints.getComments, { assetId: assetId, submissionId: submissionId, answerId: answerId }), null);
};

AssetEndpointService.prototype.addNewOrUpdateComment = function (saveCommentRequest, submissionId) {
    return this.httpRequester.post(this.replaceRouteValuesWithParams(this.endPoints.addNewOrUpdateComment, { submissionId: submissionId }), saveCommentRequest);
};

AssetEndpointService.prototype.deleteComment = function (assetId, submissionId, commentId) {
    return this.httpRequester.delete(this.replaceRouteValuesWithParams(this.endPoints.deleteComment, { assetId: assetId, submissionId: submissionId, commentId: commentId }), null);
};

/***
 * @returns {Object}
 */
AssetEndpointService.prototype.getLoggedInUserData = function (submissionId, timeslice) {
    const params = {
        submissionId: submissionId,
        timeslice: timeslice !== undefined ? timeslice : 0
    };

    const url =
        this.endPoints.getLoggedInUserData.indexOf("submissionId") > -1
            ? this.replaceRouteValuesWithParams(this.endPoints.getLoggedInUserData, this.mapExtraParameters(params))
            : this.endPoints.getLoggedInUserData;
    this.httpRequester.defaults.withCredentials = true;
    return this.httpRequester.get(url, null).then(function (response) {
        return response.data && response.data.UserId ? response.data : null;
    });
};

AssetEndpointService.prototype.getEditStatusForAssets = function (assetIds) {
    const params = this.mapExtraParameters({});
    return this.httpRequester.post(this.endPoints.getEditStatusForAssets, assetIds, { params: params }).then(function (response) {
        return response;
    });
};

AssetEndpointService.prototype.getAssetAndCheckPermission = function (params) {
    params = this.mapExtraParameters(params);
    return this.httpRequester.get(this.endPoints.getAssetAndCheckPermission, { params: params });
};

AssetEndpointService.prototype.getBuilder = function (params) {
    params = this.mapExtraParameters(params);
    return this.httpRequester.get(this.endPoints.getBuilder, { params: params });
};

AssetEndpointService.prototype.getPublicAssetReference = function (id) {
    const params = this.mapExtraParameters({ assetId: id });
    return this.httpRequester.get(this.endPoints.getPublicAssetReference, { params: params });
};

AssetEndpointService.prototype.createFeedbackTemplateResponse = function (params) {
    return this.httpRequester.post(this.replaceRouteValuesWithParams(this.endPoints.feedbackTemplateWithId, this.mapExtraParameters(params)), null);
};

AssetEndpointService.prototype.saveOrUpdate = function (params) {
    params = this.mapExtraParameters(params);
    return this.httpRequester.post(this.endPoints.saveOrUpdate, params);
};

AssetEndpointService.prototype.movePortfolioPage = function (assetId, pageMoveData) {
    const route = this.replaceRouteValuesWithParams(this.endPoints.movePortfolioPage, { assetId: assetId, pageId: pageMoveData.Id });
    return this.httpRequester.post(route, pageMoveData);
};

AssetEndpointService.prototype.getUploadUrl = function () {
    return this.replaceRouteValuesWithParams(this.endPoints.upload, this.mapExtraParameters({}));
};

AssetEndpointService.prototype.saveUpload = function (data, params) {
    const url = this.replaceRouteValuesWithParams(this.endPoints.saveUpload, this.mapExtraParameters(params));
    return this.httpRequester.post(url, data);
};

AssetEndpointService.prototype.addNewOrUpdateReplyToFeedback = function (params, reply) {
    return this.httpRequester.post(this.replaceRouteValuesWithParams(this.endPoints.feedbackReply, this.mapExtraParameters(params)), reply);
};

AssetEndpointService.prototype.deleteReplyToFeedback = function (params) {
    return this.httpRequester.delete(this.replaceRouteValuesWithParams(this.endPoints.feedbackReplyWithId, this.mapExtraParameters(params)), null);
};

AssetEndpointService.prototype.getReplyToFeedback = function (params) {
    return this.httpRequester.get(this.replaceRouteValuesWithParams(this.endPoints.feedbackReplyWithId, this.mapExtraParameters(params)), null);
};

AssetEndpointService.prototype.convertAssetIdsToBlogPostViewModels = function (params) {
    return this.httpRequester.post(this.replaceRouteValuesWithParams(this.endPoints.convertAssetIdsToBlogPostViewModels, this.mapExtraParameters(params)), params);
};

AssetEndpointService.prototype.getElementDetails = function (assetId, elementId, timeslice) {
    return this.httpRequester.get(
        this.replaceRouteValuesWithParams(this.endPoints.getElementDetails, this.mapExtraParameters({ assetId: assetId, elementId: elementId, timeslice: timeslice !== "" ? timeslice : 0 }))
    );
};

AssetEndpointService.prototype.addEvidenceMapFromResponse = function (params) {
    return this.httpRequester.put(this.replaceRouteValuesWithParams(this.endPoints.addEvidenceMapFromResponse, this.mapExtraParameters(params)), params);
};

AssetEndpointService.prototype.addTextOnlyEvidence = function (params) {
    return this.httpRequester.post(this.replaceRouteValuesWithParams(this.endPoints.addTextOnlyEvidence, this.mapExtraParameters(params)), params);
};

AssetEndpointService.prototype.removeEvidenceMapFromResponse = function (data) {
    return this.httpRequester({
        method: "DELETE",
        url: this.endPoints.removeEvidenceMapFromResponse,
        data: data,
        headers: { "Content-type": "application/json" }
    });
};

AssetEndpointService.prototype.updateEvidenceJustificationFromResponse = function (data) {
    return this.httpRequester.post(this.endPoints.updateEvidenceJustificationFromResponse, data);
};

AssetEndpointService.prototype.getImageAltText = function (assetId) {
    return this.httpRequester.get(this.replaceRouteValuesWithParams(this.endPoints.getImageAltText, { assetId: assetId }));
};

AssetEndpointService.prototype.grantEmbeddedConsent = function () {
    return this.httpRequester.put(this.endPoints.consentToEmbeddedContent);
};

AssetEndpointService.prototype.revokeEmbeddedConsent = function () {
    return this.httpRequester.delete(this.endPoints.consentToEmbeddedContent);
};

AssetEndpointService.prototype.getFeaturesState = function () {
    return this.httpRequester.get(this.endPoints.getFeaturesEnabled);
};

AssetEndpointService.prototype.createDataFilter = function (assetId, filter) {
    return this.httpRequester.post(this.replaceRouteValuesWithParams(this.endPoints.createDataFilter, this.mapExtraParameters({ assetId: assetId })), filter);
};

AssetEndpointService.prototype.updateDataFilter = function (assetId, filterData) {
    return this.httpRequester.put(this.replaceRouteValuesWithParams(this.endPoints.updateDataFilter, this.mapExtraParameters({ assetId: assetId })), filterData);
};

AssetEndpointService.prototype.removeDataFilter = function (assetId, filterId) {
    return this.httpRequester.delete(this.replaceRouteValuesWithParams(this.endPoints.removeDataFilter, this.mapExtraParameters({ assetId: assetId, filterId: filterId })));
};

AssetEndpointService.prototype.getDataFilter = function (assetId) {
    return this.httpRequester.get(this.replaceRouteValuesWithParams(this.endPoints.getDataFilter, this.mapExtraParameters({ assetId: assetId })));
};

AssetEndpointService.prototype.procureWorkbookResponsePage = function (data, params) {
    const url = this.replaceRouteValuesWithParams(this.endPoints.procureWorkbookResponsePage, this.mapExtraParameters(params));
    return this.httpRequester.post(url, data).then((response) => response.data);
};

AssetEndpointService.prototype.updateWorkbookResponsePage = function (assetId, pageId, pageUpdate) {
    const url = this.replaceRouteValuesWithParams(this.endPoints.modifyWorkbookResponsePage, this.mapExtraParameters({ assetId: assetId, pageId: pageId }));
    return this.httpRequester.put(url, pageUpdate).then((response) => response.data);
};

AssetEndpointService.prototype.removeWorkbookResponsePage = function (assetId, pageId) {
    return this.httpRequester.delete(this.replaceRouteValuesWithParams(this.endPoints.modifyWorkbookResponsePage, this.mapExtraParameters({ assetId: assetId, pageId: pageId })));
};

AssetEndpointService.prototype.updatePortfolioPage = function (assetId, pageId, pageUpdate) {
    const url = this.replaceRouteValuesWithParams(this.endPoints.modifyPortfolioPage, this.mapExtraParameters({ assetId: assetId, pageId: pageId }));
    return this.httpRequester.put(url, pageUpdate).then((response) => response.data);
};

AssetEndpointService.prototype.removePortfolioPage = function (assetId, pageId) {
    return this.httpRequester.delete(this.replaceRouteValuesWithParams(this.endPoints.modifyPortfolioPage, this.mapExtraParameters({ assetId: assetId, pageId: pageId })));
};

AssetEndpointService.prototype.convertPortfolioPage = function (assetId, pageId) {
    return this.httpRequester.post(this.replaceRouteValuesWithParams(this.endPoints.convertPortfolioPage, this.mapExtraParameters({ assetId: assetId, pageId: pageId }))).then((response) => {
        return response.data;
    });
};

AssetEndpointService.prototype.updateUserSettings = function (settings) {
    return this.httpRequester.post(this.endPoints.updateUserSettings, settings);
};

AssetEndpointService.prototype.getUserSettings = function () {
    return this.httpRequester.get(this.endPoints.getUserSettings);
};

AssetEndpointService.prototype.isLockedByAnother = function (assetId) {
    return this.httpRequester.get(this.replaceRouteValuesWithParams(this.endPoints.isLockedByAnother, { assetId: assetId }));
};

AssetEndpointService.prototype.getBasicDetails = function (assetId) {
    return this.httpRequester.get(this.replaceRouteValuesWithParams(this.endPoints.getBasicDetails, { assetId: assetId }));
};

AssetEndpointService.prototype.exportToPdfUrl = function (assetId) {
    return this.replaceRouteValuesWithParams(this.endPoints.exportToPdf, this.mapExtraParameters({ assetId: assetId }));
};

/***
 * Adds additional parameters to a query string as defined in app.js
 * @param {Object} params
 * @returns {Object} params
 */
AssetEndpointService.prototype.mapExtraParameters = function (params) {
    if (!this.extraParams) {
        return params;
    }

    const keys = Object.keys(this.extraParams);
    for (let k = 0; k < keys.length; k++) {
        const key = keys[k];
        for (let i = 0; i < this.extraParams[key].length; i++) {
            let extraParam = this.extraParams[key][i];
            if (params[extraParam] === void 0) {
                params[extraParam] = this.$routeParams[extraParam];
            }
        }
    }

    return params;
};

/***
 * Replace {{replaceable}} terms in a route with values from a parameter object
 * @param {string} route - the URL with replaceable terms encased in double braces {{ }}
 * @param {Object} params - Object with parameter values, keys should match replaceable terms
 * @returns {string} route - API URL
 */
AssetEndpointService.prototype.replaceRouteValuesWithParams = function (route, params) {
    let keys = Object.keys(params);

    for (let i = 0; i < keys.length; i++) {
        let key = keys[i];

        if (route.indexOf("{{" + key + "}}") !== -1) {
            route = route.replace("{{" + key + "}}", params[key]);
        }
    }

    return route;
};
