import { angularAMD } from "@pebblepad/amd";
import { dataEncryptionService } from "../../utilities/dataEncryptionService";
import { ASSET_CONSTANTS } from "../../constants/asset.constants";
import { PAGE_ERROR } from "../../constants/pageError.constants";
import "../../utilities/baseUrlsFactory";
import "./dtoFactory";
import "./helperService";
import "../../multiLanguageService/multiLanguageService";
import "../../formComponents/services/formComponentsHelper";
import "../../extensions/arrayExtensions";
import "../../utilities/urlService";
import "../../utilities/helpers";
import "../../modal/services/modal";
import "../../localChanges/services/localChangesResolver";
import "../tables/services/tableDtoHelper.service";
import "../../assetLocking/services/assetLockingHelper.service";
import "../../overlay/services/overlayFactory";
import "../../assetEndpointService/assetEndpoint.service";
import "../../recoveryLogger/recoveryLogger";
import "../../localStorage/persistentObjectStore.service";
import "../../utilities/migrators/migratorRunner.provider";
import "../../assetPageHandler/assetPageHandler.factory";
import "../../assetStore/services/assetStoreService";
import { PageModelSearch } from "../../pageModelSearch/pageModelSearch";
import { AssetContext } from "../../assetContext/assetContext";
import { byPageId } from "../../pageModelSearch/helpers/ByPageId.function";
import { determinePageAssetId } from "../../pageModelSearch/helpers/DeterminePageAssetId.function";
import { withoutDuplicatePageResponsesFactory } from "../../pageModelSearch/helpers/WithoutDuplicatePageResponsesFactory.function";
import { withoutDuplicatePageAssetIdsFactory } from "../../pageModelSearch/helpers/WithoutDuplicatePageAssetIdsFactory.function";
import { withoutEmptyPageAssetIds } from "../../pageModelSearch/helpers/WithoutEmptyPageAssetIds.function";

angularAMD.factory("dataManagerService", [
    "$http",
    "$q",
    "$rootScope",
    "$location",
    "$interval",
    "$routeParams",
    "$filter",
    "$timeout",
    "baseUrlsFactory",
    "dtoFactory",
    "helperService",
    "multiLanguageService",
    "formComponentsHelper",
    "urlService",
    "helpers",
    "modal",
    "localChangesResolver",
    "tableDtoHelper",
    "AssetLockingHelper",
    "overlayFactory",
    "AssetEndpointService",
    "recoveryLogger",
    "persistentObjectStore",
    "migratorRunner",
    "assetPageHandlerFactory",
    "assetStoreService",
    function (
        $http,
        $q,
        $rootScope,
        $location,
        $interval,
        $routeParams,
        $filter,
        $timeout,
        baseUrlsFactory,
        dtoFactory,
        helperService,
        multiLanguageService,
        formComponentsHelper,
        urlService,
        helpers,
        modal,
        localChangesResolver,
        tableDtoHelper,
        AssetLockingHelper,
        overlayFactory,
        AssetEndpointService,
        recoveryLogger,
        persistentObjectStore,
        migratorRunner,
        assetPageHandlerFactory,
        assetStoreService
    ) {
        var user_id = null,
            DTO_TYPES = {
                ACTIVITY_LOG: "ActivityLog",
                BLOG: "Blog",
                COLLECTION_WRAPPER: "AssetCollectionWrapper",
                CV: "CV",
                FORM: "Form",
                FORM_RESPONSE: "FormResponse",
                POST: "Post",
                WEBFOLIO: "Webfolio",
                WEBFOLIO_PAGE: "WebfolioPage",
                WORKBOOK: "WorkBook",
                WORKBOOK_RESPONSE: "WorkBookResponse",
                TEMPLATE_BUILDER: "TemplateBuilder",
                WORKBOOK_BUILDER: "WorkbookBuilder"
            };
        /********************************************
         * Service
         ********************************************/

        var dataManagerService = {
            dtos: [],
            current_navigation: undefined,
            current_page: undefined,
            primary_id: undefined,
            poll: undefined,
            recordAssetViewPromise: undefined,
            current_canvas_builder_manager: undefined,

            decrypt: function (data, return_string) {
                var result = data;

                try {
                    result = dataEncryptionService.AES.decrypt(result, user_id); // decrypt data

                    result = result.toString(dataEncryptionService.enc.Utf8); // get final object from decrypted object
                    if (return_string) {
                        return result;
                    } else {
                        return JSON.parse(result); // parse string into JSON
                    }
                    // tslint:disable-next-line:no-empty
                } catch (error) {}
            },

            /**
             * @param {string} localStorageReference
             * @return {null | Object}
             */
            loadFromPersistentObjStore: function (localStorageReference) {
                return persistentObjectStore.retrieve(localStorageReference, user_id);
            },

            isAssetInStorage: function (assetId) {
                return persistentObjectStore.has(assetId);
            },

            /**
             * @param {boolean=} returnOnlyKeys
             * @return {Array<string> | Object<string, Object>}
             */
            getAllLocalStorageData: function (returnOnlyKeys = false) {
                const data = returnOnlyKeys ? [] : {};

                for (const storage_key of persistentObjectStore.keys()) {
                    if (returnOnlyKeys) {
                        data.push(storage_key);
                    } else {
                        data[storage_key] = persistentObjectStore.retrieve(storage_key, user_id);
                    }
                }

                return data;
            },

            fetchAllDtos: function (ids) {
                var key,
                    promise,
                    promises = [],
                    all_local_storage_objs = dataManagerService.getAllLocalStorageData();

                // by default we fetch all
                if (ids === undefined || ids === null || ids.length < 1) {
                    for (key in all_local_storage_objs) {
                        var params = {
                            assetId: key,
                            permissionRequired: "View"
                        };
                        promise = AssetEndpointService.getAssetAndCheckPermission(params);
                        promises.push(promise);
                    }
                } else {
                    // only fetch the ids specified
                    for (key in all_local_storage_objs) {
                        for (var i = 0; i < ids.length; i++) {
                            // tslint:disable-next-line:triple-equals
                            if (key == ids[i]) {
                                var params = {
                                    assetId: key,
                                    permissionRequired: "View"
                                };
                                promise = AssetEndpointService.getAssetAndCheckPermission(params);
                                promises.push(promise);
                            }
                        }
                    }
                }

                return $q.all(promises);
            },

            cacheAllDtos: function (ids) {
                var i,
                    promise,
                    promises = [],
                    deferred = $q.defer();

                dataManagerService.fetchAllDtos(ids).then(function (data) {
                    for (i = 0; i < data.length; i++) {
                        if (data[i].data.MessageType === "Success") {
                            promise = dataManagerService.init(data[i].data);
                            promises.push(promise);
                        }
                    }

                    deferred.resolve({ promises: $q.all(promises) });
                });

                return deferred.promise;
            },

            /**
             * Method to check we still have a lock (or can reacquire a lock) before saving
             * @param {string} assetId - well take a wild guess
             * @param {string} mainType - used by modals to say whether its an asset or resource in the messages
             * @param {string} pageAssetId
             * @returns {Promise}
             */
            checkBeforeSave: function (assetId, mainType, pageAssetId) {
                var onFailure = function () {
                    overlayFactory.saveOverlay.hide();
                    return $q.reject();
                };

                var self = this;
                //Check for active user Lock (Collaboration)
                recoveryLogger.step("save", `Saving root ${mainType}`);
                return AssetLockingHelper.keepLockAlive(assetId, mainType).then(function () {
                    return localChangesResolver.checkBeforeSave(assetId).then(
                        function (resolved) {
                            //If we have done a recovery via any locked asset permission checks, broadcast an event to let Controllers handle it
                            if (resolved && resolved.recoveryDto.lockedAssets) {
                                $rootScope.$broadcast("pageAssetRecoveredDuringSave", assetId);
                            }
                        },
                        function (rejection) {
                            if (rejection && rejection.standaloneLockedResponse) {
                                localChangesResolver.cleanupLocalStorageAndResetManager([assetId]);
                                $location.path(urlService.createUrl(assetId, "", true));
                            }
                            onFailure();
                        }
                    );
                }, onFailure);
            },

            cleanUpRecoverOutdatedLocalStorageItems: function () {
                $rootScope.recoveryInProgress = true;

                recoveryLogger.start("n/a", "App load cleanup");
                const startRecoveryPromise = $q.defer();
                const allLocalStorageData = this.getAllLocalStorageData(true);
                const recoveryPromise = localChangesResolver.checkAndRecoverAll(allLocalStorageData, startRecoveryPromise);

                startRecoveryPromise.promise.then(
                    () => {
                        localChangesResolver.notifyUser(false, false, null, recoveryPromise, null, null).then(function () {
                            $rootScope.recoveryInProgress = false;
                        });
                    },
                    () => {
                        recoveryPromise.then(function () {
                            $rootScope.recoveryInProgress = false;
                        });
                    }
                );

                recoveryPromise.catch(() => ($rootScope.recoveryInProgress = false));
            },

            cleanUpOutdatedStorageItems: function () {
                var key,
                    all_local_storage_objs = dataManagerService.getAllLocalStorageData(),
                    decrypted_dto,
                    decrypted_dto_date;

                // deprecation date is 2 weeks back in time from
                var deprecation_date = new Date();
                deprecation_date.setHours(0, 0, 0, 0);
                deprecation_date.setDate(deprecation_date.getDate() - 7);

                for (key in all_local_storage_objs) {
                    if (all_local_storage_objs.hasOwnProperty(key)) {
                        decrypted_dto = all_local_storage_objs[key];
                        // tslint:disable-next-line:triple-equals
                        if (decrypted_dto && decrypted_dto.LocalStorageExpiryDate && decrypted_dto.is_saved && decrypted_dto.is_saved != false) {
                            decrypted_dto_date = new Date(decrypted_dto.LocalStorageExpiryDate); // get local storage date
                            if (!(decrypted_dto_date > deprecation_date)) {
                                // compare last modified against deprecation date
                                this.destroy(key); // remove item if it is deprecated
                            }
                        }
                    }
                }
            },

            getUserData: function () {
                var deferred = $q.defer();

                if (user_id) {
                    deferred.resolve(user_id);
                    return deferred.promise;
                } else {
                    $http.defaults.withCredentials = true;
                    return $http.get(baseUrlsFactory.api_base_url + "SharedMenu/GetLoggedInUserId?ticks=" + new Date().getTime());
                }
            },

            cleanUpLocalStorage: function () {
                if (helperService.isNotInAsset()) {
                    this.getUserData().then(
                        function (response) {
                            user_id = response.data ? response.data : "pebblelocalstorage";
                            // self.cleanUpOutdatedStorageItems();
                            this.cleanUpRecoverOutdatedLocalStorageItems();
                        }.bind(this)
                    );
                }
            },

            validateBeforeSave: function (dto) {
                if (dataManagerService.current_navigation === dto.AssetId || (!dataManagerService.current_navigation && dataManagerService.current_page === dto.AssetId)) {
                    dto.data.AssetFolder = "Main";
                }
            },

            /**
             * @param {string} key
             * @returns {void}
             */
            destroy: function (key) {
                persistentObjectStore.remove(key);
            },

            checkIfSaved: function (key) {
                if (dataManagerService.dtos[key] && dataManagerService.dtos[key].data) {
                    if (dataManagerService.dtos[key].data.MainType === "WorkBookResponse") {
                        return dataManagerService.workbookManagerObject.checkForIsSavedAnywhereInWorkbook(key);
                    } else {
                        return dataManagerService.dtos[key].is_saved;
                    }
                } else {
                    return false;
                }
            },

            setCurrentData: function (asset_id, page_id) {
                if (!asset_id) {
                    return;
                }

                var asset_data, asset_main_type, page_data;

                asset_data = dataManagerService.dtos[asset_id];
                asset_main_type = asset_data.data ? asset_data.data.MainType : null;

                switch (asset_main_type) {
                    case "Webfolio":
                        dataManagerService.init({ AssetId: asset_id, Asset: asset_data.data }); // webfolio page

                        page_data = dataManagerService.dtos[page_id];
                        if (page_data) {
                            dataManagerService.init({ AssetId: page_id, Asset: page_data.data });
                        }
                        break;
                    case "Form":
                    case "FormResponse":
                    case "WebfolioPage":
                    case "Post":
                    case "AssetCollectionWrapper":
                    case "ActivityLog":
                        dataManagerService.init({ AssetId: asset_id, Asset: asset_data.data });
                        break;
                    case "WorkBookResponse":
                        dataManagerService.init({ AssetId: asset_id, Asset: asset_data.data }).then(function () {
                            var page = dataManagerService.workbookManagerObject.findWorkbookPage(page_id, dataManagerService.workbookManagerObject.data.Pages);

                            if (page.UserResponseId) {
                                page_data = dataManagerService.dtos[page.UserResponseId];

                                if (page_data) {
                                    dataManagerService.init({ AssetId: page.UserResponseId, Asset: page_data.data });
                                }
                            }
                        });
                        break;
                    default:
                        throw new Error("Oh oh! I don't know [" + asset_main_type + "] type of MainType.");
                }
            },

            save: function (asset_id, page_id) {
                var unsaved_dtos = [],
                    dto_key,
                    i,
                    data_to_send,
                    promise,
                    promises = [],
                    self = this,
                    deferred = $q.defer();

                // leave this in
                console.warn("WARNING, DEFAULT SAVE BEING USED, MAINTYPE SPECIFIC METHOD SHOULD BE USED");

                // when fetch all dtos is done
                var ids = [];
                ids.push(asset_id);
                dataManagerService.cacheAllDtos(ids).then(function (data) {
                    // when caching is done
                    data.promises.then(function () {
                        self.setCurrentData(asset_id, page_id);

                        for (dto_key in dataManagerService.dtos) {
                            if (dataManagerService.dtos.hasOwnProperty(dto_key)) {
                                if (dataManagerService.dtos[dto_key].is_saved === false) {
                                    dataManagerService.validateBeforeSave(dataManagerService.dtos[dto_key]);
                                    unsaved_dtos.push(dataManagerService.dtos[dto_key]);
                                }
                            }
                        }

                        for (i = 0; i < unsaved_dtos.length; i++) {
                            // check revision number hasn't been set to NaN
                            if (isNaN(unsaved_dtos[i].data.Revision)) {
                                unsaved_dtos[i].data.Revision = -1;
                            }
                            data_to_send = { model: unsaved_dtos[i].data };
                            $http.defaults.withCredentials = true;
                            promise = AssetEndpointService.saveOrUpdate(data_to_send);
                            promises.push(promise.then(updateBaseVersion));
                        }

                        deferred.resolve({ promises: $q.all(promises) });
                    });
                });

                return deferred.promise;
            },

            init: function (data, not_main) {
                var self = this;
                var deferred = $q.defer();
                switch (data.Asset.MainType) {
                    case DTO_TYPES.ACTIVITY_LOG:
                        new ActivityLogManager(data, deferred, not_main);
                        break;
                    case DTO_TYPES.BLOG:
                        new BlogManager(data, deferred, not_main);
                        break;
                    case DTO_TYPES.COLLECTION_WRAPPER:
                        new CollectionWrapperManager(data, deferred, not_main);
                        break;
                    case DTO_TYPES.CV:
                        new CvManager(data, deferred, not_main);
                        break;
                    case DTO_TYPES.FORM:
                    case DTO_TYPES.FORM_RESPONSE:
                        new FormManager(data, deferred, not_main);
                        break;
                    case DTO_TYPES.POST:
                        self.current_canvas_builder_manager = data.Asset.MainType;
                        new PostManager(data, deferred, not_main);
                        break;
                    case DTO_TYPES.WEBFOLIO:
                        new WebfolioManager(data, deferred, not_main);
                        break;
                    case DTO_TYPES.WEBFOLIO_PAGE:
                        self.current_canvas_builder_manager = data.Asset.MainType;
                        new PageManager(data, deferred, not_main);
                        break;
                    case DTO_TYPES.WORKBOOK:
                    case DTO_TYPES.WORKBOOK_RESPONSE:
                        new WorkbookManager(data, deferred, not_main);
                        break;
                    case DTO_TYPES.TEMPLATE_BUILDER:
                        self.current_canvas_builder_manager = data.Asset.MainType;
                        new TemplateBuilderManager(data, deferred, not_main);
                        break;
                    case DTO_TYPES.WORKBOOK_BUILDER:
                        self.current_canvas_builder_manager = data.Asset.MainType;
                        new WorkbookBuilderManager(data, deferred, not_main);
                        break;
                    default:
                        throw new Error("Oh oh! I don't know [" + data.Asset.MainType + "] type of DTO. Can you check what type of DTO you trying to create?");
                }

                return deferred.promise;
            },

            cancelPromise: function (promise) {
                // If the promise does not contain a hook into the deferred timeout,
                // the simply ignore the cancel request.
                if (promise && promise._httpTimeout && promise._httpTimeout.resolve) {
                    promise._httpTimeout.resolve();
                }
            },

            getDto: function (assetId) {
                if (assetId && dataManagerService.dtos) {
                    return dataManagerService.dtos[assetId];
                }

                return null;
            },

            waitForDto: function (assetId) {
                var deferred = $q.defer();
                var dto = this.getDto(assetId);
                if (dto) {
                    deferred.resolve(dto);
                } else {
                    //get Asset Ref
                    //Check type
                    var cleanUp = $rootScope.$on(
                        "dtoAdded",
                        function () {
                            var dto = this.getDto(assetId);
                            if (dto) {
                                deferred.resolve(dto);
                                cleanUp();
                            }
                        }.bind(this)
                    );
                }
                return deferred.promise;
            },

            reset: function () {
                dataManagerService.dtos = [];
                dataManagerService.current_navigation = undefined;
                dataManagerService.current_page = undefined;
                dataManagerService.primary_id = undefined;

                $interval.cancel(dataManagerService.poll);
                dataManagerService.poll = undefined;
                dataManagerService.cancelPromise(dataManagerService.recordAssetViewPromise);
            },

            recordAssetView: function (asset_id) {
                if (!asset_id) {
                    if (angular.isDefined(dataManagerService.poll)) {
                        $interval.cancel(dataManagerService.poll);
                        dataManagerService.poll = undefined;
                    }
                    return;
                }
                dataManagerService.primary_id = asset_id;
                dataManagerService.recordAssetViewPromise = urlService
                    .promisedGet(baseUrlsFactory.api_base_url + "AssetDto/RecordAssetView?assetId=" + dataManagerService.primary_id)
                    .then(function (response) {
                        if (response.data && $rootScope.userData) {
                            // Check to see if there is another user currently viewing
                            if (response.data.UserId !== $rootScope.userData.UserId) {
                                // if so, broadcast the user to workflow bar
                                $rootScope.$broadcast("lock_workflow_bar", response.data);
                            } else if (!dataManagerService.poll) {
                                // if not, regularly poll to keep the asset view record alive
                                dataManagerService.poll = $interval(
                                    function () {
                                        dataManagerService.recordAssetView(dataManagerService.primary_id);
                                    },
                                    120000,
                                    360
                                ); // every 2 minutes for up to 12 hours
                            }
                        }
                    });
            },

            getDataManagerObject: function (mainType) {
                switch (mainType) {
                    case DTO_TYPES.ACTIVITY_LOG:
                        return dataManagerService.activityLogManagerObject;
                        break;
                    case DTO_TYPES.BLOG:
                        return dataManagerService.blogManagerObject;
                        break;
                    case DTO_TYPES.COLLECTION_WRAPPER:
                        return dataManagerService.collectionWrapperManagerObject;
                        break;
                    case DTO_TYPES.CV:
                        return dataManagerService.cvManagerObject;
                        break;
                    case DTO_TYPES.FORM:
                    case DTO_TYPES.FORM_RESPONSE:
                        return dataManagerService.formManagerObject;
                        break;
                    case DTO_TYPES.WEBFOLIO:
                        return dataManagerService.webfolioManagerObject;
                        break;
                    case DTO_TYPES.WEBFOLIO_PAGE:
                        return dataManagerService.pageManagerObject;
                        break;
                    case DTO_TYPES.WORKBOOK:
                    case DTO_TYPES.WORKBOOK_RESPONSE:
                        return dataManagerService.workbookManagerObject;
                        break;
                    case DTO_TYPES.POST:
                        return dataManagerService.postManagerObject;
                        break;
                    case DTO_TYPES.TEMPLATE_BUILDER:
                        return dataManagerService.templateBuilderManagerObject;
                        break;
                    case DTO_TYPES.WORKBOOK_BUILDER:
                        return dataManagerService.workbookBuilderManagerObject;
                        break;
                    default:
                        return dataManagerService.pageManagerObject;
                        break;
                }
            },

            _showProgressResetModal: function (modalLang = {}) {
                const deferred = $q.defer();
                const modalProps = {
                    title: multiLanguageService.getString("labels.canvas.progress_auto_reset_title"),
                    message: multiLanguageService.getString("labels.canvas.progress_auto_reset_message_on_exit"),
                    button_label: multiLanguageService.getString("labels.canvas.progress_auto_reset_button"),
                    ...modalLang,
                    onClose: () => {
                        recoveryLogger.step("save", "Saving via Mandatory Progress");
                        deferred.resolve();
                    }
                };

                modal.launch(baseUrlsFactory.shared_component_base_url + "modalDialogComponent/templates/generic-pop-up.html", modalProps);

                return deferred.promise;
            },

            saveViaAssetId: function (assetId, forceSave) {
                var promise = null,
                    managerObj = dataManagerService.dtos[assetId],
                    mainType = managerObj.data.MainType;

                // in case asset was saved, do not run save method
                if (
                    !forceSave &&
                    ((mainType !== "WorkBookResponse" && managerObj.is_saved === true && managerObj.data.is_saved === true) ||
                        (mainType === "WorkBookResponse" && managerObj.checkForIsSavedAnywhereInWorkbook(assetId)))
                ) {
                    return $q.when();
                }

                switch (mainType) {
                    case "Webfolio":
                        promise = managerObj.saveWebfolioToServer(assetId);
                        break;
                    case "WorkBook":
                    case "WorkBookResponse":
                        // check workbook is valid first
                        var validationObj = managerObj.validateWorkbook();
                        // tslint:disable-next-line:triple-equals
                        if (validationObj && validationObj.validation && validationObj.validation.valid == false) {
                            var que = $q.defer();
                            promise = que.promise;
                            que.resolve(validationObj);
                            return promise;
                        }

                        // its possible that a completed mandatory page has now become invalid... if so we will inform the user but carry on the promise chain
                        promise = managerObj.checkMandatoryBeforeSave(assetId, { forceMainFolder: true }).then((data) => {
                            return data.serverResponses;
                        });
                        break;
                    case "WorkbookBuilder":
                        promise = managerObj.saveWorkbookBuilderToServer(assetId);
                        break;
                    case "WebfolioPage":
                        promise = managerObj.saveFoliopageToServer(assetId);
                        break;
                    case "Form":
                    case "FormResponse":
                        // validate first
                        var form = managerObj.data;
                        if (form) {
                            var formValidationObj = formComponentsHelper.formValidation(form, false, "");

                            //update form in local storage
                            if (!formValidationObj.valid) {
                                var qu = $q.defer();
                                promise = qu.promise;
                                qu.resolve({ validation: formValidationObj });
                                return promise;
                            }
                        }

                        // its possible that a completed mandatory page has now become invalid... if so we will inform the user but carry on the promise chain
                        promise = managerObj.checkMandatoryBeforeSave(assetId);
                        break;
                    case "TemplateBuilder":
                        promise = managerObj.saveTemplateBuilderToServer(assetId, false);
                        break;
                    case "AssetCollectionWrapper":
                    case "AssetCollection":
                        promise = managerObj.saveCollectionToServer(assetId);
                        break;
                    case "ActivityLog":
                        promise = managerObj.saveActivityLogToServer(assetId, true);
                        break;
                    case "Post":
                        promise = managerObj.savePostToServer(assetId);
                        break;
                    case "Blog":
                        promise = managerObj.saveBlogToServer(assetId);
                        break;
                    default:
                        var q = $q.defer();
                        promise = q.promise;
                        q.resolve();
                        break;
                }

                return promise.then(
                    function (data) {
                        if (data && data.promises) {
                            var result = null;
                            return data.promises.then(
                                function (data) {
                                    if (data && data.length > 0) {
                                        for (var i = 0; i < data.length; i++) {
                                            result = data[i];
                                            if (result.status !== 200 || result.data.MessageType === "Error") {
                                                return $q.reject(result);
                                            } else {
                                                this.cleanupStorageOfAssetId(result.data.AssetId);
                                                var tempDto = dataManagerService.dtos[result.data.AssetId];
                                                if (tempDto !== null && tempDto !== undefined) {
                                                    tempDto.is_saved = true;
                                                    if (tempDto.data) {
                                                        tempDto.data.is_saved = true;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                    return data;
                                }.bind(this)
                            );
                        } else {
                            return $q.when();
                        }
                    }.bind(this)
                );
            },

            /**
             * This is used to tell the builderCanvas what asset type your in
             * Types currently have builderCanvas:
             * - FolioPage
             * - Post
             */
            getDataManagerForBuilderCanvas: function () {
                switch (dataManagerService.current_canvas_builder_manager) {
                    case DTO_TYPES.POST:
                        return dataManagerService.postManagerObject;
                    case DTO_TYPES.WEBFOLIO_PAGE:
                        return dataManagerService.pageManagerObject;
                    case DTO_TYPES.TEMPLATE_BUILDER:
                        return dataManagerService.templateBuilderManagerObject;
                    case DTO_TYPES.WORKBOOK_BUILDER:
                        return dataManagerService.workbookBuilderManagerObject;
                    default:
                        // don't throw an error here, this will sometimes get called because forms uses blockBehaviour on its hint fields
                        //throw new Error("Oh oh! The type [" + dataManagerService.current_canvas_builder_manager + "] was not recognised!");
                        return undefined;
                }
            },

            /**
             * function to be used any time you need an array of Ids from an array of objects which have Ids
             * returns a new array
             * extracts the ids of the objects in passed array
             */
            createArrayOfAssetIds: function (array) {
                var assetIds = [];
                for (var i = 0; i < array.length; i++) {
                    assetIds.push(array[i].Id);
                }
                return assetIds;
            },

            cleanupSpecificStorage: function (maintype) {
                console.warn("Incorrect clean up specific local storage called with type " + maintype);
            },

            cleanupStorage: function () {
                console.warn("Incorrect clean up local storage called");
            },

            cleanupStorageOfAssetId: function (assetId) {
                if (assetId !== null && assetId !== undefined) {
                    let assetFromStorage = persistentObjectStore.retrieve(assetId, user_id);

                    if (assetFromStorage === null) {
                        // can't find it in local storage, could be a parent with only child changes
                        var dto = dataManagerService.dtos[assetId];
                        if (dto !== null && dto !== undefined) {
                            assetFromStorage = dto.data;
                        }
                    }

                    // tslint:disable-next-line:triple-equals
                    if (assetFromStorage != false && assetFromStorage !== undefined && assetFromStorage !== null) {
                        var manager = dataManagerService.dtos[assetId];

                        if (manager) {
                            switch (assetFromStorage.MainType) {
                                case "Webfolio":
                                case "WebfolioPage":
                                case "Form":
                                case "FormResponse":
                                case "AssetCollectionWrapper":
                                case "AssetCollection":
                                case "ActivityLog":
                                case "Post":
                                case "Blog":
                                case "TemplateBuilder":
                                    // tslint:disable-next-line:triple-equals
                                    if (manager.removeFromStorage && manager.AssetId == assetId) {
                                        manager.removeFromStorage();
                                    }
                                    break;
                                case "WorkBook":
                                case "WorkBookResponse":
                                    // tslint:disable-next-line:triple-equals
                                    if (manager.AssetId == assetId) {
                                        manager.removeWorkbookFromStorage();
                                    }
                                    break;
                                case "WorkbookBuilder":
                                    manager.removeWorkbookBuilderFromStorage();
                                    break;
                            }
                        }

                        // make sure at least main is destroyed (should have been done already)
                        persistentObjectStore.remove(assetId);
                    }
                }
            },

            saveOrphanedAsset(assetId) {
                if (!persistentObjectStore.has(assetId)) {
                    return assetStoreService.changeAssetFolder(assetId, ASSET_CONSTANTS.FOLDERS.MAIN);
                }

                const onSaveFail = () => $http.post(`${baseUrlsFactory.api_base_url}AssetDto/Recover`, { MainAssetId: assetId, Dtos: [assetModel] });
                const assetModel = this.loadFromPersistentObjStore(assetId);
                assetModel.AssetFolder = ASSET_CONSTANTS.FOLDERS.MAIN;

                return $http
                    .post(`${baseUrlsFactory.api_base_url}AssetDto/GetRevisionInformation`, [assetId])
                    .then((response) => {
                        const revisionData = response.data[0];
                        if (revisionData !== undefined && assetModel.Revision <= revisionData.Value) {
                            return $q.reject();
                        }

                        return AssetEndpointService.saveOrUpdate({ model: assetModel });
                    })
                    .then((response) => {
                        if (response.data.Asset === null) {
                            return onSaveFail();
                        }
                    })
                    .catch(onSaveFail)
                    .finally(() => persistentObjectStore.remove(assetId));
            }
        };

        /**
         * Data Manager code has generic methods for all data manager types
         * @param dto
         * @param deferred
         * @param not_main
         * @constructor
         */
        var DataManager = function (dto, deferred, not_main) {
            this.deferred = deferred;
            this.data = "";
            this.AssetId = dto.AssetId;
            this.is_saved = true;
            this.not_main = not_main || false;
            var self = this;
            this.getUserData().then(
                function (response) {
                    // User is NOT always logged in so we fall back to a random string here
                    if (response.data !== null && response.data !== undefined) {
                        user_id = response.data ? response.data : "pebblelocalstorage";
                    } else {
                        user_id = response ? response : "pebblelocalstorage";
                    }

                    var promise = null;
                    if (dto.IsPreviousVersion) {
                        self.data = dtoFactory.create(dto.Asset);
                        self.buildData(true);
                        promise = $q.when();
                    } else {
                        promise = self.validateData(dto.Asset);
                    }

                    promise.then(function () {
                        self.deferred.notify({ code: 0 });
                    });
                },
                function () {
                    self.deferred.reject({ error_code: 0 });
                }
            );
        };

        DataManager.prototype = {
            getUserData: function () {
                var deferred = $q.defer();

                if (user_id) {
                    deferred.resolve(user_id);
                    return deferred.promise;
                } else {
                    return $http.get(baseUrlsFactory.api_base_url + "SharedMenu/GetLoggedInUserId?ticks=" + new Date().getTime());
                }
            },

            validateData: function (dto) {
                var self = this,
                    deferred = $q.defer();

                recoveryLogger.step("load", `DataManagerService init | ${dto && dto.MainType}`);
                this.data = dtoFactory.create(dto); // current data

                if (dto.Id) {
                    // data is stored on the server
                    localChangesResolver.checkDto(this.AssetId, localChangesResolver.hasNestedItems(this.data), false, true).then(function () {
                        self.buildData().then(() => {
                            self.data.BaseVersion = dto.Revision;
                            deferred.resolve();
                        });
                    });
                } else {
                    // data is brand new, then need to store it on server to get real ID
                    // check revision number hasn't been set to NaN
                    if (isNaN(this.data.Revision)) {
                        this.data.Revision = -1;
                    }
                    var data_to_send = { model: this.data };

                    $http.defaults.withCredentials = true;
                    AssetEndpointService.saveOrUpdate(data_to_send).then(function (response) {
                        if (response.data.AssetId !== null) {
                            self.AssetId = response.data.AssetId;
                            self.data = dtoFactory.create(response.data.Asset); // current data
                            self.buildData();
                            self.data.BaseVersion = response.data.Asset.Revision;
                        } else {
                            self.deferred.reject({ Message: response.data.Message, MessageType: response.data.MessageType });
                        }
                    });
                }

                return deferred.promise;
            },

            checkForNestedRevisionChange: function (local_storage_pages, from_server_pages) {
                if (local_storage_pages !== null && local_storage_pages !== undefined) {
                    for (var i = 0, ilen = local_storage_pages.length; i < ilen; i++) {
                        var local_page = local_storage_pages[i];

                        var server_page = null;
                        if (from_server_pages !== null && from_server_pages !== undefined) {
                            for (var j = 0, jlen = from_server_pages.length; j < jlen; j++) {
                                // tslint:disable-next-line:triple-equals
                                if (from_server_pages[j].PageId == local_page.PageId) {
                                    server_page = from_server_pages[j];
                                }
                            }
                        }

                        if (local_page.PageType === "WorkBook" || local_page.PageType === "WorkBookResponse") {
                            // tslint:disable-next-line:triple-equals
                            if (server_page != null) {
                                if (server_page.NestedWorkbookRevision > local_page.NestedWorkbookRevision) {
                                    return true; // means we've found the nav has been updated do return true
                                }

                                // if this workbook hasn't got a higher revision number we still need to check if its children do
                                var childrenResult = this.checkForNestedRevisionChange(local_page.Children, server_page.Children);
                                if (childrenResult === true) {
                                    return true;
                                }
                            }
                        }
                    }
                }

                return false; // means use local storage data
            },

            buildData: function (isVersioned) {
                let promise = $q.when();

                if (!this.not_main) {
                    this.local_storage_reference = this.AssetId;
                    var data_from_local_storage = this.load();
                    data_from_local_storage = data_from_local_storage ? dtoFactory.create(data_from_local_storage) : false; // data from local storage

                    /* tslint:disable:triple-equals */
                    if (
                        !isVersioned &&
                        data_from_local_storage != false &&
                        (data_from_local_storage.Revision > this.data.Revision ||
                            (this.data.MainType === "WorkBookResponse" && this.checkForNestedRevisionChange(data_from_local_storage.Pages, this.data.Pages) == false))
                    ) {
                        /* tslint:enable:triple-equals */
                        // save current new data
                        promise = migratorRunner.execute(this.AssetId, this.data, data_from_local_storage || null).then((migratedData) => {
                            this.data = migratedData;
                            this.is_saved = migratedData.is_saved;
                            this.data.is_saved = migratedData.is_saved;
                            if (this.is_saved === undefined || this.is_saved === null) {
                                this.is_saved = false;
                                this.data.is_saved = false;
                            }
                            this.local_storage_reference = this.AssetId;
                            if (!this.is_saved) {
                                this.save();
                            }
                        });
                    } else {
                        // else saving server item into local storage... add is_saved = true flag
                        this.is_saved = true;
                        this.data.is_saved = true;
                    }
                    // ... else server loading
                    promise.then(() => {
                        this.completeDataProcess();
                        $rootScope.$broadcast("dtoAdded", this.AssetId);
                    });
                }

                return promise.then(() => {
                    this.deferred.resolve({ AssetId: this.AssetId, Asset: this.data });
                });
            },

            reviseData: function (opts) {
                opts = opts || {};
                var onlyIsSavedFlag = opts.onlyIsSavedFlag || false;

                if (onlyIsSavedFlag) {
                    this.data.is_saved = false;
                    this.is_saved = false;
                    return;
                }

                if (this.is_saved) {
                    this.data.Revision++;
                    this.data.is_saved = false;
                    this.is_saved = false;
                }

                $rootScope.$broadcast("onReviseData", this.AssetId);
                var expiryDate = new Date();
                expiryDate.setDate(expiryDate.getDate() + 7);
                this.data.LocalStorageExpiryDate = expiryDate;

                this.data.LastModified = new Date();
            },

            hasBeenSaved: function () {
                return this.data && this.data.Revision !== 0 && this.is_saved === true;
            },

            removeFromStorage: function () {
                persistentObjectStore.remove(this.local_storage_reference);
            },

            updateDtoProperty: function (prop, value) {
                if (this.managerType === "templateBuilderManagerObject" && $location.path().indexOf("builder/workbook") > -1 && prop === "SubType") {
                    var page = dataManagerService.workbookBuilderManagerObject.getItemByPropValue("ContentId", this.AssetId, "WorkBook");
                    dataManagerService.workbookBuilderManagerObject.update(page.PageId, { PageIcon: helperService.getWorkbookPageIcon(value) });
                }

                if (this.rootManagerId) {
                    dataManagerService.dtos[this.rootManagerId].onChildUpdate();
                }

                if (this.data.hasOwnProperty(prop)) {
                    this.data[prop] = value;
                    this.reviseData();

                    if (this.save()) {
                        return true;
                    }
                }

                return false;
            },

            updateDtoModel: function (newDto) {
                this.data = newDto;
            },

            save: function (key, data) {
                let storageKey = this.local_storage_reference;
                let storageData = this.data;
                if (key && data) {
                    storageKey = key;
                    storageData = data;
                }

                persistentObjectStore.store(storageKey, storageData, user_id);
                return true;
            },

            /**
             * @returns {boolean|Object}
             */
            load: function () {
                const result = persistentObjectStore.retrieve(this.local_storage_reference, user_id);
                if (result === null) {
                    return false;
                }
                return result;
            },

            /**
             * @param {string} localStorageReference
             * @returns {Object|boolean}
             */
            loadSpecific: function (localStorageReference) {
                let result = persistentObjectStore.retrieve(localStorageReference, user_id);
                if (result === null) {
                    result = false;
                }
                return result;
            },

            updateParentSavedFlagIfExists: function () {
                // Warning: THIS IS ALMOST COPY OF CODE FROM PageManager #future-refactor
                var isWebfolio = $location.path().indexOf("webfolio") > -1,
                    manager = dataManagerService.webfolioManagerObject;

                if (isWebfolio && manager && (manager.get(this.AssetId) !== -1 || manager.getByResponseId(this.AssetId) !== null)) {
                    dataManagerService.webfolioManagerObject.reviseData();
                    dataManagerService.webfolioManagerObject.save();
                }
            }
        };

        /**
         * WebfolioManager class
         * @param dto
         * @constructor
         */
        var WebfolioManager = function (dto) {
            DataManager.apply(this, arguments); // extend from DataManager class

            this.completeDataProcess = function () {
                dataManagerService.current_navigation = this.AssetId;
                dataManagerService.dtos[this.local_storage_reference] = this; // add to the local storage data list
                dataManagerService.webfolioManagerObject = this;
            };

            this.procurePage = (pageId, assetContext, isEditable) => {
                const procurePagePromise = assetPageHandlerFactory
                    .createPortfolioPageHandler(this.data, assetContext)
                    .procurePageById(pageId, isEditable)
                    .then((result) => {
                        if (result.outdatedResponseId === "" || !isEditable) {
                            return result.page;
                        }

                        return dataManagerService.saveOrphanedAsset(result.outdatedResponseId).then(
                            () => result.page,
                            () => result.page
                        );
                    });

                if (isEditable) {
                    procurePagePromise.catch((rejection) => handleProcurePageError(rejection, this.AssetId, pageId, this));
                }

                return procurePagePromise;
            };

            this.getAllIds = function (startingId) {
                const assetId = typeof startingId === "string" && startingId !== "" ? startingId : dataManagerService.primary_id;
                const pages = dataManagerService.dtos[assetId].data.Pages;

                const ids = this._getResponseIds(pages, false);
                ids.unshift(assetId);

                return ids;
            };

            this.getUnsavedPageAssetIds = function (pages) {
                return this._getResponseIds(pages, true).filter((id) => persistentObjectStore.has(id));
            };

            this._getResponseIds = function (pages, ignoreNestedWebfolios) {
                const pageTypeFilter = ignoreNestedWebfolios ? "Webfolio" : "";
                const pageSearch = new PageModelSearch(pages);

                const ignorePageType = (page) => page.PageType !== pageTypeFilter;
                const withoutDuplicatePageAssetIds = withoutDuplicatePageAssetIdsFactory();
                return pageSearch.filter(withoutEmptyPageAssetIds, withoutDuplicatePageAssetIds, ignorePageType).map(determinePageAssetId);
            };

            // Warning (MOIST): Code below (from here till 'this.saveWebfolioToServer' method) is partial duplication
            // of code from 'workbookCtrl' & 'builderViewerCtrl' files in Plus project. Although, code below is cleaner
            // and based on promises so keep this in mind when big save refactor will happen.
            // #future-refactor #big-save-refactor
            this.validatePages = function (pages) {
                const validity = { pageId: "", validation: { valid: true } };
                const supportedPageTypes = [ASSET_CONSTANTS.TYPES.FORM_RESPONSE, ASSET_CONSTANTS.TYPES.FORM];

                const pageSearch = new PageModelSearch(pages);
                pageSearch.find((page) => {
                    const assetId = determinePageAssetId(page);
                    if (assetId === "" || !supportedPageTypes.includes(page.PageType)) {
                        return false;
                    }

                    const assetDto = this.loadSpecific(assetId);
                    if (!assetDto) {
                        return false;
                    }

                    const validation = formComponentsHelper.formValidation(assetDto, true, page.PageTitle);
                    if (validation.valid) {
                        return false;
                    }

                    validity.pageId = assetId;
                    validity.validation = validation;
                    return true;
                });

                return validity;
            };

            this.onRenderingCompleted = function () {
                var unbindRenderingCompleted = $rootScope.$on(
                    "rendering_completed",
                    function () {
                        $timeout(this.showValidationErrorsOnCurrentForm);
                        unbindRenderingCompleted(); // unbind after event listener is fired
                    }.bind(this)
                );
            };

            this.goToInvalidPage = function (pageId) {
                // trigger 'invalid elements highlighter' when form on the redirected page is ready
                this.onRenderingCompleted();

                // redirect to the page that failed validation
                $location.search("pageId", pageId);
            };

            this.showValidationErrorsOnCurrentForm = function () {
                $rootScope.$broadcast("showValidationErrorsOnCurrentForm", {});
            };

            this.onValidationFail = function (validationObj) {
                // show user error modal
                var isolatedScope = $rootScope.$new(true),
                    templateName = validationObj.pageId === $location.search().pageId ? "invalid-current-page" : "invalid-page";
                isolatedScope.showValidationErrorsOnCurrentForm = this.showValidationErrorsOnCurrentForm;
                isolatedScope.goToInvalidPage = this.goToInvalidPage.bind(this, validationObj.pageId);
                isolatedScope.confirmMessage = validationObj.validation.message;
                modal.newModal({
                    scope: isolatedScope,
                    templateUrl: "scripts/features/workbook/templates/" + templateName + ".html"
                });
            };

            this.onValidationSuccess = function (rootAssetId) {
                const promises = [];
                const managerId = typeof rootAssetId === "string" && rootAssetId !== "" ? rootAssetId : dataManagerService.primary_id;
                const pages = dataManagerService.dtos[managerId].data.Pages;
                const assetIdsToSave = this.getUnsavedPageAssetIds(pages);
                assetIdsToSave.unshift(rootAssetId);

                for (const assetId of assetIdsToSave) {
                    const assetFromStorage = this.loadSpecific(assetId);

                    if (assetFromStorage !== false && assetFromStorage !== undefined && assetFromStorage !== null) {
                        // then we have an asset to save
                        // check revision number hasn't been set to NaN
                        if (isNaN(assetFromStorage.Revision)) {
                            assetFromStorage.Revision = -1;
                        }

                        // only save pages with is_saved false... as they are the only ones to have changed
                        recoveryLogger.step("save", "Saving Webfolio");
                        $http.defaults.withCredentials = true;
                        const savePromise = AssetEndpointService.saveOrUpdate({ model: assetFromStorage }).then(updateBaseVersion).then(cleanupStorageAfterSave);

                        promises.push(savePromise);
                    }
                }

                return promises;
            };

            this.saveWebfolioToServer = function (asset_id, saveToFolder = "Main") {
                var deferred = $q.defer();

                if (this.data.AssetFolder === "Invisible") {
                    this.data.AssetFolder = saveToFolder;
                }

                this.reviseData();
                this.save();

                // check if need to validate an asset first
                var validationObj = this.validatePages(this.data.Pages);

                if (validationObj.validation.valid) {
                    //Do not like, blame locking, a non-event / signalR based system and a naff Saving tree!
                    var currentPage = this.get($routeParams.pageId, false);
                    var pageAssetId = currentPage.UserResponseId !== void 0 ? currentPage.UserResponseId : currentPage.PageAssetId;
                    dataManagerService.checkBeforeSave(asset_id, "Webfolio", pageAssetId).then(
                        function () {
                            const promises = this.onValidationSuccess(asset_id, true);
                            deferred.resolve({ promises: $q.all(promises) });
                        }.bind(this)
                    );
                } else {
                    deferred.reject();
                    this.onValidationFail(validationObj);
                }

                return deferred.promise;
            };
            // gets first page horizontally
            this.getTopLevelInitialPageFromArray = function (array) {
                array = array || this.data.Pages;

                for (var i = 0; i < array.length; i++) {
                    if (array[i].PageType !== "Webfolio" && array[i].PageType !== "WorkBook") {
                        return array[i];
                    }
                }

                for (var n = 0; n < array.length; n++) {
                    if (array[n].Children.length > 0) {
                        var foundData = this.getTopLevelInitialPageFromArray(array[n].Children);
                        if (foundData) {
                            return foundData;
                        }
                    }
                }

                return null;
            };

            // gets first page vertically
            this.getFirstPage = function (targetedPages) {
                const pages = Array.isArray(targetedPages) ? targetedPages : this.data.Pages;
                const pageSearch = new PageModelSearch(pages);

                return pageSearch.find((p) => p.PageType !== ASSET_CONSTANTS.TYPES.WEBFOLIO && p.PageType !== ASSET_CONSTANTS.TYPES.WORKBOOK);
            };

            this.getIdsTrace = function (id, targetedPages) {
                const pages = Array.isArray(targetedPages) ? targetedPages : this.data.Pages;
                const match = new PageModelSearch(pages).tracedFind(byPageId(id));

                if (match === null) {
                    return [];
                }

                const trace = match.parents.map((p) => p.PageId);
                trace.unshift(id);

                return trace;
            };

            /**
             * WARNING: Dangerously obscure code ahead.
             * Can return -1 (page not found), a PageViewModel, or { isRootLevel: boolean, page: PageViewModel, parents: { parent: PageViewModel, rootParent: PageViewModel } | Array<PageViewModel> }
             * Omitting `getParent` or passing it in as false, will result in a return value of -1 or a PageViewModel
             * If `getParent` is true and the page is not found, will result in -1.
             * If `getParent` is true and the page is at the root, will result in:
             *      { isRootLevel: true, page: PageViewModel, parents: Array<PageViewModel> }
             * If `getParent` is true and the page's direct parent is at the root, will result in:
             *      { isRootLevel: true, page: PageViewModel, parents: { parent: Parent's PageViewModel, rootParent: Parent's PageViewModel } }
             * If `getParent` is true and the page's direct parent is not at the root, will result in:
             *      { isRootLevel: true, page: PageViewModel, parents: { parent: Parent's PageViewModel, rootParent: Root Parent's PageViewModel } }
             */
            this.get = function (id, getParent, targetedPages) {
                const pages = Array.isArray(targetedPages) ? targetedPages : this.data.Pages;
                const pageSearch = new PageModelSearch(pages);

                if (!getParent) {
                    const match = pageSearch.find(byPageId(id));
                    return match !== null ? match : -1;
                }

                const match = pageSearch.tracedFind(byPageId(id));
                if (match === null) {
                    return -1;
                }

                const isPageOrParentRootLevel = match.parents.length <= 1;
                const parents = match.parents.length > 0 ? { parent: match.parents[0], rootParent: match.parents[match.parents.length - 1] } : pages;

                return {
                    isRootLevel: isPageOrParentRootLevel,
                    page: match.page,
                    parents: parents
                };
            };

            this.getByResponseId = function (id) {
                return new PageModelSearch(this.data.Pages).find((p) => p.UserResponseId === id);
            };

            this.isRootLevel = function (id, targetedPages) {
                const pages = Array.isArray(targetedPages) ? targetedPages : this.data.Pages;
                return pages.some(byPageId(id));
            };

            this.removeFromStorage = function () {
                var itemsToRemoveFromStorage = [];
                itemsToRemoveFromStorage.push(this.local_storage_reference); // get portfolio navigation id
                itemsToRemoveFromStorage = this.getAllIds(this.local_storage_reference);

                for (var n = 0; n < itemsToRemoveFromStorage.length; n++) {
                    persistentObjectStore.remove(itemsToRemoveFromStorage[n]); // remove each thing from local storage
                }
            };

            this.isPageTopLevel = function (id) {
                return this.isRootLevel(id);
            };

            this.add = function (dto) {
                return this._beforePageResponseUpdate().then(() => {
                    const pageHandler = assetPageHandlerFactory.createPortfolioPageHandler(this.data, new AssetContext(this.AssetId));
                    return pageHandler.addPage(this._normaliseNewPageModel(dto)).then((p) => p.PageId);
                });
            };

            this.addNested = function (dto, parentId) {
                return this._beforePageResponseUpdate().then(() => {
                    const pageHandler = assetPageHandlerFactory.createPortfolioPageHandler(this.data, new AssetContext(this.AssetId));
                    return pageHandler.addNestedPage(this._normaliseNewPageModel(dto), parentId).then((p) => p.PageId);
                });
            };

            this._normaliseNewPageModel = function (pageDto) {
                const pageViewModel = dtoFactory.create(pageDto);
                if (pageViewModel.PageTitle === "") {
                    pageViewModel.PageTitle = multiLanguageService.getString("labels.navigation.new_page");
                }
                return pageViewModel;
            };

            this.updatePageTitle = function (id, title) {
                const pageHandler = assetPageHandlerFactory.createPortfolioPageHandler(this.data, new AssetContext(this.AssetId));
                return pageHandler.updatePageTitle(id, title).then(() => {
                    if (!this.is_saved) {
                        this.save();
                    }
                });
            };

            this.findClosestPage = function (originalIndex, array, isRootLevelPage) {
                var closestIndex = originalIndex;
                var closestIndexTemp;
                var initialPage;
                var canContainChildren = ["webfolio", "workbook", "workbookresponse"];

                if (array.length === 1) {
                    return false;
                }

                closestIndexTemp = closestIndex - 1;
                while (closestIndexTemp >= 0) {
                    if (canContainChildren.indexOf(array[closestIndexTemp].PageType.toLowerCase()) < 0) {
                        return array[closestIndexTemp];
                    } else if (isRootLevelPage) {
                        initialPage = this.getTopLevelInitialPageFromArray(array[closestIndexTemp].Children);
                        if (initialPage) {
                            return initialPage;
                        }
                    }
                    closestIndexTemp--;
                }

                closestIndexTemp = closestIndex + 1;
                while (closestIndexTemp < array.length) {
                    if (canContainChildren.indexOf(array[closestIndexTemp].PageType.toLowerCase()) < 0) {
                        return array[closestIndexTemp];
                    } else if (isRootLevelPage) {
                        initialPage = this.getTopLevelInitialPageFromArray(array[closestIndexTemp].Children);
                        if (initialPage) {
                            return initialPage;
                        }
                    }
                    closestIndexTemp++;
                }

                return false;
            };

            this.findClosest = function (id) {
                var foundData = this.get(id, true);
                var parentId = foundData.parents.parent ? foundData.parents.parent.PageId : null;
                var isRootLevelPage = !parentId,
                    originalIndex,
                    closest;

                foundData.parents = foundData.parents.parent ? foundData.parents.parent.Children : foundData.parents;

                // get current (page that going to be removed) page index in data array
                originalIndex = foundData.parents.indexOf(foundData.page);

                // find closest to originalIndex page
                closest = this.findClosestPage(originalIndex, foundData.parents, isRootLevelPage);

                // if page can't be found in local array, then take parent array.
                if (!closest && parentId) {
                    return this.findClosest(parentId);
                }

                return closest;
            };

            this.remove = function (id) {
                return this._beforePageResponseUpdate().then(() => {
                    const pageHandler = assetPageHandlerFactory.createPortfolioPageHandler(this.data, new AssetContext(this.AssetId));
                    return pageHandler.removePageById(id);
                });
            };

            this.getNestedParent = function (pageId, pages) {
                let parent = null;
                for (let i = 0, l = pages.length, page; i < l; i++) {
                    page = pages[i];

                    parent = page.Children.findInNestedObject("PageId", pageId) !== -1 ? page : this.getNestedParent(pageId, page.Children);
                    if (parent) {
                        break;
                    }
                }

                return parent;
            };

            this._beforePageResponseUpdate = () => {
                const action = this.is_saved ? $q.resolve() : this.saveWebfolioToServer(this.AssetId, this.data.AssetFolder);

                return action.then(() => {
                    if (this.data.BaseVersion === 0 && this.data.AssetFolder === ASSET_CONSTANTS.FOLDERS.INVISIBLE) {
                        this.reviseData({ onlyIsSavedFlag: true });
                    }
                });
            };

            this.updatePageResponse = (pageId, responseId) => {
                return this._beforePageResponseUpdate().then(() => {
                    const pageHandler = assetPageHandlerFactory.createPortfolioPageHandler(this.data, new AssetContext(this.AssetId));
                    return pageHandler.updatePageResponseById(pageId, responseId);
                });
            };

            this.removePageResponse = (pageId) => {
                return this._beforePageResponseUpdate().then(() => {
                    const pageHandler = assetPageHandlerFactory.createPortfolioPageHandler(this.data, new AssetContext(this.AssetId));
                    return pageHandler.removePageResponseById(pageId);
                });
            };

            this.convertPage = (pageId) => {
                return this._beforePageResponseUpdate().then(() => {
                    const pageHandler = assetPageHandlerFactory.createPortfolioPageHandler(this.data, new AssetContext(this.AssetId));
                    return pageHandler.convertPage(pageId);
                });
            };

            /**
             * @param {{PageId: string}} pageToMove
             * @param {string | null} beforePageId
             * @param {Array<{PageId: string}>} siblings
             * @returns boolean
             */
            this._movePage = (pageToMove, beforePageId, siblings) => {
                const match = new PageModelSearch(this.data.Pages).tracedFind(byPageId(pageToMove.PageId));
                if (match === null) {
                    return false;
                }

                const parentList = match.parents.length > 0 ? match.parents[0].Children : this.data.Pages;
                const index = parentList.indexOf(match.page);
                parentList.splice(index, 1);

                const hasPageMovedToEnd = beforePageId === null;
                const newPagesIndex = hasPageMovedToEnd ? siblings.length : siblings.findIndex(byPageId(beforePageId));
                siblings.splice(newPagesIndex, 0, pageToMove);
                return true;
            };

            /**
            @param {{pageToMoveId: string, beforePageId: null | string, afterPageId: null | string}} pageMoveData
            @return {Promise<boolean>}
            */
            this.savePageMoveToServer = function (pageMoveData) {
                const pageToMove = this.get(pageMoveData.pageToMoveId, true, this.data.Pages);
                if (pageToMove === -1) {
                    return $q.reject(new Error("Could not find page to move"));
                }
                if (!this._canRemovePage(pageToMove)) {
                    return $q.reject(new Error("Page can not be moved"));
                }

                let directParentId = "";
                let siblings = null;
                if (Array.isArray(pageToMove.parents)) {
                    directParentId = this.AssetId;
                    siblings = pageToMove.parents;
                } else {
                    directParentId = pageToMove.parents.parent.PageAssetId;
                    siblings = pageToMove.parents.parent.Children;
                }

                const endpointMoveData = { Id: pageMoveData.pageToMoveId };
                if (pageMoveData.beforePageId !== null) {
                    endpointMoveData.Before = pageMoveData.beforePageId;
                }
                if (pageMoveData.afterPageId !== null) {
                    endpointMoveData.After = pageMoveData.afterPageId;
                }

                return AssetEndpointService.movePortfolioPage(directParentId, endpointMoveData).then(() => {
                    const result = this._movePage(pageToMove.page, pageMoveData.beforePageId, siblings);
                    if (!result) {
                        throw new Error("Unexpected error when moving page locally");
                    }
                });
            };

            this._canRemovePage = function (data) {
                if (data && data.parents && !data.parents.rootParent && !data.parents.parent) {
                    if (data.parents.length === 1) {
                        return false;
                    }
                }
                return true;
            };
        };

        var BuilderManager = (function () {
            helpers.extendClass(BuilderManager, DataManager);

            function BuilderManager(dto, managerType) {
                this.managerType = managerType || null;
                this.blockDefaultSize = "";
                DataManager.apply(this, arguments);
            }

            BuilderManager.prototype.cascadeBlockSettingsFromAsset = function () {
                for (var i = 0; i < this.data.Sections.length; i++) {
                    var current_row_section = this.data.Sections[i];
                    for (var j = 0; j < current_row_section.Sections.length; j++) {
                        var current_block = current_row_section.Sections[j];
                        helperService.cascadeBlockSettingsFromAsset(current_block, this.data);
                    }
                }
                //update local
                this.reviseData();
                this.save();
                $rootScope.$broadcast("updateBlockData");
            };

            BuilderManager.prototype.completeDataProcess = function () {
                dataManagerService.current_page = this.AssetId;
                dataManagerService.dtos[this.local_storage_reference] = this; // add to the local storage data list
                dataManagerService[this.managerType] = this;
            };

            BuilderManager.prototype.get = function (ids) {
                if (this.data && this.data.Sections && ids) {
                    return this.getFromRows(ids, this.data.Sections);
                }
                return -1;
            };

            BuilderManager.prototype.getFromRows = function (ids, rows) {
                if (rows && ids) {
                    for (var i = 0; i < rows.length; i++) {
                        if (ids.RowSectionId && rows[i].Id === ids.RowSectionId) {
                            var current_row_section = rows[i];
                            if (ids.BlockId) {
                                for (var j = 0; j < current_row_section.Sections.length; j++) {
                                    if (current_row_section.Sections[j].Id === ids.BlockId) {
                                        var block = current_row_section.Sections[j].ContentSection ? current_row_section.Sections[j].ContentSection : current_row_section.Sections[j];
                                        return {
                                            RowSection: current_row_section,
                                            Block: block,
                                            BlockWrapper: current_row_section.Sections[j].ContentSection ? current_row_section.Sections[j] : null
                                        };
                                    }

                                    if (current_row_section.Sections[j].SectionType === "BuilderFormBasicContainer") {
                                        // check to see if this is a container element, if so check the children
                                        var nested_return = this.getFromRows(ids, current_row_section.Sections[j].Elements);
                                        // tslint:disable-next-line:triple-equals
                                        if (nested_return != -1) {
                                            return nested_return;
                                        }
                                    }
                                }
                            } else {
                                return { RowSection: current_row_section, Block: null, BlockWrapper: null };
                            }
                        } else {
                            // although the row id is incorrect the row could be a child of this row if it has any "BuilderFormBasicContainer" sections within
                            var parent_row = rows[i];
                            for (var x = 0; x < parent_row.Sections.length; x++) {
                                if (parent_row.Sections[x].SectionType === "BuilderFormBasicContainer") {
                                    var row_nested_return = this.getFromRows(ids, parent_row.Sections[x].Elements);
                                    // tslint:disable-next-line:triple-equals
                                    if (row_nested_return != -1) {
                                        return row_nested_return;
                                    }
                                }
                            }
                        }
                    }
                }

                return -1;
            };

            BuilderManager.prototype.updateDtoTitle = function (data, title) {
                data.Title = title;
                this.reviseData();
            };

            BuilderManager.prototype.getRowIndex = function (id) {
                return this.data.Sections.map(function (x) {
                    return x.Id;
                }).indexOf(id);
            };

            function defaultBlockHandler(blockDto) {
                replaceIdsRecursive(blockDto, ["Id"]);
            }

            var specialMethods = {
                GenericBuilderTable: tableDtoHelper.duplicateTable,
                default: defaultBlockHandler
            };

            BuilderManager.prototype.duplicateSection = function (sectionDto, blockId, checkStandardWidths) {
                var duplicatedSection = angular.copy(sectionDto),
                    targetId = duplicatedSection.Id;
                duplicatedSection.Id = helperService.guid();

                //Reset section to only contain 1 block.
                if (duplicatedSection.Sections.length > 1) {
                    duplicatedSection.SectionIsFull = false;
                    var index = duplicatedSection.Sections.findInNestedObject("Id", blockId);

                    if (index !== -1) {
                        var block = duplicatedSection.Sections[index];
                        duplicatedSection.Sections.length = 0;
                        duplicatedSection.Sections.push(block);

                        helperService.resetSectionSize(duplicatedSection, block.SectionType, checkStandardWidths);
                        duplicatedSection.SectionDuplicated = true;
                    }
                }

                var blockDto = duplicatedSection.Sections[0];
                (specialMethods[blockDto.SectionType] || specialMethods["default"])(blockDto);
                var newBlockId = blockDto.Id;

                this.insertRowAtPosition(targetId, duplicatedSection, "bottom");
                this.reviseData();
                this.save();

                this.updateParent(true);

                return { RowSectionId: duplicatedSection.Id, BlockId: newBlockId };
            };

            BuilderManager.prototype.insertRowAtPosition = function (targetId, sectionDto, position) {
                var rowIndex;

                if (targetId) {
                    if (position === "top") {
                        rowIndex = this.getRowIndex(targetId);
                        this.data.Sections.splice(rowIndex, 0, sectionDto);
                    } else if (position === "bottom") {
                        rowIndex = this.getRowIndex(targetId);
                        this.data.Sections.splice(rowIndex + 1, 0, sectionDto);
                    }
                } else {
                    this.data.Sections.push(sectionDto);
                }
            };

            BuilderManager.prototype.addNew = function (dto, row_section_id, position) {
                var row_index,
                    target_row_item = this.get({ RowSectionId: row_section_id }).RowSection; // all blocks wrappers with rowSection DTO

                if (this.managerType === "templateBuilderManagerObject") {
                    // work out size of new section... banners for example default to large
                    var row_item = new dtoFactory.BuilderFormElementRowDto(
                        helperService.getDefaultRowSize(dto.MainType, this.blockDefaultSize),
                        helperService.getDefaultRowBackgroundColour(dto.MainType),
                        helperService.setSectionIsFull(dto.MainType),
                        helperService.getDefaultBlockWidth(dto.MainType)
                    ); // all blocks wrappers with rowSection DTO
                } else {
                    // work out size of new section... banners for example default to large
                    var row_item = new dtoFactory.RowSectionDto(
                        helperService.getDefaultRowSize(dto.MainType, this.blockDefaultSize),
                        helperService.getDefaultRowBackgroundColour(dto.MainType),
                        helperService.setSectionIsFull(dto.MainType),
                        helperService.getDefaultBlockWidth(dto.MainType)
                    ); // all blocks wrappers with rowSection DTO
                }

                var item = dtoFactory.create(dto); // create actual block DTO
                if (this.managerType === "templateBuilderManagerObject") {
                    helperService.cascadeBlockSettingsFromAsset(item, this.data);
                }
                row_item.Sections.push(item); // add block to the row section
                this.insertRowAtPosition(target_row_item ? target_row_item.Id : null, row_item, position);

                return { RowSectionId: row_item.Id, BlockId: item.Id };
            };

            BuilderManager.prototype.addToRow = function (dto, row_section_id, position) {
                var item;
                var row_item = this.get({ RowSectionId: row_section_id }).RowSection; // all blocks wrappers with rowSection DTO
                item = dto.MainType !== undefined ? dtoFactory.create(dto) : dto; // create actual block DTO

                if (position === "left") {
                    row_item.Sections.unshift(item);
                } else if (position === "right") {
                    row_item.Sections.push(item); // add block to the row section
                }

                row_item.SectionIsFull = true;

                return { RowSectionId: row_item.Id, BlockId: item.Id };
            };

            BuilderManager.prototype.add = function (dto, row_section_id, position) {
                var ids;
                // add new dto
                if (["top", "bottom", undefined].indexOf(position) > -1) {
                    ids = this.addNew(dto, row_section_id, position);
                }
                // add to existing row section dto
                else if (["left", "right"].indexOf(position) > -1) {
                    ids = this.addToRow(dto, row_section_id, position);
                }

                this.reviseData();
                this.updateParent(true);

                $rootScope.$broadcast("adding-block-to-builder");

                if (this.save()) {
                    return ids;
                } else {
                    return ids;
                }
            };

            // tslint:disable-next-line:no-empty
            BuilderManager.prototype.updateParent = function () {}; //Exists to ensure that any Class which inherits BuilderManager and does not have its own updateParent Method, runs without error

            BuilderManager.prototype.checkIfAloudToMove = function (row_id, direction) {
                var location_index;

                if (direction === "top") {
                    location_index = this.getRowIndex(row_id) - 1;
                    return this.data.Sections[location_index].SectionUneditable;
                }
            };

            BuilderManager.prototype.moveTo = function (ids, position, row_id) {
                var location_index,
                    items = angular.copy(this.get(ids)); // item that will be rearranged

                if (!row_id) {
                    location_index = this.getRowIndex(ids.RowSectionId);
                }

                this.remove(ids);

                if (["top", "bottom"].indexOf(position) > -1) {
                    if (row_id) {
                        location_index = this.getRowIndex(row_id);
                    }

                    if (position === "top" && !row_id) {
                        location_index -= 1;
                    }

                    if (position === "bottom") {
                        location_index += 1;
                    }

                    // move just block to new location + create new section dto for this block
                    if (items.RowSection.Sections.length > 1 && row_id) {
                        if (this.managerType === "templateBuilderManagerObject") {
                            // work out size of new section... banners for example default to large
                            var row_item = new dtoFactory.BuilderFormElementRowDto(
                                helperService.getDefaultRowSize(items.Block.SectionType),
                                helperService.getDefaultRowBackgroundColour(items.Block.SectionType),
                                helperService.setSectionIsFull(items.Block.MainType),
                                helperService.getDefaultBlockWidth(items.Block.MainType)
                            ); // all blocks wrappers with rowSection DTO
                        } else {
                            //here is where we use helper for default  size and background color for the row
                            var row_item = new dtoFactory.RowSectionDto(
                                helperService.getDefaultRowSize(items.Block.SectionType),
                                helperService.getDefaultRowBackgroundColour(items.Block.SectionType),
                                false,
                                helperService.getDefaultBlockWidth(items.Block.MainType)
                            ); // create new section for dragged block
                        }

                        row_item.Sections.push(items.Block);
                        this.data.Sections.splice(location_index, 0, row_item);
                        ids = { RowSectionId: row_item.Id, BlockId: items.Block.Id };
                    }
                    // move entire section
                    else {
                        this.data.Sections.splice(location_index, 0, items.RowSection);
                    }
                } else {
                    ids = this.addToRow(items.Block, row_id, position);
                }

                this.reviseData();
                this.updateParent(true);

                // If saved then return IDs, else revert all changes
                if (this.save()) {
                    return ids;
                } else {
                    return false;
                }
            };

            BuilderManager.prototype.remove = function (ids) {
                var result = false;

                for (var i = 0; i < this.data.Sections.length; i++) {
                    if (this.data.Sections[i].Id === ids["RowSectionId"]) {
                        if (this.data.Sections[i].Sections.length === 1 || !ids["BlockId"]) {
                            this.data.Sections.splice(i, 1);
                        } else {
                            var current_section = this.data.Sections[i];
                            for (var j = 0; j < current_section.Sections.length; j++) {
                                if (current_section.Sections[j].Id === ids["BlockId"]) {
                                    current_section.Sections.splice(j, 1);
                                    current_section.SectionIsFull = false;
                                }
                            }
                        }
                        result = true;
                    }
                }

                this.reviseData();
                this.updateParent(true);

                return result && this.save();
            };

            BuilderManager.prototype.removeFromStorage = function () {
                persistentObjectStore.remove(this.local_storage_reference);
            };

            BuilderManager.prototype.update = function (ids, obj, row_section_obj, only_styles, block_wrapper) {
                var dtos = this.get(ids);
                var item_row = dtos.RowSection; // get row
                var item_block = dtos.Block; // get block
                var item_block_wrapper = dtos.BlockWrapper;

                var original_block = angular.copy(item_block); // backup object
                var original_row = angular.copy(item_row); // backup object
                var currentRevisionNumber = this.data.Revision;

                // tslint:disable-next-line:triple-equals
                if (item_row != null && item_block != null) {
                    only_styles ? helperService.updateObject(item_block.StyleOptions, obj.StyleOptions) : helperService.updateObject(item_block, obj);
                    only_styles ? helperService.updateObject(item_row.StyleOptions, row_section_obj.StyleOptions) : helperService.updateObject(item_row, row_section_obj);
                    if (item_block_wrapper) {
                        helperService.updateObject(item_block_wrapper, block_wrapper);
                    }

                    this.reviseData();

                    if (this.save()) {
                        return true;
                    } else {
                        only_styles ? helperService.updateObject(item_block.StyleOptions, original_block.StyleOptions) : helperService.updateObject(item_block, original_block);
                        only_styles ? helperService.updateObject(item_row.StyleOptions, original_row.StyleOptions) : helperService.updateObject(item_row, original_row);
                        this.data.Revision = currentRevisionNumber;
                    }
                }
                return false;
            };

            BuilderManager.prototype.saveToServer = function (asset_id) {
                var promises = [],
                    deferred = $q.defer(),
                    managerObject = this;

                // if this is in the invisible folder it means its just been created....
                //...and therefore on first save we need to change it to main folder
                if (managerObject.data.AssetFolder === "Invisible") {
                    managerObject.data.AssetFolder = "Main";
                }

                this.reviseData();
                managerObject.save();

                // find all pages to save
                var assetIdsToSave = [];
                assetIdsToSave.push(asset_id);

                dataManagerService.checkBeforeSave(asset_id, managerObject.data.MainType).then(
                    function () {
                        for (var i = 0; i < assetIdsToSave.length; i++) {
                            var assetFromStorage = this.loadSpecific(assetIdsToSave[i]);

                            // tslint:disable-next-line:triple-equals
                            if (assetFromStorage != false && assetFromStorage !== undefined && assetFromStorage !== null) {
                                // then we have an asset to save

                                // check revision number hasn't been set to NaN
                                if (isNaN(assetFromStorage.Revision)) {
                                    assetFromStorage.Revision = -1;
                                }

                                // only save pages with is_saved false... as they are the only ones to have changed
                                recoveryLogger.step("save", `Saving ${managerObject.data.MainType}`);
                                $http.defaults.withCredentials = true;
                                var promise = AssetEndpointService.saveOrUpdate({ model: assetFromStorage });
                                promises.push(promise.then(updateBaseVersion));
                            }
                        }

                        deferred.resolve({ promises: $q.all(promises) });
                    }.bind(this)
                );

                return deferred.promise;
            };

            return BuilderManager;
        })();

        /**
         * PageManager class
         * @param dto
         * @constructor
         */
        var PageManager = function (dto) {
            BuilderManager.apply(this, arguments); //Extend BuilderManager.
            this._parent = BuilderManager;
            this.managerType = "pageManagerObject";

            this.updateWebfolioSavedFlagIfExists = function () {
                this.updateParentSavedFlagIfExists();
            };

            this.moveTo = function (ids, position, row_id) {
                var returnedIds = this._parent.prototype.moveTo.call(this, ids, position, row_id);
                this.updateWebfolioSavedFlagIfExists();
                return returnedIds;
            };

            this.remove = function (ids) {
                var removed = this._parent.prototype.remove.call(this, ids);
                this.updateWebfolioSavedFlagIfExists();
                return removed;
            };

            this.update = function (ids, obj, row_section_obj, only_styles) {
                var updated = this._parent.prototype.update.call(this, ids, obj, row_section_obj, only_styles);
                this.updateWebfolioSavedFlagIfExists();
                return updated;
            };

            this.updateParent = function () {
                this.updateWebfolioSavedFlagIfExists(true);
            };

            this.saveFoliopageToServer = function (asset_id) {
                return this.saveToServer(asset_id);
            };
        };

        /**
         * PostManager class
         * @param dto
         * @constructor
         */
        var PostManager = function (dto) {
            BuilderManager.apply(this, arguments); //Extend BuilderManager.
            this._parent = BuilderManager;
            this.managerType = "postManagerObject";

            this.updateDtoModel = function (dto) {
                this.data = dto;
                this.is_saved = false;

                return false;
            };

            this.moveTo = function (ids, position, row_id) {
                return this._parent.prototype.moveTo.call(this, ids, position, row_id);
            };

            this.remove = function (ids) {
                return this._parent.prototype.remove.call(this, ids);
            };

            this.update = function (ids, obj, row_section_obj, only_styles) {
                return this._parent.prototype.update.call(this, ids, obj, row_section_obj, only_styles);
            };

            this.savePostToServer = function (asset_id) {
                return this.saveToServer(asset_id);
            };
        };

        /**
         * FormManager class
         * @param dto
         * @constructor
         */
        var FormManager = function (dto) {
            DataManager.apply(this, arguments); // extend from DataManager class

            //Get method for content sections in forms
            this.get = function (ids) {
                var elements = this.data.Elements;
                for (var i = 0; i < elements.length; i++) {
                    var element = elements[i];
                    if (element.Id === ids.RowSectionId) {
                        var block = element.Response !== null ? element.Response : element.Builder;
                        if (block && block.Id === ids.BlockId) {
                            return {
                                RowSection: element,
                                Block: block
                            };
                        }
                    }
                }

                return -1;
            };

            this.completeDataProcess = function () {
                dataManagerService.current_page = this.AssetId;
                dataManagerService.dtos[this.local_storage_reference] = this; // add to the local storage data list
                dataManagerService.formManagerObject = this;
            };

            this.updateWorkbookDto = function () {
                /* DON'T DO ANYTHING HERE, WORKBOOK SHOULD ONLY SAVE IF ITS ACTUALLY BEEN CHANGED*/
            };

            this.update = function (newElement, forceIsSavedToUpdate) {
                // make sure we have data to work with
                $rootScope.justBeenSigned = newElement.ElementType === "FormSignatureElement";
                const updateIsSaved = forceIsSavedToUpdate !== false;
                const matchedIndex = this.data.Elements.findIndex((element) => element.Id === newElement.Id);

                if (matchedIndex === -1) {
                    throw new Error("Matching element not found");
                }

                if (newElement.ElementType !== "FormSignatureElement") {
                    const signatureElements = this.data.Elements.filter((element) => element.ElementType === "FormSignatureElement" && !element.EditedSinceSigned);

                    for (const signatureElement of signatureElements) {
                        signatureElement.EditedSinceSigned = true;
                        $rootScope.$broadcast("warningDigitalSignature" + signatureElement.Id);
                    }
                }

                this.data.Elements[matchedIndex] = newElement;

                if (updateIsSaved) {
                    this.reviseData();
                    this.save();
                    this.updateParentSavedFlagIfExists();
                }

                return newElement;
            };

            this.checkMandatoryBeforeSave = function (assetId) {
                const mandatoryFormObj = this._mandatoryProgressFormCheck();
                let promise = null;
                if (mandatoryFormObj.invalidPages.length > 0) {
                    const wasOverlayVisible = overlayFactory.saveOverlay.isOverlayVisible();
                    overlayFactory.saveOverlay.hide();
                    promise = dataManagerService._showProgressResetModal().then(() => {
                        if (wasOverlayVisible) {
                            overlayFactory.saveOverlay.show();
                        }
                    });
                }

                return $q.when(promise).then(() => {
                    return this.saveTemplateToServer(assetId);
                });
            };

            this._mandatoryProgressFormCheck = function () {
                var returnObject = { invalidPages: [] };

                var form = this.loadSpecific(this.AssetId);
                if (form && form.Elements) {
                    // check mandatory field to see if they still pass
                    var pageFailed = false,
                        mandatoryObj = null;
                    for (var a = 0; a < form.Elements.length; a++) {
                        mandatoryObj = formComponentsHelper.checkIfMandatoryForCompletionValid(form.Elements[a]);
                        if (mandatoryObj.valid === false) {
                            pageFailed = true;
                            break;
                        }
                    }

                    // if its failed mandatory checks then update progress tracking completed to false
                    if (pageFailed) {
                        returnObject.invalidPages.push(this.AssetId); // add to list of valid pages
                        form.ProgressTrackingComplete = false;
                        this.reviseData();
                        this.save();
                    }
                }

                return returnObject;
            };

            this.saveTemplateToServer = function (asset_id, doNotChangeFolder) {
                var promises = [];
                var deferred = $q.defer();

                // if this is in the invisible folder it means its just been created....
                //...and therefore on first save we need to change it to main folder
                // tslint:disable-next-line:triple-equals
                if (doNotChangeFolder === null || doNotChangeFolder === undefined || doNotChangeFolder == false) {
                    if (this.data.AssetFolder === "Invisible") {
                        this.data.AssetFolder = "Main";
                    }
                }

                // make sure main object saves
                this.reviseData();
                this.save();

                // go through list of asset ids (in forms case its just the one) get it from local storage and save
                var assetIdsToSave = [];
                assetIdsToSave.push(asset_id);

                dataManagerService.checkBeforeSave(asset_id, "FormResponse").then(
                    function () {
                        for (var i = 0; i < assetIdsToSave.length; i++) {
                            var assetFromStorage = this.loadSpecific(assetIdsToSave[i]);

                            // tslint:disable-next-line:triple-equals
                            if (assetFromStorage != false && assetFromStorage !== undefined && assetFromStorage !== null) {
                                // then we have an asset to save

                                // check revision number hasn't been set to NaN
                                if (isNaN(assetFromStorage.Revision)) {
                                    assetFromStorage.Revision = -1;
                                }

                                // only save pages with is_saved false... as they are the only ones to have changed
                                recoveryLogger.step("save", "Saving FormResponse");
                                $http.defaults.withCredentials = true;
                                var promise = AssetEndpointService.saveOrUpdate({ model: assetFromStorage });
                                promises.push(promise.then(updateBaseVersion));

                                // ensure that the just saved items last modified date is set back into the past so any serverside items will override it
                            }
                        }

                        deferred.resolve({ promises: $q.all(promises) });
                    }.bind(this)
                );

                return deferred.promise;
            };
        };

        /**
         * Workbook manager
         **/
        var WorkbookManager = function (dto) {
            DataManager.apply(this, arguments); // extend from DataManager class
            this.managerType = "workbookManagerObject";

            this.completeDataProcess = function () {
                dataManagerService.dtos[this.local_storage_reference] = this; // add to the local storage data list
                dataManagerService[this.managerType] = this;
            };

            this.procurePage = (pageId, assetContext, isEditable) => {
                const procurePagePromise = assetPageHandlerFactory
                    .createWorkbookResponseHandler(this.data, assetContext)
                    .procurePageById(pageId, isEditable)
                    .then((result) => {
                        if (result.outdatedResponseId === "" || !isEditable) {
                            return result.page;
                        }

                        return dataManagerService.saveOrphanedAsset(result.outdatedResponseId).then(
                            () => result.page,
                            () => result.page
                        );
                    });

                if (isEditable) {
                    procurePagePromise.catch((rejection) => handleProcurePageError(rejection, this.AssetId, pageId, this));
                }

                return procurePagePromise;
            };

            this.getIdsTrace = function (id) {
                const match = new PageModelSearch(this.data.Pages).tracedFind(byPageId(id));
                if (match === null) {
                    return [];
                }

                const trace = match.parents.map((p) => p.PageId);
                trace.unshift(id);
                return trace;
            };

            /**
             * WARNING: Dangerously obscure code ahead.
             * Can return -1 (page not found), a PageViewModel, or { isRootLevel: boolean, page: PageViewModel, parents: { parent: PageViewModel, rootParent: PageViewModel } | Array<PageViewModel> }
             * Omitting `getParent` or passing it in as false, will result in a return value of -1 or a PageViewModel
             * If `getParent` is true and the page is not found, will result in -1.
             * If `getParent` is true and the page is at the root, will result in:
             *      { isRootLevel: true, page: PageViewModel, parents: Array<PageViewModel> }
             * If `getParent` is true and the page's direct parent is at the root, will result in:
             *      { isRootLevel: true, page: PageViewModel, parents: { parent: Parent's PageViewModel, rootParent: Parent's PageViewModel } }
             * If `getParent` is true and the page's direct parent is not at the root, will result in:
             *      { isRootLevel: true, page: PageViewModel, parents: { parent: Parent's PageViewModel, rootParent: Root Parent's PageViewModel } }
             */
            this.get = function (id, getParent, targetedPages) {
                const pages = Array.isArray(targetedPages) ? targetedPages : this.data.Pages;
                const pageSearch = new PageModelSearch(pages);

                if (!getParent) {
                    const match = pageSearch.find(byPageId(id));
                    return match !== null ? match : -1;
                }

                const match = pageSearch.tracedFind(byPageId(id));
                if (match === null) {
                    return -1;
                }

                const isPageOrParentRootLevel = match.parents.length <= 1;
                const parents = match.parents.length > 0 ? { parent: match.parents[0], rootParent: match.parents[match.parents.length - 1] } : pages;

                return {
                    isRootLevel: isPageOrParentRootLevel,
                    page: match.page,
                    parents: parents
                };
            };

            this.isRootLevel = function (id, targetedPages) {
                const pages = Array.isArray(targetedPages) ? targetedPages : this.data.Pages;
                return pages.some(byPageId(id));
            };

            this.findWorkbookPage = function (pageToFindId, workbookPages) {
                if (workbookPages === null || pageToFindId === null) {
                    return null;
                }

                return new PageModelSearch(workbookPages).find(byPageId(pageToFindId));
            };

            this.findFirstWorkbookPage = function (workbookPages) {
                if (workbookPages === null) {
                    return null;
                }

                return new PageModelSearch(workbookPages).find((p) => p.PageType !== ASSET_CONSTANTS.TYPES.WORKBOOK);
            };

            this.updatePageResponse = (pageId, responseId) => {
                return this._beforePageResponseUpdate().then(() => {
                    const pageHandler = assetPageHandlerFactory.createWorkbookResponseHandler(this.data, new AssetContext(this.AssetId));
                    return pageHandler.updatePageResponseById(pageId, responseId);
                });
            };

            this.updatePageCompleteStatusById = (pageId, complete) => {
                return this._beforePageResponseUpdate().then(() => {
                    const pageHandler = assetPageHandlerFactory.createWorkbookResponseHandler(this.data, new AssetContext(this.AssetId));
                    return pageHandler.updatePageCompleteStatusById(pageId, complete);
                });
            };

            this.removePageResponse = (pageId) => {
                return this._beforePageResponseUpdate().then(() => {
                    const pageHandler = assetPageHandlerFactory.createWorkbookResponseHandler(this.data, new AssetContext(this.AssetId));
                    return pageHandler.removePageById(pageId);
                });
            };

            this._beforePageResponseUpdate = () => {
                const isSaved = this.is_saved && this.checkForIsSavedAnywhereInWorkbook(this.AssetId);
                return !isSaved ? this.saveWorkbookToServer(this.AssetId, false, null, true) : $q.resolve();
            };

            this.getUnsavedPageAssetIds = function (workbookPages) {
                return this.findAllUserFilledInPageIds(workbookPages).filter((id) => persistentObjectStore.has(id));
            };

            this.findAllUserFilledInPageIds = function (workbookPages) {
                if (workbookPages === null || workbookPages === undefined) {
                    return [];
                }

                const ignoreNestedWorkbooks = (p) => p.PageType !== ASSET_CONSTANTS.TYPES.WORKBOOK;
                const withoutDuplicatePageResponses = withoutDuplicatePageResponsesFactory();
                return new PageModelSearch(workbookPages).filter(withoutEmptyPageAssetIds, withoutDuplicatePageResponses, ignoreNestedWorkbooks).map((p) => p.UserResponseId);
            };

            this.update = function (pageId, obj) {
                var nav_page_item;

                if (pageId) {
                    nav_page_item = this.get(pageId); // get block
                } else {
                    nav_page_item = this.data; // update property on main DTO
                }

                var original_item = angular.copy(nav_page_item); // backup object
                var currentRevision = this.data.Revision;

                // tslint:disable-next-line:triple-equals
                if (nav_page_item != null) {
                    helperService.updateObject(nav_page_item, obj);
                    this.reviseData();

                    if (this.save()) {
                        return true;
                    } else {
                        helperService.updateObject(nav_page_item, original_item);
                        this.data.Revision = currentRevision;
                    }
                }
                return false;
            };

            this.saveWorkbookToServer = function (asset_id, save_workbook_only, forceLocalSaveOnPagesArr, force_main_folder) {
                var workbookManagerObj = this,
                    promises = [],
                    deferred = $q.defer();

                if (save_workbook_only === null || save_workbook_only === undefined) {
                    save_workbook_only = false;
                }

                if (forceLocalSaveOnPagesArr === null || forceLocalSaveOnPagesArr === undefined) {
                    forceLocalSaveOnPagesArr = [];
                }

                if (force_main_folder === null || force_main_folder === undefined) {
                    force_main_folder = false;
                }

                // if this is in the invisible folder it means its just been created....
                //...and therefore on first save we need to change it to main folder
                var autosubmit = false;
                // tslint:disable-next-line:triple-equals
                if ((force_main_folder || save_workbook_only != true) && workbookManagerObj.data.AssetFolder === "Invisible") {
                    workbookManagerObj.data.AssetFolder = "Main";
                    autosubmit = true;
                }

                this.reviseData();
                workbookManagerObj.save();

                var assetIdsToSave = [];
                var currentPage = this.get($routeParams.pageId, false); //Have to find the page id to check for locking...Do not like, blame locking!
                var pageAssetId = currentPage.UserResponseId !== void 0 ? currentPage.UserResponseId : currentPage.PageAssetId;

                // find all pages to save
                // tslint:disable-next-line:triple-equals
                if (save_workbook_only == false) {
                    assetIdsToSave = this.getUnsavedPageAssetIds(workbookManagerObj.data.Pages);
                }
                // add main workbooks id
                assetIdsToSave.push(asset_id);

                dataManagerService
                    .checkBeforeSave(asset_id, "Webfolio", pageAssetId)
                    .then(() => {
                        // get current workbook/pages revisions from server
                        for (var i = 0; i < assetIdsToSave.length; i++) {
                            var assetFromStorage = this.loadSpecific(assetIdsToSave[i]);

                            // tslint:disable-next-line:triple-equals
                            if (assetFromStorage != false && assetFromStorage !== undefined && assetFromStorage !== null && assetFromStorage.is_saved === false) {
                                $http.defaults.withCredentials = true;

                                // check revision number hasn't been set to NaN
                                if (isNaN(assetFromStorage.Revision)) {
                                    assetFromStorage.Revision = -1;
                                }
                                recoveryLogger.step("save", "Saving WorkbookResponse");
                                var promise = AssetEndpointService.saveOrUpdate({ model: assetFromStorage });
                                promise.then(function (response) {
                                    var tempDto = dataManagerService.dtos[response.data.AssetId];
                                    if (tempDto !== null && tempDto !== undefined) {
                                        tempDto.is_saved = true;
                                        tempDto.data.is_saved = true;
                                        tempDto.data.BaseVersion = response.data.Asset.Revision;
                                        if (tempDto.data.Revision < response.data.Asset.Revision) {
                                            tempDto.data.Revision = response.data.Asset.Revision;
                                        }
                                    }

                                    // if this is the main asset then check for any autosubmit asset options we need to remove
                                    // tslint:disable-next-line:triple-equals
                                    if (asset_id == response.data.AssetId) {
                                        if (workbookManagerObj !== null && workbookManagerObj !== undefined) {
                                            workbookManagerObj.is_saved = true;
                                            if (workbookManagerObj.data !== null && workbookManagerObj.data !== undefined) {
                                                workbookManagerObj.data.is_saved = true;

                                                if (autosubmit) {
                                                    if (
                                                        workbookManagerObj.data.AssetOptions !== null &&
                                                        workbookManagerObj.data.AssetOptions !== undefined &&
                                                        workbookManagerObj.data.AssetOptions.length > 0
                                                    ) {
                                                        for (var k = 0; k < workbookManagerObj.data.AssetOptions.length; k++) {
                                                            if (workbookManagerObj.data.AssetOptions[k].Key === "AutoSubmitted" && workbookManagerObj.data.AssetOptions[k].Value === "false") {
                                                                workbookManagerObj.data.AssetOptions[k].Value = "true";
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }

                                        if (autosubmit) {
                                            // ONLY ATTEMPT TO REMOVE AUTOSUBMIT IF USER HAS SAVED.... IF THIS FLAG IS TRUE THEY DIDN@T
                                            if (tempDto !== null && tempDto !== undefined) {
                                                if (tempDto.data.AssetOptions !== null && tempDto.data.AssetOptions !== undefined && tempDto.data.AssetOptions.length > 0) {
                                                    for (var j = 0; j < tempDto.data.AssetOptions.length; j++) {
                                                        if (tempDto.data.AssetOptions[j].Key === "AutoSubmitted" && tempDto.data.AssetOptions[j].Value === "false") {
                                                            tempDto.data.AssetOptions[j].Value = "true";
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }

                                    dataManagerService.destroy(response.data.AssetId); // remove item
                                }, angular.noop);

                                promises.push(promise);
                            } else {
                                dataManagerService.destroy(assetIdsToSave[i]); // remove item
                            }
                        }

                        deferred.resolve({ promises: $q.all(promises) });
                    })
                    .catch((err) => {
                        deferred.reject(err);
                    });

                $rootScope.$broadcast("savingWorkbook", this.AssetId, deferred.promise);
                return deferred.promise;
            };

            this.removeWorkbookFromStorage = function () {
                var asset_id = this.local_storage_reference;
                if (!this.data || this.data.Pages.length <= 0) {
                    return;
                }
                // find all pages to remove
                var assetIdsToRemove = this.findAllUserFilledInPageIds(this.data.Pages);
                assetIdsToRemove.push(asset_id);
                angular.forEach(assetIdsToRemove, function (key) {
                    dataManagerService.destroy(key); // remove item
                });
            };

            this.validateWorkbook = function () {
                var validationObj = this.validatePages(this.data.Pages);
                return validationObj;
            };

            this.validatePages = function (page_array) {
                for (var i = 0; i < page_array.length; i++) {
                    var page = page_array[i];
                    if (page.PageType === "Form") {
                        // tslint:disable-next-line:triple-equals
                        if (page.UserResponseId != null) {
                            const form = persistentObjectStore.retrieve(page.UserResponseId, user_id);
                            if (form !== null) {
                                var formValidationObj = formComponentsHelper.formValidation(form, true, page.PageTitle);
                                //update form in local storage
                                this.save(page.UserResponseId, form);

                                if (!formValidationObj.valid) {
                                    return { pageId: page.PageId, validation: formValidationObj };
                                }
                            }
                        }
                    } else if (page.PageType === "WorkBook" || page.PageType === "WorkBookResponse") {
                        return this.validatePages(page.Children);
                    }
                }
                return { pageId: "", validation: { valid: true } };
            };

            this.checkMandatoryBeforeSave = function (assetId, options = {}) {
                const mandatoryObj = this._mandatoryProgressWorkbookCheck();
                let promise = null;

                if (mandatoryObj.invalidPages.length > 0) {
                    const wasOverlayVisible = overlayFactory.saveOverlay.isOverlayVisible();
                    overlayFactory.saveOverlay.hide();
                    promise = dataManagerService._showProgressResetModal(options.modalLanguage).then(() => {
                        if (wasOverlayVisible) {
                            overlayFactory.saveOverlay.show();
                        }
                        const pageHandler = assetPageHandlerFactory.createWorkbookResponseHandler(this.data, new AssetContext(assetId));
                        const promises = mandatoryObj.invalidPages.map((pageId) => {
                            return pageHandler.updatePageCompleteStatusById(pageId, false);
                        });
                        return $q.all(promises);
                    });
                }

                return $q
                    .when(promise)
                    .then(() => {
                        return this.saveWorkbookToServer(assetId, false, null, options.forceMainFolder || false);
                    })
                    .then((serverResponses) => {
                        return {
                            serverResponses: serverResponses,
                            mandatoryFieldsResult: mandatoryObj
                        };
                    });
            };

            this._mandatoryProgressWorkbookCheck = function () {
                // The purpose of this method is to check any modified pages to see if...
                // a) They are a template page
                // b) They are set to completed (progress tracking)
                // c) if they are set to completed then check the user hasn't invalidated any mandatory fields since setting it to complete
                // d) if they have invalidated then set that page to incomplete and report which pages have been altered
                // NOTE: must check the progress tracking information stored on the workbook response NOT the fields stored on the forms themselves...
                // ... The ones on the forms themselves are only used when using progress tracking without workbooks (i.e. current only collections)
                return this._checkMandatoryProgressOnPages(this.data.Pages);
            };

            this._checkMandatoryProgressOnPages = function (page_array) {
                var returnObject = { invalidPages: [] },
                    form = null,
                    pageFailed = false,
                    mandatoryObj = null,
                    page = null;

                for (var i = 0; i < page_array.length; i++) {
                    page = page_array[i];
                    if (page.PageType === "Form") {
                        // tslint:disable-next-line:triple-equals
                        if (page.UserResponseId != null && page.ProgressTrackingEnabled === true && page.ProgressTrackingComplete === true) {
                            form = this.loadSpecific(page.UserResponseId);
                            if (form && form.Elements) {
                                // check mandatory field to see if they still pass
                                for (var a = 0; a < form.Elements.length; a++) {
                                    mandatoryObj = formComponentsHelper.checkIfMandatoryForCompletionValid(form.Elements[a]);
                                    if (mandatoryObj.valid === false) {
                                        pageFailed = true;
                                        break;
                                    }
                                }

                                // if its failed mandatory checks then update progress tracking completed to false
                                if (pageFailed) {
                                    returnObject.invalidPages.push(page.PageId); // add to list of valid pages
                                    page.ProgressTrackingComplete = false;
                                    dataManagerService.workbookManagerObject.reviseData();
                                    dataManagerService.workbookManagerObject.save();
                                }
                            }
                        }
                    } else if (page.PageType === "WorkBook" || page.PageType === "WorkBookResponse") {
                        var childResult = this._checkMandatoryProgressOnPages(page.Children);
                        // add result to main results if any
                        for (var j = 0; j < childResult.invalidPages.length; j++) {
                            returnObject.invalidPages.push(childResult.invalidPages[j]); // add to list of valid pages
                        }
                    }
                }
                return returnObject;
            };

            this.checkForIsSavedAnywhereInWorkbook = function (primary_workbook_asset_id) {
                var assetIdsToCheck = [];

                // attempt to get ids from local storage, if nothing there attempt from scope data
                var assetFromStorage = this.loadSpecific(primary_workbook_asset_id);
                // tslint:disable-next-line:triple-equals
                if (assetFromStorage != false && assetFromStorage !== undefined && assetFromStorage !== null && assetFromStorage.Pages !== null && assetFromStorage.Pages !== undefined) {
                    assetIdsToCheck = this.findAllUserFilledInPageIds(assetFromStorage.Pages);
                    // tslint:disable-next-line:triple-equals
                } else if (dataManagerService.workbookManagerObject && dataManagerService.workbookManagerObject.AssetId == primary_workbook_asset_id) {
                    assetIdsToCheck = this.findAllUserFilledInPageIds(dataManagerService.workbookManagerObject.data.Pages);
                }

                // add main workbooks id
                assetIdsToCheck.push(primary_workbook_asset_id);

                // loop through all ids in local storage and check the is_saved flags
                var is_saved = true;

                for (var i = 0, len = assetIdsToCheck.length; i < len; i++) {
                    var asset = this.loadSpecific(assetIdsToCheck[i]);
                    // tslint:disable-next-line:triple-equals
                    if (asset != false && asset !== undefined && asset !== null) {
                        // tslint:disable-next-line:triple-equals
                        if (asset.is_saved != true) {
                            is_saved = false;
                            i = len; // exit loop, already found a false for this workbook
                        }
                    }
                }

                return is_saved;
            };
        };

        /**
         * CollectionWrapperManager class
         * @param dto
         * @constructor
         */
        var CollectionWrapperManager = function (dto) {
            DataManager.apply(this, arguments); // extend from DataManager class

            this.completeDataProcess = function () {
                dataManagerService.dtos[this.local_storage_reference] = this; // add to the local storage data list
                dataManagerService.collectionWrapperManagerObject = this;
            };

            this.getBanner = function () {
                return dataManagerService.collectionWrapperManagerObject.data.BannerSection;
            };

            this.updateDtoModel = function (collectionDto) {
                this.updateParentSavedFlagIfExists();
                this.data = collectionDto;
                this.reviseData();

                return false;
            };

            this.saveCollectionToServer = function (asset_id) {
                var promises = [];
                var deferred = $q.defer();
                // if this is in the invisible folder it means its just been created....
                //...and therefore on first save we need to change it to main folder
                if (this.data.AssetFolder === "Invisible") {
                    this.data.AssetFolder = "Main";
                }

                this.reviseData();
                this.save();

                dataManagerService.checkBeforeSave(asset_id, "AssetCollectionWrapper").then(
                    function () {
                        // make sure main object saves
                        var assetFromStorage = this.loadSpecific(asset_id);
                        // tslint:disable-next-line:triple-equals
                        if (assetFromStorage != false && assetFromStorage !== undefined && assetFromStorage !== null) {
                            // then we have an asset to save

                            // check revision number hasn't been set to NaN
                            if (isNaN(assetFromStorage.Revision)) {
                                assetFromStorage.Revision = -1;
                            }

                            // only save pages with is_saved false... as they are the only ones to have changed
                            recoveryLogger.step("save", "Saving AssetCollectionWrapper");
                            $http.defaults.withCredentials = true;
                            var promise = AssetEndpointService.saveOrUpdate({ model: assetFromStorage });
                            promises.push(promise.then(updateBaseVersion));
                        }

                        deferred.resolve({ promises: $q.all(promises) });
                    }.bind(this)
                );

                return deferred.promise;
            };
        };

        /**
         * ActivityLogManager class
         * @param dto
         * @constructor
         */
        var ActivityLogManager = function (dto) {
            DataManager.apply(this, arguments); // extend from DataManager class

            this.completeDataProcess = function () {
                dataManagerService.dtos[this.local_storage_reference] = this; // add to the local storage data list
                dataManagerService.activityLogManagerObject = this;
            };

            this.getBanner = function () {
                return dataManagerService.activityLogManagerObject.data.BannerSection;
            };

            this.updateDtoModel = function (activityLogDto) {
                this.updateParentSavedFlagIfExists();
                this.data = activityLogDto;
                this.reviseData();

                return false;
            };

            this.saveActivityLogToServer = function (asset_id, saveToMain) {
                var promises = [];
                var deferred = $q.defer();

                if (saveToMain && this.data.AssetFolder === "Invisible") {
                    this.data.AssetFolder = "Main";
                }

                // make sure main object saves
                this.reviseData();
                this.save();

                dataManagerService.checkBeforeSave(asset_id, "ActivityLog").then(
                    function () {
                        var assetFromStorage = this.loadSpecific(asset_id);
                        // tslint:disable-next-line:triple-equals
                        if (assetFromStorage != false && assetFromStorage !== undefined && assetFromStorage !== null) {
                            // then we have an asset to save

                            // check revision number hasn't been set to NaN
                            if (isNaN(assetFromStorage.Revision)) {
                                assetFromStorage.Revision = -1;
                            }

                            // only save pages with is_saved false... as they are the only ones to have changed
                            recoveryLogger.step("save", "Saving ActivityLog");
                            $http.defaults.withCredentials = true;
                            var promise = AssetEndpointService.saveOrUpdate({ model: assetFromStorage });
                            promises.push(promise.then(updateBaseVersion));
                        }

                        deferred.resolve({ promises: $q.all(promises) });
                    }.bind(this)
                );

                return deferred.promise;
            };
        };

        /**
         * BlogManager class
         * @param dto
         * @constructor
         */
        var BlogManager = function (dto) {
            DataManager.apply(this, arguments); // extend from DataManager class

            this.completeDataProcess = function () {
                dataManagerService.current_navigation = this.AssetId;
                dataManagerService.dtos[this.local_storage_reference] = this; // add to the local storage data list
                dataManagerService.blogManagerObject = this;
            };

            this.getBanner = function () {
                return dataManagerService.blogManagerObject.data.BannerSection;
            };

            this.updateDtoModel = function (blogDto, ignoreRevision) {
                this.updateParentSavedFlagIfExists();
                this.data = blogDto;
                if (!ignoreRevision) {
                    this.reviseData();
                }

                return this.save();
            };

            this.createArrayOfAssetPostIds = function (array, extraItem) {
                var assetIds = [];
                if (extraItem) {
                    assetIds.push(extraItem);
                }
                for (var i = 0; i < array.length; i++) {
                    assetIds.push(array[i].PostId);
                }
                return assetIds;
            };

            this.convertPostViewDto = function (postViewDto) {
                var basicDto = {
                    Id: postViewDto.Id,
                    PostId: postViewDto.PostId,
                    Private: postViewDto.Private,
                    Posted: postViewDto.Posted,
                    Created: postViewDto.Created,
                    Modified: postViewDto.Modified,
                    PosterId: postViewDto.PosterId
                };
                return basicDto;
            };

            this.saveBlogToServer = function (asset_id) {
                var promises = [];
                var deferred = $q.defer();

                // if this is in the invisible folder it means its just been created....
                //...and therefore on first save we need to change it to main folder
                if (this.data.AssetFolder === "Invisible") {
                    this.data.AssetFolder = "Main";
                }

                if (this.data.AssetFolder === "Deleted") {
                    deferred.reject();
                    return deferred.promise;
                }

                // make sure main object saves
                this.reviseData();
                this.save();

                dataManagerService.checkBeforeSave(asset_id, "Blog").then(
                    function () {
                        var assetFromStorage = this.loadSpecific(asset_id);
                        // tslint:disable-next-line:triple-equals
                        if (assetFromStorage != false && assetFromStorage !== undefined && assetFromStorage !== null) {
                            // then we have an asset to save

                            // check revision number hasn't been set to NaN
                            if (isNaN(assetFromStorage.Revision)) {
                                assetFromStorage.Revision = -1;
                            }

                            // only save pages with is_saved false... as they are the only ones to have changed
                            recoveryLogger.step("save", "Saving Blog");
                            $http.defaults.withCredentials = true;
                            var promise = AssetEndpointService.saveOrUpdate({ model: assetFromStorage });
                            promises.push(promise.then(updateBaseVersion));
                        }

                        deferred.resolve({ promises: $q.all(promises) });
                    }.bind(this)
                );

                return deferred.promise;
            };

            this.updatePostPrivate = function (post_id, bool) {
                var array = this.data.Posts;
                for (var i = 0; i < array.length; i++) {
                    // tslint:disable-next-line:triple-equals
                    if (array[i].PostId == post_id) {
                        array[i].Private = bool;
                    }
                }
                this.reviseData();

                return this.save();
            };

            this.removePost = function (post_id, bool) {
                var array = this.data.Posts;
                for (var i = 0; i < array.length; i++) {
                    // tslint:disable-next-line:triple-equals
                    if (array[i].PostId == post_id) {
                        array.splice(i, 1);
                    }
                }
                this.reviseData();

                return this.save();
            };
        };

        /**
         * CvManager class
         * @param dto
         * @constructor
         */
        var CvManager = function (dto) {
            DataManager.apply(this, arguments); // extend from DataManager class

            this.completeDataProcess = function () {
                dataManagerService.dtos[this.local_storage_reference] = this; // add to the local storage data list
                dataManagerService.cvManagerObject = this;
            };

            this.getBanner = function () {
                return dataManagerService.cvManagerObject.data.BannerSection;
            };

            this.updateDtoModel = function (cvDto) {
                this.data = cvDto;
                this.reviseData();

                return false;
            };
        };

        /**
         * TemplateManager class
         * @param dto
         * @constructor
         */
        var TemplateBuilderManager = function (dto) {
            BuilderManager.apply(this, arguments); //Extend BuilderManager.
            this._parent = BuilderManager;
            this.managerType = "templateBuilderManagerObject";
            this.blockDefaultSize = "medium";
            this.rootManagerId = null;

            this.getBanner = function () {
                return dataManagerService[this.managerType].data.BannerSection;
            };

            this.updateDtoModel = function (dto) {
                this.data = dto;
                this.is_saved = false;
                this.updateParent(true);
                return false;
            };

            this.update = function (ids, obj, row_section_obj, only_styles, block_wrapper) {
                var updated = this._parent.prototype.update.call(this, ids, obj, row_section_obj, only_styles, block_wrapper);
                this.updateParent(updated);
                return updated;
            };

            this.updateParent = function (updated) {
                if (this.rootManagerId && updated) {
                    dataManagerService.dtos[this.rootManagerId].onChildUpdate();
                }
            };

            this.saveTemplateBuilderToServer = function (asset_id, doNotChangeFolder) {
                var promises = [];
                var deferred = $q.defer();

                // if this is in the invisible folder it means its just been created....
                //...and therefore on first save we need to change it to main folder
                // tslint:disable-next-line:triple-equals
                if (doNotChangeFolder === null || doNotChangeFolder === undefined || doNotChangeFolder == false) {
                    if (this.data.AssetFolder === "Invisible") {
                        this.data.AssetFolder = "Main";
                    }
                }

                // make sure main object saves
                this.reviseData();
                this.save();

                // go through list of asset ids (in forms case its just the one) get it from local storage and save
                var assetIdsToSave = [];
                assetIdsToSave.push(asset_id);

                dataManagerService.checkBeforeSave(asset_id, "TemplateBuilder").then(
                    function () {
                        for (var i = 0; i < assetIdsToSave.length; i++) {
                            var assetFromStorage = this.loadSpecific(assetIdsToSave[i]);

                            // tslint:disable-next-line:triple-equals
                            if (assetFromStorage != false && assetFromStorage !== undefined && assetFromStorage !== null) {
                                // then we have an asset to save

                                // check revision number hasn't been set to NaN
                                if (isNaN(assetFromStorage.Revision)) {
                                    assetFromStorage.Revision = -1;
                                }

                                // only save pages with is_saved false... as they are the only ones to have changed
                                recoveryLogger.step("save", "Saving Form");
                                $http.defaults.withCredentials = true;
                                var promise = AssetEndpointService.saveOrUpdate({ model: assetFromStorage });
                                promises.push(promise.then(updateBaseVersion));

                                // ensure that the just saved items last modified date is set back into the past so any serverside items will override it
                            }
                        }

                        deferred.resolve({ promises: $q.all(promises) });
                    }.bind(this)
                );

                return deferred.promise;
            };
        };

        var WorkbookBuilderManager = function (dto) {
            WorkbookManager.apply(this, arguments);
            this._parent = BuilderManager;
            this.managerType = "workbookBuilderManagerObject";

            this.completeDataProcess = function () {
                dataManagerService.dtos[this.local_storage_reference] = this;
                dataManagerService[this.managerType] = this;
            };

            this.updateDtoModel = function (dto) {
                this.data = dto;
                this.is_saved = false;

                return false;
            };

            this.onChildUpdate = function () {
                this.reviseData();
                this.save();
            };

            this.isRootLevel = function (id, pages) {
                pages = pages || this.data.Pages;
                return pages.some(byPageId(id));
            };

            this.getNestedParent = function (pageId, pages) {
                var parent = null;
                for (var i = 0, l = pages.length, page; i < l; i++) {
                    page = pages[i];

                    parent = page.Children.findInNestedObject("PageId", pageId) !== -1 ? page : this.getNestedParent(pageId, page.Children);
                    if (parent) {
                        break;
                    }
                }

                return parent;
            };

            this.getPage = function (pageId, pages, parentType) {
                var pageFound = pages.findInNestedObjectsRecursively("PageId", pageId, "Children", "PageType", parentType);
                return pageFound !== undefined ? pageFound : null;
            };

            this.getItemByPropValue = function (prop, value, parentType, arr) {
                arr = arr || this.data.Pages;
                return arr.findInNestedObjectsRecursively(prop, value, "Children", "PageType", parentType);
            };

            this.getFirstPage = function (pages, parentPageType) {
                var firstPage = null;
                for (var i = 0, l = pages.length, page; i < l; i++) {
                    page = pages[i];
                    firstPage = page.PageType === parentPageType ? this.getFirstPage(page.Children, parentPageType) : page;

                    if (firstPage) {
                        break;
                    }
                }

                return firstPage;
            };

            this.normalizeToWorkbookPageDtoArray = function (array) {
                var result = [];
                var pages = array.slice();

                for (var i = 0; i < pages.length; i++) {
                    var page = pages[i];
                    if (page.PageAssetId) {
                        page.ContentId = page.PageAssetId;
                        delete page.PageAssetId;
                    }
                    result.push(page);
                }

                return result;
            };

            this.addStandardsOverview = function () {
                const dto = {
                    PageTitle: multiLanguageService.getString("labels.builder.standards_overview.title"),
                    PageType: ASSET_CONSTANTS.SPECIAL_TYPES.STANDARDS_OVERVIEW,
                    PageIcon: "standardsoverview"
                };

                const item = new dtoFactory.FormPageDto(dto);

                this.data.Pages.unshift(item);
            };

            this.addPlaceholder = function (dto, nestId) {
                var parent;
                var deferred = $q.defer();
                var item = new dtoFactory.FormPageDto(dto);
                // tslint:disable-next-line:triple-equals
                if (item.PageTitle == "" || item.PageTitle === undefined || item.PageTitle === null) {
                    switch (item.PageType) {
                        case "WebfolioPage":
                            item.PageTitle = multiLanguageService.getString("labels.navigation.new_page_placeholder");
                            break;
                        case "Blog":
                            item.PageTitle = multiLanguageService.getString("labels.navigation.new_blog_placeholder");
                            break;
                        case "AssetCollectionWrapper":
                            item.PageTitle = multiLanguageService.getString("labels.navigation.new_collection_placeholder");
                            break;
                        case "ActivityLog":
                            item.PageTitle = multiLanguageService.getString("labels.navigation.new_log_placeholder");
                            break;
                        default:
                            item.PageTitle = multiLanguageService.getString("labels.navigation.new_page");
                            break;
                    }
                }
                if (nestId) {
                    parent = this.get(nestId);
                    item.PagePosition = parent.Children.length;
                    parent.Children.push(item);
                } else {
                    this.data.Pages.push(item);
                }

                var currentRevision = this.data.Revision;

                this.reviseData();

                if (this.save()) {
                    deferred.resolve(item.PageId);
                } else {
                    this.data.Revision = currentRevision;
                    if (nestId !== undefined) {
                        parent.Children.splice(parent.Children.length - 1, 1);
                    } else {
                        this.data.Pages.splice(this.data.Pages.length - 1, 1);
                    }
                    deferred.reject();
                }

                return deferred.promise;
            };

            // TODO: (abstract later) This is copy from WebfolioManager
            this.getTopLevelInitialPageFromArray = function (array) {
                array = array || this.data.Pages;

                for (var i = 0; i < array.length; i++) {
                    if (array[i].PageType !== "Webfolio" && array[i].PageType !== "WorkBook") {
                        return array[i];
                    }
                }

                for (var n = 0; n < array.length; n++) {
                    if (array[n].Children.length > 0) {
                        var foundData = this.getTopLevelInitialPageFromArray(array[n].Children);
                        if (foundData) {
                            return foundData;
                        }
                    }
                }

                return null;
            };

            // TODO: (abstract later) This is copy from WebfolioManager
            this.add = function (dto, skipRevision) {
                var deferred = $q.defer();
                var item = dtoFactory.create(dto);
                item.PagePosition = this.data.Pages.length;
                this.data.Pages.push(item);
                // tslint:disable-next-line:triple-equals
                if (item.PageTitle == "" || item.PageTitle === undefined || item.PageTitle === null) {
                    item.PageTitle = multiLanguageService.getString("labels.navigation.new_page");
                }

                var currentRevision = this.data.Revision;

                // If saved then return IDs, else revert all changes
                if (!skipRevision) {
                    this.reviseData();
                }
                if (this.save()) {
                    //return item.PageId;
                    deferred.resolve(item.PageId);
                } else {
                    this.data.Revision = currentRevision;
                    this.data.Pages.splice(this.data.Pages.length - 1, 1);
                    //return null;
                    deferred.reject();
                }

                return deferred.promise;
            };

            // TODO: (abstract later) This is copy from WebfolioManager
            var pushPageIdsIntoArray = function (arrayToLoop, arrayToPushInto, check_types) {
                for (var i = 0; i < arrayToLoop.length; i++) {
                    if (check_types) {
                        if (arrayToLoop[i].PageType.toLowerCase() !== "workbook") {
                            arrayToPushInto.push(arrayToLoop[i].PageAssetId);
                        }
                    } else {
                        arrayToPushInto.push(arrayToLoop[i].PageAssetId);
                    }
                    if (arrayToLoop[i].Children.length > 0) {
                        pushPageIdsIntoArray(arrayToLoop[i].Children, arrayToPushInto, check_types);
                    }
                }
            };

            this.getExcludeIds = function () {
                var allWorkbooks = $filter("filter")(this.data.Pages, { PageType: "WorkBook" });
                return allWorkbooks.getValuesFromNestedKeys("ContentId");
            };

            // TODO: (abstract later) This is copy from WebfolioManager
            this.getAllIds = function (startingId, check_types) {
                var array = [];
                // tslint:disable-next-line:triple-equals
                if (startingId === undefined || startingId === null || startingId == "") {
                    startingId = dataManagerService.primary_id;
                }
                array.push(startingId);
                pushPageIdsIntoArray(dataManagerService.dtos[startingId].data.Pages, array, check_types);
                return array;
            };

            // TODO: (abstract later) This is copy from WebfolioManager
            this.addNested = function (dto, parentId) {
                var deferred = $q.defer();
                var item = dtoFactory.create(dto);
                var parent = this.get(parentId);
                item.PagePosition = parent.Children.length;
                parent.Children.push(item);

                // tslint:disable-next-line:triple-equals
                if (item.PageTitle == "" || item.PageTitle === undefined || item.PageTitle === null) {
                    item.PageTitle = multiLanguageService.getString("labels.navigation.new_page");
                }

                var currentRevision = this.data.Revision;

                // If saved then return IDs, else revert all changes
                this.reviseData();
                if (this.save()) {
                    deferred.resolve(item.PageId);
                } else {
                    this.data.Revision = currentRevision;
                    parent.Children.splice(parent.Children.length - 1, 1);
                    deferred.reject();
                }
                return deferred.promise;
            };

            this.basicRemove = function (id, array) {
                var list = array.slice(),
                    foundItem = this.get(id);

                list.splice(list.indexOf(foundItem), 1); // delete page

                return list;
            };

            this.remove = function (id) {
                const deferred = $q.defer();
                const foundItem = this.get(id, true);
                const dataToRemoveFrom = foundItem.parents.parent ? foundItem.parents.parent.Children : foundItem.parents;
                const itemToRemove = foundItem.page;

                // remove if true
                if (helpers.isNotEmptyArray(dataToRemoveFrom) && itemToRemove) {
                    if (dataToRemoveFrom.length === 1 && foundItem.parents.parent) {
                        return this.remove(foundItem.parents.parent.PageId);
                    } else {
                        dataToRemoveFrom.splice(dataToRemoveFrom.indexOf(itemToRemove), 1); // delete page
                    }
                } else {
                    deferred.reject(PAGE_ERROR.NO_PAGES);
                }

                this.reviseData();
                if (this.save()) {
                    deferred.resolve();
                } else {
                    deferred.reject();
                }

                return deferred.promise;
            };

            // TODO: (abstract later) This is copy from WebfolioManager
            this.findClosestPage = function (originalIndex, array, isRootLevelPage) {
                var closestIndex = originalIndex;
                var closestIndexTemp;
                var initialPage;
                var canContainChildren = ["webfolio", "workbook", "workbookresponse"];

                // tslint:disable-next-line:triple-equals
                if (array.length == 1) {
                    return false;
                }

                closestIndexTemp = closestIndex - 1;
                while (closestIndexTemp >= 0) {
                    if (canContainChildren.indexOf(array[closestIndexTemp].PageType.toLowerCase()) < 0) {
                        return array[closestIndexTemp];
                    } else if (isRootLevelPage) {
                        initialPage = this.getTopLevelInitialPageFromArray(array[closestIndexTemp].Children);
                        if (initialPage) {
                            return initialPage;
                        }
                    }
                    closestIndexTemp--;
                }

                closestIndexTemp = closestIndex + 1;
                while (closestIndexTemp < array.length) {
                    if (canContainChildren.indexOf(array[closestIndexTemp].PageType.toLowerCase()) < 0) {
                        return array[closestIndexTemp];
                    } else if (isRootLevelPage) {
                        initialPage = this.getTopLevelInitialPageFromArray(array[closestIndexTemp].Children);
                        if (initialPage) {
                            return initialPage;
                        }
                    }
                    closestIndexTemp++;
                }

                return false;
            };

            // TODO: (abstract later) This is copy from WebfolioManager
            this.findClosest = function (id) {
                var foundData = this.get(id, true);

                if (foundData === -1) {
                    return foundData;
                }

                var parentId = foundData.parents.parent ? foundData.parents.parent.PageId : null;
                var isRootLevelPage = !parentId,
                    originalIndex,
                    closest;

                foundData.parents = foundData.parents.parent ? foundData.parents.parent.Children : foundData.parents;

                // get current (page that going to be removed) page index in data array
                originalIndex = foundData.parents.indexOf(foundData.page);

                // find closest to originalIndex page
                closest = this.findClosestPage(originalIndex, foundData.parents, isRootLevelPage);

                // if page can't be found in local array, then take parent array.
                if (!closest && parentId) {
                    return this.findClosest(parentId);
                }

                return closest;
            };

            // TODO: (abstract later) This is copy from WebfolioManager
            function recursiveFindById(id, array, getParent, checkRootLevelOnly, originalObjs) {
                var searchableArray = [];

                for (var i = 0; i < array.length; i++) {
                    if (array[i].PageId === id) {
                        return getParent ? { parents: originalObjs || array, page: array[i] } : array[i];
                    } else if (array[i].Children && array[i].Children.length > 0) {
                        searchableArray.push(array[i]);
                    }

                    if (i === array.length - 1 && !checkRootLevelOnly) {
                        for (var n = 0; n < searchableArray.length; n++) {
                            var recursiveData = recursiveFindById(id, searchableArray[n].Children, getParent, false, {
                                parent: searchableArray[n],
                                rootParent: (originalObjs && originalObjs.rootParent) || searchableArray[n]
                            });

                            // tslint:disable-next-line:triple-equals
                            if (recursiveData && recursiveData != -1) {
                                return recursiveData;
                            }
                        }
                    }
                }

                return -1;
            }

            // TODO: (abstract later) This is copy from WebfolioManager
            this.recursiveGetIdsTrace = function (opts) {
                var searchableArray = [];
                var id = opts.id;
                var array = opts.array || [];
                var idsTrace = opts.idsTrace || [];

                for (var i = 0; i < array.length; i++) {
                    if (array[i].PageId === id) {
                        idsTrace.unshift(array[i].PageId);
                        break;
                    } else if (array[i].Children && array[i].Children.length > 0) {
                        searchableArray.push(array[i]);
                    }

                    if (i === array.length - 1) {
                        for (var n = 0; n < searchableArray.length; n++) {
                            // tslint:disable-next-line:triple-equals
                            if (recursiveFindById(id, searchableArray[n].Children) != -1) {
                                idsTrace.unshift(searchableArray[n].PageId);

                                this.recursiveGetIdsTrace({
                                    id: id,
                                    array: searchableArray[n].Children,
                                    idsTrace: idsTrace
                                });
                            }
                        }
                    }
                }

                return idsTrace;
            };

            // TODO: (abstract later) This is copy from WebfolioManager
            this.get = function (id, get_parent, array) {
                array = array || this.data.Pages;

                var foundData = recursiveFindById(id, array, get_parent);

                if (foundData === -1) {
                    return null;
                }

                if (foundData.parents && foundData.parents.parent) {
                    foundData.isRootLevel = this.isRootLevel(foundData.parents.parent.PageId, array);
                }

                return foundData;
            };

            // TODO: (abstract later) This is copy from WebfolioManager
            this.update = function (id, obj) {
                var nav_page_item;

                if (id) {
                    nav_page_item = this.get(id); // get block
                } else {
                    nav_page_item = this.data; // update property on main DTO
                }

                var original_item = angular.copy(nav_page_item); // backup object
                var currentRevision = this.data.Revision;

                // tslint:disable-next-line:triple-equals
                if (nav_page_item != null) {
                    helperService.updateObject(nav_page_item, obj);

                    this.reviseData();

                    if (this.save()) {
                        return true;
                    } else {
                        helperService.updateObject(nav_page_item, original_item);
                        this.data.Revision = currentRevision;
                    }
                }
                return false;
            };

            this.updatePageTitle = function (id, newTitle) {
                const didUpdate = this.update(id, { PageTitle: newTitle });
                return didUpdate ? $q.resolve() : $q.reject();
            };

            this.findWorkbookPage = function (pageToFindId, workbookPages) {
                // tslint:disable-next-line:triple-equals
                if (workbookPages != null && pageToFindId != null) {
                    for (var i = 0; i < workbookPages.length; i++) {
                        if (workbookPages[i].PageId === pageToFindId) {
                            return workbookPages[i];
                        }

                        if (workbookPages[i].PageType === "WorkBook" || workbookPages[i].PageType === "WorkBookResponse") {
                            var nestedResult = this.findWorkbookPage(pageToFindId, workbookPages[i].Children);
                            // tslint:disable-next-line:triple-equals
                            if (nestedResult != null) {
                                i = workbookPages.length; // ensure the loop stops
                                return nestedResult;
                            }
                        }
                    }
                }
                return null;
            };

            //Returns a flat array of all pages within the Workbook
            this.getAllPages = function (pages, foundPages) {
                for (var i = 0, l = pages.length, page; i < l; i++) {
                    page = pages[i];
                    foundPages.push(page);

                    if (page.Children.length !== 0) {
                        this.getAllPages(page.Children, foundPages);
                    }
                }
                return foundPages;
            };

            this.getAllContentIds = function (pages) {
                const ids = new Set();
                this.getAllPages(pages, []).forEach((page) => {
                    if (!ids.has(page.ContentId)) {
                        ids.add(page.ContentId);
                    }
                });

                return Array.from(ids);
            };

            this.getIdsTrace = function (id, array) {
                return this.recursiveGetIdsTrace({
                    id: id,
                    array: array || this.data.Pages
                });
            };

            this.removeWorkbookBuilderFromStorage = function () {
                var asset_id = this.local_storage_reference;
                var foundPages = [];
                if (!this.data || this.data.Pages.length <= 0) {
                    return;
                }
                // find all pages to remove
                var assetIdsToRemove = this.getAllPages(this.data.Pages, foundPages);
                assetIdsToRemove.push(asset_id);
                angular.forEach(foundPages, function (page) {
                    dataManagerService.destroy(page.ContentId); // remove item
                });
            };

            //TODO: Saving.
            this.saveWorkbookBuilderToServer = function (assetId) {
                if (this.data.AssetFolder === "Invisible") {
                    this.data.AssetFolder = "Main";
                }

                this.reviseData();
                this.save();

                var self = this,
                    deferred = $q.defer();

                dataManagerService.checkBeforeSave(assetId, "WorkbookBuilder").then(
                    function () {
                        //Save pages
                        var promises = this.savePages();

                        // check revision number hasn't been set to NaN
                        if (isNaN(self.data.Revision)) {
                            self.data.Revision = -1;
                        }

                        //Save workbook and any of its pages without a DataManager
                        recoveryLogger.step("save", "Saving Workbook");
                        const promise = AssetEndpointService.saveOrUpdate({ model: self.data });
                        promises.push(promise.then(updateBaseVersion));

                        deferred.resolve({ promises: $q.all(promises) });
                    }.bind(this)
                );
                return deferred.promise;
            };

            this.savePages = function () {
                const promises = [];
                const contentIds = this.getAllContentIds(this.data.Pages);

                for (let shortId of contentIds) {
                    const manager = dataManagerService.dtos[shortId];

                    //Manually save page in workbook where it has a DataManager (e.g. TemplateBuilder)
                    if (manager && manager.is_saved === false) {
                        this.reviseData();
                        this.save(shortId, manager.data);

                        // check revision number hasn't been set to NaN
                        if (isNaN(manager.data.Revision)) {
                            manager.data.Revision = -1;
                        }

                        $http.defaults.withCredentials = true;
                        const promise = AssetEndpointService.saveOrUpdate({ model: manager.data });
                        promises.push(promise.then(updateBaseVersion));
                    }
                }

                return promises;
            };

            /**
            @param {{pageToMoveId: string, beforePageId: null | string}} pageMoveData
            @return {boolean}
            */
            this.movePage = (pageMoveData) => {
                const pageToMoveDetails = this.get(pageMoveData.pageToMoveId, true, this.data.Pages);
                if (pageToMoveDetails === null) {
                    return false;
                }

                const removeResult = this.remove(pageMoveData.pageToMoveId, false);

                if (!removeResult) {
                    return false;
                }

                const siblings = Array.isArray(pageToMoveDetails.parents) ? pageToMoveDetails.parents : pageToMoveDetails.parents.parent.Children;

                const hasPageMovedToEnd = pageMoveData.beforePageId === null;
                const newPagesIndex = hasPageMovedToEnd ? siblings.length : siblings.findIndex(byPageId(pageMoveData.beforePageId));
                siblings.splice(newPagesIndex, 0, pageToMoveDetails.page);

                this.reviseData();
                this.save();

                return true;
            };
        };

        /**
         * -------------------------------------------------------
         * Helpers
         * -------------------------------------------------------
         */
        var replaceIdsRecursive = function (obj, specialKeys) {
            var keys = Object.keys(obj),
                key = "",
                item = null;
            for (var i = 0, l = keys.length; i < l; i++) {
                key = keys[i];
                if (obj.hasOwnProperty(key) && obj[key]) {
                    if (specialKeys.indexOf(key) !== -1) {
                        obj[key] = helperService.guid();
                    } else {
                        item = obj[key];
                        checkForNestedMutable(item, function (nestedItem) {
                            replaceIdsRecursive(nestedItem, specialKeys);
                        });
                    }
                }
            }
        };

        var checkForNestedMutable = function (item, callback) {
            if (item.constructor === Object) {
                callback(item);
            } else if (item.constructor === Array) {
                item.forEach(function (nestedValue) {
                    if (nestedValue) {
                        checkForNestedMutable(nestedValue, callback);
                    }
                });
            }
        };

        const updateBaseVersion = function (response) {
            if (response.data !== undefined) {
                const manager = dataManagerService.getDto(response.data.AssetId);
                if (manager !== null && manager !== undefined) {
                    manager.data.BaseVersion = response.data.Asset.Revision;
                }
            }
            return response;
        };

        const cleanupStorageAfterSave = (response) => {
            if (response.data.MessageType === "Success") {
                dataManagerService.destroy(response.data.AssetId);
            }

            return response;
        };

        const handleProcurePageError = (rejection, containerAssetId, pageId, pagedManager) => {
            if (rejection.status === 404) {
                const page = pagedManager.get(pageId);

                if (page !== -1) {
                    const forwardRejection = () => $q.reject(rejection);
                    return dataManagerService
                        .saveOrphanedAsset(determinePageAssetId(page))
                        .then(() => dataManagerService.saveViaAssetId(containerAssetId))
                        .then(forwardRejection, forwardRejection);
                }
            }

            return $q.reject(rejection);
        };

        helpers.extendClass(WebfolioManager, DataManager);
        helpers.extendClass(WorkbookManager, DataManager);
        helpers.extendClass(FormManager, DataManager);
        helpers.extendClass(CollectionWrapperManager, DataManager);
        helpers.extendClass(CvManager, DataManager);
        helpers.extendClass(ActivityLogManager, DataManager);
        helpers.extendClass(BlogManager, DataManager);
        helpers.extendClass(WorkbookBuilderManager, WorkbookManager);

        helpers.extendClass(PageManager, BuilderManager);
        helpers.extendClass(TemplateBuilderManager, BuilderManager);
        helpers.extendClass(PostManager, BuilderManager);

        localChangesResolver.dataService.loadFromPersistentObjStore = dataManagerService.loadFromPersistentObjStore.bind(dataManagerService);
        localChangesResolver.dataService.destroy = dataManagerService.destroy.bind(dataManagerService);
        localChangesResolver.dataService.decrypt = dataManagerService.decrypt.bind(dataManagerService);
        localChangesResolver.dataService.clear = dataManagerService.cleanupStorageOfAssetId.bind(dataManagerService);
        localChangesResolver.dataService.getDto = dataManagerService.getDto;
        localChangesResolver.dataService.checkIfSaved = dataManagerService.checkIfSaved;
        return dataManagerService;
    }
]);
