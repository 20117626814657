import { angularAMD } from "@pebblepad/amd";
import { DialogInstance, DialogSize, DialogState, dialogDirector } from "@pjs/core-ui";
import "./dialogRouteChangeService";
import "./dialogAccessKeyService";
import "../../elementCreatedMarker/elementCreatedMarker.directive";
import "../../utilities/baseUrlsFactory";
import "../directives/modalDialog";
import "../directives/modalFacade";
import "../../utilities/helpers";

angularAMD.provider("modalConfig", function () {
    this.ariaContainers = [];
    this.$get = function () {
        return {
            ariaContainers: this.ariaContainers
        };
    }.bind(this);
});

/**
 * This file currently imports the dialogRouteChangeHandler
 * Once all modals have been migrated to react, if this file is no longer needed
 * this import will need to be moved to ensure it continues to provide its functionality
 */

angularAMD.factory("modal", [
    "$rootScope",
    "$q",
    "$compile",
    "baseUrlsFactory",
    "helpers",
    "modalConfig",
    "dialogRouteChangeService",
    "dialogAccessKeyService",
    function ($rootScope, $q, $compile, baseUrlsFactory, helpers, modalConfig, dialogRouteChangeService) {
        /**
         * Create and fill in global_scope
         *
         * @global_scope - set global elements once for all modal dialogs (optimization reason)
         * @function - fill the scope with global data
         */
        var global_scope = {};
        (function () {
            // cache HTML element to add global classes
            global_scope.modal_holder_el = angular.element(document.getElementById("modal-holder"));
        })();

        return {
            genericTemplates: {
                popup: baseUrlsFactory.shared_component_base_url + "modalDialogComponent/templates/generic-pop-up.html",
                popupWithButtons: baseUrlsFactory.shared_component_base_url + "modalDialogComponent/templates/generic-pop-up-with-buttons.html"
            },

            /**
             * @param {string} templateUrl
             * @param {object=} modalProps
             * @param {object=} options
             * @return {object}
             */
            launch: function (templateUrl, modalProps, options) {
                var modalScope = Object.assign($rootScope.$new(true), modalProps || {});

                modalScope.onClose = function () {
                    if (typeof modalProps.onClose === "function") {
                        modalProps.onClose();
                    }

                    modalScope.$destroy();
                };

                return this.newModal(
                    Object.assign(
                        {
                            scope: modalScope,
                            templateUrl: templateUrl
                        },
                        options
                    )
                );
            },

            /**
             * @deprecated - Use modal.launch as that handles scope creation and destruction
             */
            newModal: function (opts, unsafe = false) {
                const self = this;
                const _scope = opts.scope || $rootScope.$new(true);

                if (opts.autoRestoreFocus !== false) {
                    _scope.focusOnCloseElement = document.activeElement;
                }

                _scope.on_ready_deferred = $q.defer();
                _scope.focus_style = opts.focusStyle === undefined ? true : opts.focusStyle;
                _scope.autofocus = opts.autofocus === undefined ? true : opts.autofocus;
                _scope.disable_close = opts.disableClose || false;
                _scope.disable_focus_scroll = opts.disableFocusScroll || false;
                _scope.labelled_by = opts.labelledBy;
                _scope.ariaContainers = modalConfig.ariaContainers;
                _scope.modalId = opts.modal_id || helpers.guid();
                _scope.templateUrl = opts.templateUrl || baseUrlsFactory.shared_component_base_url + "modal/templates/default-template.html";
                _scope.dialogInstance = new DialogInstance(
                    {
                        size: DialogSize.Small,
                        initialModel: {},
                        steps: []
                    },
                    dialogDirector
                );

                const dialogObj = {
                    scope: _scope,
                    facade: self.compileModal(_scope),
                    canDestroyScope: unsafe !== true,
                    showModal: function () {
                        this.directorSubscription = dialogDirector.applicationState.subscribe((state) => {
                            if (state === DialogState.CLOSING) {
                                this.destroy();
                            }
                        });

                        self.bindApiMethod(_scope.on_ready_deferred.promise, "showModal");
                    },
                    hideModal: function () {
                        if (dialogDirector.dequeue(this.id) !== null) {
                            this.destroy();
                        }

                        self.bindApiMethod(_scope.on_ready_deferred.promise, "hideModal");
                    },
                    autoFocus: function (focus_style) {
                        self.bindApiMethod(_scope.on_ready_deferred.promise, "autoFocus", focus_style);
                    },
                    destroy: function () {
                        this.scope.dialogInstance.destroy();
                        dialogRouteChangeService.removeTrackedDialog(this.scope.modalId);

                        // clear the focus element from scope for the cases of persistent scopes
                        this.scope.focusOnCloseElement = null;

                        // If safe to do so manually call $destroy on scope to prevent memory leaks
                        if (this.canDestroyScope) {
                            this.scope.$destroy();
                        }

                        // If showModal has set up a subscription, call unsubscribe
                        if (this.directorSubscription !== undefined) {
                            this.directorSubscription.unsubscribe();
                        }

                        this.facade.remove();
                        self.removeModal(this.scope.modalId);

                        // clean up after modal is destroyed
                        for (let key in this) {
                            if (this[key] !== undefined) {
                                delete this[key];
                            }
                        }
                    }
                };

                dialogRouteChangeService.addTrackedDialog(dialogObj);

                dialogDirector.enqueue({
                    instance: _scope.dialogInstance,
                    render: () => dialogObj.showModal()
                });

                return dialogObj;
            },

            bindApiMethod: function (promise, method_name, param) {
                promise.then(function (data) {
                    data[method_name](param);
                });
            },

            // cleans out the DOM from previously existed modals
            removeModal: function (modalId) {
                if (modalId) {
                    const modal_el = angular.element(document.getElementById(modalId));
                    modal_el.remove();
                }
            },

            // append compiled modal to the DOM
            compileModal: function (scope) {
                if (scope.templateUrl) {
                    scope.compile_target = global_scope.modal_holder_el;
                    // appends element to the DOM (pre defined element)
                    return $compile("<modal-facade></modal-facade>")(scope);
                } else {
                    throw new Error("Modal templateUrl is empty or null. Please check your modal templateUrl.");
                }
            }
        };
    }
]);
