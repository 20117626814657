import { angularAMD } from "@pebblepad/amd";
import "../../multiLanguageService/multiLanguageService";
import "../tables/services/tableConfig.factory";
import "../../detachedToolbarRef/detachedToolbarRefContext.directive";
import "../../detachedToolbarRef/detachedToolbarRef.directive";

angularAMD.factory("helperService", [
    "$q",
    "$location",
    "multiLanguageService",
    "tableConfig",
    function ($q, $location, multiLanguageService, tableConfig) {
        return {
            convertToDirectiveName: function (dto) {
                var BlockTypes = {
                    ContentSectionRow: "rowSection",
                    ContentSectionText: "block-text",
                    ContentSectionMedia: function () {
                        var directive_name;
                        switch (dto.MediaType) {
                            case "image":
                                directive_name = "block-image";
                                break;
                            case "video":
                                directive_name = "block-video";
                                break;
                            case "audio":
                                directive_name = "block-audio";
                                break;
                            default:
                                directive_name = "block-unknown";
                        }
                        return directive_name;
                    },
                    BuilderContentSection: "block-content-section",
                    ContentSectionQuote: "block-quote",
                    ContentSectionBadge: "block-badge",
                    ContentSectionHero: "block-hero",
                    ContentSectionEmbed: "block-embed",
                    BuilderHintEmbed: "block-embed",
                    BuilderEmbed: "block-embed-user",
                    BuilderHeroBanner: "block-hero",
                    BuilderSingleLineTextBox: "block-single-line",
                    BuilderMultiLineTextBox: "block-multi-line",
                    "BuilderOptionsList-RadioButtons": "block-radio-button",
                    BuilderBinaryAnswerInput: "block-binary",
                    BuilderTitleSingleLineTextBox: "block-title-single-line",
                    "BuilderOptionsList-Checkboxes": "block-checkbox",
                    "BuilderOptionsList-DropDown": "block-drop-down",
                    "BuilderFormTableContainer-RowHeadingsNoColumnHeadings": "block-table-basic-row",
                    "BuilderFormTableContainer-FixedRows": "block-fixed-row",
                    "BuilderFormTableContainer-RepeatingRows": "block-fixed-row", //repeating uses the same template builder directive as fixed rows,
                    BuilderSingleCells: "block-single-cells",
                    "BuilderFormTableContainer-RowHeadings": "block-table-basic-col-row",
                    BuilderDatePickerInput: "block-date-picker",
                    BuilderFormSignatureElement: "block-digital-signature",
                    BuilderFormUserMediaPicker: "block-media-picker",
                    BuilderDynamicElement: "block-dynamic-element",
                    GenericBuilderTable: "block-generic-table",
                    "BuilderRangeInput-Standard": "block-numeric",
                    "BuilderRangeInput-Numeric": "block-numeric",
                    "BuilderRangeInput-Likert": "block-likert",
                    BuilderCapabilityEvidenceOnly: "block-capability-evidence",
                    BuilderHintImage: "block-hint-image",
                    BuilderHintVideo: "block-hint-video",
                    BuilderHintAudio: "block-hint-audio",
                    BuilderHintText: "block-hint-text",
                    "BuilderCapabilityRangeInput-Standard": "block-rating-capability",
                    BuilderHorizontalRule: "block-horizontal-rule",
                    "BuilderCapabilityOptionList-RadioButtons": "block-capability-radio-button",
                    BuilderCapabilityBinaryAnswerInput: "block-binary-capability",
                    BuilderUnknown: "block-unknown",
                    BuilderFormBasicContainer: "block-container",
                    BuilderStandardsSelection: "block-standards-selection",
                    BuilderStandards: "block-standard"
                };

                if (dto.SectionType && dto.SectionType === "ContentSectionMedia") {
                    return BlockTypes[dto.SectionType]();
                } else {
                    return BlockTypes[dto.SectionType];
                }
            },

            getWorkbookPageIcon: function (value, ignorePostfix) {
                var postfix = "-reversed";
                if (ignorePostfix || (value && value.indexOf(postfix) > -1)) {
                    postfix = "";
                }
                return (value + postfix || "").toLowerCase();
            },

            updateObject: function (obj_out, obj_in) {
                if (!obj_out || !obj_in) {
                    return;
                }

                // In this case obj will be treated as ARRAY
                if (obj_out.constructor === Array && obj_in.constructor === Array) {
                    var matchedObj;

                    for (var i = 0; i < obj_out.length; i++) {
                        matchedObj = this.getMatchOnKey(obj_in, obj_out[i]);

                        if (matchedObj) {
                            obj_out[i] = matchedObj;
                        }
                    }
                }
                // In this case obj will be treated as OBJECTS
                else {
                    for (var prop in obj_out) {
                        if (obj_in.hasOwnProperty(prop)) {
                            obj_out[prop] = obj_in[prop];
                        }
                    }
                }
            },

            getMatchOnKey: function (a, b) {
                for (var i = 0; i < a.length; i++) {
                    if (a[i].Key === b.Key) {
                        return a[i];
                    }
                }

                return false;
            },

            extend: function (ChildClass, ParentClass) {
                ChildClass.prototype = ParentClass.prototype;
                ChildClass.prototype.constructor = ChildClass;
            },

            /**
             * Create json string from key and value and then converts it to JSON
             * @param key
             * @param value
             * @returns {*}
             */
            toJSON: function (key, value) {
                var json_string = '{"' + key + '": "' + value + '"}';
                return JSON.parse(json_string);
            },

            guid: function () {
                return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
                    var r = (Math.random() * 16) | 0,
                        v = c === "x" ? r : (r & 0x3) | 0x8;
                    return v.toString(16);
                });
            },

            getBlockDefaultOptions: function (data, amountOfItems, itemObject) {
                if (data && data.length === 0) {
                    amountOfItems = amountOfItems || 3;
                    var dataArray = new Array(amountOfItems);
                    for (var i = 0, len = dataArray.length; i < len; i++) {
                        dataArray[i] = itemObject || { Key: this.guid(), Value: "" };
                    }

                    return dataArray;
                }

                return data;
            },

            normalizeDate: function (date) {
                if (!date) {
                    return;
                }

                if (typeof date === "string") {
                    if (date.indexOf("Z") === -1) {
                        date += "Z";
                    }
                }

                var newDate = new Date(date);
                if (isNaN(newDate.getTime())) {
                    return undefined;
                }

                return newDate;
            },

            getPreCompiledTemplateType: function (opts) {
                if (opts.row) {
                    if (opts.view_mode) {
                        return "section_body_view_mode";
                    } else if (opts.builder_mode) {
                        if (opts.data.SectionUneditable) {
                            if (opts.no_drop_area === true) {
                                return "section_body_template_builder_mode_non_editable_no_drop_area";
                            } else {
                                return "section_body_template_builder_mode_non_editable";
                            }
                        } else {
                            for (var i = 0; i < opts.data.Sections.length; i++) {
                                if (opts.data.Sections[i].SectionType === "BuilderContentSection") {
                                    return "section_body_large_template_builder_mode";
                                }
                            }
                            return "section_body_template_builder_mode";
                        }
                    } else if (opts.data.SectionUneditable) {
                        return "section_body_non_editable";
                    } else {
                        return "section_body";
                    }
                } else {
                    if (opts.view_mode) {
                        return "block_view_mode";
                    } else if (opts.builder_mode) {
                        if (opts.block_uneditable === true && opts.data !== "block-title-single-line") {
                            return "block_template_builder_mode_no_drag_no_delete";
                        } else if (opts.data === "block-title-single-line") {
                            return "block_template_builder_mode_no_delete";
                        } else if (opts.data === "block-hint-image" || opts.data === "block-hint-text" || opts.data === "block-hint-video" || opts.data === "block-hint-audio") {
                            return "block";
                        } else {
                            return "block_template_builder_mode";
                        }
                    } else if (opts.block_uneditable === true) {
                        return "block_template_builder_mode_no_drag_no_delete";
                    } else {
                        return "block";
                    }
                }
            },

            getPreCompiledString: function (opts) {
                return {
                    section:
                        '<div section-behaviour class="section">' +
                        '<drop-area class="drop-zone"></drop-area>' +
                        '<div class="container section-small">' +
                        '<div class="not-ready block-whole block-resizable" block-behaviour draggable>' +
                        "<" +
                        opts.embed_data +
                        " block-base></" +
                        opts.embed_data +
                        ">" +
                        '<button data-hook="block-remove-button" class="btn btn-transparent remove-block-btn" ng-click="::confirmRemoveBlock()" aria-label="' +
                        multiLanguageService.getString("accessibility.block_behaviour.remove") +
                        '"><span class="icon-cross"></span></button>' +
                        "</div>" +
                        "</div>" +
                        '<div class="section-button-holder">' +
                        '<div class="section-button-wrapper">' +
                        '<div class="section-button-inner-wrapper">' +
                        '<button class="btn btn-transparent move-block-up-btn inside-white-focus" ng-click="moveSectionUp()" aria-label="' +
                        multiLanguageService.getString("accessibility.section_behaviour.move_up") +
                        '"><span class="icon-thin-arrow-up"></span></button>' +
                        '<button class="btn btn-transparent move-block-down-btn inside-white-focus" ng-click="moveSectionDown()" aria-label="' +
                        multiLanguageService.getString("accessibility.section_behaviour.move_down") +
                        '"><span class="icon-thin-arrow-down"></span></button>' +
                        "</div>" +
                        "</div>" +
                        "</div>" +
                        "</div>",

                    section_body_non_editable:
                        '<div section-behaviour detached-toolbar-ref-context class="section non-editable">' +
                        "<div class='util-sticky-editor-toolbar' detached-toolbar-ref></div>" +
                        '<div class="section-button-holder">' +
                        '<div class="section-button-wrapper">' +
                        '<div class="section-button-inner-wrapper">' +
                        "</div>" +
                        "</div>" +
                        "</div>" +
                        '<div class="container section-small">' +
                        "</div>" +
                        "</div>",

                    section_body:
                        '<div section-behaviour detached-toolbar-ref-context class="section">' +
                        '<drop-area class="drop-zone"></drop-area>' +
                        '<div class="container section-small">' +
                        "<div class='util-sticky-editor-toolbar' detached-toolbar-ref></div>" +
                        "</div>" +
                        '<div class="section-button-holder">' +
                        '<div class="section-button-wrapper">' +
                        '<div class="section-button-inner-wrapper">' +
                        '<button class="btn btn-transparent move-block-up-btn inside-white-focus" ng-click="moveSectionUp()" aria-label="' +
                        multiLanguageService.getString("accessibility.section_behaviour.move_up") +
                        '"><span class="icon-thin-arrow-up"></span></button>' +
                        '<button class="btn btn-transparent move-block-down-btn inside-white-focus" ng-click="moveSectionDown()" aria-label="' +
                        multiLanguageService.getString("accessibility.section_behaviour.move_down") +
                        '"><span class="icon-thin-arrow-down"></span></button>' +
                        "</div>" +
                        "</div>" +
                        "</div>" +
                        "</div>",

                    section_body_large:
                        '<div section-behaviour class="section">' +
                        '<drop-area class="drop-zone"></drop-area>' +
                        '<div class="container section-large">' +
                        "</div>" +
                        '<div class="section-button-holder">' +
                        '<div class="section-button-wrapper">' +
                        '<div class="section-button-inner-wrapper">' +
                        '<button class="btn btn-transparent move-block-up-btn inside-white-focus" ng-click="moveSectionUp()" aria-label="' +
                        multiLanguageService.getString("accessibility.section_behaviour.move_up") +
                        '"><span class="icon-thin-arrow-up"></span></button>' +
                        '<button class="btn btn-transparent move-block-down-btn inside-white-focus" ng-click="moveSectionDown()" aria-label="' +
                        multiLanguageService.getString("accessibility.section_behaviour.move_down") +
                        '"><span class="icon-thin-arrow-down"></span></button>' +
                        "</div>" +
                        "</div>" +
                        "</div>" +
                        "</div>",

                    section_body_large_template_builder_mode:
                        '<div section-behaviour detached-toolbar-ref-context class="section" role="region" aria-label="' +
                        opts.aria_label +
                        '">' +
                        "<div class='util-sticky-editor-toolbar' detached-toolbar-ref></div>" +
                        '<drop-area class="drop-zone"></drop-area>' +
                        '<div class="container section-large">' +
                        "</div>" +
                        '<div class="section-button-holder">' +
                        '<div class="section-button-wrapper">' +
                        '<div class="section-button-inner-wrapper">' +
                        '<button class="btn btn-transparent move-block-up-btn inside-white-focus" ng-click="moveSectionUp()" aria-label="' +
                        multiLanguageService.getString("accessibility.section_behaviour.move_up") +
                        '"><span class="icon-thin-arrow-up"></span></button>' +
                        '<button class="btn btn-transparent move-block-down-btn inside-white-focus" ng-click="moveSectionDown()" aria-label="' +
                        multiLanguageService.getString("accessibility.section_behaviour.move_down") +
                        '"><span class="icon-thin-arrow-down"></span></button>' +
                        "</div>" +
                        "</div>" +
                        "</div>" +
                        "</div>",

                    section_body_template_builder_mode:
                        '<div section-behaviour detached-toolbar-ref-context class="section" role="region" aria-label="' +
                        opts.aria_label +
                        '">' +
                        "<div class='util-sticky-editor-toolbar' detached-toolbar-ref></div>" +
                        '<drop-area class="drop-zone"></drop-area>' +
                        '<div class="container section-small">' +
                        "</div>" +
                        '<div class="section-button-holder">' +
                        '<div class="section-button-wrapper">' +
                        '<div class="section-button-inner-wrapper">' +
                        '<button class="btn btn-transparent move-block-up-btn inside-white-focus" ng-click="moveSectionUp()" aria-label="' +
                        multiLanguageService.getString("accessibility.section_behaviour.move_up") +
                        '"><span class="icon-thin-arrow-up"></span></button>' +
                        '<button class="btn btn-transparent move-block-down-btn inside-white-focus" ng-click="moveSectionDown()" aria-label="' +
                        multiLanguageService.getString("accessibility.section_behaviour.move_down") +
                        '"><span class="icon-thin-arrow-down"></span></button>' +
                        "</div>" +
                        "</div>" +
                        "</div>" +
                        "</div>",

                    section_body_template_builder_mode_non_editable:
                        '<div section-behaviour detached-toolbar-ref-context class="section non-editable" role="region" aria-label="' +
                        opts.aria_label +
                        '">' +
                        "<div class='util-sticky-editor-toolbar' detached-toolbar-ref></div>" +
                        '<drop-area class="drop-zone"></drop-area>' +
                        '<div class="container section-small">' +
                        "</div>" +
                        '<div class="section-button-holder">' +
                        '<div class="section-button-wrapper">' +
                        '<div class="section-button-inner-wrapper">' +
                        "</div>" +
                        "</div>" +
                        "</div>" +
                        "</div>",

                    section_body_template_builder_mode_non_editable_no_drop_area:
                        '<div section-behaviour detached-toolbar-ref-context class="section non-editable" role="region" aria-label="' +
                        opts.aria_label +
                        '">' +
                        "<div class='util-sticky-editor-toolbar' detached-toolbar-ref></div>" +
                        '<div class="container section-small">' +
                        "</div>" +
                        '<div class="section-button-holder">' +
                        '<div class="section-button-wrapper">' +
                        '<div class="section-button-inner-wrapper">' +
                        "</div>" +
                        "</div>" +
                        "</div>" +
                        "</div>",

                    section_view_mode:
                        '<div section-behaviour class="section" tabindex="' +
                        opts.tab_index +
                        '">' +
                        '<div class="container section-small">' +
                        '<div class="not-ready block-whole block-resizable" block-behaviour>' +
                        "<" +
                        opts.embed_data +
                        " block-base" +
                        "></" +
                        opts.embed_data +
                        ">" +
                        "</div>" +
                        "</div>" +
                        "</div>",

                    section_body_view_mode: '<div section-behaviour class="section" tabindex="' + opts.tab_index + '">' + '<div class="container section-small">' + "</div>" + "</div>",

                    section_body_large_view_mode: '<div section-behaviour class="section"  tabindex="' + opts.tab_index + '">' + '<div class="container section-large">' + "</div>" + "</div>",

                    // IF YOU CHANGE THE BLOCK MAKE SURE YOU CHECK THE VIEW MODE BLOCK ONLY
                    block:
                        '<div class="block-whole block-resizable not-ready ' +
                        opts.embed_data +
                        '" role="region" block-behaviour draggable aria-label="' +
                        opts.aria_label +
                        '">' +
                        "<" +
                        opts.embed_data +
                        " block-base" +
                        "></" +
                        opts.embed_data +
                        ">" +
                        '<button data-hook="block-remove-button" class="btn btn-transparent remove-block-btn" ng-click="::confirmRemoveBlock()" aria-label="' +
                        multiLanguageService.getString("accessibility.block_behaviour.remove") +
                        '"><span class="icon-cross"></span></button>' +
                        "<block-resize></block-resize>" +
                        "</div>",

                    // IF YOU CHANGE THE BLOCK MAKE SURE YOU CHECK THE VIEW MODE BLOCK ONLY
                    block_no_drag:
                        '<div class="block-whole block-resizable not-ready ' +
                        opts.embed_data +
                        '"  role="region" block-behaviour aria-label="' +
                        opts.aria_label +
                        '">' +
                        "<" +
                        opts.embed_data +
                        " block-base" +
                        "></" +
                        opts.embed_data +
                        ">" +
                        '<button data-hook="block-remove-button" class="btn btn-transparent remove-block-btn" ng-click="::confirmRemoveBlock()" aria-label="' +
                        multiLanguageService.getString("accessibility.block_behaviour.remove") +
                        '"><span class="icon-cross"></span></button>' +
                        "<block-resize></block-resize>" +
                        "</div>",

                    block_template_builder_mode:
                        '<div class="block-whole block-resizable not-ready ' +
                        opts.embed_data +
                        '"  role="region" block-behaviour draggable aria-label="' +
                        opts.aria_label +
                        '">' +
                        "<" +
                        opts.embed_data +
                        " block-base" +
                        "></" +
                        opts.embed_data +
                        ">" +
                        '<button data-hook="block-remove-button" class="btn btn-transparent remove-block-btn" ng-click="::confirmRemoveBlock()" aria-label="' +
                        multiLanguageService.getString("accessibility.block_behaviour.remove") +
                        '"><span class="icon-cross"></span></button>' +
                        "</div>",

                    block_template_builder_mode_no_drag:
                        '<div class="block-whole block-resizable not-ready ' +
                        opts.embed_data +
                        '"  role="region" block-behaviour tabindex="' +
                        opts.tab_index +
                        '" aria-label="' +
                        opts.aria_label +
                        '">' +
                        "<" +
                        opts.embed_data +
                        " block-base" +
                        "></" +
                        opts.embed_data +
                        ">" +
                        '<button data-hook="block-remove-button" class="btn btn-transparent remove-block-btn" ng-click="::confirmRemoveBlock()" aria-label="' +
                        multiLanguageService.getString("accessibility.block_behaviour.remove") +
                        '"><span class="icon-cross"></span></button>' +
                        "</div>",

                    block_template_builder_mode_no_drag_no_delete:
                        '<div class="block-whole block-resizable not-ready ' +
                        opts.embed_data +
                        '"  role="region" block-behaviour tabindex="' +
                        opts.tab_index +
                        '" aria-label="' +
                        opts.aria_label +
                        '">' +
                        "<" +
                        opts.embed_data +
                        " block-base" +
                        "></" +
                        opts.embed_data +
                        ">" +
                        "</div>",

                    block_template_builder_mode_no_delete:
                        '<div class="block-whole block-resizable not-ready ' +
                        opts.embed_data +
                        '"  role="region" block-behaviour draggable tabindex="' +
                        opts.tab_index +
                        '" aria-label="' +
                        opts.aria_label +
                        '">' +
                        "<" +
                        opts.embed_data +
                        " block-base" +
                        "></" +
                        opts.embed_data +
                        ">" +
                        "</div>",

                    block_view_mode:
                        '<div class="block-whole block-resizable not-ready ' +
                        opts.embed_data +
                        '"  role="region" block-behaviour>' +
                        "<" +
                        opts.embed_data +
                        " block-base" +
                        "></" +
                        opts.embed_data +
                        ">" +
                        "</div>"
                }[opts.template_type];
            },

            allowedProperties: {
                width: function (section_type, section_is_full) {
                    if (section_is_full) {
                        return -1;
                    } else {
                        return ["ContentSectionText", "ContentSectionMedia", "ContentSectionQuote", "ContentSectionEmbed", "ContentSectionGallery", "ContentSectionBadge"].indexOf(section_type);
                    }
                },
                "background-color": function (section_type) {
                    return [
                        "ContentSectionRow",
                        "ContentSectionText",
                        "ContentSectionMedia",
                        "ContentSectionQuote",
                        "ContentSectionHero",
                        "ContentSectionEmbed",
                        "ContentSectionGallery",
                        "ContentSectionBadge"
                    ].indexOf(section_type);
                },
                "block-width": function (section_type) {
                    return [
                        "BuilderContentSection",
                        "ContentSection",
                        "ContentSectionRow",
                        "ContentSectionText",
                        "ContentSectionMedia",
                        "ContentSectionQuote",
                        "ContentSectionHero",
                        "ContentSectionEmbed",
                        "ContentSectionGallery",
                        "ContentSectionBadge"
                    ].indexOf(section_type);
                },
                "element-purpose-title-required": function (section_type) {
                    return [].indexOf(section_type);
                }
            },

            shouldItHasThisProperty: function (section_type, style_option, section_is_full) {
                return this.allowedProperties[style_option](section_type, section_is_full) > -1;
            },

            resetSectionSize: function (sectionDto, blockType, checkStandardWidthTypes) {
                var blockWidth = sectionDto.StyleOptions.where({ Key: "block-width" })[0],
                    rowSize = sectionDto.StyleOptions.where({ Key: "width" })[0];

                //Temp solution until Hint Image Block behaviour can be normalised, currently they jump between 100 and 75 in width. Brad has logged a task to deal with this.
                var forceStandardWidthTypes = ["BuilderHintImage", "block-hint-image"],
                    standardWidth = 75;

                blockWidth.Value = checkStandardWidthTypes && forceStandardWidthTypes.indexOf(blockType) !== -1 ? standardWidth : this.getDefaultBlockWidth(blockType);
                if (rowSize) {
                    rowSize.Value = this.getDefaultRowSize(blockType);
                }

                return sectionDto;
            },

            getDefaultRowSize: function (block_type, defaultSize) {
                switch (block_type) {
                    case "block-hero":
                    case "block-quote":
                    case "ContentSectionQuote":
                    case "ContentSectionHero":
                    case "BuilderContentSection":
                    case "block-content-section":
                    case "ContentSection":
                        return "section-large";
                        break;

                    default:
                        return defaultSize === "medium" ? "section-medium" : "section-small";
                }
            },

            getDefaultBlockWidth: function (block_type) {
                return 100;
            },

            getDefaultRowBackgroundColour: function (block_type) {
                switch (block_type) {
                    case "block-quote":
                    case "ContentSectionQuote":
                        return "#3b3c3d";
                    default:
                        return "transparent";
                }
            },

            setSectionIsFull: function (block_type) {
                var alwaysSectionIsFull = [
                    "block-hero",
                    "BuilderSingleLineTextBox",
                    "block-single-line",
                    "BuilderMultiLineTextBox",
                    "block-multi-line",
                    "BuilderOptionsList-RadioButtons",
                    "block-radio-button",
                    "BuilderBinaryAnswerInput",
                    "block-binary",
                    "BuilderOptionsList-Checkboxes",
                    "block-checkbox",
                    "block-drop-down",
                    "BuilderOptionsList-DropDown",
                    "block-drop-down",
                    "BuilderRangeInput-Standard",
                    "block-numeric",
                    "BuilderRangeInput-Likert",
                    "block-likert",
                    "BuilderCapabilityEvidenceOnly",
                    "block-capability-evidence",
                    "BuilderCapabilityRangeInput-Standard",
                    "block-rating-capability",
                    "BuilderCapabilityOptionList-RadioButtons",
                    "block-capability-radio-button",
                    "block-horizontal-rule",
                    "block-generic-table__BuilderRubricContainer",
                    "GenericBuilderTable",
                    "block-generic-table",
                    "BuilderCapabilityBinaryAnswerInput",
                    "block-binary-capability",
                    "BuilderFormUserMediaPicker",
                    "block-media-picker"
                ];

                return alwaysSectionIsFull.indexOf(block_type) > -1;
            },

            setColorBasedOnBackgroundColor: function (background_color, item) {
                var colorItem = this.getItemFromKey("color", item.StyleOptions);
                if (colorItem === undefined) {
                    colorItem = { Key: "color", Value: "#ffffff" };
                    item.StyleOptions.push(colorItem);
                }
                if (background_color === "transparent") {
                    colorItem.Value = "#202020";
                } else {
                    colorItem.Value = "#ffffff";
                }
            },

            getValueFromKey: function (key, array) {
                for (var i = 0; i < array.length; i++) {
                    if (array[i].Key === key) {
                        return array[i].Value;
                    }
                }
                return null;
            },

            getItemFromKey: function (key, array) {
                for (var i = 0; i < array.length; i++) {
                    if (array[i].Key === key) {
                        return array[i];
                    }
                }
                return null;
            },

            removeItemByKeyValue: function (array, value, keyName) {
                for (var i = 0; i < array.length; i++) {
                    if (array[i][keyName] === value) {
                        array.splice(i, 1);
                        break;
                    }
                }
            },

            removeBlankCategory: function (subType, array) {
                if (subType !== "") {
                    this.removeItemByKeyValue(array, "", "Key");
                }
            },

            isVisible: function (element) {
                "use strict";

                /**
                 * Checks if a DOM element is visible. Takes into
                 * consideration its parents and overflow.
                 *
                 * @param (el)      the DOM element to check if is visible
                 *
                 * These params are optional that are sent in recursively,
                 * you typically won't use these:
                 *
                 * @param (t)       Top corner position number
                 * @param (r)       Right corner position number
                 * @param (b)       Bottom corner position number
                 * @param (l)       Left corner position number
                 * @param (w)       Element width number
                 * @param (h)       Element height number
                 */
                function _isVisible(el, t, r, b, l, w, h) {
                    var p = el.parentNode,
                        VISIBLE_PADDING = 2;

                    if (!_elementInDocument(el)) {
                        return false;
                    }

                    if ([null, undefined].indexOf(el.getAttribute("block-base")) === -1) {
                        return true;
                    }

                    //-- Return true for document node
                    if (9 === p.nodeType) {
                        return true;
                    }

                    //-- Return false if our element is invisible
                    if (
                        //'0' === _getStyle(el, 'opacity') ||
                        "none" === _getStyle(el, "display") ||
                        "hidden" === _getStyle(el, "visibility")
                    ) {
                        return false;
                    }

                    if ("undefined" === typeof t || "undefined" === typeof r || "undefined" === typeof b || "undefined" === typeof l || "undefined" === typeof w || "undefined" === typeof h) {
                        t = el.offsetTop;
                        l = el.offsetLeft;
                        b = t + el.offsetHeight;
                        r = l + el.offsetWidth;
                        w = el.offsetWidth;
                        h = el.offsetHeight;
                    }
                    //-- If we have a parent, let's continue:
                    if (p) {
                        //-- Check if the parent can hide its children.
                        if ("hidden" === _getStyle(p, "overflow") || "scroll" === _getStyle(p, "overflow")) {
                            //-- Only check if the offset is different for the parent
                            if (
                                //-- If the target element is to the right of the parent elm
                                l + VISIBLE_PADDING > p.offsetWidth + p.scrollLeft ||
                                //-- If the target element is to the left of the parent elm
                                l + w - VISIBLE_PADDING < p.scrollLeft ||
                                //-- If the target element is under the parent elm
                                t + VISIBLE_PADDING > p.offsetHeight + p.scrollTop ||
                                //-- If the target element is above the parent elm
                                t + h - VISIBLE_PADDING < p.scrollTop
                            ) {
                                //-- Our target element is out of bounds:
                                return false;
                            }
                        }
                        //-- Add the offset parent's left/top coords to our element's offset:
                        if (el.offsetParent === p) {
                            l += p.offsetLeft;
                            t += p.offsetTop;
                        }
                        //-- Let's recursively check upwards:
                        return _isVisible(p, t, r, b, l, w, h);
                    }
                    return true;
                }

                //-- Cross browser method to get style properties:
                function _getStyle(el, property) {
                    if (window.getComputedStyle) {
                        return document.defaultView.getComputedStyle(el, null)[property];
                    }
                    if (el.currentStyle) {
                        return el.currentStyle[property];
                    }
                }

                function _elementInDocument(element) {
                    // tslint:disable-next-line:no-conditional-assignment
                    while ((element = element.parentNode)) {
                        if (element === document) {
                            return true;
                        }
                    }
                    return false;
                }

                return _isVisible(element);
            },

            friendlyMainType: function (_mainType_) {
                var returnString = "";

                if (typeof _mainType_ !== "string") {
                    return _mainType_;
                }

                returnString = multiLanguageService.getString("types.main_types." + _mainType_.toLowerCase())
                    ? multiLanguageService.getString("types.main_types." + _mainType_.toLowerCase())
                    : _mainType_;

                return returnString.toLowerCase();
            },

            cascadeBlockSettingsFromAsset: function (blockDto, assetDto) {
                switch (blockDto.SectionType) {
                    case "BuilderSingleLineTextBox":
                    case "BuilderMultiLineTextBox":
                        this.getItemFromKey("show-empty-field-when-viewing", blockDto.StyleOptions).Value = assetDto.PreferenceShowEmptyFieldWhenAnAssetIsViewed;
                        this.getItemFromKey("infield-hint", blockDto.StyleOptions).Value = assetDto.PreferenceUseInFieldHintsWherePossible;
                        this.getItemFromKey("show-hint-when-viewing", blockDto.StyleOptions).Value = assetDto.PreferenceIncludeHintWhenAssetIsViewed;
                        break;
                    case "BuilderOptionsList-RadioButtons":
                    case "BuilderOptionsList-Checkboxes":
                    case "BuilderFormTableContainer-RowHeadingsNoColumnHeadings":
                    case "BuilderFormTableContainer-RowHeadings":
                    case "BuilderFormTableContainer-FixedRow":
                    case "BuilderFormTableContainer-SingleCells":
                    case "BuilderOptionsList-DropDown":
                    case "BuilderRangeInput-Standard":
                    case "BuilderRangeInput-Likert":
                    case "BuilderCapabilityEvidenceOnly":
                    case "BuilderCapabilityOptionList-RadioButtons":
                    case "BuilderBinaryAnswerInput":
                    case "BuilderCapabilityRangeInput-Standard":
                    case "BuilderCapabilityBinaryAnswerInput":
                    case "BuilderFormSignatureElement":
                    case "BuilderFormUserMediaPicker":
                    case "BuilderDatePickerInput":
                        this.getItemFromKey("show-empty-field-when-viewing", blockDto.StyleOptions).Value = assetDto.PreferenceShowEmptyFieldWhenAnAssetIsViewed;
                        this.getItemFromKey("show-hint-when-viewing", blockDto.StyleOptions).Value = assetDto.PreferenceIncludeHintWhenAssetIsViewed;
                        break;
                }
            },

            canBlocksBeInSameRow: function (obj) {
                //we are only stopping block from being next to each other in builderMode at present
                if (obj.builderMode) {
                    // check if both dragging and drop target blocks allow be side by side (in the same row)
                    return obj.allowSideBySide && obj.data.allowSideBySide;
                } else {
                    return true;
                }
            },

            isResourceBuilder: function (type) {
                var resourceTypes = ["WorkbookBuilder", "TemplateBuilder"];
                return resourceTypes.indexOf(type) !== -1;
            },

            checkIsSaved: function () {
                if (this.checkIsSavedFn) {
                    return this.checkIsSavedFn();
                } else {
                    return $q.when();
                }
            },

            checkIsSavedFn: null,

            notAllowedRoute: ["webfolio", "foliopage", "template", "workbook", "collection", "activitylog", "post", "blog", "builder", "file", "viewer", "public"],

            generateNotAllowedRouteRegex: function () {
                var regex = "(" + this.notAllowedRoute.join("|") + ")";
                return new RegExp(regex, "g");
            },

            isNotInAsset: function () {
                var regex = this.generateNotAllowedRouteRegex();
                return !regex.test($location.path());
            }
        };
    }
]);
