import { angularAMD } from "@pebblepad/amd";
import "../../../../utilities/baseUrlsFactory";
import "../commentsDataModel";
import "../commentsService";
import "../../../../reply/replyWrapper/replyWrapper";
import "../../../../reply/reply";
import "../../../../multiLanguageService/multiLanguageService";
import template from "../templates/comment.html";

angularAMD.directive("comment", Comment);
Comment.$inject = [];

angularAMD.controller("CommentController", CommentController);
CommentController.$inject = ["commentsService", "commentsDataModel", "multiLanguageService", "modal", "baseUrlsFactory", "$filter"];

function Comment() {
    return {
        restrict: "E",
        template: template,
        scope: {
            data: "<",
            anchorId: "=anchorId",
            requiresAuthor: "=",
            summaryAsset: "=",
            commentIndex: "=",
            commentCount: "<",
            submissionId: "<",
            onUpdate: "&",
            onDelete: "&",
            isDisabled: "<"
        },
        bindToController: true, //binds directive attributes ($scope values above) directly to the Controller (this.)
        controller: "CommentController",
        controllerAs: "vm"
    };
}

function CommentController(commentsService, commentsDataModel, multiLanguageService, modal, baseUrlsFactory, $filter) {
    this.services = {
        commentsService: commentsService,
        commentsDataModel: commentsDataModel,
        multiLanguageService: multiLanguageService,
        modal: modal,
        baseUrlsFactory: baseUrlsFactory,
        $filter
    };

    this.hideReplySection = false;
    this.activeReply = false;

    this.commentEditor = multiLanguageService.getString("labels.form_element_toolbar.a11y.comment_editor");

    this.disabledDropdownToolTip = multiLanguageService.getString("sidebar.asset_comments.edit_in_progress");
    this.commentAriaTitle = multiLanguageService.getString("atlas_sidebar.a11y.comment_lower");
}

CommentController.prototype.$onChanges = function (changes) {
    if (changes.commentIndex !== undefined || changes.commentCount !== undefined) {
        this._updateMainArialLabel();
    }
};

CommentController.prototype.refreshData = function (data) {
    this.data = data;
};

CommentController.prototype.onAddComment = function (body, author) {
    return this.services.commentsDataModel.addNewComment(body, author, this.data.Id, this.data.AssetId, this.anchorId, this.submissionId);
};

CommentController.prototype.onCommentUpdate = function (commentDto) {
    return this.services.commentsDataModel.updateComment(commentDto, this.data.Id, this.anchorId, this.submissionId);
};

CommentController.prototype.onCommentDelete = function (commentDto) {
    return this.services.commentsService.deleteComment(commentDto.AssetId, this.submissionId, commentDto.Id, this.anchorId).then((response) => {
        this.onDelete({ isMainComment: false });
        return response;
    });
};

CommentController.prototype.onMainCommentDelete = function (commentDto) {
    return this.services.commentsDataModel
        .deleteComment(commentDto, this.submissionId, this.anchorId)
        .then((comment) => {
            this.data = comment;
            this.onDelete({ isMainComment: true });
        })
        .catch(() => {
            this.services.modal.launch(this.services.baseUrlsFactory.shared_component_base_url + "spaMenu/okmessage.html", {
                okButton: this.services.multiLanguageService.getString("buttons.confirm"),
                okButtonTitle: this.services.multiLanguageService.getString("buttons.generic.popup"),
                confirmMessage: this.services.multiLanguageService.getString("sidebar.asset_comments.unable_to_delete_comment")
            });
        });
};

CommentController.prototype.toggleReplyOption = function (activeEditor) {
    this.hideReplySection = activeEditor;

    this.onUpdate({ commentInProgress: activeEditor, isNewComment: false });
};

CommentController.prototype.onReplyStateChange = function (activeReply) {
    this.activeReply = activeReply;

    this.onUpdate({ commentInProgress: activeReply, isNewComment: false });
};

CommentController.prototype._updateMainArialLabel = function () {
    this.mainAriaLabel = this.services.multiLanguageService.getString("sidebar.asset_comments.comment_n_of_total_made_by_name_at_on", {
        number: this.commentIndex,
        total: this.commentCount,
        name: this.data.Poster.Name,
        time: this.services.$filter("pebbleDate")(this.data.Posted, "timeOnly"),
        date: this.services.$filter("pebbleDate")(this.data.Posted)
    });
};
