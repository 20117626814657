import { angularAMD } from "@pebblepad/amd";
import { SELECTOR } from "../../../constants/selector.constants";
import "../services/spaSelectorService";
import "../../../utilities/baseUrlsFactory";
import "../../../expandable/expandable";
import "../../../extensions/arrayExtensions";
import "../../../navigationMenu/navigationMenu";
import "../../../utilities/checklistModel";
import "../../../imgFallback/imgFallback";
import "../../../assetLocking/services/assetLockingNotification.service";
import "../../../modal/services/modal";
import "../../../assetStore/services/assetStoreService";
import "../../../userAgent/userAgent.service";
import "../../../accessKeys/accessKey.service";
import "../../../itemSelector/itemSelector.component";
import "../../../multiLanguageService/multiLanguageService";
import template from "../views/spa-selector.html";
import { bannerIcon, listIcon, gridIcon } from "@pjs/core-ui";

angularAMD.directive("spaSelector", [
    "$timeout",
    "$interval",
    "$window",
    "spaSelectorService",
    "baseUrlsFactory",
    "$rootScope",
    "multiLanguageService",
    "$q",
    "assetLockingNotificationService",
    "modal",
    "assetStoreService",
    "accessKeyService",
    function ($timeout, $interval, $window, spaSelectorService, baseUrlsFactory, $rootScope, multiLanguageService, $q, assetLockingNotificationService, modal, assetStoreService, accessKeyService) {
        return {
            template: template,
            restrict: "E",
            link: function (scope, element, attrs) {
                scope.selectorDisplayResults = {
                    Items: [],
                    Description: ""
                }; // an object that contains a description and list of results
                scope.selectedSelectors = []; // items selected by user
                scope.excludeIds = []; // list of item ids which should not appear in any search/autocomplete/default results
                scope.searchString = ""; // user search string bound to input field
                scope.selectType = ""; // What to search on (AssetStore, ResourceCentre, AssetStoreAndResourceCentre, etc)
                scope.mainTypes = []; // If searching asset store or resource centre then is used for asset maintypes
                scope.subTypes = []; // If searching asset store or resource centre then is used for asset subtypes
                scope.ownerships = []; // ByMe, ForMe, and/or WithMe
                scope.singleOrMultipleSelect = ""; // Selecting Single or Multiple items
                scope.broadcastCommand = ""; // name of command to broadcast results too
                scope.broadcastType = ""; // when to broadcast results to the broadcastCommand (everyAction or onDone)
                scope.originalSelectors = []; // takes currentlySelectedIds from setup command and gets original list of ids already selected
                scope.selectorSpinner = true; // whether to display the spinner or not
                scope.showClearAll = false; // toggle between showing a select all button and a clear all button
                scope.previousItemsFound = false; // a bool to record whether or not previously selected items have been found
                scope.defaultItemsFound = false; // a bool to record whether or not the default items have loaded
                scope.defaultBanners = {}; //object with title and arry of items to display as defaults
                scope.searchInput = document.getElementById("selector-input");
                scope.back_to_start_el = angular.element(document.getElementsByClassName("back-to-start")[0]);
                scope.viewsDirectoryUrl = baseUrlsFactory.shared_component_base_url + "spaMenu/selector/views/";
                scope.defaultLimit = 4;
                scope.currentLimit = scope.defaultLimit;
                scope.selectorLimit = 50;
                scope.uploadTypes = ["Image", "File", "Video", "Audio"]; //Determines if upload Page needs to be added.
                scope.showIWantTo = false; // Don't have all the asset management options when just selecting an asset
                scope.previousData = null;
                scope.limitedView = false;
                scope.activeRequests = [];
                scope.deleteMode = false;
                scope.requiresWritePermission = false;
                scope.defaultBannerViews = [];
                scope.selectorViews = [];
                scope.stopExtraAction = false;
                scope.errorMessage = "";

                const bannerView = { id: "banner", icon: bannerIcon, ariaLabel: multiLanguageService.getString("accessibility.spa_menu.selector.bannerView") };
                const listView = { id: "list", icon: listIcon, ariaLabel: multiLanguageService.getString("accessibility.spa_menu.selector.listView") };
                const galleryView = { id: "gallery", icon: gridIcon, ariaLabel: multiLanguageService.getString("accessibility.spa_menu.selector.thumbnailView") };

                scope.focusInSearchInput = function () {
                    if (scope.searchInput !== null && scope.searchInput !== undefined) {
                        $timeout(function () {
                            scope.searchInput.focus();
                        }, 1500);
                    }
                };

                scope.$on("setupSelector", function (event, data) {
                    scope.selectorSpinner = true;
                    scope.previousItemsFound = false;
                    scope.defaultItemsFound = false;
                    scope.hideMenu = data.hideMenu;
                    scope.selectType = data.selectType;
                    scope.singleOrMultipleSelect = data.singleOrMultipleSelect;
                    scope.broadcastCommand = data.broadcastCommand;
                    scope.broadcastType = data.broadcastType;
                    scope.excludeIds = data.excludeIds;
                    scope.excludeChildrenOf = data.excludeChildrenOf !== undefined ? data.excludeChildrenOf : [];
                    scope.excludeMainTypes = data.excludeMainTypes;
                    scope.mainTypes = data.mainTypes;
                    scope.subTypes = data.subTypes;
                    scope.ownerships = data.ownerships;
                    scope.defaultBanners = data.defaultBanners;
                    scope.restrictedFileTypes = data.restrictedFileTypes;
                    scope.lockOnSelect = data.lockOnSelect;
                    scope.limitedView = false;
                    scope.requiresWritePermission = data.requiresWritePermission === true;
                    scope.selectorViews = data.selectorViews || [];
                    scope.errorMessage = "";
                    scope.showCurrentDefaultSelector = data.showCurrentDefaultSelector;

                    if (data.currentDefault !== undefined) {
                        scope.currentDefault = {
                            Description: data.currentDefault.Title,
                            Items: [data.currentDefault]
                        };
                    }

                    scope.resetDefaultBannerDisplay();
                    scope.handleLimitedViewString(0);

                    scope.doneSelection = false;
                    scope.doneSelectionMessage = "";

                    scope.imageViewLimit = 15;
                    scope.allLoaded = false;

                    scope.defaultBannerViews = [bannerView, galleryView, listView];

                    var selectorViews = [galleryView, listView, bannerView];

                    scope.showTagger = true;
                    if (data.actionData && data.actionData.excludeAssetFunctionality) {
                        scope.showTagger = !data.actionData.excludeAssetFunctionality.contains("tagger");
                        scope.showDescription = !data.actionData.excludeAssetFunctionality.contains("asset-description");
                    }

                    if (data.actionData) {
                        scope.deleteMode = data.actionData.deleteMode;
                        if (scope.deleteMode) {
                            scope.hideGalleryUpload = true;
                        }
                    }

                    if (scope.selectType === SELECTOR.SELECT_TYPE.STANDARDS) {
                        scope.stopExtraAction = true;
                    }

                    scope.currentLimit = scope.defaultLimit;

                    scope.bindAccessKeys();
                    scope.focusInSearchInput();

                    //Reset requests
                    scope.activeRequests.forEach(function (q) {
                        if (q) {
                            q.resolve(null);
                        }
                    });
                    scope.activeRequests.length = 0;
                    scope.activeRequests.push($q.defer(), $q.defer());

                    const isFileUploadForceEnabled = data.includeUpload !== false;
                    var shouldIncludeFileUploadByDefault = false;
                    var promises = [];
                    promises.push(
                        spaSelectorService.getSelectorsBasedOnIds(scope.selectType, data.currentlySelectedIds, { timeout: scope.activeRequests[0].promise }).then(function (response) {
                            scope.previousItemsFound = true;
                            scope.originalSelectors = response.data;
                            scope.selectedSelectors = angular.copy(response.data); // take a copy, don't reference the same original data
                            // tslint:disable-next-line:triple-equals
                            if (scope.defaultItemsFound == true && scope.previousItemsFound == true) {
                                scope.selectorSpinner = false;
                            }
                        })
                    );

                    if (data.feedbackTemplates) {
                        promises.push(
                            spaSelectorService.getFeedbackTemplates({ timeout: scope.activeRequests[0].promise }).then(function (response) {
                                scope.defaultItemsFound = true;
                                scope.selectorDisplayResults = response.data[0];

                                // tslint:disable-next-line:triple-equals
                                if (scope.defaultItemsFound == true && scope.previousItemsFound == true) {
                                    scope.selectorSpinner = false;
                                }

                                if (scope.selectorDisplayResults.Items.length < 1) {
                                    scope.errorMessage = multiLanguageService.getString("search.no_results_templates");
                                }

                                selectorViews = [listView];
                                shouldIncludeFileUploadByDefault = false;
                            })
                        );
                    } else {
                        promises.push(
                            spaSelectorService
                                .getDefaultItemsForSelector(scope.selectType, scope.mainTypes, scope.subTypes, scope.excludeIds, scope.excludeMainTypes, scope.excludeChildrenOf, scope.ownerships, {
                                    timeout: scope.activeRequests[1].promise
                                })
                                .then(function (selectorItem) {
                                    scope.defaultItemsFound = true;
                                    scope.selectorDisplayResults = selectorItem;

                                    if (scope.selectorDisplayResults.Items.length > 0 && scope.selectorDisplayResults.Description === "Your image assets") {
                                        scope.selectorDisplayResults.Description = multiLanguageService.getString("labels.selector.your_image_assets");
                                    }

                                    scope.showClearAll = allSelected();
                                    // tslint:disable-next-line:triple-equals
                                    if (scope.defaultItemsFound == true && scope.previousItemsFound == true) {
                                        scope.selectorSpinner = false;
                                    }

                                    shouldIncludeFileUploadByDefault = false;
                                    const uploadSubTypes = ["image", "video", "audio"];

                                    if ((scope.mainTypes && scope.mainTypes.length > 0) || scope.selectType === SELECTOR.SELECT_TYPE.RESOURCE || scope.selectType === SELECTOR.SELECT_TYPE.STANDARDS) {
                                        selectorViews = [listView];
                                    }

                                    if (scope.subTypes && scope.subTypes.length !== 0) {
                                        if (!scope.subTypes.contains("Image")) {
                                            selectorViews = [listView];
                                        } else if (
                                            scope.subTypes.some(function (type) {
                                                return uploadSubTypes.indexOf(type.toLowerCase()) > -1;
                                            })
                                        ) {
                                            shouldIncludeFileUploadByDefault = true;
                                        }
                                    }
                                })
                        );
                    }

                    $q.all(promises).then(function () {
                        scope.onRequestUpload = isFileUploadForceEnabled || shouldIncludeFileUploadByDefault ? scope.launchFileUpload : undefined;
                        scope.selectorViews = scope.selectorViews.length > 0 ? scope.selectorViews : selectorViews;
                        if (data.showSelected && data.currentlySelectedIds && data.currentlySelectedIds.length !== 0) {
                            scope.displayCurrentlySelectedSelectors();
                        }

                        if (scope.selectedSelectors.length > 0 && scope.selectorDisplayResults.Items.length === 0) {
                            scope.selectorDisplayResults.Items = scope.selectedSelectors.slice();
                        }
                        // reset scroll position for results panel so on every
                        // other load user starts from the top of the list
                        $window.requestAnimationFrame(function () {
                            scope.resultsPanelEl = element[0].querySelector("[data-hook='spa-selector-results']");
                            scope.resultsPanelEl.scrollTop = 0;
                        });
                    });
                });

                scope.defaultSearchResults = function () {
                    return {
                        Items: [],
                        Description: ""
                    };
                };

                scope.handleLimitedViewString = function (value) {
                    scope.value = value;
                };

                scope.handleSelectorSelection = function () {
                    scope.selectorSpinner = true;
                    scope.searchString = "";
                };

                scope.selectSelector = function (selector) {
                    if (scope.singleOrMultipleSelect === "Single") {
                        scope.doneSelectionMessage = multiLanguageService.getString("labels.selector.a11y.on_selection", { title: selector.Title });
                        scope.doneSelection = true;

                        $timeout(function () {
                            scope.validateSelection([selector]).then(function () {
                                scope.selectSingle(selector);
                            });
                        }, 100);
                    } else {
                        scope.showClearAll = allSelected();
                    }
                };

                scope.selectingDone = function () {
                    scope.validateSelection(scope.selectedSelectors).then(scope.selectionConfirm);
                };

                scope.selectionConfirm = function () {
                    $rootScope.$broadcast(scope.broadcastCommand, scope.selectedSelectors);
                    $timeout(function () {
                        scope.selectorDisplayResults = scope.defaultSearchResults();
                    });
                    scope.selectedSelectors = [];
                    scope.originalSelectors = [];
                    scope.searchString = "";
                    scope.showClearAll = false;
                    scope.previousData = null;

                    scope.goToPreviousPanel(true);
                };

                scope.validateSelection = function (selection) {
                    var promise = null;
                    if (scope.requiresWritePermission && selection.length > 0) {
                        var assetIds = selection.reduce(function (assetIds, item) {
                            if (!item.IsDefault) {
                                assetIds.push(item.Id);
                            }
                            return assetIds;
                        }, []);

                        if (assetIds.length === 0) {
                            return $q.when();
                        }

                        promise = assetStoreService.getEditStatusForAssets(assetIds).then(function (assets) {
                            var invalidAssets = [];
                            var containsCollab = false;
                            var statusInfo = "";

                            for (var i = 0, l = assets.length; i < l; i++) {
                                statusInfo = assets[i];

                                if (statusInfo.Value === "NoPermission") {
                                    invalidAssets.push(selection[i]);
                                } else if (statusInfo.Value === "Collaborate") {
                                    invalidAssets.push(selection[i]);
                                    containsCollab = true;
                                }
                            }

                            if (invalidAssets.length > 0) {
                                scope.showBadPermissionModal(invalidAssets, containsCollab);
                                return $q.reject();
                            }
                        });
                    } else {
                        promise = $q.when();
                    }

                    return promise;
                };

                scope.selectSingle = function (selector) {
                    scope.selectedSelectors = []; // make sure only one object is returned
                    scope.selectedSelectors.push(selector);

                    if (scope.lockOnSelect) {
                        scope
                            .lockSingle(selector)
                            .then(function (lock) {
                                scope.selectionConfirm();
                            })
                            .catch(function (err) {
                                scope.selectedSelectors.length = 0;
                            });
                    } else {
                        scope.selectionConfirm();
                    }
                };

                scope.showBadPermissionModal = function (invalidAssets, containsCollab) {
                    var isolatedScope = scope.$new(true);
                    var modalTemplate = "spaMenu/selector/invalid-selection-modal.html";
                    var whichCentre = scope.selectType === SELECTOR.SELECT_TYPE.RESOURCE ? "resource" : "asset";

                    isolatedScope.title = multiLanguageService.getString("builder.adding_" + whichCentre + "_not_accepted_modal.title");
                    isolatedScope.message = containsCollab
                        ? multiLanguageService.getString("builder.adding_" + whichCentre + "_not_accepted_modal.collab_error")
                        : multiLanguageService.getString("builder.adding_" + whichCentre + "_not_accepted_modal.permission_error");
                    isolatedScope.invalidAssets = invalidAssets;

                    isolatedScope.button_label = multiLanguageService.getString("portfolioAndFoliopages.okay");
                    isolatedScope.onClose = function () {
                        isolatedScope.$destroy();
                    };

                    modal.newModal({
                        scope: isolatedScope,
                        templateUrl: baseUrlsFactory.shared_component_base_url + modalTemplate
                    });
                };

                scope.launchFileUpload = (selectorId) =>
                    $rootScope.$broadcast("openRightHandSidePanel", {
                        panel: "file-upload",
                        panelSettings: {
                            selectorId: selectorId
                        }
                    });

                scope.onCancel = function () {
                    // when cancelling broadcast back any original ids the user had
                    $rootScope.$broadcast(scope.broadcastCommand, scope.originalSelectors);
                    scope.selectorDisplayResults = scope.defaultSearchResults();
                    scope.selectedSelectors = [];
                    scope.originalSelectors = [];
                    scope.showClearAll = false;
                    scope.previousData = null;
                };

                scope.onClose = function (stopExtraAction) {
                    scope.onCancel();
                    scope.goToPreviousPanel(stopExtraAction);
                };

                scope.goToPreviousPanel = function (stopExtraAction) {
                    scope.unbindAccessKeys();
                    scope.previousPanel(false, stopExtraAction);
                };

                scope.lockSingle = function (selector) {
                    // Check if this asset is locked
                    return assetLockingNotificationService.setupLockWithModalForAdding(selector.Id, selector.MainType, "").catch(function () {
                        return $q.reject(selector);
                    });
                };

                scope.extraActionFromOtherSidePanel = scope.onCancel;

                scope.searchTermsOnKeyDown = function (e) {
                    if (e.keyCode === 13) {
                        e.preventDefault();
                        scope.selectingSearchButtonPressed();
                    }
                };

                scope.selectingSearchButtonPressed = function () {
                    if (scope.searchString === "") {
                        scope.selectingClearButtonPressed();
                        return;
                    }

                    $rootScope.$broadcast("filterItemSelector", scope.searchString);
                };

                scope.selectingClearButtonPressed = function () {
                    scope.searchString = "";
                    $rootScope.$broadcast("filterItemSelector");
                };

                scope.displayCurrentlySelectedSelectors = function () {
                    scope.selectorSpinner = true;

                    //Toggle view between showing only selected results and all results.
                    if (!scope.previousData) {
                        scope.previousData = scope.selectorDisplayResults;
                        scope.selectorDisplayResults = {
                            Items: angular.copy(scope.selectedSelectors),
                            Description: multiLanguageService.getString("search.current_selection")
                        };
                        scope.limitedView = true;
                    } else {
                        scope.selectorDisplayResults = scope.previousData;
                        scope.previousData = null;
                        scope.limitedView = false;
                    }

                    scope.searchString = "";
                    $rootScope.$broadcast("filterItemSelector");

                    $rootScope.$broadcast("updateItemsInSelector", scope.selectorDisplayResults);

                    scope.selectorSpinner = false;
                    scope.showClearAll = allSelected();
                };

                scope.selectAll = function () {
                    $rootScope.$broadcast("selectAllItemSelector", true);

                    if (scope.broadcastType === "everyAction") {
                        $rootScope.$broadcast(scope.broadcastCommand, scope.selectorDisplayResults.Items);
                    }
                    scope.showClearAll = true;
                };

                scope.clearAll = function () {
                    $rootScope.$broadcast("selectAllItemSelector", false);

                    if (scope.broadcastType === "everyAction") {
                        $rootScope.$broadcast(scope.broadcastCommand, scope.selectedSelectors);
                    }
                    scope.showClearAll = false;
                };

                scope.checkSelected = function (id) {
                    return checkSelected(id) !== -1;
                };

                var checkSelected = function (selector) {
                    for (var i = 0, len = scope.selectedSelectors.length; i < len; i++) {
                        if (scope.selectedSelectors[i].Id === selector.Id) {
                            return i;
                        }
                    }
                    return -1;
                };

                var allSelected = function () {
                    for (const item of scope.selectorDisplayResults.Items) {
                        if (checkSelected(item) === -1) {
                            return false;
                        }
                    }
                    return true;
                };

                /*
                 * Access key logic
                 */

                scope.onEscKey = function () {
                    scope.onClose(true);
                };

                scope.bindAccessKeys = function () {
                    const q = {
                        key: "q",
                        altKey: true,
                        callback: function () {
                            const selectAllButton = document.querySelector(`[data-hook="spa-selector__select-all-button"]`);
                            if (selectAllButton) {
                                selectAllButton.focus();
                            }
                        }
                    };
                    accessKeyService.register("spaSelector", [q]);
                };

                scope.unbindAccessKeys = function () {
                    accessKeyService.remove("spaSelector");
                };

                scope.getImageUrl = function (id) {
                    return baseUrlsFactory.api_base_url + "file/transmitImage?id=" + id + "&x=144&y=144&shortSideScale=true";
                };

                scope.resetDefaultBannerDisplay = function () {
                    scope.currentLimit = scope.defaultLimit;
                    scope.displayingAll = false;
                };

                scope.switchGallery = function () {
                    scope.imageGallery = !scope.imageGallery;
                };

                scope.openInfoPanel = function (id, panel, actionData) {
                    panel = typeof panel !== "undefined" ? panel : "asset-info";
                    $rootScope.$broadcast("openRightHandSidePanel", {
                        panel: panel,
                        assetId: id,
                        actionData: actionData
                    });
                };

                scope.showMoreImages = function (inview) {
                    if (inview) {
                        if (scope.imageViewLimit >= scope.selectorDisplayResults.Items.length) {
                            scope.allLoaded = true;
                            scope.imageViewLimit = scope.selectorDisplayResults.Items.length;
                        } else {
                            scope.imageViewLimit += 15;
                        }
                    }
                };

                // Not the best plan but it will fix the images that don't have thumbnails for now
                scope.$on("imgFallback", function (event, data) {
                    var item = scope.selectorDisplayResults.Items.where({ Id: data[0].id });
                    if (item.length > 0) {
                        item[0].showIcon = true;
                    }
                    scope.$digest(); // Unfortunately this adds a digest each time an image thumbnail fails
                });

                scope.showIcon = function (item) {
                    return item.SubType && (item.SubType.toLowerCase() !== "image" || item.showIcon);
                };

                scope.isSelected = function (item) {
                    if (scope.selectedSelectors.where({ Id: item.Id }).length) {
                        return true;
                    }
                    return false;
                };
            }
        };
    }
]);
