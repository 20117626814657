import { angularAMD } from "@pebblepad/amd";
import "../../assetEndpointService/assetEndpoint.service";
import "../../multiLanguageService/multiLanguageService";
import "../../modal/services/modal";
import "../../assetFiltering/assetFilteringConfig.provider";

import { FILTER_CRITERIA_TYPES_CONSTANTS } from "../../constants/filterCriteriaTypes.constants";
import { FILTER_TYPES_CONSTANTS } from "../../constants/filterTypes.constants";
import { EventEmitter } from "../../eventEmitter/eventEmitter";

class AssetFilteringService {
    constructor(multiLanguageService, modal, baseUrlsFactory, assetEndpointService, $rootScope, $q, $routeParams, assetFilteringConfig) {
        this._multiLang = multiLanguageService;
        this._modal = modal;
        this._baseUrlsFactory = baseUrlsFactory;
        this._assetEndpointService = assetEndpointService;
        this._$rootScope = $rootScope;
        this._$q = $q;
        this._$routeParams = $routeParams;
        this._filterCache = new Map();
        this._eventEmitter = new EventEmitter([]);
        this._inFlightLoadFilterRequests = new Map();
        this._previousRouteAssetId = this._$routeParams.assetId;
        this._assetFilteringConfig = assetFilteringConfig;

        $rootScope.$on("$routeChangeSuccess", () => this._onRouteChange());
    }

    subscribe(assetId, callback) {
        this._eventEmitter.on(assetId, callback);
    }

    unsubscribe(assetId, callback) {
        this._eventEmitter.off(assetId, callback);
    }

    loadExistingFilter(assetId) {
        const cachedFilter = this._filterCache.get(assetId);

        if (cachedFilter !== undefined) {
            return this._$q.resolve(cachedFilter);
        }

        if (!this._assetFilteringConfig.enablePersistentFilters) {
            return this._$q.resolve(null);
        }

        const inFlightRequest = this._inFlightLoadFilterRequests.get(assetId);
        if (inFlightRequest !== undefined) {
            return inFlightRequest;
        }

        const request = this._assetEndpointService
            .getDataFilter(assetId)
            .then((response) => {
                if (response.data.length > 0) {
                    const filterObject = this._mapApiFilterToLocalFilter(response.data[0]);
                    this._filterCache.set(assetId, filterObject);
                    return filterObject;
                }
                return null;
            })
            .catch(() => {
                const errorMsgs = [this._multiLang.getString("filter_evidence.unable_to_get_filters")];
                this.showFilteringErrorModal(errorMsgs);
                return null;
            })
            .finally(() => {
                this._inFlightLoadFilterRequests.delete(assetId);
            });

        this._inFlightLoadFilterRequests.set(assetId, request);

        return request;
    }

    applyFilter(assetId, filterData, saveFilter) {
        const cachedFilter = this._filterCache.get(assetId);
        const newFilter = this._mapFilterOptionsToFilter(filterData, saveFilter);

        if (saveFilter) {
            if (cachedFilter === undefined || !cachedFilter.IsPersistent) {
                return this._addFilter(assetId, newFilter);
            }

            return this._updateFilter(assetId, cachedFilter, newFilter);
        }

        if (!saveFilter && cachedFilter !== undefined && cachedFilter.IsPersistent) {
            return this._removePersistentFilter(assetId, filterData.filterId).then(() => {
                this._addLocalFilter(assetId, newFilter);
                this._notifySubscribers(assetId);
            });
        }

        this._addLocalFilter(assetId, newFilter);
        this._notifySubscribers(assetId);
        return this._$q.resolve();
    }

    removeFilter(assetId, filterId) {
        const cachedFilter = this._filterCache.get(assetId);

        if (cachedFilter !== undefined && !cachedFilter.IsPersistent) {
            this._filterCache.delete(assetId);
            this._notifySubscribers(assetId);
            return this._$q.resolve();
        }

        return this._removePersistentFilter(assetId, filterId).then(() => this._notifySubscribers(assetId));
    }

    showFilteringErrorModal(messages) {
        const filterModalTitle = this._multiLang.getString("filter_evidence.error_title");

        this._modal.launch(`${this._baseUrlsFactory.shared_component_base_url}modalDialogComponent/templates/generic-pop-up-with-repeating-content.html`, {
            title: filterModalTitle,
            messages: messages,
            buttons: [
                {
                    style: "btn-success",
                    text: this._multiLang.getString("filter_evidence.error_btn_title")
                }
            ]
        });
    }

    _addLocalFilter(assetId, newFilter) {
        this._filterCache.set(assetId, newFilter);
    }

    _getCachedFilter(assetId) {
        const filter = this._filterCache.get(assetId);
        return filter !== undefined ? filter : null;
    }

    _mapFilterOptionsToFilter(filterData, isPersistent) {
        const criteria = [];

        if (filterData.after !== null) {
            criteria.push({ Id: "", Key: FILTER_CRITERIA_TYPES_CONSTANTS.START_DATE, Value: filterData.after });
        }
        if (filterData.before !== null) {
            criteria.push({ Id: "", Key: FILTER_CRITERIA_TYPES_CONSTANTS.END_DATE, Value: filterData.before });
        }
        return {
            Criteria: criteria,
            FilterTypes: [FILTER_TYPES_CONSTANTS.EVIDENCE],
            Id: filterData.filterId,
            IsPersistent: isPersistent
        };
    }

    _mapApiFilterToLocalFilter(apiFilter) {
        return {
            ...apiFilter,
            IsPersistent: true
        };
    }

    _addFilter(assetId, filterData) {
        return this._assetEndpointService
            .createDataFilter(assetId, filterData)
            .then((response) => {
                this._addLocalFilter(assetId, this._mapApiFilterToLocalFilter(response.data));
                this._notifySubscribers(assetId);
            })
            .catch(() => {
                const errorMsgs = [this._multiLang.getString("filter_evidence.unable_to_save_filter"), this._multiLang.getString("filter_evidence.reload_save_message")];
                this.showFilteringErrorModal(errorMsgs);
                return this._$q.reject();
            });
    }

    _updateFilter(assetId, oldFilter, newFilter) {
        const mergedFilter = this._mergeFilters(oldFilter, newFilter);

        return this._assetEndpointService
            .updateDataFilter(assetId, mergedFilter)
            .then((response) => {
                this._addLocalFilter(assetId, this._mapApiFilterToLocalFilter(response.data));
                this._notifySubscribers(assetId);
            })
            .catch(() => {
                const errorMsgs = [this._multiLang.getString("filter_evidence.unable_to_update_filter")];
                this.showFilteringErrorModal(errorMsgs);
                return this._$q.reject();
            });
    }

    _removePersistentFilter(assetId, filterId) {
        return this._assetEndpointService
            .removeDataFilter(assetId, filterId)
            .then(() => {
                this._filterCache.delete(assetId);
            })
            .catch(() => {
                const errorMsgs = [this._multiLang.getString("filter_evidence.unable_to_delete_filter"), this._multiLang.getString("filter_evidence.reload_delete_message")];
                this.showFilteringErrorModal(errorMsgs);
                return this._$q.reject();
            });
    }

    _mergeFilters(existingFilter, newFilter) {
        newFilter.Id = existingFilter.Id;
        for (const criteria of newFilter.Criteria) {
            const matchingCriteria = existingFilter.Criteria.find((c) => c.Key === criteria.Key);
            if (matchingCriteria !== undefined) {
                criteria.Id = matchingCriteria.Id;
            }
        }
        return newFilter;
    }

    _notifySubscribers(assetId) {
        this._eventEmitter.emit(assetId);
    }

    _onRouteChange() {
        const newAssetId = this._$routeParams.assetId;
        if (newAssetId === undefined || newAssetId !== this._previousRouteAssetId) {
            const cachedFilter = this._getCachedFilter(this._previousRouteAssetId);
            if (cachedFilter !== null && !cachedFilter.IsPersistent) {
                this._filterCache.delete(this._previousRouteAssetId);
            }
        }

        this._previousRouteAssetId = newAssetId === undefined ? "" : newAssetId;
    }
}

AssetFilteringService.$inject = ["multiLanguageService", "modal", "baseUrlsFactory", "AssetEndpointService", "$rootScope", "$q", "$routeParams", "assetFilteringConfig"];
angularAMD.service("assetFilteringService", AssetFilteringService);
