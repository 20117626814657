import { angularAMD } from "@pebblepad/amd";
import "../../utilities/baseUrlsFactory";
import "../../utilities/bounceFix";
import "../../utilities/domSearchHelper.service";
import "../../accessKeys/accessKey.service";
import "../../stickyElementCoordinator/stickyContainer.directive";
import "../../stickyElementCoordinator/stickyItem.directive";
import "../../focusTrap/focusTrap.service";
import popupTemplate from "../templates/pop-up.html";
import popupWhiteTemplate from "../templates/pop-up-white.html";
import popupBrandingTemplate from "../templates/pop-up-branding.html";
import slideTopTemplate from "../templates/slide-from-top.html";

angularAMD.directive("modalDialog", [
    "$q",
    "$timeout",
    "$window",
    "baseUrlsFactory",
    "bounceFixTwo",
    "modal",
    "domSearchHelper",
    "focusTrapService",
    function ($q, $timeout, $window, baseUrlsFactory, bounceFixTwo, modal, domSearchHelper, focusTrapService) {
        /**
         * Create and fill in global_scope
         *
         * @global_scope - set global elements once for all modal dialogs (optimization reason)
         * @function - fill the scope with global data
         */
        var global_scope = {};
        (function () {
            // cache HTML element to add global classes
            global_scope.document_el = angular.element(document.documentElement);
        })();

        return {
            restrict: "E",
            transclude: true,
            scope: "=",
            template: function (scope, attrs) {
                switch (attrs.modalStyle) {
                    case "pop-up":
                        return popupTemplate;
                    case "pop-up-white":
                        return popupWhiteTemplate;
                    case "pop-up-branding":
                        return popupBrandingTemplate;
                    default:
                        return slideTopTemplate;
                }
            },

            controller: [
                "$scope",
                "$element",
                "$attrs",
                function ($scope, $element, $attrs) {
                    $scope._modalHiding = false;
                    $scope.focus_el = null;
                    $scope.focusTrapService = focusTrapService;
                    $scope.focusTrapId = null;
                    // ----------------------------------
                    // Generic component methods
                    // ----------------------------------
                    /**
                     * Make aliases for attribute data
                     * Initialise variables
                     */
                    $scope.presetData = function () {
                        $attrs.$observe("modalTitle", function (new_val) {
                            $scope.modal_title = new_val;
                        });
                        $attrs.$observe("modalIcon", function (new_val) {
                            $scope.modal_icon = new_val;
                        });

                        $scope.modal_style = $attrs.modalStyle;
                        $scope.timeouts = {};
                        $scope.transition_duration = 235;
                        $scope.shared_component_base_url = baseUrlsFactory.shared_component_base_url;
                        $scope.modalTitleId = "modal-dialog-title-" + $scope.$id;
                        $scope.labelledById = $scope.modalTitleId;
                        $scope.modalInterface = {
                            updateTitle: function (newTitle) {
                                $scope.modal_title = newTitle;
                            },
                            updateIcon: function (icon) {
                                $scope.modalIcon = icon;
                            },
                            updateDescribedBy: function (describedById) {
                                if (describedById) {
                                    $scope.modal_dialog_el[0].setAttribute("aria-describedby", describedById);
                                } else {
                                    $scope.modal_dialog_el[0].removeAttribute("aria-describedby");
                                }
                            },
                            updateLabelledBy: function (labelledId) {
                                $scope.labelledById = labelledId ? labelledId : $scope.modalTitleId;
                            }
                        };
                    };

                    $scope.removeFocusTrap = function () {
                        if ($scope.focusTrapId !== null) {
                            $scope.focusTrapService.removeTrap($scope.focusTrapId);
                            $scope.focusTrapId = null;
                        }
                    };

                    /**
                     * Prepare all elements that required by the directive
                     */
                    $scope.cacheElems = function () {
                        $scope.modal_holder = angular.element(document.getElementById("modal-holder"));
                        $scope.modal_el = $element;
                        $scope.modal_dialog__wrapper_el = angular.element($element[0].getElementsByClassName("modal-dialog__wrapper")[0]);
                        $scope.modal_dialog_el = angular.element($element[0].getElementsByClassName("modal-dialog")[0]);
                    };

                    $scope.onDescribedByElementCreated = function (e, eventId, element) {
                        if (eventId === "modalDescribedBy") {
                            if (!element.id) {
                                element.id = "modal-described-by-" + Math.random();
                            }
                            $scope.modalInterface.updateDescribedBy(element.id);
                        }
                    };

                    /**
                     * Add all required event listeners
                     */
                    $scope.bindInterfaceEvents = function () {
                        // hide modal by $broadcast
                        $scope.$offHideModal = $scope.$on("hideModal", $scope.hideModal);

                        bounceFixTwo.enable();

                        // hide modal on escape key
                        if (!$scope.disable_close) {
                            global_scope.document_el[0].addEventListener("keydown", $scope.onEscKey);
                        }
                    };

                    /**
                     * Remove all previously created event listeners
                     */
                    $scope.unbindInterfaceEvents = function () {
                        if ($scope.$offHideModal) {
                            $scope.$offHideModal();
                        }

                        bounceFixTwo.disable();

                        if (!$scope.disable_close) {
                            global_scope.document_el[0].removeEventListener("keydown", $scope.onEscKey);
                        }
                    };

                    /**
                     * Method to find element to focus first when modal is rendered
                     */
                    $scope.autoFocus = function (focus_style) {
                        var modal_header_el = $scope.modal_dialog_el[0].querySelector(".modal-dialog__header");
                        var modal_content_el = $scope.modal_dialog_el[0].querySelector(".modal-dialog__content");

                        // find element that define as autofocus by developer
                        $scope.auto_focus_el = modal_content_el.querySelector(".autofocus");

                        // if can't find autofocus element
                        if ($scope.auto_focus_el === null) {
                            $scope.auto_focus_el = domSearchHelper.getFirstTabbableElement(modal_content_el);
                        }

                        // if nothing found, then focus on close button
                        if ($scope.auto_focus_el === null && modal_header_el !== null) {
                            $scope.auto_focus_el = modal_header_el.querySelector(".modal-dialog__header__close");
                        }

                        // if nothing found, then focus in modal dialog
                        if ($scope.auto_focus_el === null) {
                            $scope.auto_focus_el = $scope.modal_dialog_el[0];
                        }

                        // focus in found element
                        $scope.auto_focus_el.focus();

                        // apply focus-style only if developer set it to do so
                        if (focus_style === true || ($scope.focus_style && focus_style === undefined)) {
                            global_scope.document_el.addClass("focus-style");
                        }

                        // scroll to top in case autofocus element is entire modal dialog
                        // tslint:disable-next-line:triple-equals
                        if ($scope.auto_focus_el == $scope.modal_dialog_el[0] && $scope.modal_style === "pop-up") {
                            $scope.modal_dialog__wrapper_el[0].scrollTop = 0;
                        }

                        if ($scope.disable_focus_scroll) {
                            $scope.modal_dialog__wrapper_el[0].scrollTop = 0;
                        }
                    };

                    /**
                     * Hides modal when escape key is pressed
                     */
                    $scope.onEscKey = function (e) {
                        // detect 'esc' key not any other
                        if (e.keyCode === 27) {
                            e.stopPropagation();
                            $scope.hideModal();
                        }
                    };

                    /**
                     * Checks if page has vertical scrollbar
                     * @returns {boolean}
                     */
                    $scope.hasScrollbar = function () {
                        return $window.innerWidth > global_scope.document_el[0].clientWidth;
                    };

                    // ----------------------------------
                    // Modal dialog methods
                    // ----------------------------------
                    /**
                     * Object contains functions with classes that should be applied before or after rendering
                     * @type {{show: Function, hide: Function}}
                     */
                    $scope.toggleClasses = {
                        /**
                         * Add global classes before modal appears
                         */
                        show: function () {
                            if ($scope.hasScrollbar()) {
                                global_scope.document_el.addClass("scrollbar-shuffle-fix");
                            }

                            // add class to html when modal is open
                            global_scope.document_el.addClass("modal-enabled");

                            if ($scope.modal_style) {
                                global_scope.document_el.addClass($scope.modal_style);
                            }
                        },

                        /**
                         * Remove global classes after modal is gone
                         */
                        hide: function () {
                            $scope.modal_el.removeClass("display-block");

                            // remove all modal classes when it's hidden
                            global_scope.document_el.removeClass("modal-enabled");

                            if ($scope.modal_style) {
                                global_scope.document_el.removeClass($scope.modal_style);
                            }

                            global_scope.document_el.removeClass("scrollbar-shuffle-fix");
                        }
                    };

                    /**
                     * All the actions that should happen BEFORE modal appear on the screen
                     * @type {{show: Function, hide: Function}}
                     */
                    $scope.preRender = {
                        show: function () {
                            // clear out all timeout left from previous rendering
                            $timeout.cancel($scope.timeouts.main);
                            $timeout.cancel($scope.timeouts.rendering);
                            $timeout.cancel($scope.timeouts.transition);

                            // call additional function
                            if ($scope.onOpen) {
                                $scope.onOpen();
                            }

                            // add all necessary classes
                            $scope.toggleClasses.show();
                            // bind all events
                            $scope.bindInterfaceEvents();
                        },

                        hide: function () {
                            // clear out all timeout left from previous rendering
                            $timeout.cancel($scope.timeouts.main);
                            $timeout.cancel($scope.timeouts.rendering);
                            $timeout.cancel($scope.timeouts.transition);
                            // unbind all existing events
                            $scope.unbindInterfaceEvents();
                        }
                    };

                    /**
                     * All the actions needed to make modal appear on the screen
                     * @type {{show: Function, hide: Function}}
                     */
                    $scope.render = {
                        show: function () {
                            var deferred = $q.defer();

                            $scope.focusTrapId = $scope.focusTrapService.addTrap($scope.modal_dialog_el[0], $scope.ariaContainers);

                            $scope.modal_el.addClass("display-block");

                            $scope.timeouts.main = $timeout(function () {
                                $scope.timeouts.rendering = $timeout(function () {
                                    $scope.modal_el.addClass("show");

                                    $scope.timeouts.transition = $timeout(function () {
                                        // cancel all transitions in case they are not finished on slow machines
                                        $scope.modal_dialog_el.addClass("no-transition");
                                        deferred.resolve();
                                    }, $scope.transition_duration);
                                }, 10);
                            }, 10);

                            return deferred.promise;
                        },

                        hide: function () {
                            var deferred = $q.defer();
                            $scope.modal_dialog_el.removeClass("no-transition");

                            $scope.timeouts.main = $timeout(function () {
                                $scope.timeouts.rendering = $timeout(function () {
                                    $scope.modal_el.removeClass("show");
                                    $scope.timeouts.transition = $timeout(deferred.resolve, $scope.transition_duration);
                                }, 10);
                            }, 10);

                            return deferred.promise;
                        }
                    };

                    /**
                     * All the action that happen after modal rendering is ended
                     * @type {{show: Function, hide: Function}}
                     */
                    $scope.postRender = {
                        show: function () {
                            if ($scope.autofocus) {
                                $scope.autoFocus();
                            }

                            // call additional function
                            if ($scope.onAfterOpen) {
                                $scope.onAfterOpen();
                            }
                        },

                        hide: function () {
                            // call additional function
                            if ($scope.onClose) {
                                $scope.onClose();
                            }

                            // remove all additional modal dialog component classes
                            $scope.toggleClasses.hide();
                            $scope.dialogInstance.close($scope.focusOnCloseElement);

                            if (typeof $scope.onCloseCompleted === "function") {
                                $scope.onCloseCompleted();
                            }
                        }
                    };

                    /**
                     * Call to show modal
                     */
                    $scope.showModal = function () {
                        return $timeout(function () {
                            $scope.preRender.show();
                            $scope.render.show().then($scope.postRender.show);
                        });
                    };

                    /**
                     * Call to hide modal
                     */
                    $scope.hideModal = function () {
                        $scope._modalHiding = true;
                        $scope.removeFocusTrap();

                        $scope.preRender.hide();
                        return $scope.render.hide().then($scope.postRender.hide);
                    };

                    $scope.$on("$destroy", function () {
                        $scope.removeFocusTrap();

                        if (!$scope._modalHiding) {
                            $scope.hideModal();
                        }
                    });

                    /**
                     * Initialise modal directive
                     */
                    $scope.init = function () {
                        $scope.presetData();
                        $scope.cacheElems();
                        $scope.modalInterface.updateLabelledBy($scope.labelled_by);
                        $scope.$on("elementCreatedMarkerExists", $scope.onDescribedByElementCreated);
                        $scope.on_ready_deferred.resolve($scope); // resolve promise once modal is ready
                    };

                    $scope.init();
                }
            ]
        };
    }
]);
