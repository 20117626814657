import { angularAMD } from "@pebblepad/amd";
import "../../../../utilities/baseUrlsFactory";
import "../../../../assetStore/services/assetStoreService";
import "../../../../assetViews/sidebar/shares/shares";
import "../../../../assetViews/sidebar/submissions/submissions";
import "../../../../extensions/arrayExtensions";
import "../../../../expandable/expandable";
import "../../../../tagger/tagger";
import "../../../../assetsWithLinks/directives/assetsWithLinks";
import "../services/reportService";
import "../../../../userAgent/userAgent.service";
import template from "../templates/reportInappropriate.html";

angularAMD.directive("reportInappropriate", [
    "$q",
    "$sce",
    "$rootScope",
    "$compile",
    "$http",
    "baseUrlsFactory",
    "modal",
    "reportService",
    "UserAgentService",
    "$timeout",
    function ($q, $sce, $rootScope, $compile, $http, baseUrlsFactory, modal, reportService, UserAgentService, $timeout) {
        return {
            template: template,
            restrict: "E",
            scope: {
                info: "=?info",
                assetId: "=assetId"
            },
            controller: [
                "$scope",
                "$element",
                "$attrs",
                function ($scope, $element, $attrs) {
                    $scope.multiLanguageService = $rootScope.multiLanguageService;

                    $scope.showReportModal = function () {
                        //set up data
                        var overlay = angular.element(document.getElementsByClassName("builder-canvas-dark-layer")[0]);
                        $scope.modalScope = $rootScope.$new(true);
                        $scope.modalScope.valid = false;
                        $scope.modalScope.widgetId = null;
                        $scope.modalScope.assetId = $scope.assetId;
                        $scope.modalScope.email = "";
                        $scope.modalScope.name = "";
                        $scope.modalScope.reason = "";
                        $scope.modalScope.assetTitle = $scope.info.Title;

                        if ($rootScope.loggedInUser) {
                            $scope.modalScope.email = $rootScope.loggedInUser.Email;
                            $scope.modalScope.name = $rootScope.loggedInUser.Firstname + " " + $rootScope.loggedInUser.Surname;
                        }

                        $scope.modalScope.removeRecaptcha = function () {
                            if ($rootScope.loggedInUser && $rootScope.loggedInUser.Firstname === "End to End") {
                                $scope.modalScope.valid = true;
                            }
                        };

                        $scope.modalScope.sendReportRequest = function (name, email, reason) {
                            // tslint:disable-next-line:triple-equals
                            if ($scope.modalScope.valid == true && reason != "") {
                                var reportRequest = {
                                    AssetId: $scope.modalScope.assetId,
                                    Name: name,
                                    Email: email,
                                    Reason: reason
                                };

                                //Start animation for saving.
                                overlay.addClass("show-overlay");
                                var reportPromise = reportService.reportInappropriate(reportRequest);

                                reportPromise.then(function (response) {
                                    if (response.data) {
                                        if (response.data.toLowerCase() === "success") {
                                            overlay.addClass("report-complete");
                                        } else {
                                            overlay.addClass("report-reject");
                                        }
                                    }

                                    $timeout(function () {
                                        $scope.modalScope.reportCompleted(response);
                                    }, 1000);
                                });
                            }
                        };

                        $scope.modalScope.reportCompleted = function (response) {
                            $scope.$emit("reportSuccessful");
                            //finish up the saving animation

                            if (response.data.toLowerCase() === "success") {
                                overlay.removeClass("report-complete");
                                overlay.removeClass("show-overlay");
                                report_modal.hideModal();
                            } else {
                                overlay.removeClass("report-reject");
                                overlay.removeClass("show-overlay");
                            }
                        };

                        var report_modal = modal.newModal({
                            scope: $scope.modalScope,
                            templateUrl: baseUrlsFactory.shared_component_base_url + "spaMenu/assetInfoPanel/reportInappropriate/templates/report-inappropriate-modal.html"
                        });

                        if (UserAgentService.ie) {
                            $scope.modalScope.onAfterOpen = function () {
                                var recaptchaBoxEl = document.getElementsByClassName("recaptcha-box")[0];
                                recaptchaBoxEl.style.opacity = 1;
                            };
                        }

                        $scope.modalScope.model = {
                            key: "6LdSPgATAAAAAIIUjQsU2d_c1ZluwOSxs8pxSFov"
                        };

                        $scope.modalScope.setResponse = function () {
                            $scope.modalScope.valid = true;
                        };

                        $scope.modalScope.setWidgetId = function (widgetId) {
                            $scope.modalScope.widgetId = widgetId;
                        };

                        $scope.modalScope.cbExpiration = function () {
                            $scope.modalScope.valid = false;
                        };
                    };
                }
            ]
        };
    }
]);
