import { NgComponent } from "angular/ngComponent";
import { angularAMD } from "@pebblepad/amd";
import { textEvidenceIcon, warningNotice } from "@pjs/core-ui";
import { noCkEditorLinkUnlinkPlugin as editorConfig } from "../../../inlineTextEditor/editorConfigs/noCkEditorLinkUnlinkPlugin";
import "../../../react2angular/htmlNotice";
import "../../../react2angular/assetIcon";
import "../../../react2angular/icon";
import "../../../ckEditorModule/editors/persistentCompactMultiLineEditorWithFallback.component";
import "../../../utilities/baseUrlsFactory";
import "../../../utilities/subscriptionServiceProvider";
import "../../../modal/services/modal";
import "../../../preventRedirect/preventRedirect";
import template from "./evidence-justification-editor.html";

class EvidenceJustificationEditor extends NgComponent {
    constructor(multiLanguageService, formElementInfoService, modal, baseUrlsFactory, $sce) {
        super();

        this._formElementInfoService = formElementInfoService;
        this._modal = modal;
        this._baseUrlsFactory = baseUrlsFactory;
        this._$sce = $sce;
        this._originalEvidence = this.evidenceItem.originalEvidence;
        this.textEvidenceIcon = textEvidenceIcon;
        this.mappedEvidence = this.evidenceItem.mappedEvidence;
        this.multiLanguageService = multiLanguageService;
        this.noticeType = warningNotice;
        const labelType = multiLanguageService.getString("accessibility.notice.type.evidence_justification");
        this.noticeLabel = multiLanguageService.getString("accessibility.notice.labels.warning", { type: labelType });
        this.noticeMessage = "";
        this._latestJustification = this._$sce.trustAsHtml(this.mappedEvidence.justification).toString();
        this.announcerMessage = this.multiLanguageService.getString("labels.form_element_info.edit_evidence_justification.title");
        this.richTextEditorDataHook = "justification-editor-text-editor";
        this.richTextAutoFocus = true;
        this.justificationLabel = this.multiLanguageService.getString("labels.evidence.evidence_justification_editor");

        this.richTextConfig = {
            ...editorConfig,
            extraPlugins: "sharedspace",
            startupFocus: this.richTextAutoFocus
        };

        if (this.mappedEvidence.assetId === "") {
            this.richTextConfig.removeButtons += ",Link";
        } else {
            this.richTextConfig.extraPlugins += ",linker";
        }

        if (this.mappedEvidence.justification === "") {
            this.saveButtonText = this.multiLanguageService.getString("labels.form_element_info.edit_evidence_justification.controls.confirm_add");
        } else {
            this.saveButtonText = this.multiLanguageService.getString("labels.form_element_info.edit_evidence_justification.controls.confirm_edit");
        }

        this.onSetEvidenceJustificationContent = this._setEvidenceJustificationContent.bind(this);
        this.onSaveEditedJustification = this._saveUpdatedEvidenceJustification.bind(this);
        this.onCancelClicked = this._cancelEdit.bind(this);
        this._hasLiveChanges = false;
    }

    $onDestroy() {
        this.preventRedirectResolver.removeTrackedId(this.evidenceItem.originalEvidence.AggregateId);
    }

    $onChanges(changes) {
        if (changes.isActive !== undefined) {
            if (!changes.isActive.currentValue) {
                this.preventRedirectResolver.removeTrackedId(this.evidenceItem.originalEvidence.AggregateId);
            } else {
                if (this._hasLiveChanges) {
                    this.preventRedirectResolver.addTrackedId(this.evidenceItem.originalEvidence.AggregateId);
                }
            }
        }
    }

    _setEvidenceJustificationContent(update) {
        const updateTrustedAsHtml = this._$sce.trustAsHtml(update).toString();
        if (updateTrustedAsHtml !== this._latestJustification) {
            this._latestJustification = updateTrustedAsHtml;
            if (!this.preventRedirectResolver.redirectionIsBlocked()) {
                this.preventRedirectResolver.addTrackedId(this.evidenceItem.originalEvidence.AggregateId);
                this._hasLiveChanges = true;
            }
        }
    }

    _saveUpdatedEvidenceJustification() {
        this.noticeMessage = "";
        this.announcerMessage = "";
        this._originalEvidence.Justification = this._latestJustification;
        this.isSavingEvidenceJustification = true;

        this._formElementInfoService
            .updateEvidenceJustification(this._originalEvidence)
            .then(() => {
                this.preventRedirectResolver.removeTrackedId(this.evidenceItem.originalEvidence.AggregateId);
                this._hasLiveChanges = false;
                this.onConfirmSave();
            })
            .catch(() => {
                this.noticeMessage = this.multiLanguageService.getString("labels.form_element_info.edit_evidence_justification.save_error");
                this.announcerMessage = this.noticeMessage;
            })
            .finally(() => {
                this.isSavingEvidenceJustification = false;
            });
    }

    _cancelEdit() {
        if (!this.preventRedirectResolver.redirectionIsBlocked()) {
            this._hasLiveChanges = false;
            this.onCancelEdit();
        } else {
            this._showUnsavedChangesModal();
        }
    }

    _showUnsavedChangesModal() {
        const modalTemplateUrl = `${this._baseUrlsFactory.shared_component_base_url}preventRedirect/templates/warning-modal-alt.html`;

        this._modal.launch(modalTemplateUrl, {
            title: this.multiLanguageService.getString("logout.title_unsaved_changes"),
            message: this.multiLanguageService.getString("logout.message_unsaved_changes"),
            continueBtn: this.multiLanguageService.getString("buttons.discard_changes"),
            cancelRedirectBtn: this.multiLanguageService.getString("buttons.cancel"),
            continue: () => {
                this.preventRedirectResolver.removeTrackedId(this.evidenceItem.originalEvidence.AggregateId);
                this._hasLiveChanges = false;
                this.onCancelEdit();
            }
        });
    }
}

export const EvidenceJustificationEditorDefinition = {
    bindings: {
        evidenceItem: "<",
        onConfirmSave: "<",
        onCancelEdit: "<",
        isActive: "<",
        preventRedirectResolver: "<"
    },
    template: template,
    controller: EvidenceJustificationEditor
};

EvidenceJustificationEditor.$inject = ["multiLanguageService", "formElementInfoService", "modal", "baseUrlsFactory", "$sce"];

angularAMD.component("evidenceJustificationEditor", EvidenceJustificationEditorDefinition);
