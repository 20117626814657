import { angularAMD } from "@pebblepad/amd";
import "../../user/user.service";
import "../../featureSettings/FeatureSettings";
import "../../userAgent/userAgent.service";

export class RichTextEditingSettings {
    constructor(user, featureSettings, userAgentService) {
        this._user = user;
        this._featureSettings = featureSettings;
        this._userAgentService = userAgentService;
    }

    isLegacyEditorEnabled() {
        const cke5Enabled = this._featureSettings.isEnabled("CKEditor5");
        const orgEnabled = this._isOrgEnabled();
        const isLegacyEditorSupported = !this._userAgentService.ios;

        return ((cke5Enabled && !orgEnabled) || (!cke5Enabled && orgEnabled)) && isLegacyEditorSupported;
    }

    isEditorEnabled() {
        return this._featureSettings.isEnabled("CKEditor5") && this._isOrgEnabled();
    }

    _isOrgEnabled() {
        const user = this._user.getDto();
        return user === null ? true : user.CkEditorEnabled;
    }
}

RichTextEditingSettings.$inject = ["User", "featureSettings", "UserAgentService"];
angularAMD.service("richTextEditingSettings", RichTextEditingSettings);
