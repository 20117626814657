import { angularAMD } from "@pebblepad/amd";
import { richTextEditorComponentDefinition } from "../components/richTextEditor.component";
import { withEditorFallback } from "../../withEditorFallback/WithEditorFallback.function";
import "../components/richTextEditor.component";
import { compactFloatingRichTextEditorDefinition } from "../../compactFloatingRichTextEditor/compactFloatingRichTextEditor.component";

const component = withEditorFallback(
    "compactFloatingMultiLineTextEditor",
    {
        legacyEditor: richTextEditorComponentDefinition,
        editor: compactFloatingRichTextEditorDefinition
    },
    false,
    "compact-fallback-editor",
    "legacy-compact-editor"
);
angularAMD.component(component.name, component);
