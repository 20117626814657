import { NgComponent } from "angular/ngComponent";
import { angularAMD } from "@pebblepad/amd";
import { warningNotice } from "@pjs/core-ui";
import { STATUS_PERMISSIONS_CONSTANTS } from "../../constants/statusPermissions.constants";
import { MAP_TO_STANDARDS_CONSTANTS } from "../../constants/mapToStandards.constants";
import { noCkEditorLinkUnlinkPlugin as editorConfig } from "../../inlineTextEditor/editorConfigs/noCkEditorLinkUnlinkPlugin";
import { tracker } from "@pjs/analytics";
import "../../formComponents/StandardsSelection/StandardsSelectionSidebar/standardsSelectionSidebar";
import "../../ckEditorModule/editors/persistentCompactMultiLineEditorWithFallback.component";
import "../../standardsNavigationalButtons/standardsNavigationalButtons.component";
import "./standardsRatingSlider/standardsRatingSlider.component";
import "./standardsRatingInfo/standardsRatingInfo.component";
import "../../multiLanguageService/multiLanguageService";
import "../../workbook/service/standards.service";
import "../../tabSet/tabSet.component";
import "../mapToStandards.service";
import "../../formComponents/StandardsElement/standardsInformation.service";
import "../../assetEndpointService/assetEndpoint.service";
import "../../user/user.service";
import "../../tagger/services/tagService";
import "../../react2angular/checkbox";
import "../../react2angular/htmlNotice";
import "../../loadingSpinner/loadingSpinner";
import template from "./map-to-standards-wrapper.html";

class MapToStandardsWrapper extends NgComponent {
    constructor($rootScope, $timeout, $location, $scope, multiLanguageService, standardsService, mapToStandardsService, standardsInformation, AssetEndpointService, tagService, User) {
        super();

        this.MAP_TO_STANDARDS_CONSTANTS = MAP_TO_STANDARDS_CONSTANTS;
        this.$timeout = $timeout;
        this.$rootScope = $rootScope;
        this.$location = $location;
        this.$scope = $scope;
        this.multiLanguageService = multiLanguageService;
        this.standardsService = standardsService;
        this.mapToStandardsService = mapToStandardsService;
        this.standardsInformation = standardsInformation;
        this.assetEndpointService = AssetEndpointService;
        this._tagService = tagService;
        this._userService = User;
        this._neededKeyNames = ["PageId", "PageFormId"];
        this.isStandardsSaving = false;
        this.hasStandards = true;
        this.allElementsArray = [];
        this.matchedStandards = [];
        this.savingError = null;

        this.courseTags = [];
        this.showTagError = false;
        this.showTagLoading = true;
        this.showCourseTagsSelection = false;

        this.mappingId = "map-to-standards-wrapper__intro-text";
        this.thumbDimensions = {
            thumbHeight: MAP_TO_STANDARDS_CONSTANTS.RATING_SLIDER.DEFAULT_VALUES.THUMB_HEIGHT,
            thumbWidth: MAP_TO_STANDARDS_CONSTANTS.RATING_SLIDER.DEFAULT_VALUES.THUMB_WIDTH
        };
        this._originalSelectedStandards = [];

        this.onReflectionChange = this._updateReflection.bind(this);
        this.richTextAutoFocus = true;
        this.richTextConfig = {
            ...editorConfig,
            extraPlugins: "sharedspace,linker",
            startupFocus: this.richTextAutoFocus
        };
        this.richTextEditorDatahook = "justification-textbox";

        const labelType = this.multiLanguageService.getString("accessibility.notice.type.mapping");

        this.errorNotification = {
            message: this.multiLanguageService.getString("accessibility.spa_menu.map_to_standards_course_tags.course_error"),
            type: warningNotice,
            label: this.multiLanguageService.getString("accessibility.notice.labels.warning", { type: labelType })
        };

        const reApprovalLabelType = this.multiLanguageService.getString("accessibility.notice.type.asset_re_approval");

        this.reApprovalNotification = {
            message: multiLanguageService.getString("accessibility.spa_menu.map_to_standards_re_approval_msg"),
            type: warningNotice,
            label: this.multiLanguageService.getString("accessibility.notice.labels.warning", { type: reApprovalLabelType })
        };

        this.editorLabel = this.multiLanguageService.getString("sidebar.standards_selection.justification_editor");

        this.selectTab = {
            active: true,
            id: "select",
            canView: true,
            buttons: [
                {
                    title: this.multiLanguageService.getString("buttons.cancel"),
                    class: "mix-button-outlined",
                    dataHook: "mapping-standards-cancel",
                    ariaLabel: this.multiLanguageService.getString("accessibility.spa_menu.map_to_standards_nav_buttons.select.cancel"),
                    action: () => {
                        tracker.trackEvent("EWC", "Cancel mapping for standards");
                        this.$location.url(this.url);
                    }
                },
                {
                    title: this.multiLanguageService.getString("buttons.next"),
                    dataHook: "mapping-standards-select-next",
                    ariaLabel: this.multiLanguageService.getString("accessibility.spa_menu.map_to_standards_nav_buttons.select.next"),
                    class: "mix-button-contained",
                    action: () => {
                        this.sortElements();
                        this.changeDirection(1);
                    }
                }
            ]
        };

        this.initialFocusedTab = "";

        this.assessTab = {
            active: false,
            id: "assess",
            canView: true,
            buttons: [
                {
                    title: this.multiLanguageService.getString("buttons.back"),
                    class: "mix-button-outlined",
                    dataHook: "mapping-standards-assess-back",
                    ariaLabel: this.multiLanguageService.getString("accessibility.spa_menu.map_to_standards_nav_buttons.assess.back"),
                    action: () => this.changeDirection(-1)
                },
                {
                    title: this.multiLanguageService.getString("buttons.next"),
                    dataHook: "mapping-standards-assess-next",
                    class: "mix-button-contained",
                    ariaLabel: this.multiLanguageService.getString("accessibility.spa_menu.map_to_standards_nav_buttons.assess.next"),
                    action: () => this.changeDirection(1)
                }
            ]
        };

        this.justifyTab = {
            active: false,
            id: "justify",
            canView: true,
            buttons: [
                {
                    title: this.multiLanguageService.getString("buttons.back"),
                    class: "mix-button-outlined",
                    dataHook: "mapping-standards-justify-back",
                    ariaLabel: this.multiLanguageService.getString("accessibility.spa_menu.map_to_standards_nav_buttons.justify.back"),
                    action: () => this.changeDirection(-1)
                },
                {
                    title: this.multiLanguageService.getString("buttons.save"),
                    class: "mix-button-contained",
                    action: this.saveStandards.bind(this),
                    dataHook: "mapping-standards-save",
                    ariaLabel: this.multiLanguageService.getString("accessibility.spa_menu.map_to_standards_nav_buttons.justify.save"),
                    isActionBtn: true
                }
            ]
        };

        this.tabs = [this.selectTab, this.assessTab, this.justifyTab];
        this._setFocusTab();
    }

    $onInit() {
        if (!Array.isArray(this.standardsData) || this.standardsData.length < 1) {
            this._getAllStandards()
                .then(() => {
                    if (this.standardsData.length === 0) {
                        this.hasStandards = false;
                        this._preventMapping();
                    }

                    this._getStandardsForAsset();
                    this.allElementsArray = this.mapToStandardsService.createAllElementsArray(this.standardsData);
                })
                .catch((error) => {
                    this.hasStandards = false;
                });
        } else {
            this._updateStandardsCount();
            this.allElementsArray = this.mapToStandardsService.createAllElementsArray(this.standardsData);
            this.matchedStandards = this.mapToStandardsService.getMatchedStandards(this.selectedStandards, this.standardsData, true);
        }

        this.ratingSliderCallbackFunction = (id, value) => {
            this.matchedStandards.forEach((val) => {
                if (val.standard.uniqueId === id) {
                    val.selectedStandard.Rating = value;
                }
            });
        };

        this.assetEndpointService.getCreatorPermission(STATUS_PERMISSIONS_CONSTANTS.CAN_SELF_ASSESS, this.assetId).then((response) => {
            this.assessTab.canView = response.data;
            if (!this.assessTab.canView) {
                const assessTabIndex = this.tabs.findIndex((item) => item.id === this.assessTab.id);
                if (assessTabIndex > -1) {
                    this.tabs.splice(assessTabIndex, 1);
                }
            }
        });

        const workbookId = this.mainAssetId === undefined ? this.workbookId : this.mainAssetId;
        this._tagService
            .getCourseTags(workbookId, this.assetId)
            .then((response) => {
                this.courseTags = response.data.map((tag) => {
                    const tagModel = { label: tag.Label, isChecked: tag.IsChecked, onChange: null };
                    tagModel.onChange = (isChecked) => {
                        this.onTagSelect(tagModel, isChecked);
                    };
                    return tagModel;
                });

                this.showCourseTagsSelection = this.courseTags.length !== 0;
            })
            .catch(() => {
                this.showTagError = true;
            })
            .finally(() => {
                this.showTagLoading = false;
            });
    }

    $onChanges(changes) {
        if (changes.focus !== undefined) {
            this._setFocusTab();
        }
    }

    $onDestroy() {
        this.standardsInformation.removeStorageStandardsData(this.assetId);
        this.standardsInformation.removeNavigationalUrl(this.assetId);
    }

    _setFocusTab() {
        if (!this.focus) {
            this.initialFocusedTab = "";
            return;
        }
        const activeTab = this.tabs.find((tab) => tab.active)?.id;
        this.initialFocusedTab = activeTab ?? "";
    }

    _updateReflection(content) {
        this.reflection = content;
    }

    _preventMapping() {
        this.selectTab.buttons = this.selectTab.buttons.filter((button) => button.dataHook !== "mapping-standards-select-next");
        this.justifyTab.canView = false;
    }

    onReflectionUpdate() {
        this.onUpdate({
            id: this.assetId,
            updateInProgress: true
        });
    }

    _updateStandardsCount() {
        for (let i = 0; i < this.standardsData.length; i++) {
            if (this.standardsData[i].StandardsSelected) {
                this.standardsData = this.mapToStandardsService.resetCount(this.standardsData);
                return;
            }
        }
    }

    _getStandardsForAsset() {
        this.standardsService
            .getStandardsForAsset(this.assetId)
            .then((data) => {
                this.selectedStandards = data.Standards;
                this._originalSelectedStandards = [...data.Standards];
                this.reflection = data.Reflection;
                this.matchedStandards = this.mapToStandardsService.getMatchedStandards(this.selectedStandards, this.standardsData, true);
                this.preventMappingChanges = data.PreventMappingChanges;
            })
            .catch(() => {
                this.selectedStandards = [];
                this._originalSelectedStandards = [];
            });
    }

    _getAllStandards() {
        return this.standardsService
            .getAllStandardsWorkbooksForOrg(this.assetId)
            .then((data) => {
                this.standardsData = data;
                this.isFormResponse = false;
            })
            .catch(() => {
                this.standardsData = [];
            });
    }

    onStandardsSelect(data, value) {
        this.matchedStandards = this.mapToStandardsService.updateMatchedStandards(data[0], this.standardsData, this.matchedStandards, value);

        if (value === true) {
            this.selectedStandards.push(data[0]);
            const selectedStandard = this.matchedStandards.find((x) => data[0].ElementId === x.standard.ElementId);

            if (selectedStandard !== undefined) {
                tracker.trackEvent("EWC", "Select element for mapping", selectedStandard.standard.Title);
            }
        } else {
            tracker.trackEvent("EWC", "Deselect element for mapping");

            this.selectedStandards = this.selectedStandards.filter((obj) => {
                return data[0].AssetRefId !== obj.AssetRefId || data[0].PageId !== obj.PageId ? true : data[0].FormElementId !== obj.FormElementId;
            });
        }

        this.onUpdate({
            id: this.assetId,
            updateInProgress: true
        });
    }

    sortElements() {
        this.matchedStandards = this.mapToStandardsService.getOrderedMatchedStandards(this.allElementsArray, this.matchedStandards);
    }

    _recoverRatingValue(currentValue, matchedStandards) {
        for (let standard of matchedStandards) {
            if (standard.selectedStandard.ElementId === currentValue.ElementId) {
                currentValue.Rating = standard.selectedStandard.Rating !== undefined ? standard.selectedStandard.Rating : 0;
                return currentValue.Rating;
            }
        }
    }

    _getSelectedStandardsList(standardsPanels) {
        return standardsPanels.reduce((accumulator, currentValue) => {
            if (currentValue.Standards === undefined && currentValue.Groups === undefined) {
                if (currentValue.Checked === true) {
                    accumulator.push({
                        FormElementId: currentValue.ElementId,
                        ElementId: currentValue.ElementId,
                        PageId: null,
                        PageFormId: null,
                        WorkBookId: null,
                        Tags: this.courseTags.filter((t) => t.isChecked).map((t) => t.label),
                        Rating: currentValue.Rating !== undefined ? currentValue.Rating : this._recoverRatingValue(currentValue, this.matchedStandards)
                    });
                }
            }

            if (currentValue.Standards !== undefined && currentValue.Standards.length > 0) {
                accumulator = accumulator.concat(this._getSelectedStandardsList(currentValue.Standards));
            }

            if (currentValue.Groups !== undefined && currentValue.Groups.length > 0) {
                accumulator = accumulator.concat(this._getSelectedStandardsList(currentValue.Groups));
            }

            this._neededKeyNames.forEach((name) => {
                if (currentValue.hasOwnProperty(name)) {
                    accumulator.forEach((standard) => {
                        if (standard[name] === null) {
                            standard[name] = currentValue[name];
                        }

                        if (standard.WorkBookId === null || standard.WorkBookId === "") {
                            standard.WorkBookId = this.workbookId !== "" ? this.workbookId : this._getStandardWorkbookId(standard);
                        }
                    });
                }
            });
            return accumulator;
        }, []);
    }

    onTagSelect(tag, isChecked) {
        tag.isChecked = isChecked;

        this.onUpdate({
            id: this.assetId,
            updateInProgress: true
        });

        this.$scope.$applyAsync();
    }

    _getStandardWorkbookId(standard) {
        for (const group of this.standardsData) {
            const found = group.Groups.find((element) => element.PageId === standard.PageId);
            if (found !== undefined) {
                return group.AssetRefId;
            }
        }
    }

    saveStandards() {
        this.justifyTab.buttons[1].title = this.multiLanguageService.getString("loading_spinner.saving");
        this.isStandardsSaving = true;
        this.savingError = null;

        const newStandards = {
            AssetId: this.assetId,
            Standards: this._getSelectedStandardsList(this.standardsData),
            WorkbookIds: this.standardsData.map((standard) => {
                return standard.AssetRefId;
            })
        };

        if (newStandards.WorkbookIds[0] === undefined) {
            newStandards.WorkbookIds = [this.workbookId];
        }

        if (this.reflection && this.reflection !== "") {
            newStandards.Reflection = this.reflection;
        }

        this.standardsService
            .setStandards(newStandards)
            .then((outcome) => {
                if (outcome.data === true) {
                    this.justifyTab.buttons[1].title = this.multiLanguageService.getString("buttons.saved");
                    this.isStandardsSaving = false;

                    this.$timeout(() => {
                        this.onUpdate({
                            id: this.assetId,
                            updateInProgress: false
                        });
                        this.$location.url(this.url);
                    }, 1000);

                    this.$rootScope.$broadcast("updateStandardsElement", this._getSelectedStandardsList(this.standardsData), newStandards.WorkbookIds);
                    tracker.trackEvent("EWC", "Save mapping for standards");
                } else {
                    this.justifyTab.buttons[1].title = this.multiLanguageService.getString("buttons.save");
                    this.savingError = this.multiLanguageService.getString("sidebar.standards_selection.error_saving");
                    this.isStandardsSaving = false;
                }
            })
            .catch(() => {
                this.justifyTab.buttons[1].title = this.multiLanguageService.getString("buttons.save");
                this.savingError = this.multiLanguageService.getString("sidebar.standards_selection.error_saving");
                this.isStandardsSaving = false;
            });
    }

    changeDirection(direction) {
        this.savingError = null;
        for (let i = 0; i < this.tabs.length; i++) {
            if (this.tabs[i].active) {
                if (this.tabs[i + direction] !== undefined) {
                    this.tabs[i + direction].active = true;
                }
                return;
            }
        }
    }

    setActiveTab(activeTab) {
        for (let i = 0; i < this.tabs.length; i++) {
            this.tabs[i].active = activeTab.id === this.tabs[i].id;
        }
    }
}

export const mapToStandardsWrapper = {
    template: template,
    controller: MapToStandardsWrapper,
    bindings: {
        selectedStandards: "<",
        standardsData: "<",
        reflection: "<",
        assetId: "<",
        workbookId: "<",
        mainAssetId: "<?",
        hasActiveApproval: "<",
        preventMappingChanges: "<",
        url: "<",
        onUpdate: "&",
        focus: "<?"
    }
};

MapToStandardsWrapper.$inject = [
    "$rootScope",
    "$timeout",
    "$location",
    "$scope",
    "multiLanguageService",
    "StandardsService",
    "mapToStandardsService",
    "standardsInformation",
    "AssetEndpointService",
    "tagService",
    "User"
];
angularAMD.component("mapToStandardsWrapper", mapToStandardsWrapper);
