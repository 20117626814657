import { KillSignal, Observable, Subject } from "@pjs/observables";
import { createGuid, Immutable } from "@pjs/utilities";
import { DialogState } from "../dialog-director/enums/DialogState";
import { IDialogDirector } from "../dialog-director/interfaces/IDialogDirector";
import { IMultiStepDialogInstance } from "./interfaces/IMultiStepDialogInstance";
import { DialogSize } from "./enums/DialogSize";
import { IMultiStepDialogInstanceConfig } from "./interfaces/IMultiStepDialogInstanceConfig";
import { IDialogSteps } from "./interfaces/IDialogSteps";
import { IBaseDialogInstance } from "./interfaces/IBaseDialogInstance";
import { DialogSteps } from "./DialogSteps";

export class DialogInstance<TModel> implements IMultiStepDialogInstance<TModel> {
    public model: Immutable<TModel>;
    public readonly modelChanges: Observable<Immutable<TModel>>;
    public readonly destroyObservable: Observable<void>;
    public readonly size: DialogSize;
    public readonly id: string = createGuid();
    public readonly steps: IDialogSteps<TModel>;
    public readonly dismissAction: ((model: Immutable<TModel>, instance: IBaseDialogInstance<TModel>) => void) | null;

    private readonly _director: IDialogDirector;
    private readonly _modelChangesSubject: Subject<Immutable<TModel>> = new Subject<Immutable<TModel>>();
    private readonly _killSignal: KillSignal;
    private _closingFocusElement: HTMLElement | null = null;

    constructor(config: IMultiStepDialogInstanceConfig<TModel>, director: IDialogDirector) {
        this._director = director;
        this.size = config.size;
        this.model = config.initialModel;

        this.dismissAction = null;

        if (config.dismissAction !== undefined) {
            this.dismissAction = config.dismissAction;
        }

        this.modelChanges = this._modelChangesSubject.asObservable();

        this._killSignal = new KillSignal();
        this.destroyObservable = this._killSignal.asObservable();

        this.steps = new DialogSteps(config.steps, this._killSignal);
    }

    public updateModel(model: Partial<TModel>): void {
        const newModel = Object.assign({}, this.model, model);

        this.model = newModel;
        this._modelChangesSubject.next(newModel);
    }

    public close(elementToFocus?: HTMLElement): void {
        this._closingFocusElement = elementToFocus ?? null;

        this._director.requestStateChange(DialogState.CLOSING, this.id);
    }

    public destroy(): void {
        this._killSignal.send();
        this._modelChangesSubject.complete();

        const dequeue = this._director.dequeue(this.id);

        if (dequeue !== null) {
            return;
        }

        this._director.requestStateChange(DialogState.CLOSED, this.id);
    }

    public focusOnClose(): void {
        if (this._closingFocusElement !== null && document.body.contains(this._closingFocusElement)) {
            this._closingFocusElement.focus();
        }
    }
}
