import { angularAMD } from "@pebblepad/amd";
import { SIZES } from "../constants/size.constants";
import "../multiLanguageService/multiLanguageService";
import "../dropdownWrapper/dropdownWrapper.component";
import "../userInfo/userInfo.component";
import "../inlineTextEditor/services/richTextEditingSettings.service";
import template from "./reply.html";

angularAMD.controller("ReplyController", ReplyController);
ReplyController.$inject = ["$scope", "multiLanguageService", "$timeout", "$filter", "richTextEditingSettings"];

var replyDefinition = {
    bindings: {
        data: "<",
        permissions: "<?",
        convertDate: "@",
        hideHeader: "@",
        onEdit: "&",
        onDelete: "&",
        onStateChange: "&",
        summaryAsset: "=",
        itemIndex: "<",
        itemCount: "<",
        itemType: "<",
        itemGroup: "<?",
        replyIndex: "=",
        replyCount: "=",
        isMainComment: "=",
        editorLabel: "<",
        disableDropdown: "<?",
        disabledToolTip: "<?"
    },
    template: template,
    controller: ReplyController
};

angularAMD.component("reply", replyDefinition);

function ReplyController($scope, multiLanguageService, $timeout, $filter, richTextEditingSettings) {
    this.SIZES = SIZES;
    this.$scope = $scope;
    this.multiLanguageService = multiLanguageService;
    this.isEditing = false;
    this.isDeleting = false;
    this.feedbackItemOptions = [];

    this.services = {
        $timeout: $timeout,
        $filter: $filter
    };

    this._populateFeedbackOptions();
    this.additionalInfo = this.isMainComment ? this.multiLanguageService.getString("sidebar.asset_feedback.labels.left_on", { page: this.data.AssetTitle }) : "";
    this.isCKEditor5Enabled = richTextEditingSettings.isEditorEnabled();
}

ReplyController.prototype.$postLink = function () {
    this.editBtnId = "reply__btn-" + this.$scope.$id;
};

ReplyController.prototype.$onChanges = function (changes) {
    const repliesChanged = changes.replyIndex !== undefined || changes.replyCount !== undefined;
    const itemsChanged = changes.itemIndex !== undefined || changes.itemCount !== undefined;
    if (changes.data !== undefined || changes.permissions !== undefined || repliesChanged || itemsChanged) {
        this._populateFeedbackOptions();
    }
};

ReplyController.prototype._populateFeedbackOptions = function () {
    this.feedbackItemOptions = [];

    let type = "";
    if (this.isMainComment) {
        type = this.multiLanguageService.getString("sidebar.asset_comments.comment");
        this.feedbackOptionsLabel = this.multiLanguageService.getString("sidebar.asset_comments.aria.comment_options", {
            number: this.itemIndex,
            total: this.itemCount
        });
    } else {
        type = this.multiLanguageService.getString("sidebar.asset_feedback.buttons.reply");
        const key = `atlas_sidebar.a11y.reply_options${this.itemGroup !== undefined ? "_in_the_group" : ""}`;

        this.feedbackOptionsLabel = this.multiLanguageService.getString(key, {
            number: this.replyIndex,
            total: this.replyCount,
            itemIndex: this.itemIndex,
            type: this.itemType,
            groupValue: this.itemGroup
        });
    }

    if (this.data.Permissions.AllowEdit) {
        this.feedbackItemOptions.push({
            name: this.multiLanguageService.getString("sidebar.asset_feedback.buttons.edit.name", { type: type }),
            ally: this.multiLanguageService.getString("sidebar.asset_feedback.buttons.edit.title", { type: type }),
            displayConfirmation: false,
            onConfirm: this.toggleEditor.bind(this, true),
            icon: "icon-edit"
        });
    }

    if (this.data.Permissions.AllowDelete) {
        this.feedbackItemOptions.push({
            name: this.multiLanguageService.getString("sidebar.asset_feedback.buttons.delete.name", { type: type }),
            ally: this.multiLanguageService.getString("sidebar.asset_feedback.buttons.delete.title", { type: type }),
            displayConfirmation: true,
            confirm: this.multiLanguageService.getString("sidebar.asset_feedback.buttons.delete.confirm"),
            confirmA11y: this.multiLanguageService.getString("sidebar.asset_feedback.buttons.delete.confirm_ally"),
            onConfirm: this.deleteReply.bind(this),
            icon: "icon-delete"
        });
    }
};

ReplyController.prototype.onCommentChange = function (data) {
    var newBody = angular.copy(this.data);
    newBody.Body = data.body;
    return this.onEdit({
        dto: newBody
    }).then(
        function () {
            this.data.Comment = newBody.Body;
            this.onStateChange({ active: false });
            this.refocus();
        }.bind(this)
    );
};

ReplyController.prototype.toggleEditor = function (isActive) {
    this.isEditing = isActive;
    this.onStateChange({
        active: isActive
    });

    this.refocus();
    this._populateFeedbackOptions();
};

ReplyController.prototype.refocus = function () {
    this.services.$timeout(
        function () {
            var element = document.querySelector("#" + this.editBtnId + " button");
            if (element) {
                element.focus();
            }
        }.bind(this)
    );
};

ReplyController.prototype.toggleFullDateTime = function () {
    if (!this.isEditing && !this.isDeleting) {
        this.showFullDateTime = !this.showFullDateTime;
    }
};

ReplyController.prototype.deleteReply = function () {
    this.isDeleting = true;

    this.onDelete({
        dto: this.data
    }).then(
        function () {
            this.isDeleting = false;
            this._populateFeedbackOptions();
        }.bind(this)
    );
};
