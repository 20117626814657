import { FunctionComponent } from "react";
import { ParseHtml } from "@pjs/security";
import { coreUiI18n } from "../i18n/CoreUiI18n.const";
import { Icon } from "../icon/Icon.component";
import { crossIcon } from "../icon/icons/Cross.icon";
import { Notice } from "../notice/Notice.component";
import { IDismissableHtmlNoticeProps } from "./interfaces/IDismissableHtmlNoticeProps";
import "./styles/dismissable-html-notice.css";

export const DismissableHtmlNotice: FunctionComponent<IDismissableHtmlNoticeProps> = (props) => {
    const ariaLabel = coreUiI18n.getString("notice.labels.dismiss");

    return (
        <Notice type={props.type} ariaLabel={props.ariaLabel}>
            <div className="cui-dismissable-notice__wrapper">
                <ParseHtml className="cui-dismissable-notice__content" htmlString={props.content} />
                <button className="cui-dismissable-notice__button" onClick={props.onDismiss} data-hook="dimissable-notice-button" aria-label={ariaLabel}>
                    <Icon className="cui-dismissable-notice__dismiss-icon" source={crossIcon} />
                </button>
            </div>
        </Notice>
    );
};
