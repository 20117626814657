import { FunctionComponent } from "react";
import { ParseHtml } from "@pjs/security";
import { feedbackI18n } from "../../i18n/FeedbackI18n.const";
import { approvalStatusMap } from "../consts/ApprovalMap";
import { FeedbackNotice } from "../../feedback-notice/FeedbackNotice.component";
import { IApprovalViewBaseProps } from "./interfaces/IApprovalViewBaseProps";
import "./styles/approval-view-base.css";

export const ApprovalViewBase: FunctionComponent<IApprovalViewBaseProps> = (props) => {
    const noticeLabel = feedbackI18n.getString(approvalStatusMap[props.statusNotice.status]);

    return (
        <div className="fbk-approval-view-base">
            <div className="fbk-approval-view-base__header" data-hook="approval-view-base-header">
                {props.header}
            </div>
            <FeedbackNotice statusNotice={props.statusNotice} noticeAriaLabel={noticeLabel} />
            {props.feedback !== "" && <ParseHtml className="fbk-approval-view-base__feedback util-rich-text-font-styles" dataHook="approval-feedback-base-container" htmlString={props.feedback} />}
        </div>
    );
};
