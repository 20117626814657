import { angularAMD } from "@pebblepad/amd";
import "../utilities/baseUrlsFactory";
import "../multiLanguageService/multiLanguageService";
import "../datePicker/datePicker";
import "../datePicker/datePickerHelper";
import template from "./templates/date-toggle.html";

var ToggleOption = function (active, element, clearKeys) {
    this.active = active || false;
    this.element = element || null;
    this.clearKeys = clearKeys || null;
};

angularAMD.directive("dateToggle", [
    "$sce",
    "baseUrlsFactory",
    "multiLanguageService",
    "$timeout",
    "datePickerHelper",
    function ($sce, baseUrlsFactory, multiLanguageService, $timeout, datePickerHelper) {
        return {
            restrict: "E",
            template: template,
            scope: {
                after: "=",
                before: "=",
                dateLabels: "=", //Object - {after: 'After', before: 'Before'}
                optionLabels: "=", //Optional - {after: 'Modified After', before: 'Modified Before', range: 'Date Range'}
                singleSelection: "@"
            },

            controller: [
                "$scope",
                function ($scope) {
                    //Initialise variables ---------------------------------------------------------------------------------
                    var activeClass = "active";
                    $scope.multiLanguageService = multiLanguageService;

                    $scope.dateAfter = "after";
                    $scope.dateBefore = "before";
                    $scope.toggles = {
                        after: new ToggleOption(false, null, ["before"]),
                        before: new ToggleOption(false, null, ["after"]),
                        both: new ToggleOption(false)
                    };
                    $scope.viewData = {
                        images: {
                            beforeDate: $sce.getTrustedResourceUrl(baseUrlsFactory.shared_component_base_url + "dateToggle/images/beforeDate.svg"),
                            afterDate: $sce.getTrustedResourceUrl(baseUrlsFactory.shared_component_base_url + "dateToggle/images/afterDate.svg"),
                            dateRange: $sce.getTrustedResourceUrl(baseUrlsFactory.shared_component_base_url + "dateToggle/images/dateRange.svg")
                        }
                    };
                    $scope.lastToggle = null;
                    $scope.minDate = null;

                    //Functions --------------------------------------------------------------------------------------------
                    $scope.toggleDateSelection = function (toggleObj) {
                        //Clear values based off keys.
                        if (toggleObj.clearKeys) {
                            $timeout(function () {
                                clearDateValuesFromKeys(toggleObj.clearKeys);
                            });
                        } else {
                            $timeout(function () {
                                updateAllDates();
                            });
                        }

                        if ($scope.lastToggle) {
                            //Reset last toggle
                            $scope.lastToggle.active = false;
                            $scope.lastToggle.element.removeClass(activeClass);
                        }

                        //Make toggle active.
                        toggleObj.active = true;
                        toggleObj.element.addClass(activeClass);
                        $scope.lastToggle = toggleObj;
                    };

                    $scope.resetToggles = function () {
                        var item;
                        for (var toggle in $scope.toggles) {
                            if ($scope.toggles.hasOwnProperty(toggle)) {
                                item = $scope.toggles[toggle];
                                item.active = false;
                            }
                        }
                        if ($scope.lastToggle) {
                            $scope.lastToggle.element.removeClass(activeClass);
                            $scope.lastToggle = null;
                        }
                        clearDates();
                    };

                    //Private Functions ------------------------------------------------------------------------------------
                    var clearDateValuesFromKeys = function (keys) {
                        for (var i = 0, l = keys.length, key; i < l; i++) {
                            //Clear date value
                            key = keys[i];
                            if ($scope[key] !== undefined) {
                                $scope[key] = null;
                            }
                        }
                        $scope.minDate = null;
                        updateAllDates();
                    };

                    var clearDates = function () {
                        $scope.after = null;
                        $scope.before = null;
                        $scope.minDate = null;
                        dateUpdated($scope.dateAfter, $scope.after);
                        dateUpdated($scope.dateBefore, $scope.before);
                    };

                    var dateUpdated = function (type, value) {
                        $scope.$emit("datesUpdated", [
                            {
                                type: type,
                                value: value
                            }
                        ]);
                    };

                    var updateAllDates = function () {
                        $scope.$emit("datesUpdated", [
                            { type: $scope.dateAfter, value: $scope.after },
                            { type: $scope.dateBefore, value: $scope.before }
                        ]);
                    };

                    var onDateChange = function (event, type, value) {
                        if (event) {
                            event.stopPropagation();
                        }
                        dateUpdated(type, value);
                    };

                    //EVENTS -----------------------------------------------------------------------------------------------
                    $scope.$on("calendarCleared" + $scope.dateAfter, function (event, value) {
                        $scope.minDate = $scope.after = null;
                        onDateChange(event, $scope.dateAfter, value);
                    });
                    $scope.$on("calendarCleared" + $scope.dateBefore, function (event, value) {
                        $scope.before = null;
                        onDateChange(event, $scope.dateBefore, value);
                    });

                    $scope.afterUpdate = function (date) {
                        $scope.after = date;
                        $scope.minDate = datePickerHelper.dateAdd($scope.after, "day", 1);
                        if ($scope.after !== null) {
                            onDateChange(null, $scope.dateAfter, $scope.after);
                        }
                    };

                    $scope.beforeUpdate = function (date) {
                        $scope.before = date;
                        if ($scope.before !== null) {
                            onDateChange(null, $scope.dateBefore, $scope.before);
                        }
                    };
                }
            ],

            link: function (scope, element, attr) {
                //Get Elements
                scope.toggles.after.element = angular.element(element[0].getElementsByClassName("date-toggle__after")[0]);
                scope.toggles.before.element = angular.element(element[0].getElementsByClassName("date-toggle__before")[0]);
                scope.toggles.both.element = angular.element(element[0].getElementsByClassName("date-toggle__both")[0]);

                $timeout(function () {
                    if (scope.after && scope.before) {
                        scope.toggleDateSelection(scope.toggles.both);
                    } else if (scope.after) {
                        scope.minDate = scope.after;
                        scope.toggleDateSelection(scope.toggles.after);
                    } else if (scope.before) {
                        scope.toggleDateSelection(scope.toggles.before);
                    }
                    scope.$emit("dateToggleLoaded", true);
                }, 250);

                scope.$on("$destroy", function () {
                    scope.after = null;
                    scope.before = null;
                    scope.minDate = null;
                });
            }
        };
    }
]);
