import { angularAMD } from "@pebblepad/amd";
import "../../../multiLanguageService/multiLanguageService";
import "../../../extensions/arrayExtensions";
import "../../../share/services/shareService";
import "../../../modal/services/modal";
import "../submissionInfoView/submissionInfoView.directive";
import "../../../multiLanguageService/multiLanguageService";
import "../../../assetsWithLinks/directives/assetsWithLinks";
import "../../../share/directives/shareSelectExternals.directive";
import "../../../utilities/pebbleDate";
import "../../../react2angular/collapsibleInformation";
import template from "./templates/submissions.html";
import { tracker } from "@pjs/analytics";

angularAMD.directive("sidebarSubmissions", [
    "$http",
    "$sce",
    "$rootScope",
    "$compile",
    "$window",
    "baseUrlsFactory",
    "shareService",
    "modal",
    "multiLanguageService",
    function ($http, $sce, $rootScope, $compile, $window, baseUrlsFactory, shareService, modal, multiLanguageService) {
        return {
            template: template,
            restrict: "E",
            scope: {
                asset: "="
            },
            controller: [
                "$scope",
                function ($scope) {
                    // SVG Images
                    $scope.imageWorkspace = $sce.getTrustedResourceUrl(baseUrlsFactory.shared_component_base_url + "images/sharing/workspace48.svg");
                    $scope.imageAssignment = $sce.getTrustedResourceUrl(baseUrlsFactory.shared_component_base_url + "images/sharing/assignment48.svg");

                    $scope.multiLanguageService = multiLanguageService;

                    $scope.confirmMessage = $scope.multiLanguageService.getString("sidebar.submissions.about_to_ask_remove_submission_message");

                    $scope.shareLimit = 5;
                    var submissionToRemove;
                    $scope.expandedAssessorListState = {};

                    $scope.workspaceSharingInfoProps = {
                        header: $scope.multiLanguageService.getString("sidebar.submissions.workspace_sharing_info.header"),
                        content: $scope.multiLanguageService.getString("sidebar.submissions.workspace_sharing_info.content"),
                        isOpen: false,
                        onToggle: () => {
                            $scope.workspaceSharingInfoProps.isOpen = !$scope.workspaceSharingInfoProps.isOpen;
                            $scope.$applyAsync();
                        }
                    };

                    $scope.toggleAssessorListView = function (id) {
                        $scope.expandedAssessorListState[id] = !$scope.expandedAssessorListState[id];
                    };
                    $scope.showAllSubmissions = function () {
                        $scope.shareLimit = $scope.asset.Submissions.length;
                    };

                    $scope.requestRemoval = function (submission) {
                        submissionToRemove = submission;

                        modal.newModal(
                            {
                                scope: $scope,
                                templateUrl: baseUrlsFactory.shared_component_base_url + "assetViews/sidebar/submissions/templates/confirmmessage.html"
                            },
                            true
                        );
                    };

                    $scope.doRequestRemoval = function () {
                        shareService.removeSubmission(submissionToRemove.Id);
                        $scope.asset.Submissions[$scope.asset.Submissions.indexOf(submissionToRemove)].Status = "removal_requested";
                        submissionToRemove = null;
                    };

                    var statusMap = {
                        accepted: "success",
                        submitted: "success",
                        moved: "neutral",
                        removal_requested: "neutral",
                        rejected: "danger",
                        pending: "neutral"
                    };

                    var statusLang = {
                        accepted: $scope.multiLanguageService.getString("sidebar.submissions.accepted"),
                        submitted: $scope.multiLanguageService.getString("sidebar.submissions.submitted"),
                        moved: $scope.multiLanguageService.getString("sidebar.submissions.moved"),
                        removal_requested: $scope.multiLanguageService.getString("sidebar.submissions.removal_requested"),
                        rejected: $scope.multiLanguageService.getString("sidebar.submissions.rejected"),
                        pending: $scope.multiLanguageService.getString("sidebar.submissions.pending"),
                        removedbyowner: $scope.multiLanguageService.getString("sidebar.submissions.removedbyowner"),
                        removedbytutor: $scope.multiLanguageService.getString("sidebar.submissions.removedbytutor")
                    };

                    $scope.getStatusClass = function (status) {
                        if (!status || !statusMap[status.toLowerCase()]) {
                            return "danger";
                        }
                        return statusMap[status.toLowerCase()];
                    };

                    $scope.getStatusText = function (status) {
                        if (statusLang[status.toLowerCase()] !== void 0) {
                            return statusLang[status.toLowerCase()];
                        }

                        return $scope.multiLanguageService.getString("sidebar.submissions.unknown");
                    };

                    $scope.removeLocalAssessor = function (submission, assessorIndex) {
                        submission.ExternalAssessors.removeOne(assessorIndex);
                    };
                    $scope.editExternal = function (user, submission) {
                        var modalScope = $scope.$new(true);
                        modalScope.modalTitle = $scope.multiLanguageService.getString("sharing.header.share_with_external_assessor");
                        modalScope.modalIcon = "share-to-people";
                        modalScope.multiLanguageService = $scope.multiLanguageService;
                        modalScope.assetId = $scope.asset.Id;
                        modalScope.submissionId = submission.Id;
                        modalScope.workspaceId = submission.Location;

                        modalScope.onSubmit = function (externalAssessorShare) {
                            var externalShareUpdate = {
                                UserId: externalAssessorShare.UserIds[0],
                                SubmissionId: externalAssessorShare.SubmissionId,
                                WorkspaceId: externalAssessorShare.WorkspaceId,
                                Expiry: externalAssessorShare.Expiry
                            };

                            return shareService.updateExternalAssessorShare(externalShareUpdate);
                        };

                        modalScope.expiryDate = user.ExpiryDate ? new Date(user.ExpiryDate) : null;
                        modalScope.users = [user];

                        modalScope.onClose = function () {
                            modalScope.$destroy();
                        };

                        var shareModal = modal.newModal({
                            scope: modalScope,
                            templateUrl: baseUrlsFactory.shared_component_base_url + "share/templates/external-assessor-modal.html"
                        });

                        modalScope.onComplete = function (externals, expiryDate, cancelled) {
                            if (!cancelled) {
                                user.ExpiryDate = expiryDate;
                            }

                            shareModal.hideModal();
                        };
                    };

                    $scope.removeAssessor = function (assessor, submission, assessorIndex) {
                        var modalScope = $scope.$new(true);
                        modalScope.on_okay_fn = function () {
                            shareService.removeSLA(assessor.UserId, submission.Id).then(function () {
                                $scope.removeLocalAssessor(submission, assessorIndex);
                            });
                        };

                        modalScope.title = $scope.multiLanguageService.getString("sharing.header.remove_external_share");
                        modalScope.message = $scope.multiLanguageService.getString("sharing.labels.remove_external_share");
                        modalScope.okay_button_label = $scope.multiLanguageService.getString("buttons.delete");
                        modalScope.cancel_button_label = $scope.multiLanguageService.getString("buttons.cancel");

                        modalScope.onClose = function () {
                            modalScope.$destroy();
                        };

                        modal.newModal({
                            scope: modalScope,
                            templateUrl: baseUrlsFactory.shared_component_base_url + "modalDialogComponent/templates/generic-pop-up-with-buttons.html"
                        });
                    };

                    $scope.showRemovalButton = function (submission) {
                        return submission && submission.Status.toLowerCase() === "accepted" && submission.PreventUserFromRequestingSubmissionRemoval !== true;
                    };

                    $scope.isDefaultAssignment = function (submission) {
                        return submission.SubLocationName && submission.SubLocationName.indexOf("Assignment #1") > -1;
                    };

                    $scope.triggerRequestRemoval = function (e, submission) {
                        if (e.keyCode === 13 || e.keyCode === 32) {
                            $scope.requestRemoval(submission);
                        }
                    };

                    $scope.goToSubmissionLink = function (link) {
                        tracker.trackEvent("Workspaces shared to", "Click", "Go to assessor view");
                        $window.open(link, "_blank");
                    };
                }
            ],
            link: function (scope, element, attrs) {
                //console.log(scope.asset.Submissions[0]);
            }
        };
    }
]);
