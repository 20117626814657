import { angularAMD } from "@pebblepad/amd";
import { ASSET_CONSTANTS } from "../../constants/asset.constants";
import "../../multiLanguageService/multiLanguageService";
import "../../modal/services/modal";
import "../../share/services/shareService";
import "../../utilities/baseUrlsFactory";
import "../../builder/dataManager/dataManagerService";

angularAMD.factory("workbookService", [
    "$filter",
    "$rootScope",
    "$sce",
    "$location",
    "$q",
    "multiLanguageService",
    "modal",
    "shareService",
    "baseUrlsFactory",
    "dataManagerService",
    "StatusToolbarFactory",
    "StatusToolbar",
    function ($filter, $rootScope, $sce, $location, $q, multiLanguageService, modal, shareService, baseUrlsFactory, dataManagerService, statusToolbarFactory, statusToolbar) {
        return {
            createCriteriaString: function (collectionDto) {
                var resultString = "";
                var spacer = " | ";

                if (collectionDto.Search.ResponsesTo !== null && collectionDto.Search.ResponsesTo !== undefined) {
                    resultString += multiLanguageService.getString("collections.criteria.stringText.responses_to", { title: collectionDto.Search.ResponsesTo.Title });
                } else {
                    // add the asset type
                    resultString += this.getTypeFromCriteria(collectionDto);
                }

                // if keyword list exists add them
                if (collectionDto.Search.Keywords && collectionDto.Search.Keywords.length > 0) {
                    resultString +=
                        spacer +
                        multiLanguageService.getString("collections.criteria.stringText.keywords", {
                            count: collectionDto.Search.Keywords.length,
                            keywords: collectionDto.Search.Keywords
                        });
                }

                // if tags exist add them
                if (collectionDto.Search.Tags && collectionDto.Search.Tags.length > 0) {
                    resultString += spacer + multiLanguageService.getString("collections.criteria.stringText.tagged", { tags: collectionDto.Search.Tags });
                }

                // if created after exists
                if (collectionDto.Search.CreatedAfter) {
                    resultString +=
                        spacer + multiLanguageService.getString("collections.criteria.stringText.created_after", { createdAfter: $filter("pebbleDate")(collectionDto.Search.CreatedAfter.DateValue) });
                }

                // if created before exists
                if (collectionDto.Search.CreatedBefore) {
                    resultString +=
                        spacer +
                        multiLanguageService.getString("collections.criteria.stringText.created_before", { createdBefore: $filter("pebbleDate")(collectionDto.Search.CreatedBefore.DateValue) });
                }

                if (collectionDto.Search.EnableSearchByDefault) {
                    resultString += spacer + multiLanguageService.getString("collections.criteria.stringText.search_enabled");
                }

                return resultString;
            },

            getTypeFromCriteria: function (collectionDto) {
                var type = collectionDto.Search.SubType || collectionDto.Filter.Category;

                if (!type) {
                    // if null or undefined then the search is for all assets
                    return multiLanguageService.getString("pebble_terms.asset_categories.all_assets");
                }

                var dict = {
                    activitylog: multiLanguageService.getString("builder.workbook.asset_names.activity_log"),
                    assetcollectionwrapper: multiLanguageService.getString("builder.workbook.asset_names.collection"),
                    blog: multiLanguageService.getString("builder.workbook.asset_names.blog"),
                    post: multiLanguageService.getString("builder.workbook.asset_names.post"),
                    webfolio: multiLanguageService.getString("builder.workbook.asset_names.portfolio"),
                    webfoliopage: multiLanguageService.getString("builder.workbook.asset_names.page"),
                    workbookresponse: multiLanguageService.getString("builder.workbook.asset_names.workbook"),
                    worksheet: multiLanguageService.getString("builder.workbook.asset_names.worksheet"),
                    experience: multiLanguageService.getString("builder.workbook.asset_names.experience"),
                    plan: multiLanguageService.getString("builder.workbook.asset_names.plan"),
                    reflection: multiLanguageService.getString("builder.workbook.asset_names.reflection"),
                    talent: multiLanguageService.getString("builder.workbook.asset_names.talent"),
                    file: multiLanguageService.getString("builder.workbook.asset_names.file"),
                    image: multiLanguageService.getString("builder.workbook.asset_names.image"),
                    video: multiLanguageService.getString("builder.workbook.asset_names.video"),
                    audio: multiLanguageService.getString("pebble_terms.asset_types.audio", { count: 1 }),
                    "document/pdf": multiLanguageService.getString("pebble_terms.asset_types.documentpdf", { count: 1 }),
                    documents: multiLanguageService.getString("pebble_terms.asset_categories.documents"),
                    otherfiles: multiLanguageService.getString("pebble_terms.asset_categories.other_files"),
                    allassets: multiLanguageService.getString("pebble_terms.asset_categories.all_assets")
                };

                var foundType = type ? dict[type.toLowerCase()] : "";
                return foundType || type;
            },

            onLoadAutoSubmitModal: function (response) {
                var isolatedScope = $rootScope.$new(true);
                isolatedScope.goToResourceStore = function () {
                    $location.path("store/").search({ type: "resource" });
                };
                return shareService.getSubmissionLocationDetails(response.Asset.AssignmentId).then(
                    function (response) {
                        isolatedScope.workspaceAssignmentBlockUrl = baseUrlsFactory.shared_component_base_url + "share/templates/workspace-assignment-deadline-blocks.html";
                        isolatedScope.imageSharedBy = $sce.getTrustedResourceUrl(baseUrlsFactory.shared_component_base_url + "images/sharing/sharedBy.svg");
                        isolatedScope.imageAssignment = $sce.getTrustedResourceUrl(baseUrlsFactory.shared_component_base_url + "images/sharing/assignment.svg");
                        isolatedScope.imageWorkspace = $sce.getTrustedResourceUrl(baseUrlsFactory.shared_component_base_url + "images/sharing/workspace.svg");
                        isolatedScope.imageSharedAfter = $sce.getTrustedResourceUrl(baseUrlsFactory.shared_component_base_url + "images/sharing/sharedAfter.svg");
                        isolatedScope.imageRevisedUntil = $sce.getTrustedResourceUrl(baseUrlsFactory.shared_component_base_url + "images/sharing/revisedUntil.svg");

                        isolatedScope.selectedAssignment = response.data;
                        isolatedScope.selectedAssignment.IsAutoSubmitInfo = true;
                        isolatedScope.showTerms = false;
                        isolatedScope.submissionCountInfo = multiLanguageService.getString("sharing.labels.submission_count_info", {
                            user_num_shares: isolatedScope.selectedAssignment.UserSubmissionCount,
                            total_num_shares: isolatedScope.selectedAssignment.SubmissionsPerUser,
                            count: isolatedScope.selectedAssignment.UserSubmissionCount
                        });

                        isolatedScope.onClose = function () {
                            isolatedScope.$destroy();
                        };

                        modal.newModal(
                            {
                                scope: isolatedScope,
                                templateUrl: baseUrlsFactory.shared_component_base_url + "workbook/workbookFiller/templates/auto-submit-warning-modal.html",
                                disableClose: true
                            },
                            true
                        );
                    },
                    function (rejection) {
                        var deferred = $q.defer();

                        isolatedScope.title = multiLanguageService.getString("workbook.error_modals.generic_title");
                        isolatedScope.message = multiLanguageService.getString("workbook.error_modals.unable_get_response.message");
                        isolatedScope.button_label = multiLanguageService.getString("buttons.confirm");
                        isolatedScope.onClose = function () {
                            deferred.reject();
                            isolatedScope.goToResourceStore();
                            isolatedScope.$destroy();
                        };

                        modal.newModal(
                            {
                                scope: isolatedScope,
                                templateUrl: modal.genericTemplates.popup
                            },
                            true
                        );

                        return deferred.promise;
                    }
                );
            },

            isPageUserDefined: function (page) {
                var isUserDefined = false;

                switch (page.PageType) {
                    case ASSET_CONSTANTS.TYPES.ACTIVITY_LOG:
                        var extraInfo = page.ExtraInfo;
                        isUserDefined = extraInfo && !(extraInfo.TargetHours || extraInfo.TargetPoints) && page.ExtraInfo.UserCreatedCollection;
                        break;
                    case ASSET_CONSTANTS.TYPES.COLLECTION_WRAPPER:
                        isUserDefined = page.ExtraInfo && page.ExtraInfo.UserCreatedCollection;
                        break;
                    case ASSET_CONSTANTS.SPECIAL_TYPES.APPLICANT:
                    case ASSET_CONSTANTS.TYPES.COLLECTION:
                        isUserDefined = false;
                        break;
                    default:
                        isUserDefined = true;
                        break;
                }

                return !!isUserDefined;
            },

            isTemplatePage: function (page) {
                return page.PageType === ASSET_CONSTANTS.TYPES.FORM || page.PageType === ASSET_CONSTANTS.TYPES.FORM_RESPONSE;
            },

            displayRemovalModal: function (assetId, action, isRemove) {
                var manager = dataManagerService.getDto(assetId);
                var isolatedScope = $rootScope.$new(true);
                isolatedScope.title = isRemove === true ? multiLanguageService.getString("buttons.remove") : multiLanguageService.getString("buttons.replace");
                isolatedScope.confirm_label = multiLanguageService.getString("buttons.confirm");
                isolatedScope.cancel_label = multiLanguageService.getString("buttons.cancel");
                manager.data.Title !== "" ? (isolatedScope.assetTitle = manager.data.Title) : (isolatedScope.assetTitle = multiLanguageService.getString("labels.pop_ups.this_asset"));

                isolatedScope.messages = [
                    multiLanguageService.getString("labels.pop_ups.are_you_sure", {
                        actionName: isolatedScope.title,
                        assetTitle: isolatedScope.assetTitle
                    })
                ];

                isolatedScope.buttons = [
                    {
                        action: action,
                        style: "btn-success",
                        text: isolatedScope.confirm_label
                    },
                    {
                        action: angular.noop, // needs to be a function but modal closes by default on button press
                        style: "btn-success-alt",
                        text: isolatedScope.cancel_label
                    }
                ];

                isolatedScope.onClose = function () {
                    isolatedScope.$destroy();
                };

                modal.newModal({
                    scope: isolatedScope,
                    templateUrl: baseUrlsFactory.shared_component_base_url + "modalDialogComponent/templates/generic-pop-up-with-repeating-content.html",
                    disableClose: true
                });
            },

            displayPlaceholderPageStatus: function (pageType, onReplace, onRemove, onEdit) {
                const message = `
                    <span class="text--bold" aria-hidden="true">${multiLanguageService.getString("tips.placeholder_page.actions")}</span>
                    <span class="screen-reader-only">${multiLanguageService.getString("tips.placeholder_page.action_bar_region", { type: pageType })}</span>
                `;

                const statusDictionary = statusToolbarFactory.createStatusDictionary(message, "", "", "");
                const content = [
                    {
                        text: multiLanguageService.getString("buttons.replace_caps_first_char"),
                        onClick: onReplace,
                        ariaLabel: multiLanguageService.getString("tips.placeholder_page.replace_chosen_type", { type: pageType }),
                        dataHook: "placeholder-replace-asset-btn"
                    },
                    {
                        text: multiLanguageService.getString("buttons.remove"),
                        onClick: onRemove,
                        ariaLabel: multiLanguageService.getString("tips.placeholder_page.remove_chosen_type", { type: pageType }),
                        dataHook: "placeholder-remove-asset-btn"
                    },
                    {
                        text: multiLanguageService.getString("buttons.edit"),
                        onClick: onEdit,
                        ariaLabel: multiLanguageService.getString("tips.placeholder_page.edit_chosen_type", { type: pageType }),
                        dataHook: "placeholder-edit-asset-btn"
                    }
                ];
                const status = statusToolbarFactory.createStatus(statusDictionary, false, Infinity, null, null, null, null, content);
                statusToolbar.addActionStatus(status);
                return () => statusToolbar.removeActionStatus(status);
            },

            focusPlaceholderReplaceButton: function () {
                statusToolbar.focusElement("placeholder-replace-asset-btn");
            },

            launchPageUpdateErrorModal: function (onClose) {
                const model = {
                    button_label: multiLanguageService.getString("buttons.ok"),
                    message: multiLanguageService.getString("workbook.error_modals.unable_to_update.message"),
                    onClose: onClose,
                    title: multiLanguageService.getString("workbook.error_modals.generic_title")
                };

                return modal.launch(`${baseUrlsFactory.shared_component_base_url}modalDialogComponent/templates/generic-pop-up.html`, model);
            }
        };
    }
]);
