import { angularAMD } from "@pebblepad/amd";
import { withEditorFallback } from "../../withEditorFallback/WithEditorFallback.function";
import { richTextEditorComponentDefinition } from "../components/richTextEditor.component";
import { stickyPersistentCompactRichTextEditorDefinition } from "../../persistentCompactRichTextEditor/stickyPersistentCompactRichTextEditor.component";

const component = withEditorFallback(
    "stickyPersistentCompactMultiLineEditor",
    {
        legacyEditor: richTextEditorComponentDefinition,
        editor: stickyPersistentCompactRichTextEditorDefinition
    },
    false,
    "compact-fallback-editor",
    "legacy-compact-editor"
);
angularAMD.component(component.name, component);
