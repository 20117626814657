import { angularAMD } from "@pebblepad/amd";
import "../utilities/baseUrlsFactory";
import "../a11y/services/a11yMenuFactory.service";
import "../multiLanguageService/multiLanguageService";
import template from "./navigation-menu.html";

angularAMD.directive("navigationMenu", [
    "$location",
    "$sce",
    "baseUrlsFactory",
    "$routeParams",
    "$interval",
    "$timeout",
    "A11yMenuFactory",
    "multiLanguageService",
    function ($location, $sce, baseUrlsFactory, $routeParams, $interval, $timeout, A11yMenuFactory, multiLanguageService) {
        return {
            template: template,
            restrict: "E",
            scope: {
                pages: "=", //List of pages to display and navigate with
                preventLocationSearch: "=" //Prevent navigation menu from using location.search
            },
            controller: [
                "$scope",
                function ($scope) {
                    $scope.multiLanguageService = multiLanguageService;

                    $scope.componentLang = {
                        navAriaLabel: $scope.multiLanguageService.getString("asset_store.navigation.navigation_menu"),
                        pageArias: {},
                        navItemAriaLabel: function (page) {
                            var isNested = page.subpages && page.subpages.length > 1;
                            var isActive = $scope.activePage.type === page.type;
                            if (isNested) {
                                var dictionaryLookup = isActive ? "asset_store.navigation.external_stores_current_page" : "asset_store.navigation.external_stores";
                                return $scope.multiLanguageService.getString(dictionaryLookup, {
                                    currentStoreName: page.name
                                });
                            }
                            if (isActive) {
                                return $scope.multiLanguageService.getString("asset_store.navigation.title_current_page", {
                                    pageTitle: page.name
                                });
                            }
                            return `${page.name}. ${$scope.multiLanguageService.getString("labels.cards.page_change_alert")}`;
                        },
                        viewItemAriaLabel: function (page) {
                            return $scope.multiLanguageService.getString("asset_store.accessibility.view", {
                                pageTitle: page.name
                            });
                        },
                        update: function () {
                            for (var i = 0; i < $scope.pages.length; i++) {
                                var page = $scope.pages[i];
                                var text = $scope.componentLang.navItemAriaLabel(page);
                                $scope.componentLang.pageArias[page.type] = text;
                            }
                        }
                    };
                }
            ],
            link: function (scope, element, attrs) {
                scope.flattenedPages = [];

                var pagesLength = scope.pages.length;

                scope.setActivePage = function (index) {
                    scope.activePage = scope.pages[index];
                    scope.componentLang.update();

                    if (!scope.preventLocationSearch) {
                        $location.search("type", scope.activePage.type).replace();
                    }

                    scope.$emit("setActivePage", scope.activePage);
                };

                scope.$on("setActivePageByType", function (event, data) {
                    setActivePageByType(data);
                });

                scope.setActivePageByType = function (pageType) {
                    setActivePageByType(pageType);
                };

                var setActivePageByType = function (type) {
                    if (type === null || type === undefined) {
                        return scope.setActivePage(0);
                    }

                    for (var i = 0; i < scope.pages.length; i++) {
                        // tslint:disable-next-line:triple-equals
                        if (scope.pages[i].type == type) {
                            return scope.setActivePage(i);
                        }

                        if (scope.pages[i].subpages) {
                            for (var subIndex = 0; subIndex < scope.pages[i].subpages.length; subIndex++) {
                                // tslint:disable-next-line:triple-equals
                                if (scope.pages[i].subpages[subIndex].type == type) {
                                    scope.pages[i].name = scope.pages[i].subpages[subIndex].name;
                                    scope.pages[i].type = scope.pages[i].subpages[subIndex].type;
                                    return scope.setActivePage(i);
                                }
                            }
                        }
                    }
                    return scope.setActivePage(0);
                };

                var nav_el = angular.element(element[0].getElementsByClassName("store-navigation")[0]);
                nav_el.bind("focus", scope.addRoleMenuKeyBindings);

                scope.$watchCollection("pages", function (newVal) {
                    if (newVal && newVal.length > 0) {
                        scope.flattenedPages = [];

                        for (var i = 0; i < newVal.length; i++) {
                            var page = newVal[i];

                            if (page.subpages) {
                                for (var s = 0; s < page.subpages.length; s++) {
                                    var subpage = page.subpages[s];
                                    scope.flattenedPages.push({
                                        name: subpage.name,
                                        type: subpage.type,
                                        hook: subpage.hook
                                    });
                                }
                            } else {
                                scope.flattenedPages.push({
                                    name: page.name,
                                    type: page.type,
                                    hook: page.hook
                                });
                            }
                        }

                        scope.addItemsClass();

                        if (nav_el && !scope.roleMenu) {
                            var list_el = nav_el[0].getElementsByClassName("list")[0];

                            if (list_el) {
                                $timeout(function () {
                                    scope.roleMenu = A11yMenuFactory.createMenubar(nav_el, angular.element(list_el.querySelector('[role="menuitem"]')));
                                    scope.roleMenu.configureMenu();
                                });
                            }
                        }

                        scope.componentLang.update();
                    }
                });

                scope.addItemsClass = function () {
                    nav_el.removeClass("items-" + pagesLength);
                    pagesLength = scope.pages.length;
                    nav_el.addClass("items-" + pagesLength);
                };

                setActivePageByType($routeParams.type);

                var removeUpdateActive = scope.$on("updateActivePage", function (event, data) {
                    if (data) {
                        setActivePageByType(data);
                    } else {
                        setActivePageByType($routeParams.type);
                    }
                });

                scope.$on("$destroy", function () {
                    removeUpdateActive();

                    if (scope.roleMenu) {
                        scope.roleMenu.unconfigureMenu();
                    }
                });
            }
        };
    }
]);
